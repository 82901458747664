import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const AdminRoute = ({ component: Component, ...rest }) => {
  const [hasUser, setHasUser] = useState(false);
  const { isLogged, user } = useAuth();

  useEffect(() => {
    if (Object.keys(user).length) {
      setHasUser(true);
    }
  }, [user]);

  return (
    <>
      {hasUser && (
        <Route
          {...rest}
          render={() => {
            return isLogged && user.is_admin ? (
              <Component />
            ) : !user.is_admin ? (
              <Redirect to={{ pathname: '/projects' }} />
            ) : (
              (window.location.href = '/')
            );
          }}
        />
      )}
    </>
  );
};

export default AdminRoute;

import styled from 'styled-components';

export const Container = styled.div`
  .Tabtitle {
    font: normal normal 300 42px/55px IBM Plex Sans;
    font-weight: 700;
    color: #004e70;
    margin: 18px 0;
  }

  .sutitle {
    margin-bottom: 15px;
  }

  .option {
    margin-top: 8px;

    button {
      color: #6d6e71;
      background: transparent;
      text-decoration: underline;
      text-align: left;
    }
  }
`;

import React from 'react';
import { AuthProvider } from './auth';
import { LoaderProvider } from './loader';

const AppProvider = ({ children }) => (
  <LoaderProvider>
    <AuthProvider>{children}</AuthProvider>
  </LoaderProvider>
);

export default AppProvider;

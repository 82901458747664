import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    line-height: 1.7rem;
    text-align: left;
    width: 100%;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 994px) {
    h1 {
      text-align: center;
    }
  }
  .yellow {
    color: rgb(189, 147, 19);
  }
  .orange {
    color: rgb(172, 82, 11);
  }
  .red {
    color: rgb(138, 43, 31);
  }
  .green {
    color: rgb(50, 101, 86);
  }
  .purple {
    color: rgb(85, 39, 83);
  }
  .blue {
    color: rgb(16, 56, 92);
  }
  .modal-title {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .modal-text {
    margin-bottom: 1rem;
  }
`;

export const ModalArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 994px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .card-content {
      width: 100%;
    }
    .programa-yellow-card {
      width: 100%;
      max-width: 100% !important;
    }
    .programa-orange-card {
      width: 100%;
      max-width: 100% !important;
    }
    .programa-red-card {
      width: 100%;
      max-width: 100% !important;
    }
    .programa-green-card {
      width: 100%;
      max-width: 100% !important;
    }
    .programa-purple-card {
      width: 100%;
      max-width: 100% !important;
    }
    .programa-blue-card {
      width: 100%;
      max-width: 100% !important;
    }
  }
  div {
    margin-top: 20px;
  }
  .margin {
    margin-left: 50px;
  }
  .click {
    cursor: pointer;
  }
  .programa-card-footer {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 8px 8px;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    color: #fff;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .card-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 100px;
    padding: 10px 50px 0px 50px;
  }
  .programa-yellow-card {
    background-color: #bd9313;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
  .programa-orange-card {
    background-color: #ac5200;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
  .programa-red-card {
    background-color: #891f1f;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
  .programa-green-card {
    background-color: #106456;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
  .programa-purple-card {
    background-color: #520a53;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
  .programa-blue-card {
    background-color: #10385c;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }
  }
`;

export const Content = styled.div``;

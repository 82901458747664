import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/auth';
import PrintLoader from '../../../components/PrintLoader';
import DonutChart from '../../../components/DonutChart';
import MultiLine from './graphics/MultiLine';
import Bar from './graphics/Bar';
import Line from './graphics/Line';
import { printDocumentPage, getPageCanvas, formatDate } from './reportService';
import { IoIosArrowForward } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';
import {
  Container,
  Header,
  HeaderContent,
  Main,
  MainContent,
  PrintDiv,
  OperationCard,
  HeaderCard,
  Card,
  Title,
  GraphicsContainer,
  DonutContainer,
  ProjectContainer,
} from './styles';
import { Row, Column } from '../../../styles/components';

const ExecutiveReport3 = () => {
  const printRef = useRef(null);
  const { user } = useAuth();
  const { operationId } = useParams();
  const [reportData, setReportData] = useState({});
  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/credits/api/v1/report/credit-operation/${operationId}/`)
      .then(response => {
        setReportData(response.data);
        setLoading(false);
      });
  }, []);

  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  const multiLineData = useMemo(() => {
    let returnData = [];
    if (Object.keys(reportData).length) {
      returnData = [
        {
          percentage: reportData.status_critical.execution,
          color: '#5CB24B',
        },
        {
          percentage: reportData.status_critical.finish,
          color: '#014D90',
        },
        {
          percentage: reportData.status_critical.delay,
          color: '#EB5B23',
        },
        {
          percentage: reportData.status_critical.restriction,
          color: '#F7A643',
        },
        {
          percentage: reportData.status_critical.delay_restriction,
          color: '#C1856F',
        },
      ];
    }
    return returnData;
  }, [reportData]);

  const getMultilineData = useCallback(data => {
    return [
      {
        percentage: data.execution,
        color: '#5CB24B',
      },
      {
        percentage: data.finish,
        color: '#014D90',
      },
      {
        percentage: data.delay,
        color: '#EB5B23',
      },
      {
        percentage: data.restriction,
        color: '#F7A643',
      },
      {
        percentage: data.delay_restriction,
        color: '#C1856F',
      },
    ];
  }, []);

  const printDocument = useCallback(async () => {
    window.scrollTo(0, 0);
    setPrinting(true);
    setTimeout(async () => {
      const page = await getPageCanvas(printRef.current);
      const pdf = new jsPDF('pt', 'mm');
      await printDocumentPage(page, pdf, false);
      window.open(pdf.output('bloburl'));
      setPrinting(false);
    }, 500);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <PrintDiv printing={printing} ref={printRef}>
          {printing && <PrintLoader />}
          <Header>
            <HeaderContent printing={printing}>
              <div className="optionsContainer">
                <button
                  className="back"
                  type="button"
                  onClick={() => printDocument()}
                >
                  Imprimir
                </button>
              </div>
              <div className="content">
                <div className="textContent">
                  <h1>Relatório Executivo</h1>
                  <h2>Visão Por Operação</h2>
                </div>
                <OperationCard printing={printing}>
                  {`Operação de crédito: (${reportData.operation_entity.abbreviation} ${reportData.code}) ${reportData.operation_title}`}
                </OperationCard>
                <HeaderCard printing={printing}>
                  Data do Relatório:
                  <b>{`${formatDate(new Date(), 'dd/mm/YYYY')}`}</b> | Última
                  atualização:
                  <b>{`${formatDate(
                    new Date(reportData.last_update),
                    'dd/mm/YYYY',
                  )}`}</b>
                </HeaderCard>
                <Row>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      Órgão responsável:
                      <b>{reportData.manager_org}</b>
                    </HeaderCard>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      Instituição financiadora:
                      <b>{reportData.operation_entity.abbreviation}</b>
                    </HeaderCard>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      {/* Secretário(a): <b>{reportData.manager_name}</b> */}
                      Usuário(a): <b>{reportData.manager_name}</b>
                    </HeaderCard>
                  </Column>
                </Row>
              </div>
            </HeaderContent>
          </Header>
          <Main>
            <MainContent>
              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                {/* Visão geral do Projeto */}
                Visão geral da operação de crédito
              </Title>
              <Row>
                {/* <Column small="4" medium="4" large="4" className="mb0">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>Execução Física</h1>
                      <div className="graphs">
                        <DonutChart
                          value={toFixed(
                            reportData.execution.physics.opcredit,
                            2,
                          )}
                          color="#075E89"
                          size={150}
                          strokewidth={13}
                          type="percent"
                          large
                        />

                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={toFixed(
                              reportData.execution.physics.proseg,
                              2,
                            )}
                            color="#6D6E71"
                            size={50}
                            fontSize="15"
                            type="percent"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      </div>
                      <span>
                        Fórmula de cálculo: Total de marcos críticos concluídos
                        sobre o total de marcos críticos
                      </span>
                    </DonutContainer>
                  </Card>
                </Column> */}
                <Column small="6" medium="6" large="6" className="mb0">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>Execução Financeira</h1>
                      <div className="graphs">
                        <DonutChart
                          value={toFixed(
                            reportData.execution.finance.opcredit,
                            2,
                          )}
                          color="#075E89"
                          size={150}
                          strokewidth={13}
                          type="percent"
                          large
                        />

                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={toFixed(
                              reportData.execution.finance.proseg,
                              2,
                            )}
                            color="#6D6E71"
                            size={50}
                            fontSize="15"
                            strokewidth={7}
                            type="percent"
                          />
                          <small>Demais Estados</small>
                        </div>
                      </div>
                      <span>
                        Fórmula de cálculo: Valor pago sobre o valor total da
                        operação
                      </span>
                    </DonutContainer>
                  </Card>
                </Column>
                <Column small="6" medium="6" large="6" className="mb0">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>Tempo</h1>
                      <div className="graphs">
                        <DonutChart
                          value={toFixed(
                            reportData.execution.time_month.opcredit,
                            2,
                          )}
                          color="#075E89"
                          size={150}
                          strokewidth={13}
                          type="percent"
                          large
                        />
                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={toFixed(
                              reportData.execution.time_month.proseg,
                              2,
                            )}
                            color="#6D6E71"
                            size={50}
                            fontSize="15"
                            type="percent"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      </div>
                      <span>
                        Fórmula de cálculo: meses executados sobre o total de
                        meses
                      </span>
                    </DonutContainer>
                  </Card>
                </Column>
              </Row>

              <Card printing={printing}>
                <Row>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="border-dashed"
                  >
                    <GraphicsContainer>
                      <h1>Situação dos Marcos Críticos(%)</h1>
                      <MultiLine data={multiLineData} disableInfo={false} />
                    </GraphicsContainer>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="border-dashed"
                  >
                    <GraphicsContainer>
                      <h1>Execução Financeira</h1>
                      <div>
                        <div>
                          <span className="graphSpan">% Empenhado</span>
                          <Bar
                            percentage={toFixed(
                              reportData.execution_financial.settled,
                              2,
                            )}
                            color="#014D90"
                          />
                        </div>
                        <div>
                          <span className="graphSpan">% Liquidado</span>
                          <Bar
                            percentage={toFixed(
                              reportData.execution_financial.pawned,
                              2,
                            )}
                            color="#014D90"
                          />
                        </div>
                        <div>
                          <span className="graphSpan">% Pago</span>
                          <Bar
                            percentage={toFixed(
                              reportData.execution_financial.paid,
                              2,
                            )}
                            color="#014D90"
                          />
                        </div>
                      </div>
                    </GraphicsContainer>
                  </Column>
                  <Column small="4" medium="4" large="4">
                    <GraphicsContainer>
                      <h1>Índices de Desempenho</h1>
                      <Line
                        color="#014D90"
                        middleValue={0.6}
                        value={reportData.indices_performance_deadline}
                        small="Valor médio do PROSEG: 0,6"
                        title="IDP (Índice de Desempenho do Prazo)"
                      />
                      <Line
                        color="#014D90"
                        middleValue={0.8}
                        value={reportData.indices_performance_finance}
                        small="Valor médio do PROSEG: 0,8"
                        title="IDF (Índice de Desempenho Financeiro)"
                      />
                    </GraphicsContainer>
                  </Column>
                </Row>
              </Card>
              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                Visão por projeto
              </Title>
              {reportData.projects_operation.map((item, index) => (
                <Card printing={printing} key={`card-${index}`}>
                  <Row>
                    <Column small="12" medium="12" large="12" className="mb0">
                      <ProjectContainer>
                        <div className="mainContainer">
                          <h1>
                            <b>{item.title}</b>
                          </h1>
                          <div className="lineContainer">
                            <MultiLine
                              data={getMultilineData(item.status_critical)}
                              disableInfo
                            />
                          </div>
                        </div>
                        <Link
                          to={`/relatorio-executivo-3/${item.id}`}
                          className="buttonContainer"
                        >
                          <FaEye color="#00A9EE" size={18} />
                        </Link>
                      </ProjectContainer>
                    </Column>
                  </Row>
                </Card>
              ))}
            </MainContent>
          </Main>
        </PrintDiv>
      )}
    </Container>
  );
};

export default ExecutiveReport3;

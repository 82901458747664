import React, { useEffect, useCallback, useState } from 'react';
import api from '../../../../../services/api';
import { Row, Column } from '../../../../../styles/components';
import Loader from '../../../../../components/Loader';
import Categories from '../Categories';
import IndicatorCard from '../IndicatorCard';
import DiagnosticLengend from '../DiagnosticLengend';
import { Container, Title, SubTitle, IndicatorTitle } from './styles';

function IndicatorsPanel({
  diagnostico,
  setBreadCrumbActive,
  setDetailIndicator,
  tab,
  setTab,
}) {
  const [indicators, setIndicators] = useState([]);
  const [loadingIndicators, setLoadingIndicators] = useState(true);

  const getIndicators = useCallback(async url => {
    try {
      const response = await api.get(url);
      console.log({ url })
      console.log({ response })
      setIndicators(response.data.indicators);
      setLoadingIndicators(false);
    } catch (err) {
      setLoadingIndicators(false);
    }
  }, []);

  const setBreadCrumb = useCallback(
    (tabName, breadCrumbNumber, url) => {
      setTab(tabName);
      setBreadCrumbActive(breadCrumbNumber);
      if (breadCrumbNumber === 2)
        document.getElementById('IndicatorsPanel').scrollIntoView();
      getIndicators(url);
    },
    [setBreadCrumbActive, setTab, getIndicators],
  );

  const initIndicators = useCallback(() => {
    const filteredCategory = diagnostico.categories.filter(
      category => category.name === tab,
    )[0];
    setBreadCrumb(tab, 2, filteredCategory.indicators);
    document.getElementById('IndicatorsPanel').scrollIntoView();
  }, [diagnostico.categories, tab, setBreadCrumb]);

  const initDetailIndicator = useCallback(
    indicator => {
      setDetailIndicator(indicator);
      setBreadCrumbActive(4);
      document.getElementById('mainContent').scrollIntoView();
    },
    [setDetailIndicator, setBreadCrumbActive],
  );

  useEffect(() => {
    initIndicators();
    document.getElementById('IndicatorsPanel').scrollIntoView();
  }, [initIndicators]);

  return (
    <Container tab={tab}>
      <Title style={{ marginTop: '31px' }}>Painel de Indicadores</Title>
      <SubTitle style={{ marginBottom: '31px' }}>
        O dashboard abaixo apresenta o desempenho de seu estado/município em
        cada um dos indicadores selecionados, considerando a sua performance
        recente (série histórica) e alguns referenciais comparativos. Para
        visualizar as informações de cada indicador, clique em cima de cada
        painél
      </SubTitle>
      <Title>Acesse os painéis de indicadores clicando nos ícones abaixo</Title>
      <Row style={{ marginTop: '20px' }} className="TabsContainer">
        <Categories
          categories={diagnostico.categories}
          setTab={setBreadCrumb}
        />
        <Column large="12" medium="12" small="12">
          {/* <Link to="/metodologia/indicador-sintetico" className="redirectLink">
            Saiba mais sobre os indicadores selecionados
          </Link> */}
        </Column>
      </Row>
      <Row id="IndicatorsPanel">
        {loadingIndicators ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Column large="12" medium="12" small="12">
                <IndicatorTitle>{tab}</IndicatorTitle>
              </Column>
            </Row>
            <Row>
              {indicators.map(indicator => (
                <Column large="3" medium="4" small="12">
                  <IndicatorCard
                    indicator={indicator}
                    showButton
                    initDetailIndicator={initDetailIndicator}
                  />
                </Column>
              ))}
            </Row>
          </>
        )}
      </Row>

      <DiagnosticLengend diagnostico={diagnostico} />
    </Container>
  );
}

export default IndicatorsPanel;

import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  text-align: left;
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #0c9fd8;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const TitleText = styled.p`
  text-align: left;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  margin-bottom: 32px;
`;

export const Card = styled.section`
  background: var(--white);
  box-shadow: ${props => !props.printing && '0px 1px 6px #00000029'};
  border: ${props => props.printing && '1px solid #6d6e71'};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  margin: 30px 0;
`;

export const CardTitle = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  margin-bottom: 24.5px;
  border-bottom: ${props => props.color && `3px solid ${props.color}`};

  text-align: left;
  font: normal normal bold 20px/25px IBM Plex Sans;
  letter-spacing: 0px;
  color: ${props => props.color};

  svg {
    width: 43px;
    height: 43px;
    margin-bottom: 6px;
    margin-right: 12.47px;
    path {
      fill: ${props => props.color};
    }
  }
`;

export const CardItem = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const IndicatorCard = styled.div`
  width: 256px;
  height: 133px;
  text-align: left;
  font: normal normal bold 20px/25px IBM Plex Sans;
  letter-spacing: 0px;
  color: #007bac;
  margin-right: 21px;

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #075e89;
  border-radius: 5px;
  padding: 12px;

  .titleContainer {
    height: 72px;
    border-bottom: 1px solid #6d6e71;

    h1 {
      text-align: left;
      font: normal normal bold 15px/19px IBM Plex Sans;
      letter-spacing: 0px;
      color: #075e89;
      margin-bottom: -5px;
    }

    small {
      text-align: left;
      font: normal normal normal 11px/14px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .IconContent {
      display: flex;
    }
    .indicatorIcon {
      width: 33px;
      height: 33px;
      margin-right: 5px;

      border: 1px solid #30e861;
      border-radius: 8px;
      opacity: 1;
    }

    span {
      text-align: left;
      font: normal normal normal 12px/15px IBM Plex Sans;
      letter-spacing: 0px;
      color: #d0d0d0;
      opacity: 1;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 33px;
  height: 33px;

  border: ${props => `1px solid ${props.color}`};

  border-radius: 8px;
  opacity: 1;
  margin-right: 10px;

  svg {
    transform: ${props => (props.rotate ? `rotate(180deg)` : 'rotate(0deg)')};
    width: 20px;
    height: 20px;
    path {
      fill: ${props => props.color};
    }
  }
`;

export const IndicatorText = styled.div`
  text-align: left;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  width: 70%;
`;

export const OtherIndicators = styled.div`
  .title {
    text-align: left;
    font: normal normal bold 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ff534a;

    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const IndicatorItem = styled.div`
  height: 31px;
  margin-top: 23px;
  text-align: left;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  border-bottom: 1px solid #eaeaea;
`;

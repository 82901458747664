import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans !important;
    background: #075e89 !important;
    color: #ffffff !important;
    text-align: center !important;
    border-left: 1px solid #d0d0d0 !important;
  }

  .rdt_TableCell {
    text-align: left !important;
    font: normal normal normal 14px/18px IBM Plex Sans !important;
    letter-spacing: 0px !important;
    color: #6d6e71 !important;
    border-left: 1px solid #d0d0d0 !important;
    border-bottom: 1px solid #d0d0d0 !important;
    min-height: 44px !important;

    & > div {
      white-space: inherit !important;
    }
  }
`;

export const StatusSpan = styled.span`
  color: ${props => props.color && `var(--${props.color})`};
  text-align: center;
  font: normal normal bold 12px/15px IBM Plex Sans;
  letter-spacing: 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SearchButton = styled.button`
  background: var(--green) 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 7px;
  padding: 6px;
  display: flex;
  align-items: center;
  transition: background 0.3s ease;

  &:hover {
    background: #33791e;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 25px;

  & > div {
    flex: 1;
    height: 21px;
    border-radius: 4px;
    background: var(--gray);
    position: relative;
    transition: all 0.5s ease;
    display: flex;
  }

  .info-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background: #ffffff;
  }
`;

export const BarItem = styled.div`
  min-width: 5%;
  width: ${props => `${props.percentage}%`};
  background: ${props => props.color};

  height: 24px;
  transition: all 0.5s ease;

  display: flex;
  align-self: center;
  justify-content: center;

  span {
    display: flex;
    align-self: center;
    justify-content: center;

    font: normal normal bold 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font: normal normal normal 12px/15px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;

  div {
    width: 15px;
    height: 15px;
    background: ${props => props.color};
    margin-right: 5px;
  }
`;

export const GraphContainer = styled.section`
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  margin-bottom: 15px;

  .nameContainer {
    width: 32%;

    text-align: right;
    font: normal normal normal 12px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    margin-right: 5px;
  }

  .barContainer {
    display: flex;
    width: 68%;
    background: #e8e8e8;
  }
`;

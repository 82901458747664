import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, AsideMenu } from './styles';
import Logo from '../../../assets/logo_plataforma_novo.png';
import { useCreditOperation } from '../../../hooks/creditOperation';
import { FaHome } from 'react-icons/fa';
import { MdForum } from 'react-icons/md';
import { ReactComponent as Book } from '../../../assets/book.svg';
import { FiMonitor } from 'react-icons/fi';
import { FaStickyNote } from 'react-icons/fa';
import { FaUsersCog } from 'react-icons/fa';

const Aside = ({ setMenu, user }) => {
  const { creditOperation } = useCreditOperation();
  const [hasCreditOperation, setHasCreditOperation] = useState(false);

  useEffect(() => {
    if (Object.keys(creditOperation).length) {
      setHasCreditOperation(true);
    }
  }, [creditOperation]);

  return (
    <Container
      active={!!(window.location.pathname === '/projects/diagnostico/')}
    >
      <div className="aside_close-icon" onClick={setMenu}>
        <strong>&times;</strong>
      </div>
      <div className="logo-container">
        <img src={Logo} alt="plataforma de segurança" />
      </div>
      <ul className="menu-aside-links">
        {/* <li className="icon-link">
          <FaHome color="white" size={22} />
          <a href="/"> Ir para tela inicial</a>
        </li> */}
        <li className="icon-link" style={{ marginTop: '20px' }}>
          <FaStickyNote color="white" size={18} />
          <a href="/projects"> Construção de Pré Proposta</a>
        </li>
        <li>
          <div className="steps-vertical">
            <div
              className={
                window.location.pathname === '/projects/diagnostico/'
                  ? 'step active-step'
                  : 'step'
              }
            >
              <div className="step-info">
                <div className="step-label">
                  <a href="/projects/diagnostico/">
                    Diagnóstico de Indicadores
                  </a>
                </div>
              </div>
              <div className="step-content ">Etapa 1</div>
            </div>
            {/* <div
              className={
                window.location.pathname === '/projects/diagnostico/'
                  ? 'step'
                  : 'step'
              }
            >
              <div className="step-info">
                <div className="step-label">
                  <a href="/platform/autoavaliacao/">Autoavaliação</a>
                </div>
              </div>
              <div className="step-content">Etapa 2</div>
            </div> */}
            <div
              className={
                window.location.pathname === '/projects/diagnostico/'
                  ? 'step'
                  : 'step'
              }
            >
              <div className="step-info">
                <div className="step-label">
                  <a href="/platform/selecao-de-solucoes/">
                    Seleção de Soluções
                  </a>
                </div>
              </div>
              <div className="step-content">Etapa 2</div>
            </div>
            <div
              className={
                window.location.pathname === '/projects/diagnostico/'
                  ? 'step'
                  : 'step'
              }
            >
              <div className="step-info">
                <div className="step-label">
                  <a href="/platform/detalhar-pre-proposta/">
                    Detalhar Pré-Proposta
                  </a>
                </div>
              </div>
              <div className="step-content">Etapa 3</div>
            </div>
          </div>
        </li>
        <li className="icon-link last">
          <MdForum color="white" size={22} />
          <a href="/platform/forum/">Fórum</a>
        </li>
      </ul>
      <ul>
      {user.is_focal_point && <AsideMenu>
            <li className="icon-link home">
              <div className="svg">
                <FaUsersCog color="white" size={22} />
              </div>
              <div className="link">
                <a href="/platform/gestao/usuarios/">Gestão de Usuários</a>
              </div>
            </li>
          </AsideMenu>}
      </ul>
      {hasCreditOperation && (
        <>
          <AsideMenu>
            <li className="icon-link home">
              <div className="svg">
                <FiMonitor color="white" size={22} />
              </div>
              <div className="link">
                <Link to="/projects/gestao-monitoramento">
                  Gestão e Monitoramento
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/monitoramento-credito">
                  Minha Operação de crédito
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/monitoramento-impactos">
                  Monitoramento dos Impactos
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/operacoes-credito">
                  Visão de Conjunto: Operações de Crédito do PROSEG Federativo
                </Link>
              </div>
            </li>
          </AsideMenu>
          <AsideMenu>
            <li className="icon-link home">
              <div className="svg">
                <Book color="white" />
              </div>
              <div className="link">
                <Link to="/projects/nucleo-conhecimento">
                  Núcleo de Conhecimento
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/repositorio-documentos">
                  Repositório de Documentos
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/plataforma-ensino">
                  Plataforma de Ensino
                </Link>
              </div>
            </li>
            <li className="icon-link links">
              <div className="svg" />
              <div className="link">
                <Link to="/projects/banco-duvidas">Banco de Dúvidas</Link>
              </div>
            </li>
          </AsideMenu>
        </>
      )}
    </Container>
  );
};

export default Aside;

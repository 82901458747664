import styled from 'styled-components';

export const Container = styled.div`
  .docContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rdt_Table {
    margin-top: 25px;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #164e70;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    color: #6d6e71;
    background: #f1f1f1;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 60px;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #004e70;
    opacity: 1;

    & > div {
      white-space: inherit;
    }
  }

  .modal-body {
    form {
      display: flex;
      flex-direction: column;
      button {
        align-self: flex-end;
        background: #5eb245;
        border-radius: 9px;

        color: #ffffff;
        font-weight: 600;

        width: 109px;
        height: 50px;
        margin-top: 20px;
      }
    }
  }
`;

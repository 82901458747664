import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${props => (props.faq ? `0px` : `25px`)};
`;

export const AccordionItem = styled.div`
  background: ${props => (props.faq ? `#f8f8f8` : `var(--text)`)};
  border: ${props =>
    props.faq ? `1px solid rgba(0,0,0,.125);)` : `var(--text)`};
  width: 100%;
  min-height: 65px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const InitialContainer = styled.div`
  display: flex;
  margin: 0 25px;

  .text-container {
    width: 100%;
    font-family: 'Source Sans Pro' !important;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      font: normal normal bold 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: ${props => (props.faq ? `#6d6e71;` : `white`)};
    }

    .subtitle-container {
      width: 84%;
      span {
        color: var(--gray);
        font-style: normal;
        font-weight: normal;
        font-size: 1.1rem;
        line-height: 1.1rem;
      }
    }
  }

  button {
    color: ${props => (props.faq ? `black` : `var(--white)`)};
    transition: all 0.5s ease;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;

    .arrowIcon {
      svg {
        display: flex;
        margin-right: 3px;
      }

      ${props =>
        props.active &&
        css`
          transform: rotate(90deg);
        `};
    }
  }
`;

export const CollapseContainer = styled.div`
  transition: all 0.5s ease;
  min-height: 0;
  height: 0;
  transition: all 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  border: ${props => (props.faq ? `1px solid rgba(0,0,0,.125);)` : ``)};
  padding: ${props => (props.faq && props.active ? `20px` : `0 5px`)};

  ${props =>
    props.active &&
    css`
      min-height: 30px;
      height: auto;
      transition: all 0.25s ease-in;
    `}
`;

export const DeleteContainer = styled.div`
  z-index: 100;
  display: ${props => (props.show ? 'inherit' : 'none')};
`;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import DataTable from 'react-data-table-component';
import Brasil from '../../../../assets/brasil.png';
import Radio from '../../../../components/Radio';
import { ReactComponent as City } from '../../../../assets/building.svg';
import {
  Container,
  Card,
  StateCard,
  TableContainer,
  LegendContainer,
} from './styles';
import { Row, Column } from '../../../../styles/components';

const SyntheticIndicator = () => {
  const FormRef = useRef(null);
  const { user } = useAuth();
  const [tabActive, setTabActive] = useState('states');
  const [loading, setLoading] = useState(false);
  const [counties, setCounties] = useState([]);
  const [States, setStates] = useState([]);

  const paymentOptions = [
    { id: 'capitals', label: 'Ver o Ranking das Capitais' },
    { id: 'counties', label: 'Ver o Ranking para os Demais Municípios' },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.is_proseg_participant,
      style: {
        backgroundColor: '#def6ff',
      },
    },
  ];

  const getStates = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/v1/syntetic-report/states/');
      const chagedResponse = response.data.locations.map((item, index) => {
        return {
          ...item,
          ranking: index + 1,
        };
      });
      setStates(chagedResponse);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getAllCapitals = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/v1/syntetic-report/all-capitals/');
      const chagedResponse = response.data.locations.map((item, index) => {
        return {
          ...item,
          ranking: index + 1,
        };
      });
      setCounties(chagedResponse);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getNotCapitals = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/v1/syntetic-report/not-capitals/');
      const chagedResponse = response.data.locations.map((item, index) => {
        return {
          ...item,
          ranking: index + 1,
        };
      });
      setCounties(chagedResponse);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const selectRadioRequisition = useCallback(
    ev => {
      const value = ev.target.value;
      value === 'capitals' ? getAllCapitals() : getNotCapitals();
    },
    [getAllCapitals, getNotCapitals],
  );

  useEffect(() => {
    if (tabActive === 'states') {
      getStates();
    } else {
      FormRef.current.setFieldValue('option', 'capitals');
      getAllCapitals();
    }
  }, [tabActive, getStates, getAllCapitals]);

  const handleChangeTable = useCallback(type => {
    setTabActive(type);
  }, []);

  const columns = [
    {
      name: 'Ranking',
      selector: 'ranking',
      sortable: true,
    },
    {
      name: `${tabActive === 'states' ? 'Estado' : 'Cidade'}`,
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Nota Geral',
      selector: 'score',
      sortable: true,
    },
    {
      name: 'Nota - Criminalidade Violência',
      selector: 'score_criminal',
      sortable: true,
    },
    {
      name: 'Nota - Fatores de Risco',
      selector: 'score_factors',
      sortable: true,
    },
    {
      name: 'Nota - Desempenho institucional',
      selector: 'score_performance',
      sortable: true,
    },
  ];

  return (
    <Container tab={tabActive}>
      <Card shadow>
        <h1>Painel de Indicadores</h1>
        <p>
          Nesta Seção, é possível visualizar a nota geral do indicador sintético
          e as notas específicas das tipologias de indicadores.
        </p>
        <Link
          to={
            user.is_admin
              ? '/management/metodologia-indicador'
              : '/projects/metodologia-indicador'
          }
        >
          Saiba mais sobre a metodologia de cálculo do indicador sintético
        </Link>
        <h2>Acesse os painéis de indicadores clicando nos ícones abaixo</h2>
        <Row className="TabsContainer">
          <Column small="12" medium="6" large="6">
            <StateCard
              onClick={() => handleChangeTable('states')}
              color="#00a9ee"
            >
              <div className="imagecontainer">
                <img src={Brasil} alt="" />
              </div>
              <div className="textcontainer">
                <h1>Estados elegiveis</h1>
                <button>ver painel de indicadores</button>
              </div>
            </StateCard>
          </Column>
          <Column small="12" medium="6" large="6">
            <StateCard
              onClick={() => handleChangeTable('counties')}
              color="#007BAC"
            >
              <div className="imagecontainer">
                <City />
              </div>
              <div className="textcontainer">
                <h1>Municipios elegiveis</h1>
                <button>ver painel de indicadores</button>
              </div>
            </StateCard>
          </Column>
        </Row>
        {tabActive === 'states' && (
          <TableContainer>
            <h1>Estados Elegíveis</h1>
            {loading ? (
              <ModalLoader />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={States}
                  conditionalRowStyles={conditionalRowStyles}
                  noDataComponent=""
                  noHeader
                />
              </>
            )}
          </TableContainer>
        )}
        {tabActive === 'counties' && (
          <TableContainer>
            <h1>Municípios Elegíveis</h1>
            <Form ref={FormRef} onChange={selectRadioRequisition}>
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Column small="12" medium="6" large="6">
                  <Radio name="option" options={paymentOptions} />
                </Column>
              </Row>
            </Form>
            {loading ? (
              <ModalLoader />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={counties}
                  conditionalRowStyles={conditionalRowStyles}
                  noDataComponent=""
                  noHeader
                />
              </>
            )}
          </TableContainer>
        )}
        <h2 style={{ marginTop: '20px' }}>Legenda:</h2>
        <LegendContainer>
          <div className="legend-item">
            <div className="blue" />
            Ente participante do PROSEG Federativo
          </div>
          <div className="legend-item">
            <div className="white" />
            Demais Entes
          </div>
        </LegendContainer>
      </Card>
    </Container>
  );
};

export default SyntheticIndicator;

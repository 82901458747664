/* eslint-disable */
import React, { useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import TextArea from '../../../../components/TextArea';
import FileInput from '../../../../components/FileInput';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';

const FinishCritical = ({ show, handleClose, handleSuccess, critical }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errmessage, setErrMessage] = useState('');

  const schema = Yup.object().shape({
    comment: Yup.string().required('Comentário é obrigatório'),
    // document: Yup.string().required('Documento é obrigatório'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        setErrMessage('');

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append('document', data.document);
        formData.append('comment', data.comment);
        formData.append('status', 'finalized');

        const apiUrl = `/credits/api/v1/entregable/${critical.entregable}/critical/${critical.id}/status/`;
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        setLoading(true);

        await api.post(apiUrl, formData, config);
        setTimeout(() => {
          handleSuccess({});
          setLoading(false);
          setsuccess(true);
        }, 3000);
      } catch (err) {
        setLoading(false);
        const { ...error } = err;
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        } else {
          setErrMessage(error.response.data.message);
        }
      }
    },
    [schema, critical.id, handleSuccess],
  );

  return (
    <Container>
      <Modal
        title="Concluir Marco crítico"
        success={success}
        successText="Marco crítico Concluido com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            {!!errmessage && (
              <span style={{ color: '#de6666' }}>{errmessage}</span>
            )}
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FileInput
                name="document"
                title="Anexar Documento Comprobatório"
                schema={schema}
              />
              <TextArea
                name="comment"
                className="comment"
                placeholder="Comentário"
                schema={schema}
              />
              <button type="submit">Salvar</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default FinishCritical;

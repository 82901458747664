import React from 'react';
import ExecutiveReportGraphic from '../../../../../components/ExecutiveReportGraphic';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';

import { Container, Title, TitleText, Card, CardTitle } from './styles';

function Page4({ data, printing }) {
  return (
    <Container>
      <Title>Termômetro geral</Title>
      <TitleText>
        {data.is_state && (
          <p>
            Nesta seção você pode ver a performance de seu estado em todos os
            indicadores disponíveis na plataforma.
          </p>
        )}
        {(data.is_capital || data.is_county) && (
          <p>
            Nesta seção você pode ver a performance de seu município em todos os
            indicadores disponíveis na plataforma.
          </p>
        )}
      </TitleText>
      {data.category.map(category => (
        <Card printing={printing}>
          <CardTitle
            color={
              category.category_name === 'Criminalidade e Violência'
                ? '#0c9fd8'
                : category.category_name === 'Desempenho Institucional'
                ? '#00486B'
                : category.category_name === 'Fatores de Risco'
                ? '#007BAC'
                : '#0c9fd8'
            }
          >
            {category.category_name === 'Criminalidade e Violência' && (
              <Algemas />
            )}
            {category.category_name === 'Desempenho Institucional' && (
              <Government />
            )}
            {category.category_name === 'Fatores de Risco' && <Hands />}
            {category.category_name}
          </CardTitle>
          <ExecutiveReportGraphic data={category.all_indicator} />
        </Card>
      ))}
    </Container>
  );
}

export default Page4;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: 1;
  @media screen and (max-width: 994px) {
    display: block;
    text-align: center;
    p {
      width: 100%;
      text-align: left;
    }
    div + div {
      margin-left: 0px !important;
    }
    img {
      width: 100%;
    }
    div {
      width: 100% !important;
    }
  }

  div {
    width: 50vw;
  }
  p {
    font-size: 18px;
    line-height: 1.7rem;
  }
  p + p {
    margin-top: 15px;
  }
  img {
    width: 100%;
  }
  div + div {
    margin-left: 20px;
  }
  .img-wrapper {
    width: 100%;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  .reportButton {
    float: right;
    background: #075e89;
    border-radius: 7px;

    padding: 9px;
    width: 149px;
    color: #ffffff;
    font: normal normal bold 14px/18px IBM Plex Sans;

    text-align: center;
    transition: background 0.3s ease;

    &:hover {
      background: #053e5a;
    }
  }
  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    background: #6d6e71;
    text-align: center;
    border: 1.5px solid #f1f1f1;

    &:nth-child(1) {
      background: #ffffff;
      font-size: 18px;
      color: #004e70;
    }
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border: 1.5px solid #f1f1f1;
    min-height: 153px;

    &:nth-child(1) {
      background: #f1f1f1;
    }

    & > div {
      white-space: inherit;
    }
  }
`;

export const SearchButton = styled.button`
  background: #aab9bf 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 6px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  transition: background 0.3s ease;

  &:hover {
    background: #828d92;
  }
`;

export const DeleteButton = styled.button`
  background: ${props => (props.color ? `${props.color}` : 'var(--red)')};
  border-radius: 7px;
  padding: 6px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  transition: background 0.3s ease;

  &:hover {
    background: ${props => (props.color ? `#38961d` : '#f73131')};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TotalizerCard = styled.div`
  height: 147px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--blue)`};

  h1 {
    text-align: center;
    font: normal normal bold 50px IBM Plex Sans;
    word-break: break-all;
    color: #ffffff;
  }

  small {
    text-align: center;
    font: normal normal bold 20px IBM Plex Sans;

    color: #ffffff;
  }
`;

export const TableContainer = styled.div`
  overflow: hidden;
  display: grid;
  margin-bottom: 35px;

  .itens-row {
    margin-top: -25px;
  }

  .noOptionsMessage {
    margin-top: -22px;
  }

  .tableTitle {
    text-align: left;
    font: normal normal bold 24px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #004e70;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div``;

export const SolutionTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  svg {
    path {
      fill: ${props => props.color};
    }
  }

  h1 {
    text-align: left;
    font: normal normal bold 25px/33px IBM Plex Sans;
    letter-spacing: 0px;
    margin-left: 15px;
    color: ${props => props.color};
  }
`;

export const Card = styled.section`
  background: var(--white);
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
`;

import React from 'react';
import Balanca from '../../../../../assets/balanca.png';
import Mao from '../../../../../assets/aperto-mao.png';
import Lampada from '../../../../../assets/lampada.png';
import Police from '../../../../../assets/police-officer.png';
import Casa from '../../../../../assets/casa-familia.png';
import Predio from '../../../../../assets/predio.png';
import { Row, Column } from '../../../../../styles/components';
import { Container, EvidenceCard } from './styles';

function ResultAreas() {
  return (
    <Container>
      <h1>Áreas de Resultado</h1>

      <Row>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#bd9313">
            <img src={Casa} alt="" />
            <span>Prevenção Social</span>
            <h3>
              Fatores de risco e protetivos na área do desenvolvimento
              individual, familiar, escolar ou comunitário.
            </h3>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#ac5200">
            <img src={Predio} alt="" />
            <span>Prevenção Situacional</span>
            <h3>
              Condições ambientais/situacionais que facilitam a ocorrência de
              crimes ou violências e/ou a incidência de determinados fatores
              criminogênicos.
            </h3>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#891f1f">
            <img src={Police} alt="" />
            <span>Policiamento</span>
            <h3>
              Temas associados à aplicação do poder de polícia e estratégias de
              policiamento baseadas em evidência.
            </h3>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#106456">
            <img src={Balanca} alt="" />
            <span>Justiça Criminal</span>
            <h3>
              Temas relacionados à política criminal e à eficiência do sistema
              de justiça e do sistema prisional.
            </h3>
          </EvidenceCard>
        </Column>

        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#520a53">
            <img src={Mao} alt="" />
            <span>Reinserção Social</span>
            <h3>
              Programas voltados à redução da reincidência criminal, realizados
              em meio comunitário ou dentro do sistema prisional/internação.
            </h3>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#10385c">
            <img src={Lampada} alt="" />
            <span>Gestão e Modernização Institucional</span>
            <h3>
              Qualificação da gestão pública, das instituições e dos recursos
              humanos na área de segurança pública.
            </h3>
          </EvidenceCard>
        </Column>
      </Row>
    </Container>
  );
}

export default ResultAreas;

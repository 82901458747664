import React from 'react';
import PercentageBar from '../PercentageBar';
import { Container } from './styles';

const TablePercentageBar = ({ percentage, color }) => {
  return (
    <Container>
      <PercentageBar percentage={percentage} color={color} tolltip />
    </Container>
  );
};

export default TablePercentageBar;

import React from 'react';

import { Container, BarItem, InfoLabel } from './styles';

function Multiline({ data, disableInfo }) {
  return (
    <>
      <Container>
        <div>
          <>
            {data.map(item => (
              <>
                {item.percentage > 0 && (
                  <BarItem
                    key={item.status}
                    color={item.color}
                    percentage={item.percentage}
                  >
                    <span>{`${item.percentage}`}</span>
                  </BarItem>
                )}
              </>
            ))}
          </>
        </div>
        {!disableInfo && (
          <div className="info-labels">
            <>
              <InfoLabel color="#5CB24B">
                <div />
                Em execução
              </InfoLabel>
              <InfoLabel color="#014D90">
                <div />
                Concluído
              </InfoLabel>
              <InfoLabel color="#EB5B23">
                <div />
                Em atraso
              </InfoLabel>
              <InfoLabel color="#F7A643">
                <div />
                Com restrição
              </InfoLabel>
              <InfoLabel color="#C1856F">
                <div />
                Em atraso e com restrição
              </InfoLabel>
            </>
          </div>
        )}
      </Container>
    </>
  );
}

export default Multiline;

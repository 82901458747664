import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      div {
        margin-bottom: 0;
      }

      button {
        align-self: flex-end;
        background: #5eb245;
        border-radius: 9px;
        margin-right: 5px;

        color: #ffffff;
        font-weight: 600;

        width: 109px;
        height: 50px;
        margin-top: 20px;
      }

      .no {
        background: var(--red);
      }
    }
  }
`;

export const Title = styled.h1`
  text-align: left;
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #075e89;
  opacity: 1;
  margin-left: 5px;
  margin-top: 25px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    min-height: 473px;
    form {
      display: flex;
      flex-direction: column;
      .button {
        align-self: flex-end;
        background: #5eb245;
        border-radius: 9px;

        color: #ffffff;
        font-weight: 600;

        width: 109px;
        height: 50px;
        margin-top: 20px;
      }
    }
  }
`;

export const ImpactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    background: var(--gray);
    color: var(--white);
    font: normal normal normal 16px/20px IBM Plex Sans;
    color: var(--text2);
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border-radius: 9px;
    padding-left: 18px;
  }

  button {
    background: var(--red) !important;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: inherit;
  }
`;

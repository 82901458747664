import styled from 'styled-components';

export const Container = styled.div``;

export const BannerContainer = styled.div`
  img {
    height: auto;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #6d6e71;
  opacity: 1;
  font-size: 18px;

  p {
    margin-bottom: 25px;
  }

  h3 {
    font-weight: bold;
  }
`;

export const ImageCard = styled.div`
  img {
    height: auto;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: -1px 13px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const Card = styled.a`
  height: 96px;
  background: ${props => props.background && `${props.background}`};

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 15px;
  cursor: pointer;

  h1 {
    text-align: left;
    font: normal normal bold 18px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }

  span {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
`;

import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  text-align: left;
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #0c9fd8;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const TitleText = styled.p`
  text-align: left;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  margin-bottom: 32px;
`;

export const Card = styled.section`
  background: var(--white);

  box-shadow: ${props => !props.printing && '0px 1px 6px #00000029'};
  border: ${props => props.printing && `1px solid #6d6e71`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 30px;
`;

export const IndicatorContainer = styled.div`
  background: var(--white);

  overflow: hidden;
  padding: 15px;

  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #00000029 !important;

  p {
    text-align: left;
    margin-left: 15px;
    width: 65%;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;
  }
`;

export const IndicatorCard = styled.div`
  background: #ffffff;
  width: 100%;
  box-shadow: ${props => !props.printing && '0px 3px 6px #00000029'};

  height: 131px;
  border: ${props => props.color && `1px solid ${props.color}`};
  border-radius: 10px;
  opacity: 1;
  padding: 19px 26px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;

  .IconContainer {
    display: flex;
    flex-direction: column;

    h2 {
      text-align: left;
      font: normal normal bold 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: ${props => props.color};
    }

    svg {
      width: 63px;
      height: 63px;
      margin-bottom: 6px;
      path {
        fill: ${props => props.color};
      }
    }
  }

  .GraphContainer {
    width: 60px;
    height: 60px;

    small {
      text-align: left;
      font: normal normal normal 12px/15px IBM Plex Sans;
      letter-spacing: 0px;
      color: #aab9bf;
      opacity: 1;
    }
  }
`;

export const GraphContainer = styled.div`
  padding: 15px;
  display: flex;
`;

export const Graph = styled.div`
  margin-left: 10px;
  width: 50%;
  display: flex;

  h3 {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .graphContainer {
    display: flex;
  }

  .sideBars {
    display: flex;
    flex-direction: column;
  }

  .bars {
    display: flex;
    flex-direction: column;

    .bests,
    .worst,
    .empty {
      height: 139px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      text-align: center;
      transform: translateX(-10px);
    }

    .barContainer {
      display: flex;
      justify-content: space-between;

      .placing {
        font: normal normal bold 14px/28px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 7px;
      }
    }
  }

  .gradient {
    position: relative;
  }
`;

export const SideBar = styled.div`
  width: 35px;
  height: 137px;

  color: ${props => props.color};
  opacity: 1;
  background: ${props => props.background};
  border: ${props => props.color && `1px solid ${props.color}`};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;

  span {
    transform: rotate(-90deg);
    position: absolute;
    text-align: center;
    font: normal normal bold 12px/28px IBM Plex Sans;
    letter-spacing: 0px;
  }
`;

export const Bar = styled.div`
  width: 317px;
  height: 25px;
  position: relative;

  color: ${props => props.color};
  border: ${props => props.color && `1px solid ${props.color}`};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  margin-left: 3px;

  span {
    text-align: center;
    font: normal normal normal 14px/28px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const GradientBar = styled.div`
  margin-left: 15px;
  width: 30px;
  height: 276px;
  background: transparent
    linear-gradient(180deg, #39d600 0%, #fae840 36%, #ff6026 76%, #ff0000 100%)
    0% 0% no-repeat padding-box;
`;

export const IconContainer = styled.div`
  width: 45px;
  height: 45px;
  background: ${props => props.color};
  box-shadow: ${props => !props.printing && '0px 3px 6px #00000029'};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(15%);

  &.up {
    top: -15px;
  }

  &.down {
    bottom: -15px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  & > div {
    flex: 1;

    border-radius: 4px;
    background: var(--gray);
    position: relative;
    transition: all 0.5s ease;
    display: flex;
  }

  .info-labels {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    background: #ffffff;

    font: normal normal normal 12px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }
`;

export const BarItem = styled.div`
  width: ${props => props.percentage && `${props.percentage}%`};
  background: ${props => props.color};

  height: 35px;
  transition: all 0.5s ease;
  align-items: center;
  display: flex;
  position: relative;

  span {
    display: flex;
    align-self: center;
    justify-content: center;

    font: normal normal bold 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: var(--white);
    position: absolute;
    right: 50%;
    left: 50%;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  div {
    width: 15px;
    height: 15px;
    background: ${props => props.color};
    margin-right: 5px;
  }
`;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashBoardLayout from '../../components/DashboardLayout';
import DataTable from 'react-data-table-component';
import {
  criminalityData,
  RiskFactorsData,
  institutionalPerformanceData,
} from './IndicatorMethodologyService';
import Algemas from '../../assets/algemas.svg';
import Hands from '../../assets/hands.svg';
import Government from '../../assets/government.svg';
import {
  Container,
  BackButton,
  Title,
  TextContainer,
  TabButton,
} from './styles';
import { Column, Row } from '../../styles/components';

const IndicatorMethodology = () => {
  const { goBack } = useHistory();
  const [tab, setTab] = useState('1');

  const columns = [
    {
      name: 'Indicador',
      selector: 'indicator',
      minWidth: '0px',
      sortable: true,
    },
    {
      name: 'Fórmula de Calculo',
      selector: 'calculation_method',
      minWidth: '0px',
      sortable: true,
    },
    {
      name: 'Fonte',
      selector: 'source',
      minWidth: '0px',
      sortable: true,
    },
  ];

  return (
    <Container tab={tab}>
      <DashBoardLayout title="Monitoramento dos Impactos">
        <Row>
          <Column large="12" medium="12" small="12">
            <>
              <BackButton onClick={() => goBack()}>Voltar</BackButton>
              <Title>Metodologia de cálculo do indicador sintético</Title>
            </>
          </Column>
          <Column large="12" medium="12" small="12">
            <TextContainer>
              <p>
                A construção de um indicador sintético para o PROSEG Federativo
                teve como objetivo principal permitir aos usuários da plataforma
                e aos gestores públicos, vinculados a entes contratantes das
                operações de crédito, uma visão de conjunto que sintetizasse a
                situação atualmente vivida por seu estado/município,
                considerando os três principais eixos de focalização estratégica
                do programa: &nbsp;
                <b>
                  Criminalidade e Violência, Fatores de Risco e Desempenho
                  Institucional.
                </b>
              </p>
              <p>
                Nesse sentido, além de &nbsp;
                <b>
                  permitir a comparação de cada estado/município aos seus pares,
                </b>
                tem-se a intenção de dotar estes gestores de um &nbsp;
                <b>
                  instrumento de sinalização, capaz de apontar os principais
                  focos e, consequentemente, os caminhos com maior potencial de
                  impacto sobre os principais condicionantes da criminalidade e
                  da violência,
                </b>
                &nbsp; considerando as especificidades de cada localidade.
              </p>
              <p>
                A construção do indicador sintético do PROSEG Federativo segue
                metodologia semelhante àquela utilizada pelo Programa das Nações
                Unidas para o Desenvolvimento (PNUD) para o cálculo do Índice de
                Desenvolvimento Humano (IDH).
              </p>
              <p>
                Assim sendo, cada estado e município recebeu uma pontuação para
                cada um dos eixos e uma pontuação agregada, considerando, em
                todos os casos, os últimos dados disponíveis (referentes a 2020,
                2019 2018 ou 2017, dependendo do caso).
              </p>
              <p>
                As pontuações de cada eixo foram obtidas por uma média
                quantitativa, que considera os indicadores mapeados e
                selecionados para o eixo em questão.
              </p>
              <p>
                Para a seleção dos indicadores a serem considerados no cálculo
                do indicador sintético, partiu-se da lista de 104 indicadores
                que fazem parte do diagnóstico socioeconômico inserido nessa
                mesma plataforma.
              </p>
              <p>
                Dessa listagem, foram excluídos, para os fins de cálculo do
                indicador sintético:
              </p>
              <p>
                <b>
                  1) Aqueles casos em que havia grande quantidade de “missings”
                </b>
                &nbsp; (dados faltantes ≥ 20% do total de ocorrências
                registradas na base)
              </p>
              <p>
                <b>2) Os “outliers” (“pontos fora da curva”:</b>&nbsp; dados que
                fogem da normalidade estatística por se diferenciarem
                drasticamente dos outros”). Nesse caso a exceção foram os
                indicadores com limites máximos e mínimos teóricos)
              </p>
              <p>
                <b>
                  3) Os indicadores para os quais há observação/expectativa de
                  baixa variância
                </b>
                &nbsp; (em alguns casos decorrente da própria periodicidade de
                divulgação, como no caso de indicadores do Censo/IBGE, por
                exemplo).
              </p>
              <p>
                <b>4) Os indicadores sem polaridade definida</b> (ex.:
                indicadores de investimento per capita, total de servidores por
                habitantes, etc.).
                <p>
                  Além desses critérios também foi feita uma &nbsp;
                  <b>análise teórica</b>&nbsp; dos indicadores, com o objetivo
                  de identificar aqueles mais relevantes (ou mais
                  adequados/robustos estatisticamente) para mensurar os aspectos
                  essenciais de cada eixo. No âmbito dessa análise, foram feitos
                  escolhas entre indicadores que se referiam ao mesmo fenômeno,
                  ainda que através de métricas relativamente diferentes (ex.:
                  taxa de homicídios dolosos divulgada pelo SINESP vs. Taxa de
                  Homicídios calculada pelo DataSUS (“óbitos por causas
                  externas”, segundo classificação CID 10: X95-Y09 e Y35-Y36).
                  Com isso, objetivou-se &nbsp;
                  <b>garantir não viesamento do indicador composto.</b>
                </p>
                <p>
                  Uma vez identificado o pool de indicadores a serem
                  considerados, as &nbsp;
                  <b>
                    variáveis foram normalizadas de acordo com o método min-max
                  </b>
                  &nbsp; (com obtenção de valores mínimos e máximos de acordo
                  com a construção teórica, sempre que possível, ou máximo e
                  mínimos observados empiricamente, na série histórica global
                  disponível, após a exclusão dos outliers.
                </p>
                <p>
                  Para os indicadores cujo crescimento significa melhoria, a
                  formula utilizada é: 𝐼_𝑖=(𝑉_𝑖 − 𝑉𝑎𝑙𝑜𝑟 𝑚í𝑛𝑖𝑚𝑜)/(𝑉𝑎𝑙𝑜𝑟 𝑚á𝑥𝑖𝑚𝑜 −
                  𝑉𝑎𝑙𝑜𝑟 𝑚í𝑛𝑖𝑚𝑜), onde 𝐼_𝑖 é o índice do município 𝑖 e 𝑉_𝑖 é o
                  valor do indicador no muncípio 𝑖. Se o crescimento do
                  indicador significa piora da situação do município, a fórmula
                  utilizada para o cálculo do índice é: 𝐼_𝑖=(𝑉_𝑖 − 𝑉𝑎𝑙𝑜𝑟
                  𝑚á𝑥𝑖𝑚𝑜)/(𝑉𝑎𝑙𝑜𝑟 𝑚í𝑛𝑖𝑚𝑜 − 𝑉𝑎𝑙𝑜𝑟 𝑚á𝑥𝑖𝑚𝑜)
                </p>
                Clicando nos ícones abaixo você encontra as listagens contendo
                os indicadores contemplados no cálculo do sintético de cada
                eixo.
              </p>
            </TextContainer>
          </Column>
        </Row>
        <Row className="TabsContainer">
          <Column large="4" medium="4" small="4">
            <TabButton color="#3aa9ee" onClick={() => setTab('1')}>
              <img src={Algemas} alt="algemas" />
              <span>Criminalidade e Violência</span>
              <div>
                <h2>{criminalityData.length} Indicadores</h2>
              </div>
            </TabButton>
          </Column>
          <Column large="4" medium="4" small="4">
            <TabButton color="#287bac" onClick={() => setTab('2')}>
              <img src={Hands} alt="Hands" />
              <span>Fatores de Risco</span>
              <div>
                <h2>{RiskFactorsData.length} Indicadores</h2>
              </div>
            </TabButton>
          </Column>
          <Column large="4" medium="4" small="4">
            <TabButton color="#14486b" onClick={() => setTab('3')}>
              <img src={Government} alt="Government" />
              <span>Desempenho Institucional</span>
              <div>
                <h2>{institutionalPerformanceData.length} Indicadores</h2>
              </div>
            </TabButton>
          </Column>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Column large="12" medium="12" small="12">
            {tab === '1' && (
              <DataTable
                columns={columns}
                data={criminalityData}
                noDataComponent="Nenhum Indicador"
                noHeader
              />
            )}
            {tab === '2' && (
              <DataTable
                columns={columns}
                data={RiskFactorsData}
                noDataComponent="Nenhum Indicador"
                noHeader
              />
            )}
            {tab === '3' && (
              <DataTable
                columns={columns}
                data={institutionalPerformanceData}
                noDataComponent="Nenhum Indicador"
                noHeader
              />
            )}
          </Column>
        </Row>
        <small>
          ¹ A importância de utilização de dados com níveis aprofundados de
          desagregação geográfica e/ou demográfica que permitam a comparação
          entre os entes subnacionais e a caracterização dos públicos mais
          afetados pela violência, justifica, por exemplo, a utilização de
          indicadores extraídos do DataSUS, na medida em que esta base
          disponibiliza dados com considerável nível de desagregação
          socioespacial (grupos populacionais, objetos utilizados, localidade,
          dados referentes a todos os municípios brasileiros por ocorrência e
          residência, etc.).
        </small>
      </DashBoardLayout>
    </Container>
  );
};

export default IndicatorMethodology;

import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container } from './styles';

function Table({ data }) {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const renameKeys = useCallback((obj, newKeys) => {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }, []);

  const createColumns = useCallback(
    (cols, data) => {
      const newKeys = Object.assign({}, cols);

      const generatedColumns = cols.map(column => {
        return {
          name: column,
          selector: column,
        };
      });

      const newData = data.map(d => {
        const changedData = Object.assign({}, d);
        const renamedObj = renameKeys(changedData, newKeys);
        return renamedObj;
      });

      setColumns(generatedColumns);
      setTableData(newData);
    },
    [renameKeys],
  );

  useEffect(() => {
    createColumns(data.label, data.data);
  }, [data, createColumns]);

  return (
    <Container>
      <DataTable data={tableData} columns={columns} />
    </Container>
  );
}

export default Table;

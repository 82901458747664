import React from 'react';

import Arte from '../../../../../assets/arte7.png';
import { Container, Content } from './styles';

function HowAccess() {
  return (
    <Container>
      <h1>Passo a Passo</h1>
      <Content>
        <img src={Arte} alt="arte" />
      </Content>
    </Container>
  );
}

export default HowAccess;

import styled from 'styled-components';
import ImageHeader from '../../../assets/faq.png';

export const Container = styled.div`
  @media screen and (max-width: 994px) {
    h1 {
      text-align: center;
    }
  }
  .react-select__control {
    background: #fff;
    height: 50px;
    border-radius: 16px;
  }

  .react-select__placeholder,
  .react-select__single-value {
    background: transparent;
    border: 0;
    width: 100%;
    font: normal normal normal 24px/32px IBM Plex Sans;
  }

  .react-select__placeholder {
    color: #aab9bf;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .react-select__loading-indicator {
    font-size: 10px !important;
    svg {
      path {
        fill: rgb(29, 94, 137) !important;
      }
    }
  }

  .react-select__indicator {
    font-size: 24px;
    svg {
      path {
        fill: rgb(29, 94, 137);
      }
    }
  }

  .react-select__input {
    font: normal normal normal 24px/32px IBM Plex Sans;
  }

  .react-select__menu {
    .react-select__group-heading {
      color: #495057;
      background: #f8f8f8;
      font-weight: 700;
    }
  }
  .img-proseg {
    margin-top: 40px;
    width: 95%;
    height: 95%;
    padding: 0 24px;
  }

  .tab-container {
    display: flex;
    margin-top: -40px;
    @media only screen and (max-width: 442px) {
      overflow: scroll;
      font-size: 15px;
    }
  }

  .tab1 {
    color: #fff;
    background: #00a9ee 0% 0% no-repeat padding-box;
    border-radius: 9px 9px 0px 0px;
    display: flex;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 442px) {
      height: 100px;
    }
  }
  .tab2 {
    color: #fff;
    background: #d0d0d0 0% 0% no-repeat padding-box;
    border-radius: 9px 9px 0px 0px;
    display: flex;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 2px;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 442px) {
      height: 100px;
    }
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    width: 100%;
    height: 420px;
  }

  .title {
    h1 {
      font-weight: bold;
      font-size: 55px;
      color: #fff;
    }
    position: absolute;
    top: 200px;
    margin-left: 15rem;
    width: 100%;
    @media only screen and (min-width: 1400px) and (max-width: 1599px) {
      margin-left: 28rem;
    }
    @media only screen and (min-width: 1600px) {
      margin-left: 42rem;
    }
  }

  .content {
    width: 100%;
    max-width: 1140px;
    padding: 24px 0;
    padding-top: 0;
  }

  .img-proseg-wrapper {
    padding: 50px 0px 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageWrapper {
    width: 100%;
    height: 300px;
    background-image: url(${ImageHeader});
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .imageContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;
      .titleContent {
        text-align: left;
        font: normal normal bold 55px/71px IBM Plex Sans;
        letter-spacing: 0px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000029;
        height: 348px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  .grayWrapper {
    width: 100%;
    padding-top: 0;
    background: #f8f8f8;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blueWrapper {
    width: 100%;
    padding-top: 0;
    background: #00a9ee;

    display: flex;
    flex-direction: column;
    align-items: center;

    .blueContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      .initContainer {
        .text {
          width: 100%;
        }
        .image {
          display: none;
        }
      }
    }
    .imageWrapper {
      .imageContent {
        padding: 0 24px;
        .titleContent {
          font-size: 37px;
        }
      }
    }
  }

  @media only screen and (max-width: 1141px) {
    .content {
      padding: 0 24px;
    }

    .imageWrapper {
      .imageContent {
        padding: 0 24px !important;
      }
    }
  }
  h1 {
    font-weight: bold;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: 30px;
    font-size: 19px;
  }
`;

import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


const StackedBarChart= ({
  data,
  options,
  slug=''
}) => {
  useEffect(() => {
    am5.ready(function() {
      am5.array.each(am5.registry.rootElements, function(root) {
        if (root.dom.id === `chartdivstackedBar${ '-' + slug ? slug : '' }`) {
          root.dispose();
        }
      });
    });

    const root = am5.Root.new(`chartdivstackedBar${ '-' + slug ? slug : '' }`);

    root.locale = am5locales_pt_BR;

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 100,
      paddingRight: 20,
      renderer: am5xy.AxisRendererY.new(root, {
       
      })
    }));


    const xRenderer = am5xy.AxisRendererX.new(root, {});
    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      paddingTop: 20,
      paddingBottom: 20,
      categoryField: "year",
      renderer: xRenderer,
    }));
    
    xRenderer.grid.template.setAll({
      location: 1
    })

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      scale: 0.75,
    }));
    
    legend.data.setAll(chart.series.values);


    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      pdfOptions: {
        pageSize: "LETTER",
        pageOrientation: "landscape",
        pageMargins: [20, 20, 20, 40]
      },
    });

    
    data.datasets.forEach((dataset, index) => {

      let hh = []

      data.labels.forEach((label, index) => {
        hh.push({
          year: label,
          value: dataset?.data[index]
        })
      })

      xAxis.data.setAll(hh);        

      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: dataset?.label,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: true,
        valueYField: "value",
        categoryXField: "year",
        fill: dataset?.backgroundColor,
        stroke: dataset?.borderColor,
      }));

      let tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        labelText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });
      
      tooltip.get("background").setAll({
        fill: am5.color(dataset?.backgroundColor),
        fillOpacity: 1
      });
      
      series.set("tooltip", tooltip); 


      series.data.setAll(hh);
      series.appear(1000);

      legend.data.push(series);

    })
 
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
    }));

    cursor.lineX.setAll({
      stroke: am5.color('#000000'),
      strokeWidth: 1,
      strokeOpacity: 0.1,
      strokeDasharray: []
    });
    

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div>
    <div id={`chartdivstackedBar${ '-' + slug ? slug : '' }`} style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}


export default StackedBarChart;
import React from 'react';

import Arte from '../../../../../assets/arte7.png';
import { Container } from './styles';

function HowAccess() {
  return (
    <Container>
      <h1>Como acessar as linhas de crédito do PROSEG Federativo?</h1>
      <img src={Arte} alt="arte" />
    </Container>
  );
}

export default HowAccess;

import React from 'react';
import { Row, Column } from '../../.././../../styles/components';
import { Container } from './styles';


import like from "../../../../../assets/new/like.png";
import unlike from "../../../../../assets/new/unlike.png";


import PeriodOrange from '../../../../../assets/new/period-orange.png';
import PeriodEmpty from '../../../../../assets/new/period-empty.png';


import regionGreen from "../../../../../assets/new/region-green.png";
import regionRed from "../../../../../assets/new/region-red.png";
import regionOrange from "../../../../../assets/new/region-orange.png";
import regionEmpty from "../../../../../assets/new/region-empty.png";

import flagGreen from '../../../../../assets/new/flag-green.png';
import flagRed from "../../../../../assets/new/flag-red.png";
import flagOrange from "../../../../../assets/new/flag-orange.png";
import flagEmpty from "../../../../../assets/new/flag-empty.png";


function DiagnosticLengend({ diagnostico }) {
  return (
    <Container>
      <Row>
        <Column
          large="12"
          medium="12"
          small="12"
          style={{ marginBottom: '10px', marginLeft: '4px' }}
        >
          <span className="legendText">Legenda:</span>
        </Column>
        {diagnostico.is_state && (
          <>
            <Column large="4" medium="4" small="12">
              <span className="legendTitle">Evolução</span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src={like}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Melhora no período</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={unlike}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Piora no período</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={PeriodOrange}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Estável no Período</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={PeriodEmpty}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Não comparável</span>
              </Column>
            </Column>
            <Column large="4" medium="4" small="12">
              <span className="legendTitle">Comparação com a média nacional</span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src={flagGreen}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">
                  Melhor que o país no último ano
                </span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={flagRed}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Pior que o país no último ano</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={flagOrange}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Igual ao país no último ano</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={flagEmpty}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Não comparável*</span>
              </Column>
            </Column>
            <Column large="4" medium="4" small="12">
              <span className="legendTitle">Comparação com a média regional</span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src={regionGreen}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">
                  Melhor que a região no último ano
                </span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={regionRed}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">
                  Pior que a região no último ano
                </span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={regionOrange}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Igual à região no último ano</span>
              </Column>
              <Column large="12" medium="12" small="12" className="iconContainer">
                <img
                  src={regionEmpty}
                  alt=""
                  style={{ height: "30px", width: "30px" }}
                />
                <span className="legendName">Não comparável*</span>
              </Column>
            </Column>
          </>
        )}
        {!diagnostico.is_state && (
          <>
            <Column large="3" medium="3" small="12">
              <span className="legendTitle">Evolução</span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src="/static/media/period-green.png"
                  className="indicadores-green-border"
                  alt=""
                />
                <span className="legendName">Melhora no período</span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/period-red.png"
                  className="indicadores-red-border"
                  alt=""
                />
                <span className="legendName">Piora no período</span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/period-grey.png"
                  className="indicadores-grey-border"
                  alt=""
                />
                <span className="legendName">Estável no Período</span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/period-empty.png"
                  className="indicadores-empty-border"
                  alt=""
                />
                <span className="legendName">Não comparável</span>
              </Column>
            </Column>
            <Column large="3" medium="3" small="12">
              <span className="legendTitle">
                Comparação com a média nacional
              </span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src="/static/media/flag-green.png"
                  className="indicadores-green-border"
                  alt=""
                />
                <span className="legendName">
                  Melhor que o país no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/flag-red.png"
                  className="indicadores-red-border"
                  alt=""
                />
                <span className="legendName">
                  Pior que o país no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/flag-grey.png"
                  className="indicadores-grey-border"
                  alt=""
                />
                <span className="legendName">
                  Igual ao país no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/flag-empty.png"
                  className="indicadores-empty-border"
                  alt=""
                />
                <span className="legendName">Não comparável*</span>
              </Column>
            </Column>
            <Column large="3" medium="3" small="12">
              <span className="legendTitle">
                Comparação com a média estadual
              </span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src="/static/media/state-green.png"
                  className="indicadores-green-border"
                  alt=""
                />
                <span className="legendName">
                  Melhor que o estado no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/state-red.png"
                  className="indicadores-red-border"
                  alt=""
                />
                <span className="legendName">
                  Pior que o estado no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/state-grey.png"
                  className="indicadores-grey-border"
                  alt=""
                />
                <span className="legendName">
                  Igual o estado no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/state-empty.png"
                  className="indicadores-empty-border"
                  alt=""
                />
                <span className="legendName">Não comparável*</span>
              </Column>
            </Column>
            <Column large="3" medium="3" small="12">
              <span className="legendTitle">
                Comparação com a região metropolitana
              </span>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer mt5"
              >
                <img
                  src="/static/media/metrop-green.png"
                  className="indicadores-green-border"
                  alt=""
                />
                <span className="legendName">
                  Melhor que a região no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/metrop-red.png"
                  className="indicadores-red-border"
                  alt=""
                />
                <span className="legendName">
                  Pior que a região no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/metrop-grey.png"
                  className="indicadores-grey-border"
                  alt=""
                />
                <span className="legendName">
                  Igual à região no último ano
                </span>
              </Column>
              <Column
                large="12"
                medium="12"
                small="12"
                className="iconContainer"
              >
                <img
                  src="/static/media/metrop-empty.png"
                  className="indicadores-empty-border"
                  alt=""
                />
                <span className="legendName">Não comparável*</span>
              </Column>
            </Column>
          </>
        )}

      </Row>
    </Container>
  );
}

export default DiagnosticLengend;

/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Loader from '../../../../components/Loader';
import getValidationErrors from '../../../../services/getValidationErrors';
import { isBefore, isAfter, isEqual } from 'date-fns';
import DataTable from 'react-data-table-component';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import Modal from '../../../../components/Modal';
import ErrorModal from '../../../../components/ErrorModal';
import api from '../../../../services/api';
import { Container, ProgramationContainer } from './styles';
import { Row, Column } from '../../../../styles/components';

const AddAgreedResults = ({
  show,
  handleClose,
  handleSuccess,
  deliverable,
  operation,
}) => {
  const UpdateformRef = useRef(null);
  const TableFormRef = useRef(null);
  const RealizedTableFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const [deliv, setDeliv] = useState(deliverable);
  const [programming, setProgramming] = useState([]);
  const [realized, setRealized] = useState([]);
  const [endYear, setEndYear] = useState('');
  const [initYear, setInitYear] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const schema = Yup.object().shape({
    title: Yup.string().required('Titulo é obrigatório'),
    indicator: Yup.string().required('Indicador é obrigatório'),
    unit_measure: Yup.string().required('Unidade de medida é obrigatória'),
    ref_value: Yup.string().required('Valor de referência é obrigatório'),
    source: Yup.string().required('A Fonte é obrigatória'),
    goal: Yup.string().required('A meta é obrigatória'),
    resp_measure: Yup.string().required(
      'Responsável pela medida é obrigatório',
    ),
    deadline: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de prazo válida',
    ),
  });

  const calculateYearsBetween = useCallback((startDate, endDate) => {
    const startYear = startDate.getYear() + 1900,
      endYear = endDate.getYear() + 1900,
      output = [];
    for (var year = startYear; year <= endYear; year++) output.push(year);
    return output;
  }, []);

  const generateProgramming = useCallback(
    (initYear, deadlineYear) => {
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadlineYear}`),
      );
      const programmingArray = years.map(year => {
        return {
          year: year,
          january: { value: 0, year },
          february: { value: 0, year },
          march: { value: 0, year },
          april: { value: 0, year },
          may: { value: 0, year },
          june: { value: 0, year },
          july: { value: 0, year },
          august: { value: 0, year },
          september: { value: 0, year },
          october: { value: 0, year },
          november: { value: 0, year },
          dezembro: { value: 0, year },
        };
      });
      setProgramming([...programmingArray]);
    },
    [calculateYearsBetween],
  );

  const getYearData = useCallback((data, year) => {
    const rename = {
      [`january${year}`]: 'jan',
      [`february${year}`]: 'feb',
      [`march${year}`]: 'mar',
      [`april${year}`]: 'apr',
      [`may${year}`]: 'may',
      [`june${year}`]: 'jun',
      [`july${year}`]: 'jul',
      [`august${year}`]: 'aug',
      [`september${year}`]: 'sep',
      [`october${year}`]: 'oct',
      [`november${year}`]: 'nov',
      [`dezembro${year}`]: 'dec',
    };
    var filteredYear = Object.keys(data).reduce((res, key) => {
      if (key.indexOf(year) !== -1) {
        res[rename[key]] = !!data[key] ? data[key] : 0;
      }
      return res;
    }, {});
    return { year, ...filteredYear };
  }, []);

  const getRelizedData = useCallback((data, year) => {
    const rename = {
      [`january${year}`]: 'jan',
      [`february${year}`]: 'feb',
      [`march${year}`]: 'mar',
      [`april${year}`]: 'apr',
      [`may${year}`]: 'may',
      [`june${year}`]: 'june',
      [`july${year}`]: 'july',
      [`august${year}`]: 'aug',
      [`september${year}`]: 'sep',
      [`october${year}`]: 'oct',
      [`november${year}`]: 'nov',
      [`dezembro${year}`]: 'dec',
    };
    var filteredYear = Object.keys(data).reduce((res, key) => {
      if (key.indexOf(year) !== -1) {
        res[rename[key]] = 0;
      }
      return res;
    }, {});
    return { year, ...filteredYear };
  }, []);

  const changeEndYear = useCallback(() => {
    const value = UpdateformRef.current.getFieldValue('deadline').split('/');
    setEndYear(value[2]);
  }, []);

  const handleSave = useCallback(
    async data => {
      const tableData = TableFormRef.current.getData();
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${endYear}`),
      );

      const filteredYears = years.map(year => getYearData(tableData, year));
      const realizedData = years.map(year => getRelizedData(tableData, year));
      const deadline = data['deadline'].split('/');

      const postData = {
        ...data,
        goal: Number(data.goal),
        deadline: `${deadline[2]}-${deadline[1]}-${deadline[0]}`,
        programming_result: filteredYears,
        realized_result: realizedData,
        credit_operation: operation.code,
      };

      try {
        UpdateformRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });
        setLoading(true);
        await api.post(
          `/credits/api/v1/opcredit-manager/${operation.code}/agreed-results/`,
          postData,
        );
        handleSuccess();
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          UpdateformRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);
        const { ...error } = err;
        if (Object.keys(error.response.data).length) {
          if (!!error.response.data.programming_result) {
            setError(true);
            setErrorMessage(error.response.data.programming_result[0]);
          }
          if (!!error.response.data.realized_result) {
            setError(true);
            setErrorMessage(error.response.data.realized_result[0]);
          }
          if (!!error.response.data.deadline) {
            setError(true);
            setErrorMessage(error.response.data.deadline[0]);
          }
        }
      }
    },
    [
      initYear,
      endYear,
      calculateYearsBetween,
      getRelizedData,
      getYearData,
      operation.code,
    ],
  );

  useEffect(() => {
    generateProgramming(initYear, endYear);
  }, [generateProgramming, endYear, initYear, operation]);

  useEffect(() => {
    if (!!Object.keys(operation).length) {
      setInitYear(operation.start_date.split('-')[0]);
      setEndYear(operation.start_date.split('-')[0]);
    }
  }, [operation]);

  const handleDisabled = useCallback((initDate, endDate, month, year) => {
    if (!!initDate && !!endDate) {
      const actualDate = new Date(`${month} 1 ${year}`);
      const newInitDate = new Date(
        `${initDate.split('-')[1]} 1  ${initDate.split('-')[0]}`,
      );
      const newEndDate = new Date(
        `${endDate.split('/')[1]} 1 ${endDate.split('/')[2]}`,
      );
      const initIsBefore = isBefore(actualDate, newInitDate);
      const endIsAfter = isAfter(actualDate, newEndDate);
      const isEqualInit = isEqual(actualDate, newInitDate);
      return (initIsBefore || endIsAfter) && !isEqualInit ? true : false;
    } else {
      return null;
    }
  }, []);

  const hasDisabled = useCallback((initDate, endDate, month, year) => {
    const value = handleDisabled(initDate, endDate, month, year);
    const actualDate = new Date(`${month} 1 ${year}`);
    const newInitDate = new Date(
      `${initDate.split('-')[1]} 1 ${initDate.split('-')[0]}`,
    );
    const initIsBefore = isBefore(actualDate, newInitDate);
    return !!value ? value : initIsBefore;
  }, []);

  const columns = [
    {
      name: 'Ano',
      selector: 'year',
    },
    {
      name: 'Jan',
      selector: 'january',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jan',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`january${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Fev',
      selector: 'february',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Feb',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`february${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Mar',
      selector: 'march',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Mar',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`march${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Abr',
      selector: 'april',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Apr',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`april${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Mai',
      selector: 'may',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'May',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`may${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Jun',
      selector: 'june',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jun',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`june${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Jul',
      selector: 'july',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jul',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`july${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Ago',
      selector: 'august',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Aug',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`august${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Set',
      selector: 'september',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Sep',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`september${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Out',
      selector: 'october',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Oct',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`october${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Nov',
      selector: 'november',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Nov',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`november${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
    {
      name: 'Dez',
      selector: 'dezembro',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Dec',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`dezembro${row.year}`}
            placeholder="Digite o valor"
            defaultValue={null}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Modal
        title="Adicionar Resultado pactuado"
        success={success}
        successText="Resultado pactuado Adicionado com Sucesso!!"
        handleClose={handleClose}
        show={show}
      >
        <>
          {loading && <Loader />}
          <Form ref={UpdateformRef} onSubmit={handleSave}>
            <Row>
              <Column small="12" medium="12" large="12">
                <Input
                  label="Titulo"
                  name="title"
                  className="title"
                  placeholder="Titulo"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="12" large="12">
                <Input
                  label="Indicador"
                  name="indicator"
                  className="indicator"
                  placeholder="Indicador"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="3">
                <Input
                  label="Unidade de Medida"
                  name="unit_measure"
                  className="unit_measure"
                  placeholder="Unidade de Medida"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="9">
                <Input
                  label="Responsável pela Medição"
                  name="resp_measure"
                  className="resp_measure"
                  placeholder="Responsável pela Medição"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="3">
                <Input
                  type="number"
                  label="Valor de Referência"
                  name="ref_value"
                  className="ref_value"
                  placeholder="Valor de Referência"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="9">
                <Input
                  label="Fonte"
                  name="source"
                  className="source"
                  placeholder="Fonte"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="4">
                <InputMask
                  label="Prazo Final"
                  name="deadline"
                  className="deadline"
                  placeholder="Prazo Final"
                  mask="99/99/9999"
                  maskChar={null}
                  onChange={changeEndYear}
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="4">
                <Input
                  label="Meta"
                  name="goal"
                  className="goal"
                  placeholder="Meta"
                  schema={schema}
                />
              </Column>
            </Row>
            <Form ref={TableFormRef} onSubmit={handleSave}>
              <ProgramationContainer
              // readonly={operation.status === 'execution'}
              >
                <h1>Programação :</h1>
                <DataTable
                  columns={columns}
                  data={programming}
                  noDataComponent=""
                  noHeader
                />
              </ProgramationContainer>
            </Form>
            <button className="save" type="submit">
              Salvar
            </button>
          </Form>
        </>
      </Modal>
      {error && (
        <ErrorModal
          show={error}
          Message={errorMessage}
          handleClose={() => setError(false)}
        />
      )}
    </Container>
  );
};

export default AddAgreedResults;

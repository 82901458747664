import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Row, Column } from '../../../styles/components';
import SelectOptions from '../../../components/RegisterModal/options';
import Select from '../../../components/Select';
import ExternalLayout from '../../../components/ExternalLayout';
import AnswersCard from './components/AnswersCard';
import { Container, MainContent } from './styles';

function MaturityChecklist() {
  const { goBack } = useHistory();
  const formRef = useRef(null);
  const handleSubmit = useCallback(() => {}, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ExternalLayout headerTransparent={true}>
      <Container>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Metodologia</div>
            </div>
          </div>
          <div className="content">
            <button className="gobackLink" onClick={goBack}>
              Voltar
            </button>
            <div className="title">
              Autoavaliação (‘Checklist de Maturidade’)
            </div>
            <div className="text">
              <p className="text py-2">
                O checklist é um &nbsp;
                <b>
                  instrumento de avaliação do grau de maturidade dos estados e
                  municípios com relação à sua capacidade de elaboração, gestão
                  e avaliação de iniciativas e políticas pertinentes ao campo da
                  segurança pública baseada em evidências.
                </b>
                &nbsp;
                <br />
                <br />
                O checklist se soma e se integra ao diagnóstico de indicadores
                para ensejar uma percepção global (quantitativa e qualitativa)
                dos principais desafios de cada estado/município, considerando
                as 06 áreas de resultado do PROSEG Federativo e os desafios e
                determinantes a elas associados.
                <br />
                <br />
                Cada área de resultado está associada, no checklist, a uma lista
                de perguntas, que foram formuladas tendo em vista três objetivo
                específicos:
                <br />
                <br />
                1) Identificar, dentre as boas práticas de segurança pública
                baseadas em evidências,&nbsp;
                <b>
                  quais são as soluções já aplicadas pelo ente em questão em seu
                  estado/município;
                </b>
                <br />
                2) Identificar o &nbsp;
                <b>
                  nível de maturidade de cada ente federativo na aplicação das
                  práticas
                </b>
                &nbsp; que já são desenvolvidas por ele
                <br />
                3) Avaliar, no caso das soluções ainda não implantadas, se o
                estado/município em questão possui as &nbsp;
                <b>
                  (pré-)condições necessárias para a efetiva implantação, no
                  futuro.
                </b>
              </p>
            </div>
            <AnswersCard />
            <div className="text" style={{ margin: '40px 0' }}>
              <p>
                Cada pergunta poderá ser respondida através de uma lista
                padronizada, com 05 opções. São elas: “Não e não há planejamento
                para que seja implantado no futuro próximo”; “Não, porém há
                planejamento para que seja implantado no futuro próximo”;
                “Existe, porém não está sistematizado (iniciativas
                pontuais/esporádicas)”; “Sim e apresenta boa maturidade, porém
                ainda precisa de ajustes”, “Sim e funciona plenamente”.
                <br />
                <br />O checklist de maturidade só está disponível na área
                logada da plataforma, a qual só poderá acessada pelos estados e
                municípios que fazem parte do público-alvo do PROSEG Federativo.
                Cheque, abaixo, que Governos Estaduais e Prefeituras Municipais
                estão atualmente habilitados:
              </p>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Row>
                <Column small="12" medium="6" large="6">
                  <Select
                    name="location"
                    options={SelectOptions.estados.sort(function (a, b) {
                      if (a.label > b.label) {
                        return 1;
                      }
                      if (a.label < b.label) {
                        return -1;
                      }
                      return 0;
                    })}
                    label="Estado"
                    placeholder="Selecione um Estado"
                  />
                </Column>
                <Column small="12" medium="6" large="6">
                  <Select
                    name="location"
                    options={SelectOptions.municipios.sort(function (a, b) {
                      if (a.label > b.label) {
                        return 1;
                      }
                      if (a.label < b.label) {
                        return -1;
                      }
                      return 0;
                    })}
                    label="Cidade"
                    placeholder="Selecione uma Cidade"
                  />
                </Column>
              </Row>
            </Form>
          </div>
        </MainContent>
      </Container>
    </ExternalLayout>
  );
}

export default MaturityChecklist;

import React, { useMemo, useCallback } from 'react';

import { Container } from './styles';

function Donut({
  value,
  color,
  strokeWidth,
  strokeDasharray,
  width,
  height,
  circleX,
  circleY,
  radius,
  fontSize,
  title,
}) {
  const GetPercent = useCallback((number, total) => {
    return (number / total) * 100;
  }, []);

  const GetPercentValue = useCallback((number, percentToGet) => {
    return (percentToGet / 100) * number;
  }, []);

  const ChangedDashArray = useMemo(() => {
    const percent = GetPercent(value, 10);
    const valueToPercent = !!strokeDasharray ? strokeDasharray : 438;
    const returnValue = GetPercentValue(valueToPercent, percent);
    return returnValue;
  }, [strokeDasharray, GetPercentValue, GetPercent, value]);

  return (
    <Container
      color={color}
      fontSize={fontSize}
      strokeDasharray={ChangedDashArray}
    >
      <div className="item css">
        <h2>{!!value ? value : 'N/A'}</h2>
        <svg
          width={!!width ? width : '160'}
          height={!!height ? height : '160'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <title>{title}</title>
            <circle
              className="circle_animation"
              r={!!radius ? radius : '69.85699'}
              cy={!!circleY ? circleY : '81'}
              cx={!!circleX ? circleX : '81'}
              strokeWidth={!!strokeWidth ? strokeWidth : '10'}
              stroke={color}
              fill="none"
            />
          </g>
        </svg>
      </div>
    </Container>
  );
}

export default Donut;

import styled from 'styled-components';
export const Container = styled.div`
  h2 {
    text-align: center;
    font: normal normal bold 34px/44px IBM Plex Sans;
    letter-spacing: 0px;
  }

  .item {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item h2 {
    text-align: center;
    position: absolute;
    width: 100%;
    font: normal normal bold 36px/47px IBM Plex Sans;
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '36px')};
    letter-spacing: 0px;
    color: ${props => (props.color ? `${props.color}` : '#00a9ee')};
  }

  svg {
    transform: rotate(-90deg);
  }

  .circle_animation {
    stroke-dasharray: ${props =>
      props.strokeDasharray ? props.strokeDasharray : 439};
  }
`;

import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../assets/loader.json';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      height={50}
      width={50}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default Loader;

/* eslint-disable */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useAuth } from '../../../../hooks/auth';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FileInput from '../../../../components/FileInput';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';
import { Row, Column } from '../../../../styles/components';

const CreateNewDocument = ({ show, handleClose, handleSuccess }) => {
  const formRef = useRef(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errmessage, setErrMessage] = useState('');
  const [documentTypes, setDocumentTypes] = useState([]);

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    file: Yup.string().required('Arquivo é obrigatório'),
    type_doc: Yup.string().required('Tipo de documento é obrigatório'),
    subject: Yup.string().required('Assunto é obrigatório'),
  });

  const getDocumentTypes = useCallback(async () => {
    try {
      const response = await api.get('/learning/api/v1/types/');
      setDocumentTypes(response.data.results);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const DocumentTypeOptions = useMemo(() => {
    return documentTypes.map(type => ({
      value: type.id,
      label: type.name,
    }));
  }, [documentTypes]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        setErrMessage('');

        await schema.validate(data, {
          abortEarly: false,
        });
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('subject', data.subject);
        formData.append('file', data.file);
        formData.append('type_doc', data.type_doc);
        formData.append('org_user', user.id);

        const apiUrl = `/learning/api/v1/documents/`;
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        setLoading(true);
        await api.post(apiUrl, formData, config);
        handleSuccess();
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        setLoading(false);
        const { ...error } = err;
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        } else {
          setErrMessage(error.response.data.message);
        }
      }
    },
    [schema, handleSuccess, user.id],
  );

  return (
    <Container>
      <Modal
        title="Cadastrar novo documento"
        success={success}
        successText="Documento Adicionado com sucesso!!"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            {!!errmessage && (
              <span style={{ color: '#de6666' }}>{errmessage}</span>
            )}
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Row>
                <Column small="12" medium="12" large="12">
                  <Input
                    label="Nome do documento"
                    name="name"
                    className="name"
                    placeholder="Nome do documento"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <Input
                    label="Assunto"
                    name="subject"
                    className="subject"
                    placeholder="Assunto"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <Select
                    label="Tipo de Documento"
                    name="type_doc"
                    className="type_doc"
                    placeholder="Selecione o tipo de documento"
                    options={DocumentTypeOptions}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <FileInput
                    name="file"
                    title="Selecionar Arquivo"
                    schema={schema}
                  />
                </Column>
              </Row>

              <button type="submit">Enviar Documento</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default CreateNewDocument;

import React, { useState, useEffect } from 'react';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';
import ImageHeader from '../../../assets/faq.png';
import ProgramaEvidencias from '../../../assets/programa-evidencias.png';
import { Link } from 'react-router-dom';

import { ColWrapper, Container, FlexWrapper, MainContent } from './styles';

function SegurancaEvidencia() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={true}>
          <Container>
            <MainContent>
              <div className="imageWrapper">
                <div className="imageContent">
                  <div className="titleContent">O Programa</div>
                </div>
              </div>
              <div className="content">
                <div className="tab-container">
                  <Link
                    to="/programa/sobre-o-programa"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">O PROSEG Federativo</div>
                  </Link>
                  <Link
                    to="/programa/seguranca-baseada-em-evidencias"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab1">Segurança Baseada em Evidências</div>
                  </Link>
                  <Link
                    to="/programa/ficha-tecnica"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Ficha Técnica</div>
                  </Link>
                  <Link
                    to="/programa/perguntas-e-respostas"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Perguntas Frequentes</div>
                  </Link>
                </div>
              </div>
              <div className="content">
                <h1>Segurança Baseada em Evidências</h1>
                <FlexWrapper>
                  <div className="left">
                    <ColWrapper>
                      <div>
                        <p>
                          As soluções, programas e projetos recomendados pela
                          Plataforma de Segurança se baseiam no{' '}
                          <b>
                            conhecimento acumulado e em pesquisas sistemáticas
                            desenvolvidas por instituições e pesquisadores no
                            Brasil e no exterior.
                          </b>
                        </p>
                        <p>
                          Com o passar dos anos, o acúmulo de experiências e a
                          condução de pesquisas sistemáticas de avaliação de
                          impacto, tornou-se possível, às equipes do Banco
                          Interamericano de Desenvolvimento (BID),{' '}
                          <b>
                            segregar os tipos de intervenções e projetos que
                            funcionam{' '}
                          </b>
                          daquela(e)s que não funcionam, do ponto de vista da
                          redução da violência e da criminalidade (saiba mais
                          acessando o Banco de Evidências do E-Segurança).
                        </p>
                      </div>
                    </ColWrapper>
                  </div>
                  <div className="right">
                    <img src={ProgramaEvidencias} alt="" />
                  </div>
                </FlexWrapper>
              </div>
              <div className="content">
                <p>
                  Ainda que esse levantamento venha a ser continuamente revisado
                  e aprimorado, inclusive para se manter coerente com as
                  evidências científicas mais recentes,{' '}
                  <b>
                    o conhecimento acumulado até o presente momento já permite
                    identificar um conjunto de soluções e programas com elevado
                    potencial de impacto. São estas soluções em linha com as
                    melhores experiências e com os programas reconhecidos
                    internacionalmente como bem sucedidos na área de segurança
                    pública
                  </b>{' '}
                  que são ofertadas no âmbito dessa plataforma, em geral, e do
                  PROSEG Federativo, especificamente.
                </p>
                <p>
                  O BID e suas instituições parceiras sabem, entretanto, que há
                  diversas limitações no que diz respeito à utilização de boas
                  práticas e de dados e evidências na formulação de políticas
                  públicas.
                </p>
                <p>
                  No caso da Segurança Pública, especificamente, deve-se
                  destacar , ainda o fato de que há, no Brasil, certa “
                  <b>
                    rarefação” de indicadores oficiais, de acesso público e
                    comparáveis entre os entes federativos na área da segurança
                    e justiça,
                  </b>{' '}
                  especialmente quando buscamos dados que permitam ampla
                  desagregação espacial (dados por município ou mesmo no nível
                  de bairro ou setor censitário) e demográfica (por grupo
                  populacional ou social).
                </p>
                <p>
                  Nesse sentido,{' '}
                  <b>
                    o conjunto de indicadores utilizados na etapa de diagnóstico
                    desta plataforma representam um conjunto inicial, que será
                    objeto de constante aprimoramento,
                  </b>{' '}
                  sempre que novos indicadores e estatísticas forem lançados
                  e/ou identificados junto às bases nacionais de acesso público.
                </p>
                <p>
                  Ademais, temos plena consciência de que ambientes sociais,
                  econômica e culturalmente diferentes exigem adequações e,
                  muitas vezes abordagens específicas, para dar conta da
                  dinâmica da violência específica de cada localidade e dos
                  fatores de risco e dos determinantes individuais, sociais e
                  institucionais mais relevantes à cada realidade.
                </p>
                <p>
                  Nosso objetivo{' '}
                  <b>
                    {' '}
                    não é, portanto, o de fomentar a replicação literal e
                    automática de práticas e programas bem sucedidos, sejam elas
                    internacionais ou nacionais, mas sim o de mobilizar as
                    evidências e aprendizados existentes para fomentar a
                    formulação de políticas com potencial de fazer a diferença,
                  </b>{' '}
                  de acordo com as evidências científicas e com as avaliações
                  profissionais disponíveis.
                </p>
                <p>
                  Temos certeza de que, juntos, profissionais, gestores,
                  pesquisadores e ativistas da área de segurança pública,
                  poderemos somar forças para aprimorar progressivamente os
                  instrumentos e o portfólio ofertados, construindo soluções
                  cada vez mais adequadas às especificidades dos estados e
                  municípios brasileiros.
                </p>
              </div>
            </MainContent>
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default SegurancaEvidencia;

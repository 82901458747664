import React, { useState, useEffect } from 'react';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';
import ImageHeader from '../../../assets/faq.png';
import Accordion from '../../../components/Accordion';
import { Container, MainContent } from './styles';
import { Link } from 'react-router-dom';

function PerguntasFrequentes() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={true}>
          <Container>
            <MainContent>
              <div className="imageWrapper">
                <div className="imageContent">
                  <div className="titleContent">O Programa</div>
                </div>
              </div>
              <div className="content">
                <div className="tab-container">
                  <Link
                    to="/programa/sobre-o-programa"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">O PROSEG Federativo</div>
                  </Link>

                  <Link
                    to="/programa/ficha-tecnica"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Ficha Técnica</div>
                  </Link>
                  <Link
                    to="/programa/perguntas-e-respostas"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab1">Perguntas Frequentes</div>
                  </Link>
                </div>
              </div>
              <div className="content">
                <h1>Perguntas Frequentes e Respostas</h1>
                <Accordion
                  title="1. O que é o PROSEG Federativo, e para o que serve a plataforma?"
                  faq
                >
                  <span>
                    O Programa Federativo para Segurança Pública Inteligente
                    (PROSEG Federativo) é fruto de uma parceria entre o Banco
                    Interamericano de Desenvolvimento (BID) e o Banco Nacional
                    de Desenvolvimento Econômico e Social (BNDES), e tem como
                    objetivo apoiar estados e municípios a fortalecer os
                    mecanismos de planejamento, governança e gestão dos projetos
                    de segurança pública através de linhas de crédito
                    específicas para esta área. A plataforma é uma ferramenta
                    que facilita a operacionalização do programa e tem como
                    público-alvo os servidores públicos vinculados às
                    Prefeituras Municipais e/ou aos Governos do Estado dos entes
                    federativos que compõem o público-alvo do PROSEG (é
                    necessário um e-mail institucional – “.gov” – para acessar a
                    área restrita). A plataforma facilita o processo de
                    requisição e acesso aos financiamentos previstos no programa
                    ao conduzir os usuários através de uma jornada que lhes
                    permite construir uma pré-proposta de financiamento baseada
                    tanto na compreensão aprofundada de seus desafios
                    (entendimento e organização da “demanda” de cada ente
                    através de um diagnóstico de indicadores e de um checklist
                    de autoavaliação institucional), como na seleção e
                    formulação estratégias e programas orientados à superação
                    destes, considerando como ponto de partida um menu de
                    soluções baseadas em evidências (“oferta”)
                  </span>
                </Accordion>
                <Accordion title="2. Quem pode aderir ao programa?" faq>
                  <span>
                    O PROSEG Federativo tem como propósito apoiar a produção de
                    um impacto significativo sobre os índices brasileiros de
                    criminalidade e violência. Em função disso, nesta primeira
                    etapa foi focalizado um grupo específico de municípios, mais
                    representativos em termos populacionais e/ou de registro de
                    homicídios nos anos mais recentes. Fazem parte do
                    público-alvo do PROSEG Federativo, nesse primeiro momento,
                    os 26 Estados, o Distrito Federal e outros 315 municípios
                    brasileiros, incluídos nesse quantitativo os 120 municípios
                    que concentram 50% dos homicídios no Brasil, segundo o Atlas
                    da Violência, e outros 195 municípios que têm população
                    igual ou superior a 100.000 habitantes, segundo estimativas
                    do IBGE. Confira a lista completa na página “O Programa”.
                  </span>
                </Accordion>
                <Accordion
                  title="3. Meu município não está contemplado na lista de entes elegíveis. O que posso fazer? "
                  faq
                >
                  <p>
                    Os municípios que não estiverem listados como
                    municípios-alvo não poderão aderir ao programa nesse
                    primeiro momento. No entanto, seus servidores (e cidadãos)
                    <a href="https://cloud.mail.iadb.org/idbSubscriptionForm?TForm=F&UTMC=7013s000000jH2FAAU&UTMM=Direct&UTMS=Website&lang=PT">
                      podem se cadastrar na nossa newsletter
                    </a>{' '}
                    para serem notificados sobre as atualizações e próximas
                    fases do programa, bem como receber novidades em relação a
                    novas evidências sobre o que funciona e o que não funciona
                    para melhorar os indicadores de segurança cidadã. Ademais,
                    convidamos a todos para que acessem o{' '}
                    <a href="https://www.e-seguranca.org/">
                      Portal E-Segurança
                    </a>
                    (onde poderão conferir dados e construir um diagnóstico
                    executivo de sua cidade), e a{' '}
                    <a href="https://plataformadeevidencias.iadb.org/">
                      Plataforma de Evidências em Segurança & Justiça
                    </a>{' '}
                    (onde estão reunidas soluções e exemplos de programa com
                    eficácia comprovada para reduzir a violência, a
                    criminalidade e fatores associados).
                  </p>
                </Accordion>
                <Accordion
                  title="4. De que modo a plataforma auxilia a operacionalização do PROSEG Federativo?"
                  faq
                >
                  <p>
                    A plataforma foi estruturada em torno de uma “jornada”, isto
                    é, de uma sucessão de etapas que ajudem os usuários a
                    melhorar os seus entendimentos a respeito do tema da
                    segurança pública e que os oriente na construção de
                    (pré-)propostas de financiamento alinhadas às melhores
                    evidências e às boas práticas existentes.
                  </p>
                  <p>
                    Especificamente, a plataforma se subdivide em quatro etapas
                    subsequentes, a saber:
                  </p>

                  <li>
                    Diagnóstico de Indicadores, onde dados e indicadores
                    relevantes para o tema da segurança pública estarão
                    disponíveis para o acesso de todos os estados e municípios
                  </li>
                  <li>
                    Autoavaliação, operacionalização, a partir de um instrumento
                    de autoavaliação (“checklist de maturidade”), de um processo
                    através do qual os estados e municípios partícipes possam
                    avaliar os seus estágios de maturidade na aplicação de
                    práticas e soluções respaldadas pelas melhores evidências na
                    área de segurança pública
                  </li>
                  <li>
                    Seleção de Soluções, estágio em que os estados e municípios
                    partícipes podem definir, partindo de uma lista padronizada
                    baseada em evidências, as soluções que desejam implementar,
                    tendo em vista os problemas e debilidades identificados em
                    cada território
                  </li>
                  <li>
                    Detalhamento da Pré-proposta, onde os usuários deverão
                    detalhar as soluções selecionadas, através de um formulário
                    pré-determinado, com informações tais como os componentes,
                    entregáveis e custo estimado dos projetos.
                  </li>
                </Accordion>
                <Accordion title="5. Como acessar as linhas de crédito?" faq>
                  <p>
                    Os estados e municípios habilitados que tiverem interesse em
                    acessar as linhas de crédito devem se cadastrar na
                    plataforma, passar por todas as etapas de diagnóstico,
                    avaliação de maturidade, seleção e construção da
                    pré-proposta para, a partir disso, iniciar um processo de
                    negociação formal com a instituição financeira responsável
                    (BID e/ou BNDES).
                  </p>
                </Accordion>
                <Accordion
                  title="6. O que é a segurança baseada em evidências?"
                  faq
                >
                  <p>
                    Trata-se de movimento que preconiza a difusão do uso de
                    evidências científicas para aprofundar a compreensão sobre
                    que políticas, práticas e programas são, efetivamente,
                    respaldados por dados e pesquisas rigorosas, no que diz
                    respeito à sua capacidade de geração de impacto. As soluções
                    ofertadas pelo PROSEG Federativo são baseadas em evidências
                    e, assim sendo, estão alinhadas com o mais rigoroso e
                    recente conhecimento científico acumulado no Brasil e no
                    exterior a respeito do que funciona e do que não funciona
                    para reduzir a criminalidade, a violência e fatores
                    associados. Quer saber mais?{' '}
                    <a href="https://plataformadeevidencias.iadb.org/index.php/pt-br/sobre">
                      Clique aqui.
                    </a>
                  </p>
                </Accordion>
                <Accordion
                  title="7. Meu município tem interesse em obter um empréstimo para um valor superior a 20 milhões. O que fazer?"
                  faq
                >
                  <p>
                    O PROSEG Federativo tem como foco financiar o
                    desenvolvimento de projetos de entre US$ 2 milhões e US$ 20
                    milhões. Entretanto, há outros caminhos e alternativas para
                    obtenção de empréstimos para valores superiores, através de
                    operações de crédito negociadas diretamente com o BID. É o
                    caso do seu estado ou município? Entre em{' '}
                    <a href="mailto:CSJ@idbg.onmicrosoft.com">
                      contato conosco.
                    </a>
                  </p>
                </Accordion>
                <Accordion
                  title="8. Quais tipos de projetos poderão ser apoiados pelo PROSEG Federativo?"
                  faq
                >
                  <p>
                    A linha de crédito compreendida pelo PROSEG Federativo tem
                    como objetivo fortalecer os mecanismos de planejamento,
                    governança e gestão dos projetos de segurança pública, e
                    busca orientar os entes federativos partícipes na construção
                    de projetos que estejam respaldados pelas melhores e mais
                    recentes evidências científicas da área da segurança cidadã
                    e justiça. Assim sendo, a “jornada” preconizada pela
                    plataforma permitirá a seleção, por cada estado/município,
                    das soluções baseadas em evidências mais recomendadas, tendo
                    em vista os problemas e debilidades identificadas em cada
                    caso, especificamente. Estados e municípios também têm
                    autonomia para propor ações e projetos próprios, desde que
                    apresentem evidências e/ou exemplos de programas semelhantes
                    que apontem ou demonstrem o seu potencial, em termos da
                    geração de efeitos positivos para a melhoria das condições
                    de segurança.
                  </p>
                </Accordion>

                <p className="mais-duvidas">
                  Não encontrou sua dúvida? Entre em contato com a gente
                  clicando <a href="mailto:proseg@iadb.org">aqui.</a>
                </p>
              </div>
            </MainContent>
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default PerguntasFrequentes;

import React, { useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../../hooks/auth';

function SignOut() {
    const {signOut} = useAuth()

  useEffect(() => {
    signOut()
  }, []);

  return (
    <></>
  );
}

export default SignOut;

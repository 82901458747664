import React, { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import BreadCrumb from './components/BreadCrumb';
import ExecutiveSynthesis from './components/ExecutiveSynthesis';
import IndicatorsPanel from './components/IndicatorsPanel';
import DetailIndicator from './components/DetailIndicator';
import DashBoardLayout from '../../../components/DashboardLayout';
import Loader from '../../../components/Loader';

import { Container, TitleContainer } from './styles';

const Diagnosis = () => {
  const { user } = useAuth();
  const [diagnostico, setDiagnostico] = useState(null);
  const [detailIndicator, setDetailIndicator] = useState(null);
  const [breadCrumbActive, setBreadCrumbActive] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('Criminalidade e Violência');

  const getDiagnostico = useCallback(async () => {
    try {
      if (user.location_ibge_code) {
        const response = await api.get(
          `/v1/diagnostico/${user.location_ibge_code}/`,
        );
        setDiagnostico(response.data.location);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getDiagnostico();
    }
    const stringParams = decodeURIComponent(window.location.href.split('/')[5]);
    if (stringParams) {
      const params = new URLSearchParams(stringParams);
      const tab = params.get('tab');
      setBreadCrumbActive(Number(tab));
    }
  }, [getDiagnostico, user]);

  const BreadCrumbOptions = [
    { step: 1, name: 'Síntese Executiva', link: '' },
    { step: 2, name: 'Painel de Indicadores', link: '' },
    {
      step: 3,
      name: 'Complementação Local',
      link: '/platform/diagnostico/complementacao-local/',
    },
  ];

  return (
    <DashBoardLayout title="Diagnóstico de Indicadores">
      {loading ? (
        <Loader />
      ) : (
        <>
          {diagnostico && (
            <Container>
              <div className="headerContent">
                <div className="text">
                  <p>
                    O diagnóstico de indicadores é um instrumento quantitativo
                    de identificação e mensuração de problemas relacionados à
                    segurança pública baseada em evidências.
                    <br /> <br />
                    Nesta etapa você poderá visualizar a performance de seu
                    estado/município em uma série de indicadores, bem como
                    analisar o seu desempenho com relação a alguns
                    referenciais/benchmarks (média brasileira, média regional,
                    etc.).
                    <br /> <br />
                    Com base nessas avaliações, a plataforma identificará,
                    automaticamente, uma lista de indicadores para os quais
                    recomendamos maior atenção de sua parte, no que diz respeito
                    à seleção das soluções mais pertinentes para o seu caso,
                    especificamente.
                    <br /> <br />
                    Você também poderá, no passo 03, complementar o diagnóstico
                    padrão (elaborado a partir de dados e estatísticas extraídas
                    de bases públicas de escopo nacional) a partir de
                    indicadores que você calcule localmente.
                  </p>
                </div>

                <TitleContainer>
                  <div className="titleContent">
                    <h1>{diagnostico.name}</h1>
                  </div>
                </TitleContainer>
                <BreadCrumb
                  diagnostico={diagnostico}
                  options={BreadCrumbOptions}
                  setCallBack={setBreadCrumbActive}
                  active={breadCrumbActive}
                />
              </div>

              <div id="mainContent" className="mainContent">
                <div className="content">
                  {breadCrumbActive === 1 && (
                    <ExecutiveSynthesis
                      diagnostico={diagnostico}
                      setBreadCrumbActive={setBreadCrumbActive}
                      tab={tab}
                      setTab={setTab}
                    />
                  )}
                  {breadCrumbActive === 2 && (
                    <IndicatorsPanel
                      diagnostico={diagnostico}
                      setBreadCrumbActive={setBreadCrumbActive}
                      setDetailIndicator={setDetailIndicator}
                      tab={tab}
                      setTab={setTab}
                    />
                  )}
                  {breadCrumbActive === 4 && (
                    <DetailIndicator
                      detailIndicator={detailIndicator}
                      setBreadCrumbActive={setBreadCrumbActive}
                      breadCrumbActive={breadCrumbActive}
                    />
                  )}
                </div>
              </div>
            </Container>
          )}
        </>
      )}
    </DashBoardLayout>
  );
};

export default Diagnosis;

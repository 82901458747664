import React, { useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import Loader from './loader';

function AsyncAutoComplete({ placeholder, emptyMessage, loadOptions }) {
  const formatOptionLabel = ({ label }) => (
    <div dangerouslySetInnerHTML={{ __html: label }} />
  );

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <MdSearch />
      </components.DropdownIndicator>
    );
  };

  const handleChange = useCallback(data => {
    window.location.href = `/diagnostico-indicadores/${data.value}`;
  }, []);

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      placeholder={placeholder}
      components={{ DropdownIndicator }}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      noOptionsMessage={() => emptyMessage}
      loadingMessage={() => <Loader />}
      classNamePrefix="react-select"
    />
  );
}

export default AsyncAutoComplete;

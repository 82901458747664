import React, { useEffect, useCallback, useState } from 'react';
import api from '../../services/api';
import Loader from '../../components/Loader';
import DashBoardLayout from '../../components/DashboardLayout';
import DataTable from 'react-data-table-component';
import AutoAvaliacao from '../../assets/auto-avaliacao.png';
import Diagnostico from '../../assets/diagnostico.png';
import Solucoes from '../../assets/icon_solucoes@2x.png';
import PreProposta from '../../assets/pre-proposta.png';
import CSV from '../../assets/Insight.png';
import Complementacao from '../../assets/complementacao.svg';
import { Container, ButtonsContainer, Button } from './styles';
import TitleInfo from '../../components/TitleInfo';
import { Column, Row } from '../../styles/components';

const MonitoringPanel = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/accounts/users/').then(response => {
      setUsers(response.data.results);
      setLoading(false);
    });
  }, []);

  const DiagnosticRedirect = useCallback(data => {
    window.open(
      `/diagnostico-indicadores/${data.location_ibge_code}`,
      '_blank',
    );
  }, []);

  const LocalComplementationRediect = useCallback(data => {
    window.open(
      `/platform/diagnostico/complementacao-local/${data.id}/`,
      '_blank',
    );
  }, []);

  const ChecklistRedirect = useCallback(data => {
    window.open(`/platform/autoavaliacao/visao-detalhada/${data.id}`, '_blank');
  }, []);

  const SolutionRedirect = useCallback(data => {
    window.open(`/management/solucoes/${data.id}`, '_blank');
  }, []);

  const PreProposalRedirect = useCallback(data => {
    window.open(`/management/pre-proposta/${data.pre_proposal}`, '_blank');
  }, []);

  const InsigthRedirect = useCallback(data => {
    window.open(
      `/platform/selecao-de-solucoes/exportar-relatorio/${data.id}/`,
      '_blank',
    );
  }, []);

  const columns = [
    {
      name: 'Ente',
      sortable: true,
      selector: 'member_name',
    },
    {
      name: 'Usuário',
      sortable: true,
      selector: 'full_name',
    },
    {
      name: 'E-mail',
      sortable: true,
      selector: 'institutional_mail',
    },
    {
      name: 'Tipo de Ente',
      sortable: true,
      selector: 'get_place_acting_display',
    },
    {
      name: 'Status',
      sortable: true,
      selector: 'proseg_status_verbose',
    },
    {
      name: 'Visualizar',
      minWidth: '0px',

      cell: row => (
        <ButtonsContainer>
          <Button onClick={() => DiagnosticRedirect(row)}>
            <TitleInfo
              info="Ver Diagnótico (Indicadores)"
              color="#316fa6"
              icon={Diagnostico}
            />
          </Button>
          <Button onClick={() => LocalComplementationRediect(row)}>
            <TitleInfo
              info="Complementação local"
              color="#cba9e5"
              icon={Complementacao}
            />
          </Button>
          <Button
            onClick={() => ChecklistRedirect(row)}
            blocked={
              !(
                row.proseg_status === 'filling_autoavaliacao' ||
                row.proseg_status !== 'customizing_prepoposal' ||
                row.proseg_status !== 'choosing_solutions'
              ) && row.proseg_status !== 'pending_aproval'
            }
          >
            <TitleInfo
              info="Autoavaliação (Checklist)"
              color="#d29500"
              icon={AutoAvaliacao}
            />
          </Button>
          <Button onClick={() => InsigthRedirect(row)}>
            <TitleInfo
              info="Insights do diagnóstico/checklist"
              blocked={
                !(
                  row.proseg_status === 'filling_autoavaliacao' ||
                  row.proseg_status !== 'customizing_prepoposal' ||
                  row.proseg_status !== 'choosing_solutions'
                ) && row.proseg_status !== 'pending_aproval'
              }
              color="#dab6a9"
              icon={CSV}
            />
          </Button>
          <Button
            onClick={() => SolutionRedirect(row)}
            blocked={
              row.proseg_status === 'filling_autoavaliacao' ||
              row.proseg_status === 'pending_aproval'
            }
          >
            <TitleInfo
              info="Seleção de Soluções"
              color="#4b8623"
              icon={Solucoes}
            />
          </Button>
          <Button
            onClick={() => PreProposalRedirect(row)}
            blocked={
              row.proseg_status === 'choosing_solutions' ||
              row.proseg_status === 'filling_autoavaliacao' ||
              row.proseg_status === 'pending_aproval'
            }
          >
            <TitleInfo info="Pré Proposta" color="#c05710" icon={PreProposta} />
          </Button>
        </ButtonsContainer>
      ),
    },
  ];

  return (
    <Container>
      <DashBoardLayout title="Painel de Acompanhamento">
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Column>
              <DataTable
                columns={columns}
                data={users}
                noDataComponent="Nenhuma Operação de crédito em andamento"
                noHeader
              />
            </Column>
          </Row>
        )}
      </DashBoardLayout>
    </Container>
  );
};

export default MonitoringPanel;

import React, { useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { FaTrash } from 'react-icons/fa';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
  DeleteContainer,
} from './styles';

const DeliverableAccordion = ({
  title,
  onOpen,
  hasDelete,
  active,
  deleteCallBack,
  children,
}) => {
  const handleToggle = useCallback(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Container>
      <AccordionItem onClick={handleToggle}>
        <InitialContainer active={active}>
          <div className="text-container">
            <div>
              <div className="info-container">
                {/* <div className="status-circle"></div>
                <div className="restriction-flag">R</div> */}
                <h1>{title}</h1>
              </div>
              <button type="button" className="icon-container">
                <div className="arrowIcon">
                  <IoIosArrowForward size={25} color="#AAB9BF" />
                </div>
                <DeleteContainer
                  className="delete"
                  onClick={deleteCallBack}
                  show={hasDelete}
                >
                  <FaTrash size={20} color="#FD5454" />
                </DeleteContainer>
              </button>
            </div>
          </div>
        </InitialContainer>
      </AccordionItem>
      <CollapseContainer active={active}>{children}</CollapseContainer>
    </Container>
  );
};

export default DeliverableAccordion;

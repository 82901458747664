import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans !important;
    background: #10385c !important;
    color: #ffffff !important;
    text-align: center !important;
    border-left: 1px solid #d0d0d0 !important;
  }

  .rdt_TableCell {
    text-align: left !important;
    font: normal normal normal 14px/18px IBM Plex Sans !important;
    letter-spacing: 0px !important;
    color: #6d6e71 !important;
    border-left: 1px solid #d0d0d0 !important;
    border-bottom: 1px solid #d0d0d0 !important;
    min-height: 44px !important;

    & > div {
      white-space: inherit !important;
    }
  }
`;

export const TextContainer = styled.div`
  font: normal normal normal 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  opacity: 1;

  p {
    margin-bottom: 25px;
  }

  h3 {
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font: normal normal 600 38px/49px IBM Plex Sans;
  letter-spacing: 0px;
  color: #10385c;
  opacity: 1;
`;

export const AddButon = styled.button`
  margin-bottom: 25px;
  text-align: left;
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #0c9fd8 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  padding: 12px;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }

  &:hover {
    background: #097ca9;
  }
`;

export const TableContainer = styled.div`
  display: grid;
`;

import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { Container, BarItem, InfoLabel, GraphContainer } from './styles';
import { formatDate } from '../../reportService';
import { Row, Column } from '../../../../../styles/components';

function MultiVerticalBar({ data }) {
  const [graphData, setGraphData] = useState(data);
  const isFloat = useCallback(x => !!(x % 1), []);

  useEffect(() => {
    setGraphData(data);
  }, []);

  const changedData = useMemo(() => {
    let returnData = [
      {
        name: formatDate(new Date(), 'dd/m'),
        graphData: [],
      },
    ];
    if (graphData.delay > 0) {
      returnData[0].graphData.push({
        percentage: isFloat(graphData.delay)
          ? graphData.delay.toFixed(0)
          : graphData.delay,
        color: '#EB5B23',
      });
    }
    if (graphData.delay_restriction > 0) {
      returnData[0].graphData.push({
        percentage: isFloat(graphData.delay_restriction)
          ? graphData.delay_restriction.toFixed(0)
          : graphData.delay_restriction,
        color: '#C1856F',
      });
    }
    if (graphData.execution > 0) {
      returnData[0].graphData.push({
        percentage: isFloat(graphData.execution)
          ? graphData.execution.toFixed(0)
          : graphData.execution,
        color: '#5CB24B',
      });
    }
    if (graphData.finalized > 0) {
      returnData[0].graphData.push({
        percentage: isFloat(graphData.finalized)
          ? graphData.finalized.toFixed(0)
          : graphData.finalized,
        color: '#014D90',
      });
    }
    if (graphData.restriction > 0) {
      returnData[0].graphData.push({
        percentage: isFloat(graphData.restriction)
          ? graphData.restriction.toFixed(0)
          : graphData.restriction,
        color: '#F7A643',
      });
    }
    return returnData;
  }, [graphData, isFloat]);

  return (
    <>
      <Container>
        <div className="bars">
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {changedData.map(item => (
              <Column
                small="4"
                medium="4"
                large="4"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <GraphContainer className="graphContainer">
                  <div className="barContainer">
                    <>
                      {item.graphData.map(graph => (
                        <>
                          <BarItem
                            color={graph.color}
                            percentage={graph.percentage}
                          >
                            <span>{graph.percentage}</span>
                          </BarItem>
                        </>
                      ))}
                    </>
                  </div>
                </GraphContainer>
                <span className="graphName">{item.name}</span>
              </Column>
            ))}
          </Row>
        </div>
        <div className="info-labels">
          <>
            <InfoLabel color="#5CB24B">
              <div />
              Em execução
            </InfoLabel>
            <InfoLabel color="#014D90">
              <div />
              Concluído
            </InfoLabel>
            <InfoLabel color="#EB5B23">
              <div />
              Em atraso
            </InfoLabel>
            <InfoLabel color="#F7A643">
              <div />
              Com restrição
            </InfoLabel>
            <InfoLabel color="#C1856F">
              <div />
              Em atraso e com restrição
            </InfoLabel>
          </>
        </div>
      </Container>
    </>
  );
}

export default MultiVerticalBar;

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useLoader } from '../../hooks/loader';
import { useCreditOperation } from '../../hooks/creditOperation';
import { parseISO, format } from 'date-fns';
import { formatPrice } from '../../services/currencyFormatter';
import DashboardLayout from '../../components/DashboardLayout';
import PercentageBar from '../../components/PercentageBar';
import TimePercentageBar from '../../components/TimePercentageBar';
import Button from '../../components/Button';
import Table from '../../components/Table';
import ProjectAcoordion from './ProjectAccordion';
import AddTargetAudience from './modals/AddTargetAudience';
import AddMainGoal from './modals/AddMainGoal';
import ConfirmationModal from '../../components/ConfirmationModal';
import AddDesiredImpacts from './modals/AddDesiredImpacts';
import AddGovernance from './modals/AddGovernance';
import AddAgreedResults from './modals/AddAgreedResults';
import EditAgreedResults from './modals/EditAgreedResults';
import DeliverablesCriticalMilestones from './modals/DeliverablesCriticalMilestones';
import StandardDocuments from '../../components/StandardDocuments';
import api from '../../services/api';
import { IoIosPaper } from 'react-icons/io';
import {
  FaEdit,
  FaInfoCircle,
  FaEnvelope,
  FaFilePdf,
} from 'react-icons/fa';

import {
  Container,
  TitleButton,
  BackButton,
  OptionsContainer,
  Card,
  CardTitle,
  BlueContainer,
  Separator,
  TableContainer,
  ProjectsContainer,
  AddButton,
  HeaderCard,
} from './styles';
import Attention from "../../assets/Attention.svg";

const CreditOperationMonitoring = () => {
  const { goBack, push } = useHistory();
  const { activeLoader } = useLoader();
  const [showTargetAudience, setShowTargetAudience] = useState(false);
  const [showMainGoal, setShowMainGoal] = useState(false);
  const [showDesiredImpacts, setShowDesiredImpacts] = useState(false);
  const [showGovernance, setShowGovernance] = useState(false);
  const [showStandardDocuments, setShowStandardDocuments] = useState(false);
  const [showDeliverables, setShowDeliverables] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showAgreedResults, setShowAgreedResults] = useState(false);
  const [showEditAgreedResults, setShowEditAgreedResults] = useState(false);

  const [projects, setProjects] = useState([]);
  const [modalProject, setModalProject] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [governances, setGovernances] = useState([]);
  const [agreedResults, setAgreedResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState();
  const { creditOperation, updateCreditOperation, reloadCreditOperation } =
    useCreditOperation();

  const getImpacts = useCallback(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/desired-impacts/`;
    api.get(apiUrl).then(response => {
      setImpacts(response.data.results);
    });
  }, [creditOperation.code]);

  const getGovernances = useCallback(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/governance/`;
    api.get(apiUrl).then(response => {
      setGovernances(response.data.results);
    });
  }, [creditOperation.code]);

  const getAgreeedResults = useCallback(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/agreed-results/`;
    api.get(apiUrl).then(response => {
      setAgreedResults(response.data.results);
    });
  }, [creditOperation.code]);

  const getContractedProjects = useCallback(
    async code => {
      const apiUrl = `/credits/api/v1/contracted-project/?operation=${code}`;
      try {
        const response = await api.get(apiUrl);
        const unresolvedPromises = response.data.results.map(async project => {
          const responsibles = await api.get(
            `/credits/api/v1/contracted-project/${project.id}/responsible/`,
          );
          const filteredResponsibles = responsibles.data.results.filter(
            resp => resp.contract_project === Number(project.id),
          );
          project.responsibles = filteredResponsibles;
          return project;
        });
        const promisesResponse = await Promise.all(unresolvedPromises);
        setProjects(promisesResponse);
        activeLoader(false);
      } catch (err) {
        console.error(err);
      }
    },
    [activeLoader],
  );

  useEffect(() => {
    activeLoader(true);
    if (!!Object.keys(creditOperation).length) {
      activeLoader(false);
      getImpacts();
      getGovernances();
      getAgreeedResults();
      getContractedProjects(creditOperation.code);
    }
  }, [
    activeLoader,
    creditOperation,
    getContractedProjects,
    getGovernances,
    getImpacts,
    getAgreeedResults,
  ]);

  useEffect(() => {
    reloadCreditOperation();
  }, []);

  const handleAddSuccess = useCallback(
    data => {
      updateCreditOperation(data);
    },
    [updateCreditOperation],
  );

  const handleAddImpactSuccess = useCallback(
    data => {
      setImpacts([...impacts, data]);
    },
    [setImpacts, impacts],
  );

  const handleDeleteImpact = useCallback(
    data => {
      setImpacts([...data]);
    },
    [setImpacts],
  );

  const handleAddGovernance = useCallback(
    data => {
      setGovernances([...governances, data]);
    },
    [governances],
  );

  const handleDeleteGovernance = useCallback(data => {
    setGovernances([...data]);
  }, []);

  const handleDeleteProject = useCallback(
    async project => {
      try {
        await api.delete(`/credits/api/v1/contracted-project/${project}/`);
        getContractedProjects(creditOperation.code);
      } catch (err) {
        console.error(err);
      }
    },
    [getContractedProjects, creditOperation.code],
  );

  const handleOpenDeleteProject = useCallback(
    async project => {
      try {
        await setProjectId(project);
        setShowDeleteProjectModal(true);
      } catch (err) {
        console.error(err);
      }
    },
    [getContractedProjects],
  );

  const handleDeliverablesModal = useCallback(async project => {
    setProjectId(project.id);
    setModalProject(project);
    setShowDeliverables(true);
  }, []);

  const handleSelectResult = useCallback(
    result => {
      if (!creditOperation.is_delete) {
        setSelectedResult(result);
        setShowEditAgreedResults(true);
      }
    },
    [creditOperation.is_delete],
  );
  
  console.log(creditOperation);
  return (
    <Container>
      <DashboardLayout title="Minha operação de crédito">
        {!!Object.keys(creditOperation).length && (
          <>
            <BackButton onClick={() => goBack()}>Voltar</BackButton>
            <TitleButton>{`(${creditOperation.financing_entity} ${creditOperation.code}) ${creditOperation.operation_title}`}</TitleButton>
            {/* <OptionsContainer style={{display: '-webkit-box'}}>
              <div>
                <FaEdit />
                Editar
              </div>
            </OptionsContainer> */}
            <Card shadow>
              <div className="content">
                <div className="info">
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Público Alvo:
                    </CardTitle>
                    {!!creditOperation.target_public && (
                      <span>{creditOperation.target_public}</span>
                    )}
                    {creditOperation.target_public && (
                      <>
                        <AddButton
                          show={!!(creditOperation.status === 'structuring')}
                          onClick={() => setShowTargetAudience(true)}
                        >
                          Editar
                        </AddButton>
                      </>
                    )}
                    {!creditOperation.target_public && (
                      <AddButton
                        show={
                          !!(
                            creditOperation.status === 'structuring' &&
                            !creditOperation.is_delete
                          )
                        }
                        onClick={() => setShowTargetAudience(true)}
                      >
                        + Adicionar
                      </AddButton>
                    )}
                    {showTargetAudience && (
                      <AddTargetAudience
                        handleClose={() =>
                          setShowTargetAudience(!showTargetAudience)
                        }
                        handleSuccess={data => handleAddSuccess(data)}
                        show={showTargetAudience}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Objetivo Geral:
                    </CardTitle>
                    {!!creditOperation.gen_goal && (
                      <span>{creditOperation.gen_goal}</span>
                    )}
                    {creditOperation.gen_goal && (
                      <>
                        <span>{creditOperation.gen_goal}</span>
                        <AddButton
                          show={!!(creditOperation.status === 'structuring')}
                          onClick={() => setShowMainGoal(true)}
                        >
                          Editar
                        </AddButton>
                      </>
                    )}
                    {!creditOperation.gen_goal && (
                      <AddButton
                        show={
                          !!(
                            creditOperation.status === 'structuring' &&
                            !creditOperation.is_delete
                          )
                        }
                        onClick={() => setShowMainGoal(true)}
                      >
                        + Adicionar
                      </AddButton>
                    )}
                    {showMainGoal && (
                      <AddMainGoal
                        handleClose={() => setShowMainGoal(!showMainGoal)}
                        handleSuccess={data => handleAddSuccess(data)}
                        show={showMainGoal}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Impactos Desejados:
                    </CardTitle>
                    {impacts.length >= 1 && (
                      <>
                        {impacts.map(impacto => (
                          <div key={impacto.id}>
                            <p style={{ marginBottom: '8px' }}>
                              {impacto.impact_indicator.impact_name}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                    <AddButton
                      show={
                        !!(
                          creditOperation.status === 'structuring' &&
                          !creditOperation.is_delete
                        )
                      }
                      onClick={() => setShowDesiredImpacts(true)}
                    >
                      editar
                    </AddButton>
                    {showDesiredImpacts && (
                      <AddDesiredImpacts
                        handleClose={() =>
                          setShowDesiredImpacts(!showDesiredImpacts)
                        }
                        handleSuccess={data => handleAddImpactSuccess(data)}
                        handleDelete={data => handleDeleteImpact(data)}
                        show={showDesiredImpacts}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Governança (Linha Gerencial):
                    </CardTitle>
                    {governances.map(governance => (
                      <div key={governance.id}>
                        <p>
                          <FaEnvelope color="#0C9FD8" size={16} />
                          <b>{governance.job}:&nbsp;</b> {governance.name}
                        </p>
                      </div>
                    ))}
                    <AddButton
                      show={
                        !!(
                          creditOperation.status === 'structuring' &&
                          !creditOperation.is_delete
                        )
                      }
                      onClick={() => setShowGovernance(true)}
                    >
                      editar
                    </AddButton>
                    {showGovernance && (
                      <AddGovernance
                        handleClose={() => setShowGovernance(!showGovernance)}
                        handleSuccess={data => handleAddGovernance(data)}
                        handleDelete={data => handleDeleteGovernance(data)}
                        show={showGovernance}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>
                </div>
                <div className="cards">
                  {creditOperation.status === 'structuring' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="red">
                        Projeto em Estruturação
                      </HeaderCard>
                    )}

                  {creditOperation.status === 'execution' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="dark-green">
                        Estágio de Execução
                      </HeaderCard>
                    )}

                  {creditOperation.status === 'done' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="dark-green">
                        Projeto Concluido
                      </HeaderCard>
                    )}

                  {creditOperation.is_delete && (
                    <HeaderCard background="text">Projeto Inativo</HeaderCard>
                  )}

                  <div className="content">
                    <>
                      <div className="bar-container">
                        <PercentageBar
                          percentage={creditOperation.execution.physics}
                          title="Execução Fisica(%)"
                          color="yellow"
                        />
                      </div>
                      <div className="bar-container">
                        <PercentageBar
                          percentage={creditOperation.execution.finance}
                          title="Execução Financeira(%)"
                          color="green"
                        />
                      </div>
                      <div className="bar-container">
                        <PercentageBar
                          percentage={(creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100) > 100 ? 100 : (creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100)}
                          color="blue"
                          title="Tempo(%)"
                        />
                      </div>

                      {
                        (creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100) > 100 && (
                          <>
                            <div className="bar-container">
                              <span><img src={Attention} width="15px" style={{ paddingRight: "5px" }} />Atraso(%)</span>
                            </div>
                            <div className="bar-container">
                              <div style={{
                                maxWidth: "100%",
                                backgroundColor: "#FC6B6B",
                                color: "#fff",
                                minWidth: "50px",
                                borderRadius: "3px",
                                width: (creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100) - 100
                              }}>
                                {((creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100) - 100).toFixed(2)}
                              </div>
                            </div>
                          </>
                        )
                      }
                    </>
                  </div>
                  <BlueContainer>
                    <CardTitle>Montante Financeiro</CardTitle>
                    <span>
                      <b>Valor Total:&nbsp;</b>
                      {`${formatPrice(creditOperation.global_value.value)}`}
                    </span>
                    <span>
                      <b>Programação Financeira:&nbsp;</b>

                      {creditOperation.status !== 'done' &&
                        !creditOperation.is_delete && (
                          <AddButton
                            show
                            onClick={() =>
                              push('/projects/execucao-financeira')
                            }
                          >
                            Editar
                          </AddButton>
                        )}
                    </span>
                  </BlueContainer>
                  <BlueContainer>
                    <CardTitle>
                      Data de início e conclusão da operação
                    </CardTitle>
                    <span>
                      <b>Data de inicio:&nbsp;</b>
                      {format(
                        parseISO(creditOperation.start_date),
                        'dd/MM/yyyy',
                      )}
                    </span>
                    <span>
                      <b>Data de Termino:&nbsp;</b>
                      {format(parseISO(creditOperation.end_date), 'dd/MM/yyyy')}
                    </span>
                  </BlueContainer>
                  <Button
                    color="gray3"
                    onClick={() => setShowStandardDocuments(true)}
                  >
                    <IoIosPaper /> Acessar Documentos de Referência
                  </Button>
                  {showStandardDocuments && (
                    <StandardDocuments
                      handleClose={() =>
                        setShowStandardDocuments(!showStandardDocuments)
                      }
                      show={showStandardDocuments}
                      creditOperation={creditOperation}
                    />
                  )}
                  <Link
                    to={`/relatorio-executivo-2/${creditOperation.code}/`}
                    target="__blank"
                    color="dark-blue2"
                    className="linkButton"
                  >
                    <FaFilePdf /> Gerar Relatório Executivo
                  </Link>
                </div>
              </div>
              <Separator />
              <TableContainer>
                <CardTitle>
                  <FaInfoCircle />
                  Resultados Pactuados:
                </CardTitle>
                <Table
                  isEdit={!(creditOperation?.status === 'done' || creditOperation?.is_delete === true)}
                  data={agreedResults}
                  resultCallback={handleSelectResult}
                />
                <AddButton
                  show={
                    !!(
                      creditOperation.status === 'structuring' &&
                      !creditOperation.is_delete
                    )
                  }
                  onClick={() => setShowAgreedResults(true)}
                >
                  + Adicionar
                </AddButton>
                {showAgreedResults && (
                  <AddAgreedResults
                    handleClose={() => setShowAgreedResults(!showAgreedResults)}
                    handleSuccess={data => getAgreeedResults(data)}
                    show={showAgreedResults}
                    operation={creditOperation}
                  />
                )}
              </TableContainer>
              <Separator />
              <ProjectsContainer>
                <CardTitle>
                  <FaInfoCircle />
                  Projetos Contratados:
                </CardTitle>
                {!!projects.length && (
                  <ProjectAcoordion
                    projects={projects}
                    creditOperation={creditOperation}
                    handleOpenDeleteProject={handleOpenDeleteProject}
                    handleDeliverablesModal={handleDeliverablesModal}
                  />
                )}
                {showDeliverables && (
                  <DeliverablesCriticalMilestones
                    handleClose={() => setShowDeliverables(!showDeliverables)}
                    projectId={projectId}
                    project={modalProject}
                    operation={creditOperation}
                    show={showDeliverables}
                    handleGetProjects={getContractedProjects}
                    handleGetCreditOperation={reloadCreditOperation}
                    isUpdate={!(creditOperation?.status === 'done' || creditOperation?.is_delete === true)}
                    isCompleteDelivery={!(creditOperation?.status === 'done' || creditOperation?.is_delete === true)}
                    isEditComments={!(creditOperation?.status === 'done' || creditOperation?.is_delete === true)}
                    isPendingRestrictionsFinish={!(creditOperation?.status === 'done' || creditOperation?.is_delete === true)}
                  />
                )}
                {showDeleteProjectModal && (
                  <ConfirmationModal
                    handleClose={() =>
                      setShowDeleteProjectModal(!showDeleteProjectModal)
                    }
                    handleSuccess={() => handleDeleteProject(projectId)}
                    show={showDeleteProjectModal}
                    title="Você tem certeza que deseja excluir esse projeto?"
                  />
                )}
                <AddButton
                  show={
                    !!(
                      creditOperation.status === 'structuring' &&
                      !creditOperation.is_delete
                    )
                  }
                  onClick={() => push('/projects/projetos-contratados')}
                >
                  + Adicionar
                </AddButton>
              </ProjectsContainer>
              {showEditAgreedResults && (
                <EditAgreedResults
                  handleClose={() =>
                    setShowEditAgreedResults(!showEditAgreedResults)
                  }
                  agreedResult={selectedResult}
                  handleSuccess={data => getAgreeedResults(data)}
                  show={showEditAgreedResults}
                  operation={creditOperation}
                />
              )}
            </Card>
          </>
        )}
      </DashboardLayout>
    </Container>
  );
};

export default CreditOperationMonitoring;

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, AsideMenu } from './styles';
import Logo from '../../../assets/logo_plataforma_novo.png';
import { FaUsersCog } from 'react-icons/fa';
import { MdForum } from 'react-icons/md';
import { IoIosPaper } from 'react-icons/io';
import { ReactComponent as Book } from '../../../assets//book.svg';
import { FiMonitor } from 'react-icons/fi';

const AdminAside = ({ setMenu }) => {
  return (
    <Container>
      <div className="aside_close-icon" onClick={setMenu}>
        <strong>&times;</strong>
      </div>
      <div className="logo-container">
        <img src={Logo} alt="plataforma de segurança" />
      </div>
      <AsideMenu>
        <li className="icon-link home">
          <div className="svg">
            <IoIosPaper color="white" size={22} />
          </div>
          <div className="link">
            <Link to="/management/painel-monitoramento">
              Construção de Pré-Proposta
            </Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/painel-monitoramento">
              Painel de Acompanhamento
            </Link>
          </div>
        </li>
      </AsideMenu>
      <AsideMenu>
        <li className="icon-link home">
          <div className="svg">
            <FaUsersCog color="white" size={22} />
          </div>
          <div className="link">
            <a href="/platform/gestao/usuarios/">Gestão de Usuários</a>
          </div>
        </li>
      </AsideMenu>
      <AsideMenu>
        <li className="icon-link home">
          <div className="svg">
            <MdForum color="white" size={22} />
          </div>
          <div className="link">
            <a href="/platform/forum/">Fórum</a>
          </div>
        </li>
      </AsideMenu>
      <AsideMenu>
        <li className="icon-link home">
          <div className="svg">
            <FiMonitor color="white" size={22} />
          </div>
          <div className="link">
            <Link to="/management">Gestão e Monitoramento</Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/operacoes-credito">
              Monitoramento das Operações de Crédito
            </Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/monitoramento-impactos">
              Monitoramento dos Impactos
            </Link>
          </div>
        </li>
      </AsideMenu>
      <AsideMenu>
        <li className="icon-link home">
          <div className="svg">
            <Book color="white" />
          </div>
          <div className="link">
            <Link to="/management/nucleo-conhecimento">
              Núcleo de Conhecimento
            </Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/repositorio-documentos">
              Repositório de Documentos
            </Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/plataforma-ensino">Plataforma de Ensino</Link>
          </div>
        </li>
        <li className="icon-link links">
          <div className="svg" />
          <div className="link">
            <Link to="/management/banco-duvidas">Banco de Dúvidas</Link>
          </div>
        </li>
      </AsideMenu>
    </Container>
  );
};

export default AdminAside;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  button {
    width: 100%;
    cursor: pointer;
    width: 153px !important;
    height: 27px !important;
    background: #6d6e71 0% 0% no-repeat padding-box !important;
    border-radius: 9px !important;
    font-size: 12px;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    display: flex;
    top: 0;
    width: 100%;
    height: 40px;
    cursor: pointer;
  }

  span {
    color: #e83f5b;
  }
`;

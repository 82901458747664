import styled from 'styled-components';

export const Container = styled.div`
  .askContainer {
    h2 {
      text-align: left;
      font: normal normal normal 22px/29px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
      opacity: 1;
      margin-top: 25px;
    }
  }
`;

export const Topic = styled.div`
  background: #f1f1f1;
  display: flex;
  align-items: center;
  height: 69px;
  padding: 0 17px;

  h1 {
    font: normal normal bold 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;
  }
`;

export const Ask = styled.div`
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d0d0d0;
  margin-bottom: 20px;

  h2 {
    text-align: left;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #004e70;
    transition: color 0.3s ease;
    opacity: 1;
    cursor: pointer;
    margin: 10px 0;

    &:hover {
      color: #082b3a;
    }
  }

  .count {
    display: flex;
    flex-direction: column;

    align-items: center;

    font: normal 20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #004e70;
    margin: 10px 0;

    span {
      font: normal normal normal 14px IBM Plex Sans;
      letter-spacing: 0px;
      color: #aab9bf;
    }
  }
`;

export const Button = styled.div`
  width: 362px;
  background: #6d6e71;
  padding: 15px;
  border-radius: 9px;
  text-align: left;
  font: normal normal bold 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #464648;
  }
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin-top: 31px;
  background: transparent;
  margin-bottom: 15px;
`;

export const AnswerCard = styled.div`
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ccdbe2;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;

  small {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #004e70;
    opacity: 1;
    margin-bottom: 10px;
  }
  .likeContainer {
    display: flex;
    margin-top: 15px;

    .rotate {
      transform: rotate(180deg);
    }

    .up,
    .down {
      margin-right: 20px;
      display: flex;
      align-items: flex-end;
      cursor: pointer;
    }

    .down {
      margin-top: 5px;
    }

    small {
      color: #aab9bf;
      font: normal normal normal 15px/19px IBM Plex Sans;
      margin-bottom: 0 !important;
    }

    svg {
      margin-right: 5px;
      path {
        fill: #ccdbe2;
      }
    }
  }
`;

import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div``;

export const TabsContainer = styled.div`
  display: flex;
`;

export const Tab = styled.button`
  background: ${props => (props.active ? '#6d6e71' : '#d0d0d0')};
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  text-align: left;
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 3px;
  transition: background 0.3s ease;
  animation: ${EaseIn} 0.5s;

  &:hover {
    background: #6d6e71;
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;
  animation: ${EaseIn} 0.3s;

  header {
    padding: 10px 16px;
    color: var(--white);
    height: 52px;
    font: normal normal bold 19px/24px IBM Plex Sans;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .content {
    padding: 15px;
    display: flex;

    .info {
      width: 55%;
      padding-right: 20px;

      .container-text {
        margin-bottom: 30px;
        font-size: 1.1rem;

        p,
        span {
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .cards {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .content {
        border: 1px solid #f1f1f1;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        padding: 15px 25px;
      }

      button {
        justify-content: center;
        margin-top: 10px;
      }
    }

    .buttonContainer {
      display: block;
    }
  }
`;

import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import DashBoardLayout from '../../components/DashboardLayout';
import CreateNewDocument from './modals/CreateNewDocument';
import DataTable from 'react-data-table-component';
import {
  Container,
  TextContainer,
  Title,
  AddButon,
  TableContainer,
} from './styles';
import { Column, Row } from '../../styles/components';
import { ImPlus } from 'react-icons/im';
import { MdFileDownload } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';

const DocumentRepository = () => {
  const [documents, setDocuments] = useState([]);
  const [show, setShow] = useState(false);

  const getDocuments = useCallback(async () => {
    try {
      const apiUrl = '/learning/api/v1/documents/';
      const response = await api.get(apiUrl);
      setDocuments(response.data.results);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const deleteDocument = useCallback(async (id) => {
    const apiUrl = `learning/api/v1/documents/${id}/`;
    await api.delete(apiUrl);
  }, [])

  useEffect(() => {
    getDocuments();
  }, []);

  const handleAddSuccess = useCallback(() => {
    getDocuments();
  }, []);

  const columns = [
    {
      name: 'Documento',
      selector: 'name',
      sortable: true,
      minWidth: '0px',
      cell: row => (
        <div style={{ width: '100%' }}>
          <b>{row.name}</b>
        </div>
      ),
    },
    {
      name: 'Tipo de Documento',
      sortable: true,
      selector: 'type_doc',
      cell: row => <div>{row.name_type}</div>,
    },
    {
      name: 'Assunto',
      sortable: true,
      selector: 'subject',
    },
    {
      name: 'Adicionado por',
      sortable: true,
      selector: 'org',
    },
    {
      name: 'Data de Inclusão',
      sortable: true,
      selector: 'date',
      cell: row => (
        <div style={{ width: '100%' }}>
          {`${row.date.split('-')[2]}/${row.date.split('-')[1]}/${
            row.date.split('-')[0]
          }`}
        </div>
      ),
    },
    {
      name: 'Ações',
      selector: 'file',
      minWidth: '0px',
      maxWidth: '80px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <a href={row.file} target="_blank" rel="noreferrer" download>
            <MdFileDownload
              style={{ cursor: 'pointer' }}
              color="#0C9FD8"
              size={25}
            />
          </a>
          
          <MdDelete
            onClick={async () => {
              await deleteDocument(row.id)
              await getDocuments();
            }}
            style={{ cursor: 'pointer' }}
            color="#0C9FD8"
            size={25}
          />

        </div>
      ),
    },
  ];

  return (
    <Container>
      <DashBoardLayout title="Repositório de Documentos">
        <Row>
          <Column large="12" medium="12" small="12">
            <TextContainer>
              <p>
                O Repositório de Documentos reúne documentos padrão utilizados
                pelas instituições financiadoras para garantir a conformidade
                com os princípios da economia, transparência e eficiência na
                execução dos projetos financiados pela Operação de Crédito.
                Estes documentos contêm instruções e disposições sobre a gestão
                dos recursos financeiros e a execução das atividades a fim de
                garantir a transparência e integridade na relação entre o banco
                e o mutuário.
              </p>
              <p>
                Esta seção também conta com um ambiente de colaboração entre os
                entes, onde é possível que estados e municípios contribuam com
                modelos de documentos frequentemente utilizados pelas operações
                de crédito, tais como atas de registro de preço, editais, termos
                de referência, entre outros.
              </p>
            </TextContainer>
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <Title>Documentos modelos dos entes participantes</Title>
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <AddButon onClick={() => setShow(true)}>
              Cadastrar Novo Documento <ImPlus size={16} />
            </AddButon>
          </Column>
          <TableContainer>
            <DataTable
              columns={columns}
              data={documents}
              noDataComponent="Nenhum Documento Cadastrado"
              noHeader
            />
          </TableContainer>
        </Row>
        {show && (
          <CreateNewDocument
            handleClose={() => setShow(!show)}
            handleSuccess={() => handleAddSuccess()}
            show={show}
          />
        )}
      </DashBoardLayout>
    </Container>
  );
};

export default DocumentRepository;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../../../services/api';
import IndicatorCard from '../IndicatorCard';
import Graphic from '../Graphic';
import Loader from '../../../../../components/Loader';
import { Container, DetailHeaderContainer, GraphicContainer } from './styles';

function DetailIndicator({ detailIndicator, setBreadCrumbActive }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const initDetail = useCallback(async url => {
    try {
      const response = await api.get(url);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, []);

  const goBack = useCallback(() => {
    setBreadCrumbActive(2);
    setTimeout(function () {
      document.getElementById('IndicatorsPanel').scrollIntoView();
    }, 20);
  }, [setBreadCrumbActive]);

  useEffect(() => {
    initDetail(detailIndicator.detail);
  }, [detailIndicator, initDetail]);

  console.log({
    data
  })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container id="detailContainer">
          {/* <Link
            to="/metodologia/indicador-sintetico"
            className="redirectLink"
            style={{ marginBottom: '10px' }}
          >
            Saiba mais sobre os indicadores selecionados
          </Link> */}
          <div>
            <a
              href="#"
              onClick={goBack}
              className="redirectLink"
              style={{ marginBottom: '10px' }}
            >
              Voltar ao Painel de indicadores
            </a>
          </div>
          <DetailHeaderContainer>
            <div className="cardContainer">
              <IndicatorCard indicator={detailIndicator} />
            </div>
            <div className="titleContainer">
              <span>Indicador</span>
              <h1>{detailIndicator.indicator.complete_name}</h1>
            </div>
          </DetailHeaderContainer>
          <GraphicContainer>
            {data.chart && (
              <Graphic
                title={data.chart_title}
                subtitle={data.chart_subtitle}
                description={data.description}
                data={data.chart}
                type={data.chart_type}
                calculation_formula={data.calculation_formula}
                content={data.content}
              />
            )}
            {data.secundary_chart && (
              <Graphic
                title={data.secundary_chart_title}
                subtitle={data.secundary_chart_subtitle}
                description=""
                data={data.secundary_chart}
                type={data.secundary_chart_type}
                calculation_formula={data.calculation_formula}
              />
            )}
          </GraphicContainer>
        </Container>
      )}
    </>
  );
}

export default DetailIndicator;

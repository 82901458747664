import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 362px;
  display: flex;
`;

export const Button = styled.a`
  align-items: center;
  justify-content: center;
  background: #6d6e71;
  padding: 15px;
  border-radius: 9px;
  text-align: left;
  font: normal normal bold 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #464648;
  }

  @media screen and (max-width: 1024px) {
    font: normal normal bold 14px/23px IBM Plex Sans;
  }
`;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import DashBoardLayout from '../../components/DashboardLayout';
import { Container, Topic, Ask } from './styles';
import { useAuth } from '../../hooks/auth';
import Mailto from '../../components/Mailto';
import { Column, Row } from '../../styles/components';
import api from '../../services/api';

const BankOfDoubts = () => {
  const { push } = useHistory();
  const { user } = useAuth();
  const [topics, setTopics] = useState([]);
  const [formatedTopics, setFormatedTopics] = useState([]);

  const getTopics = useCallback(async () => {
    try {
      const topics = await api.get('/learning/api/v1/topics/');
      setTopics(topics.data.results);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getTopics();
  }, []);

  useMemo(async () => {
    const FormatedTopics = topics.map(async topic => {
      const asks = await api.get(`/learning/api/v1/ask/?topic=${topic.id}`);
      return { ...topic, asks: asks.data.results };
    });
    const promisesResponse = await Promise.all(FormatedTopics);
    setFormatedTopics(promisesResponse);
  }, [topics]);

  return (
    <Container>
      <DashBoardLayout title="Banco de Dúvidas">
        <Row>
          <Column large="12" medium="12" small="12">
            {formatedTopics.map(topic => (
              <div key={topic.id}>
                <Topic>
                  <h1>{topic.name}</h1>
                </Topic>
                {topic.asks.map(ask => (
                  <Ask key={ask.id}>
                    <h2
                      onClick={() =>
                        push(
                          `${
                            user.is_admin ? '/management' : '/projects'
                          }/banco-duvidas/${topic.id}`,
                        )
                      }
                    >
                      {ask.description}
                    </h2>
                    <div className="count">
                      {ask.answer_ask.length}
                      <span>Respostas</span>
                    </div>
                  </Ask>
                ))}
              </div>
            ))}
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <Mailto mailto="mailto:macroplan@gmail.com">
              Não encontrou sua dúvida? Clique aqui
            </Mailto>
          </Column>
        </Row>
      </DashBoardLayout>
    </Container>
  );
};

export default BankOfDoubts;

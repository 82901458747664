import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 5px;
    min-height: 50px;
    padding: 15px;
    background: ${props => (props.root ? `#f1f1f1` : '#dceaf0')};

    p {
      text-align: left;
      font: normal normal bold 14px/18px IBM Plex Sans;
      letter-spacing: 0px;
      color: #aab9bf;
      margin-top: 10px;

      button {
        font: normal normal bold 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: #0c9fd8;
        margin-left: 10px;
        opacity: 1;
        transition: color 0.3s ease;
        background: transparent;

        &:hover {
          color: #0b6588;
        }
      }
    }

    & > div {
      background: #dceaf0;
      margin: -15px;
      padding: 15px;
    }
  }
`;

export const Item = styled.div`
  background: ${props => (props.type === 'root' ? `#f1f1f1` : '#dceaf0')};
`;

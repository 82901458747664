import styled from 'styled-components';

export const Container = styled.div``;

export const BannerContainer = styled.div`
  img {
    height: auto;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #6d6e71;
  opacity: 1;
  font-size: 18px;

  p {
    margin-bottom: 25px;
  }

  h3 {
    font-weight: bold;
  }
`;

export const ImageCard = styled.div`
  img {
    height: auto;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: -1px 13px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }
`;

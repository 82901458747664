import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  .sub-title {
    font-size: 24px;
    font-weight: bold;
  }
  @media screen and (max-width: 994px) {
    h1 {
      text-align: center;
    }
  }
  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
  .select {
    margin-top: 20px;
  }
  .select-1 {
    width: 300px;
  }
  .select-wrapper {
    display: flex;
  }
  @media only screen and (max-width: 666px) {
    .select-wrapper {
      flex-direction: column;
      width: 100%;
    }
    .select-2 {
      margin-left: 0px !important;
    }
  }
  .select-2 {
    width: 300px;
    margin-left: 30px;
    margin-bottom: 50px;
  }
  Form {
    display: flex;
  }
  .react-select__placeholder,
  .react-select__single-value {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
  .react-select__control {
    background: #004e70;
    border: 0;
    border-radius: 5px;
  }
  .react-select__indicator {
    font-size: 24px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .react-select__indicator-separator {
    background-color: transparent;
    width: 0px;
  }
`;

export const Content = styled.div`
  p {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #6d6e71;
    width: 100%;
  }
`;

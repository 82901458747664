import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    background: var(--white) !important;
  }

  form {
    display: flex;
    flex-direction: column;

    .save {
      background: #04547c 0% 0% no-repeat padding-box;
      border-radius: 9px;
      width: 206px;
      align-self: flex-end;
      color: #ffffff;
      margin-top: 20px;
      padding: 15px;
      font: normal normal bold 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
`;

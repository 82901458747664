import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  animation: ${EaseIn} 0.3s;

  .mb0 {
    margin-bottom: 0;
  }

  .graphSpan {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }

  .flex-column {
    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #0b5e83;
    color: #ffffff;
    text-align: center;
  }

  .rdt_TableRow {
    min-height: 37px;
    border-bottom: 0.5px solid #f1f1f1;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;

    & > div {
      white-space: inherit;
    }
  }

  .border-dashed {
    border-right: 2px dashed #d0d0d0;
  }

  .desenpenhoCard {
    min-height: 322px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const Header = styled.div`
  background: #f8f8f8;
  min-height: 235px;
  height: 462px;
`;

export const HeaderContent = styled.section`
  padding-top: 27px;
  max-width: 1004px;
  margin: 0 auto;

  .optionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .back {
      text-align: left;
      text-decoration: underline;
      font: normal normal normal 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
      opacity: 1;
      display: ${props => (props.printing ? 'none' : 'block')};
    }
  }

  .content {
    align-items: center;
    justify-content: space-between;
    max-width: 1004px;
    margin: 0 auto;

    .img-container {
      border-radius: 5px;
      width: 190px;
      margin: 10px 0;

      img {
        width: 100%;
        margin-left: 26px;
      }
    }

    .textContent {
      h2 {
        text-align: left;
        font: normal normal bold 25px/33px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        opacity: 1;
      }

      h1 {
        text-align: left;
        font: normal normal bold 55px/71px IBM Plex Sans;
        letter-spacing: 0px;
        color: #00a9ee;
      }

      .ranking {
        max-width: 190px;
        height: 38px;
        background: #00a9ee;

        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15px;

        small {
          text-align: left;
          font: normal normal medium 16px/25px IBM Plex Sans;
          letter-spacing: 0px;
          color: #f8f8f8;
        }

        span {
          text-align: left;
          font: normal normal bold 20px/25px IBM Plex Sans;
          letter-spacing: 0px;
          color: #f8f8f8;
        }
      }
    }

    .left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .graphContent {
      display: flex;
      align-items: center;
      small {
        text-align: center;
        font: normal normal normal 22px/29px IBM Plex Sans;
        letter-spacing: 0px;
        color: #aab9bf;
        opacity: 1;
        margin-right: 5px;
      }
    }
  }
`;

export const Main = styled.div``;

export const MainContent = styled.div`
  margin-top: 15px;
  max-width: 1004px;
  margin: 0 auto;
`;

export const PrintDiv = styled.div`
  width: ${props => (props.printing ? '1100px' : 'inherit')};
`;

export const Card = styled.section`
  background: var(--white);

  box-shadow: ${props => !props.printing && '0px 1px 6px #00000029'};
  border: ${props => props.printing && `1px solid #6d6e71`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  text-align: left;
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #0c9fd8;
  margin: 14px 0;
  display: flex;
  align-items: center;

  .iconContainer {
    background: #0c9fd8;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
`;

export const GraphicsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 10px 15px 10px;
  h1 {
    text-align: center;
    font: normal normal bold 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    margin-bottom: 10px;
  }

  .graphicContainer1 {
    border-bottom: 2px solid #eaeaea;
    height: 80px;
  }
`;

export const DonutContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  .graphs {
    display: flex;
  }

  h1 {
    text-align: center;
    font: normal normal bold 17px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    min-height: 46px;
  }

  small {
    margin-top: 5px;
  }

  .smallDonut {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .donut {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  span {
    text-align: left;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    padding: 10px 0;
  }
`;

export const OperationCard = styled.div`
  background: #ffffff;
  box-shadow: ${props => !props.printing && '0px 1px 6px #00000029'};
  border: ${props =>
    props.project ? '1px solid #6D6E71' : '1px solid #00a9ee'};
  border-radius: 10px;
  padding: 18px;

  text-align: left;
  font: normal normal bold 23px/30px IBM Plex Sans;
  letter-spacing: 0px;
  color: ${props => (props.project ? '#6D6E71' : '#00a9ee')};

  margin: 10px 0;
`;

export const HeaderCard = styled.section`
  background: #e0e0e0;

  box-shadow: ${props => !props.printing && '0px 1px 6px #00000029'};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 10px;
  min-height: 40px;

  b {
    color: #333333;
  }
`;

export const ProjectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 93%;
  }

  .buttonContainer {
    width: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  h1 {
    font: normal normal normal 23px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }

  .lineContainer {
    width: 290px;
  }
`;

export const EntregableContainer = styled.div`
  .titleContainer {
    background: #1c5e83;
    color: #ffffff;
    padding: 16px;
    font-weight: bold;
  }
`;

export const StatusSpan = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => props.color};
`;

import React, { useRef, useEffect, useCallback, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core';
import * as Yup from 'yup';
import { Container, Label, Error } from './styles';

const InputMask = ({ name, label, icon: Icon, schema, disabled, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current);
  }, []);

  const handleChange = useCallback(async () => {
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        inputRef.current.value,
        { abortEarly: true },
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <Container
        disabled={disabled}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isCorrect={isCorrect}
      >
        <ReactInputMask
          disabled={disabled}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleChange}
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />
        {Icon && <Icon size={20} />}
      </Container>
      {!isCorrect && <Error>{error}</Error>}
    </>
  );
};

export default InputMask;

import React, { useState } from 'react';

import { Container, TitleText } from './styles';
import { FaInfoCircle } from 'react-icons/fa';

function TitleInfo({ info, icon, color }) {
  const [showTitle, setShowTitle] = useState(false);

  return (
    <Container
      onMouseEnter={() => setShowTitle(true)}
      onMouseLeave={() => setShowTitle(false)}
    >
      <TitleText show={showTitle} icon={!!icon} color={color}>
        {info}
      </TitleText>
      {!!icon ? (
        <img src={icon} alt="img" />
      ) : (
        <FaInfoCircle size={20} color="#3a7cac" />
      )}
    </Container>
  );
}

export default TitleInfo;

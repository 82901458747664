import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  small {
    margin-left: 8%;
    margin-right: 20px;
    width: 20%;
    font: normal normal normal 18px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    text-align: right;
  }

  span {
    font: normal normal bold 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 10px;
    margin-right: 10%;
  }

  & > div {
    flex: 1;
    height: 21px;
    border-radius: 4px;

    position: relative;
    transition: all 0.5s ease;
  }
`;

export const Title = styled.h1`
  font: normal normal normal 14px/18px IBM Plex Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 5px;
`;

export const Bar = styled.div`
  width: ${props => `${props.percentage}%`};
  background: ${props => props.color};
  height: 21px;
  border-radius: 4px;
  transition: all 0.5s ease;
`;

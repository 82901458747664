import styled from 'styled-components';

export const Container = styled.div`
  .card3,
  .card4,
  .card5 {
    background: #f8f8f8;
    cursor: pointer;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        text-align: center;
        font: normal normal bold 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: var(--dark-blue);
        margin: 15px 5px;
      }

      p {
        text-align: center;
        font: normal normal normal 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        opacity: 1;
      }
    }
  }
`;

export const ManagementGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 23px;
  grid-template-areas:
    'card1 card2 card2'
    'card3 card4 card5';

  .card1 {
    grid-area: card1;
    width: 100%;
    min-height: 464px;

    header {
      background: var(--blue);

      h1 {
        text-align: left;
        font: normal normal bold 20px/25px 'IBM Plex Sans';
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    .content {
      .info-container {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        svg {
          margin-right: 8px;
          path {
            fill: var(--gray2);
          }
        }

        span {
          text-align: left;
          font: normal normal normal 14px/30px IBM Plex Sans;
          letter-spacing: 0px;
          color: #6d6e71;

          .green {
            color: var(--green);
          }

          .blue {
            color: var(--blue);
          }
        }
      }

      .bar-container {
        span {
          margin-bottom: 15px;
        }
      }

      .link {
        display: flex;
        align-items: center;
        color: var(--red);
        margin-bottom: 20px;

        font: normal normal normal 12px/15px IBM Plex Sans;
        letter-spacing: 0px;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .card2 {
    grid-area: card2;
    width: 100%;

    .content {
      height: 100%;

      h1 {
        text-align: left;
        font: normal normal bold 29px/23px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        margin-bottom: 30px;
        line-height: 1.1;
      }

      p {
        text-align: left;
        font: normal normal normal 18px/23px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
      }

      button {
        margin-top: 50px;
      }
    }
  }
  .card3 {
    grid-area: card3;
    width: 100%;
    background: #f8f8f8;
  }
  .card4 {
    grid-area: card4;
    width: 100%;
    background: #f8f8f8;
  }
  .card5 {
    grid-area: card5;
    width: 100%;
    background: #f8f8f8;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'card1'
      'card2'
      'card3'
      'card4'
      'card5';

    .card1 {
      min-height: inherit;
    }
  }
`;

export const Card = styled.section`
  background: var(--white);

  box-shadow: ${props => (props.noShadow ? 0 : '0px 1px 6px #00000029')};

  border-radius: 10px;
  overflow: hidden;

  header {
    padding: 10px 16px;
  }

  .content {
    padding: 15px;
  }
`;

export const AdminButtons = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    height: 180px;
    border: 2px dashed #aab9bf;
    background: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--gray);
    }
  }
  span {
    max-width: 195px;
    margin-top: 15.5px;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #aab9bf;
    opacity: 1;
  }

  section {
    display: flex;
    background: var(--blue);
    color: var(--white);
    margin-top: 19px;
    height: 123px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    padding: 0px 40px;
    cursor: pointer;

    span {
      text-align: center;
      font: normal normal bold 55px/71px IBM Plex Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      margin-right: 25px;
      margin-bottom: 15px;
    }

    p {
      text-align: left;
      max-width: 120px;
      font: normal normal bold 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
`;

/* eslint-disable */
import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import { Form } from '@unform/web';
import TextArea from '../TextArea';
import { Container, Item } from './styles';

const Comments = (comment, deliverableId, root, SucessCallback, loading) => {
  const formRef = useRef(null);
  const [showResponse, setShowResponse] = useState(false);

  const schema = Yup.object().shape({
    comment: Yup.string().required('Comentário é obrigatório'),
  });

  const nestedComments = () => {
    const nestedComments = (comment.comment.replies || []).map(comment => {
      return (
        <Comments
          key={comment.id}
          comment={comment}
          root={false}
          loading={loading}
          SucessCallback={SucessCallback}
        />
      );
    });
    return nestedComments;
  };

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current.getData();
      await schema.validate(data, { abortEarly: false });
      comment.loading(true);
      await api.post(
        `/credits/api/v1/entregable/${deliverableId}/critical/${comment.comment.critical}/comments/`,
        {
          critical: comment.comment.critical,
          comment: data.comment,
          comment_comment: comment.comment.id,
        },
      );
      comment.loading(false);
      comment.SucessCallback();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }, [schema, comment]);

  return (
    <Container
      root={comment.root}
      style={{ marginLeft: '20px', marginTop: '8px' }}
    >
      <Item>
        {root}
        {comment.comment.comment}
        <p className="count">
          {`${comment.comment.replies.length} Respostas`}
          <button type="button" onClick={() => setShowResponse(!showResponse)}>
            Responder
          </button>
        </p>
        {showResponse && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TextArea
              name="comment"
              className="comment"
              placeholder="Digite seu comentário"
              schema={schema}
            />
            <button type="button" className="save" onClick={handleSubmit}>
              Enviar Comentário
            </button>
          </Form>
        )}
      </Item>
      {nestedComments()}
    </Container>
  );
};

export default Comments;

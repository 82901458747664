import React from 'react';
import Logo from '../../assets/logo_plataforma_novo.png';
import LogoBid from '../../assets/logo-bid-no-background.png';
import LoboBndes from '../../assets/logo_bndes.png';
import { useAuth } from '../../hooks/auth';
import { Container, Container2 } from './styles';
import { Link } from 'react-router-dom';

function Footer() {
  const { changeLoginModal, isLogged } = useAuth();

  return (
    <>
    {/*  <Container>
        <footer>
          <div className="container">
            <div className="row">
              <footer className="footer">
                <div className="footer-logo">
                  <img src={Logo} className="mb-2" alt="Logo" />
                  <img src={LogoBid} className="float-left" alt="Logo BID" />
                  <img
                    src={LoboBndes}
                    className="float-left"
                    alt="Logo BNDES"
                  />
                </div>
                <ul className="footer__nav">
                  <li>
                    <ul className="nav__ul">
                      <li className="nav__ul_li_title">
                        <Link to="/">Homepage</Link>
                      </li>
                      <li className="nav__ul_li_title">
                        <Link to="/programa/sobre-o-programa/">O Programa</Link>
                      </li>
                      <li className="nav__ul_li subitem">
                        <Link to="/programa/sobre-o-programa/">
                          Sobre o programa
                        </Link>
                      </li>
                      <li className="nav__ul_li subitem">
                        <Link to="/programa/seguranca-baseada-em-evidencias/">
                          Segurança Baseada em Evidências
                        </Link>
                      </li>
                      <li className="nav__ul_li subitem">
                        <Link to="/programa/perguntas-e-respostas/">
                          Perguntas e Respostas
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul className="nav__ul">
                      <li className="nav__ul_li_title">
                        <Link to="/metodologia">Metodologia</Link>
                      </li>
                       <li className="nav__ul_li_title">
                        <a
                          href="https://esegurancat-idb-blogs.pantheonsite.io/brazil/banco/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          E-Segurança
                        </a>
                      </li> 
                    </ul>
                  </li>
                  <li>
                    <ul className="nav__ul">
                      {!isLogged ? (
                        <li
                          className="nav__ul_li_title"
                          onClick={() => changeLoginModal(true)}
                        >
                          <button>Entrar</button>
                        </li>
                      ) : (
                        <li
                          className="nav__ul_li_title"
                          onClick={() => changeLoginModal(true)}
                        >
                          <a href="/projects/">Entrar</a>
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </footer>
      </Container> */}
      <Container2>
        <div className="Footerfooter">
          <div className="textContainer">
            <span>© PROSEG Federativo. Todos os direitos reservados</span>
            <span>
              Banco Interamericano de Desenvolvimento (BID). Setor de Embaixadas
              Norte (SEN). Conjunto F – Lote 39: Asa Norte, Brasília/DF, CEP:
              70800-400.
            </span>
            <span>
              Banco Nacional de Desenvolvimento Econômico e Social(BNDES). Av.
              República do Chile, 100, Centro, Rio de Janeiro/RJ, CEP-20031-917.
            </span>
          </div>
        </div>
      </Container2>
    </>
  );
}

export default Footer;

/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import ErrorModal from '../../../../components/ErrorModal';
import getValidationErrors from '../../../../services/getValidationErrors';
import Comments from '../../../../components/Comments';
import TextArea from '../../../../components/TextArea';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';

const CriticalComments = ({ show, handleClose, critical, deliverableId, isEditComments }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const schema = Yup.object().shape({
    comment: Yup.string().required('Comentário é obrigatório'),
  });

  const getComments = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/credits/api/v1/entregable/${deliverableId}/critical/${critical.id}/comments/`,
    );
    setComments(response.data.results);
    setLoading(false);
  }, [deliverableId, critical.id]);

  const handleSubmit = useCallback(async () => {
    const data = formRef.current.getData();
    try {
      await schema.validate(data, { abortEarly: false });
      setLoading(true);
      await api.post(
        `/credits/api/v1/entregable/${deliverableId}/critical/${critical.id}/comments/`,
        {
          critical: critical.id,
          comment: data.comment,
        },
      );
      setLoading(false);
      getComments();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      const { ...error } = err;
      setLoading(false);
      if (error.response.data.detail) {
        setError(true);
        setErrorMessage(error.response.data.detail);
      }
    }
  }, [critical.id, schema, getComments, deliverableId]);

  const handleCommentCalback = useCallback(() => {
    getComments();
  }, [getComments]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  return (
    <Container>
      <Modal
        title="Comentários"
        successText="Comentário adicionado com Sucesso!"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              {comments.length > 0 ? comments.map(comment => (
                <Comments
                  key={comment.id}
                  comment={comment}
                  root={true}
                  SucessCallback={handleCommentCalback}
                  loading={setLoading}
                  deliverableId={deliverableId}
                />
              )) : <h1 className="emptyDeliverable">
              Nenhum Comentário
            </h1>}
              {isEditComments && (
                <>
                  <TextArea
                    name="comment"
                    className="comment"
                    placeholder="Digite seu comentário"
                    schema={schema}
                  />
                  <button
                    className="save"
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    Enviar Comentário
                  </button>
                </>
              )}
            </Form>
          </>
        )}
      </Modal>
      {error && (
        <ErrorModal
          show={error}
          Message={errorMessage}
          handleClose={() => setError(false)}
        />
      )}
    </Container>
  );
};

export default CriticalComments;

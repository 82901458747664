/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import StepDonut from '../StepDonut';
import { Container, MobileContainer } from './styles';
import { useAuth } from '../../../../../hooks/auth';

function BreadCrumb({ options, active, setCallBack, diagnostico, ibgeCode }) {
  const { user } = useAuth();
  const [mobileDonutText, setMobileDonutText] = useState('1/3');
  const [mobileDonutPercentage, setMobileDonutPercentage] = useState(33.33);

  const openReport = useCallback(() => {
    // window.location.href = `/relatorio-executivo/${diagnostico.id}`;
    // window.location.href = `/relatorio-executivo/${diagnostico.id}`;
    window.open(`/relatorio-executivo/${diagnostico.id}`, '_blank');
  }, []);

  const getExtract = React.useCallback(() => {
    window.open(
      `https://prosegfederativo.com.br/platform/api/v1/indicator-extract/${ibgeCode}/`,
      '_blank',
    );
  }, []);

  const setMobileBreadCrumb = useCallback(active => {
    if (active === 1) {
      setMobileDonutText('1/3');
      setMobileDonutPercentage(33.33);
    }
    if (active === 2) {
      setMobileDonutText('2/3');
      setMobileDonutPercentage(66.66);
    }
    if (active === 3) {
      setMobileDonutText('3/3');
      setMobileDonutPercentage(100);
    }
  }, []);

  useEffect(() => {
    setMobileBreadCrumb(active);
  }, [active, setMobileBreadCrumb]);

  return (
    <>
      <Container>
        <ul className="breadcrumb">
          {options.map(option => (
            <div className="li-container">
              <span className="text">{`Passo ${option.step}`}</span>
              <li className={active >= option.step ? 'active' : 'inactive'}>
                <a href="#" onClick={() => setCallBack(option.step)}>
                  {option.name}
                </a>
              </li>
            </div>
          ))}
        </ul>
        <div>
          <button type="button" onClick={openReport}>
            <FaDownload />
            <span className="">Download do Relatório Executivo</span>
          </button>
          {ibgeCode && user?.is_admin && (
            <button
              type="button"
              style={{ marginTop: 10 }}
              onClick={getExtract}
            >
              <FaDownload />
              <span className="">Obter Extrato</span>
            </button>
          )}
        </div>
      </Container>
      <MobileContainer>
        {/* <div className="donutContainer">
          <StepDonut
            value={mobileDonutPercentage}
            text={mobileDonutText}
            type="percent"
            color="#075E89"
            size={116}
            strokewidth={8}
            large
          />
        </div> */}
        <div className="contentContainer">
          <div>
            {active <= 3 && (
              <h1 className="text">{`Passo ${active} - ${
                options[active - 1].name
              }`}</h1>
            )}

            {active <= 2 && (
              <>
                <small>{`Próximo passo - ${options[active].name}`}</small>
                <br />
              </>
            )}
            {active > 1 && (
              <small>{`Passo Anterior - ${options[active - 2].name}`}</small>
            )}
          </div>
          <div>
            {active > 1 && (
              <button type="button" onClick={() => setCallBack(active - 1)}>
                Anterior
              </button>
            )}
            {active <= 2 && (
              <button type="button" onClick={() => setCallBack(active + 1)}>
                Próximo
              </button>
            )}
          </div>
        </div>
      </MobileContainer>
    </>
  );
}

export default BreadCrumb;

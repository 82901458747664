import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import Cookie from 'js-cookie';
import api from '../services/api';

import { useLoader } from './loader';

const CreditOperationContext = createContext({});

const CreditOperationProvider = ({ children }) => {
  const [creditOperation, setCreditOperation] = useState({});
  const { activeLoader } = useLoader();

  const getCreditOperation = async () => {
    if (!!Cookie.get('sessionid')) {
      const response = await api.get('/credits/api/v1/opcredit-manager/');
      response.data.results.length &&
        setCreditOperation(response.data.results[0]);
      activeLoader(false);
    }
  };

  const updateCreditOperation = useCallback(data => {
    setCreditOperation({ ...data });
  }, []);

  const reloadCreditOperation = useCallback(() => {
    getCreditOperation();
  }, []);

  useEffect(() => {
    getCreditOperation();
  }, []);

  return (
    <CreditOperationContext.Provider
      value={{ creditOperation, updateCreditOperation, reloadCreditOperation }}
    >
      {children}
    </CreditOperationContext.Provider>
  );
};

function useCreditOperation() {
  const context = useContext(CreditOperationContext);

  if (!context) {
    throw new Error(
      'useCreditOperation só pode ser usado dentro de um CreditOperationProvider',
    );
  }

  return context;
}

export { CreditOperationProvider, useCreditOperation };

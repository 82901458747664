import React, { useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { Container, Card, IconContainer } from './styles';


import { ReactComponent as BrasilFlag } from '../../../../../assets/brazil-flag.svg';
import { ReactComponent as Like } from '../../../../../assets/like.svg';

import CapitalEmpty from '../../../../../assets/capital-empty.png';
import CapitalIgual from '../../../../../assets/capital-igual.png';
import CapitalMaior from '../../../../../assets/capital-maior.png';
import CapitalMenor from '../../../../../assets/capital-menor.png';

import EstadoEmpty from '../../../../../assets/estado-empty.png';
import EstadoIgual from '../../../../../assets/estado-igual.png';
import EstadoMaior from '../../../../../assets/estado-maior.png';
import EstadoMenor from '../../../../../assets/estado-menor.png';

import MetropEmpty from '../../../../../assets/new/metrop-empty.png';
import MetropIgual from '../../../../../assets/new/metrop-orange.png';
import MetropMaior from '../../../../../assets/new/metrop-green.png';
import MetropMenor from '../../../../../assets/new/metrop-red.png';


import Igual from '../../../../../assets/igual.png';
import Empty from '../../../../../assets/empty.png';



const Icon = ({icon}) => {

  const getColor = useCallback(value => {
    const colors = {
      '-1': '#ff8585',
      0: '#c0c0c0',
      1: '#139b66',
      2: '#c0c0c0',
    };
    return colors[value];
  }, []);

  return (
    <>
      {icon.icon === "evolution_cmp" && (<IconContainer
        color={getColor(
          icon.evolution_cmp,
        )}
        rotate={
          !!(icon.evolution_cmp === 1)
            ? true
            : false
        }
      >
        {icon.evolution_cmp === 0 && (
          <img src={Igual} alt="" />
        )}
        {icon.evolution_cmp === 2 && (
          <img src={Empty} alt="" />
        )}
        {icon.evolution_cmp !== 2 &&
          icon.evolution_cmp !== 0 && (
            <Like />
          )}
      </IconContainer>)}

      {icon.icon === "region_cmp" && (<IconContainer
        color={getColor(
          icon.region_cmp,
        )}
      >
        {icon.region_cmp === -1 && (
          <img src={CapitalMenor} alt="" />
        )}
        {icon.region_cmp === 0 && (
          <img src={CapitalIgual} alt="" />
        )}
        {icon.region_cmp === 1 && (
          <img src={CapitalMaior} alt="" />
        )}
        {icon.region_cmp === 2 && (
          <img src={CapitalEmpty} alt="" />
        )}
      </IconContainer>)}

      {icon.icon === "country_cmp" && <IconContainer
        color={getColor(
          icon.country_cmp,
        )}
      >
        {icon.country_cmp !== 2 && (
          <BrasilFlag />
        )}
        {icon.country_cmp === 2 && (
          <BrasilFlag />
        )}
      </IconContainer>}


      {icon.icon === "state_cmp" && (
        <IconContainer
          color={getColor(
            icon.state_cmp,
          )}
        >
          {icon.state_cmp === -1 && (
            <img src={EstadoMenor} alt="" />
          )}
          {icon.state_cmp === 0 && (
            <img src={EstadoIgual} alt="" />
          )}
          {icon.state_cmp === 1 && (
            <img src={EstadoMaior} alt="" />
          )}
          {icon.state_cmp === 2 && (
            <img src={EstadoEmpty} alt="" />
          )}
        </IconContainer>
      )}
      {icon.icon === "metropolis_cmp" && (
        <IconContainer
          color={getColor(
            icon.metropolis_cmp,
          )}
        >
          {icon.metropolis_cmp ===
            -1 && <img src={MetropMenor} alt="" />}
          {icon.metropolis_cmp === 0 && (
            <img src={MetropIgual} alt="" />
          )}
          {icon.metropolis_cmp === 1 && (
            <img src={MetropMaior} alt="" />
          )}
          {icon.metropolis_cmp === 2 && (
            <img src={MetropEmpty} alt="" />
          )}
        </IconContainer>
      )}
    </>
  )
}

function IndicatorCard({ indicator, initDetailIndicator, showButton }) {

  return (
    <Container showButton={showButton}>
      <div className="textContainer">
        <div className="text">
          {indicator.indicator.short_name}
          <p>{`(${indicator.indicator.source})`}</p>
        </div>
        {showButton && (
          <button type="button" onClick={() => initDetailIndicator(indicator)}>
            <MdSearch color="#fff" />
          </button>
        )}
      </div>
      <div className="bottomContainer">
        <div className="iconsContainer">
          {indicator.icons.map((icon, index) => (

            // <Card className={icon.border} key={`ci-${index}`}>
            //   <div className="imgContainer">
            //     <img
            //       className={icon.border}
            //       src={`/static/media/${icon.icon_name}`}
            //       alt={`${icon.border}} - ${icon.icon_name}`}
            //     />
            //   </div>
            // </Card>

            <Icon icon={icon} key={`ic-${index}`}/>

          ))}
        </div>
        <div className="periodContainer">
          <p>Periodo:</p>
          <p>{indicator.indicator.period}</p>
        </div>
      </div>
    </Container>
  );
}

export default IndicatorCard;

import styled from 'styled-components';

export const Container = styled.div`
  .initText {
    color: #6d6e71;
    font-size: 22px;
    text-align: justify;
    line-height: 1.5;
  }
`;

export const Content = styled.div``;

export const Box = styled.div`
  display: flex;
  background: ${props => props.color1};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  .contentBox {
    width: 90%;
    display: flex;
    height: 150px;
    overflow: hidden;
    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 89.5px;
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      padding: 0 5px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
      .subtitle {
        font-size: 15px;
        color: #fff;
      }
    }

    @media only screen and (max-width: 425px) {
      .textContainer {
        .title {
          font-size: 15px;
        }
        .subtitle {
          font-size: 14px;
        }
      }
    }
    @media only screen and (max-width: 375px) {
      .textContainer {
        .title {
          font-size: 14px;
        }
        .subtitle {
          font-size: 13px;
        }
      }
    }
    @media only screen and (max-width: 348px) {
      .textContainer {
        .title {
          font-size: 13px;
        }
        .subtitle {
          font-size: 12px;
        }
      }
    }
  }
  .arrowContainer {
    background: ${props => props.color2};
    width: 10%;

    display: flex;
    border-radius: 0px 10px 10px 0px;
    align-items: center;
    justify-content: center;
  }
`;

import React from 'react';
import DeliverableTable from '../../components/DeliverableTable';
import { formatPrice } from '../../services/currencyFormatter';

import {
  Container,
  Content,
  DeliverableContainer,
  Deliverable,
  EstimativaContainer,
  ValueInput,
} from './styles';

const Solution = ({ solution }) => {
  return (
    <Container>
      <Content area={solution.result_area}>
        <span> Solução {solution.code} </span>
        <h1>{solution.name}</h1>
      </Content>
      <DeliverableContainer area={solution.result_area}>
        {solution.deliverables.length >= 1 && (
          <h1>Componentes Financiáveis Selecionados </h1>
        )}
        {solution.deliverables.length < 1 && (
          <h1>Nenhum Componente Financiável Selecionado </h1>
        )}
        {solution.deliverables.map(deliverable => (
          <Deliverable key={deliverable.id}>
            <h1>{deliverable.name}</h1>
            <h2>Lista de Entregas</h2>
            <DeliverableTable
              products={deliverable.products}
              area={solution.result_area}
            />
          </Deliverable>
        ))}
        <EstimativaContainer area={solution.result_area}>
          <span>Estimativa Financeira</span>
          <ValueInput area={solution.result_area}>
            {formatPrice(solution.cost_estimate)}
          </ValueInput>
        </EstimativaContainer>
      </DeliverableContainer>
    </Container>
  );
};

export default Solution;

import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  a {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: #0c9fd8;
    opacity: 1;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #0f5e7d;
    }
  }

  .empty {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;
      left: 0;
      right: 0;
      margin-left: ${props =>
        props.tab === '1'
          ? '11%'
          : props.tab === '2'
          ? '36%'
          : props.tab === '3'
          ? '61%'
          : props.tab === '4' && '86%'};
    }
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;
  animation: ${EaseIn} 0.3s;

  h1 {
    margin: 10px 0;
    color: black;
    font-size: 24px;
  }

  h2 {
    text-align: left;
    font: normal normal bold 25px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
    margin-bottom: 25px;
    margin-top: 15px;
  }
`;

export const TabButton = styled.button`
  position: relative;
  background: var(--white);
  width: 100%;
  height: 137px;
  background: #ffffff;

  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #007bac`};
  border-radius: 20px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 8px 8px 10px #00000029;
  }

  img {
    width: 78px;
    height: 73px;
  }

  span {
    text-align: center;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;
  }

  div {
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
    width: 100%;
    height: 54px;
    background: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      font: normal normal bold 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #f8f8f8;
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    span {
      font: normal normal bold 12px/22px IBM Plex Sans;
    }
    img {
      width: 70px;
      height: 65px;
    }
  }
  @media screen and (max-width: 767px) {
    span {
      font: normal normal bold 17px/22px IBM Plex Sans;
    }
    img {
      width: 78px;
      height: 73px;
    }
  }
`;

export const TableContainer = styled.div`
  animation: ${EaseIn} 0.3s;
  h1 {
    text-align: center;
    font: normal normal bold 50px/65px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
    margin-bottom: 30px;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #10385c;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 44px;

    & > div {
      white-space: inherit;
    }
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  .legend-item {
    display: flex;
    align-items: center;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-right: 15px;

    .green {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: #5eb245 0% 0% no-repeat padding-box;
      border: 1px solid #d0d0d0;
      opacity: 1;
    }

    .blue {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #0c9fd8;
      border: 1px solid #d0d0d0;
      opacity: 1;
    }

    .gray {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #d0d0d0;
      border: 1px solid #d0d0d0;
      opacity: 1;
    }
  }
`;

export const StrategicIndicatorsContainer = styled.div`
  h1 {
    text-align: center;
    font: normal normal bold 42px/55px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
  }

  .DonutColumn {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    span {
      text-align: center;
      font: normal normal normal 18px/23px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
      opacity: 1;
    }
  }
`;

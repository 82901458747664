import React, { useRef, useCallback, useState } from 'react';
import { Container, Content } from './styles';
import Select from '../../../../../components/Select';
import SelectOptions from '../../../../../components/RegisterModal/options';
import { Form } from '@unform/web';

function PublicoAlvo() {
  const formRef = useRef(null);
  const handleSubmit = useCallback(() => {}, []);
  const [location, setLocation] = useState(null);
  return (
    <Container>
      <h1>Público-alvo</h1>
      <Content>
        <p className="sub-title">
          Veja se seu estado ou município está elegível para participar do
          programa
        </p>
        <p>
          Por uma questão operacional e de capacidade de atendimento, fez-se
          necessário priorizar um subgrupo de entes federativos que, nesse
          primeiro momento, serão os únicos elegíveis para acessar a área logada
          desta plataforma e, consequentemente, as soluções e linhas de crédito
          que compõem o PROSEG Federativo.
        </p>
        <p>
          Com o objetivo de alavancar o potencial de impacto do programa foram
          selecionados:
        </p>
        <p>1) Os 26 estados e o Distrito Federal.</p>
        <p>
          2) Os 120 municípios que, juntos, respondem por 50% das ocorrências de
          homicídios registradas no Brasil (segundo dados do{' '}
          <a
            href="https://www.ipea.gov.br/atlasviolencia/arquivos/artigos/6363-atlasdaviolencia2019completo.pdf"
            target="_blank"
          >
            Atlas da Violência
          </a>
          ).
        </p>
        <p>
          3) Outros 195 municípios que, apesar de não se encaixarem no critério
          anterior, são cidades com porte populacional médio ou grande (100.000
          habitantes ou mais, conforme{' '}
          <a
            href="https://biblioteca.ibge.gov.br/visualizacao/livros/liv100643.pdf"
            target="_blank"
          >
            critérios
          </a>{' '}
          e{' '}
          <a
            href="https://biblioteca.ibge.gov.br/visualizacao/livros/liv100643.pdf"
            target="_blank"
          >
            projeções
          </a>{' '}
          do IBGE).
        </p>
        <p>
          Seu município não está contemplado? Confira o{' '}
          <a href="https://www.e-seguranca.org/" target="_blank">
            Portal E-Segurança
          </a>{' '}
          para fazer um diagnóstico executivo de sua cidade ou estado, e acesse
          a{' '}
          <a href="https://plataformadeevidencias.iadb.org/" target="_blank">
            Plataforma de Evidências em Segurança & Justiça
          </a>{' '}
          para ver que soluções podem ser aplicadas para superar desafios
          relacionados à criminalidade e a violência
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="select-wrapper">
            <div className="select select-1">
              <Select
                name="location"
                options={SelectOptions.estados.sort(function (a, b) {
                  if (a.label > b.label) {
                    return 1;
                  }
                  if (a.label < b.label) {
                    return -1;
                  }
                  return 0;
                })}
                placeholder="Veja a lista de Estados"
                onChange={e => setLocation(e)}
              />
            </div>
            <div className="select select-2">
              <Select
                isDisabled={!location}
                name="location"
                options={(() => {
                  return SelectOptions.municipios.filter(municipio => {
                    if (municipio.label === location?.label) {
                      return true;
                    }
                    return false;
                  });
                })()}
                placeholder="Veja a lista de Municipios"
              />
            </div>
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default PublicoAlvo;

import React, { useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, Title } from './styles';
import { FaExclamationCircle } from 'react-icons/fa';

function TimePercentageBar({
  percentage,
  color,
  title,
  tolltip,
  text,
  dataTip,
}) {
  const getPercentage = useCallback((partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  }, []);

  const PercentageTimeValue = useMemo(() => {
    let returnValue = 0;
    returnValue = getPercentage(percentage.time, percentage.total);
    return returnValue;
  }, [getPercentage, percentage]);

  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  return (
    <>
      {!!title && (
        <Title>
          {!!dataTip && (
            <FaExclamationCircle
              style={{ marginRight: 5 }}
              data-tip={dataTip}
            />
          )}
          {title}
        </Title>
      )}
      <Container
        percentage={toFixed(PercentageTimeValue, 0)}
        color={color}
        text={text}
      >
        <div
          title={`${percentage.time} ${percentage.time <= 1 ? 'Mês' : 'Meses'}`}
        >
          {!tolltip && (
            <>
              <div>
                <span>{percentage.time}</span>
              </div>
            </>
          )}
        </div>
      </Container>
      <ReactTooltip place="right" />
    </>
  );
}

export default TimePercentageBar;

import React from 'react';
import Modal from '.././../../../../components/Modal';
import { Row, Column } from '../../../../../styles/components';
import { Container } from './styles';

function CategoryModal({ show, handleClose, option }) {
  return (
    <Container>
      <Modal
        title=""
        success={false}
        successText=""
        handleClose={handleClose}
        show={show}
      >
        <Row>
          <Column small="12" medium="12" large="12">
            <div className="detailContainer">
              <h5>Detalhamento</h5>
              <h1>{option['TIPOLOGIA/EIXO']}</h1>

              <div className="textContainer">
                <label>Definição</label>
                <p>{option.definicao}</p>
              </div>
              <div className="textContainer">
                <label>Formula de Cálculo</label>
                <p>{option.forma_calculo}</p>
              </div>
              <div className="textContainer">
                <label>Fonte</label>
                <p>{option.fonte}</p>
              </div>
            </div>
          </Column>
        </Row>
      </Modal>
    </Container>
  );
}

export default CategoryModal;

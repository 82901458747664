import React, { useCallback } from 'react';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';

import { ReactComponent as Like } from '../../../../../assets/like.svg';


import like from "../../../../../assets/new/like.png";
import PeriodEmpty from '../../../../../assets/new/period-empty.png';
import Empty from '../../../../../assets/empty.png';

import regionGreen from "../../../../../assets/new/region-green.png";
import regionRed from "../../../../../assets/new/region-red.png";
import regionOrange from "../../../../../assets/new/region-orange.png";
import regionEmpty from "../../../../../assets/new/region-empty.png";

import flagGreen from '../../../../../assets/new/flag-green.png';
import flagRed from "../../../../../assets/new/flag-red.png";
import flagOrange from "../../../../../assets/new/flag-orange.png";
import flagEmpty from "../../../../../assets/new/flag-empty.png";

import {
  Container,
  Title,
  TitleText,
  Card,
  CardTitle,
  CardItem,
  IndicatorCard,
  IndicatorText,
  IconContainer,
  OtherIndicators,
  IndicatorItem,
} from './styles';

function Page3({ data, printing }) {
  const getColor = useCallback(value => {
    const colors = {
      '-1': '#fe8586',
      0: '#d29500',
      1: '#209969',
      2: '#bfbfbf',
    };
    return colors[value];
  }, []);

  return (
    <Container>
      <Title>Destaques negativos</Title>
      <TitleText>
        Nesta seção são apresentados os indicadores que tiveram desempenho
        abaixo da média em comparação com seus grupos de análise (abaixo da
        média nacional, abaixo da média regional etc.). Em destaque encontram-se
        os cinco indicadores que obtiveram o pior desempenho no período de
        referência. Ao final da página, você tem acesso aos demais indicadores
        que se configuram como pontos de atenção
      </TitleText>
      {data.category.map(category => (
        <Card printing={printing}>
          <CardTitle
            color={
              category.category_name === 'Criminalidade e Violência'
                ? '#0c9fd8'
                : category.category_name === 'Desempenho Institucional'
                ? '#00486B'
                : category.category_name === 'Fatores de Risco'
                ? '#007BAC'
                : '#0c9fd8'
            }
          >
            {category.category_name === 'Criminalidade e Violência' && (
              <Algemas />
            )}
            {category.category_name === 'Desempenho Institucional' && (
              <Government />
            )}
            {category.category_name === 'Fatores de Risco' && <Hands />}
            {category.category_name}
          </CardTitle>
          {category.worsts_indicators.map(indicator => (
            <CardItem>
              <IndicatorCard printing={printing}>
                <div className="titleContainer">
                  <h1>{indicator.name}</h1>
                  <small>{indicator.source}</small>
                </div>
                <div className="buttonsContainer">
                  <div className="IconContent">
                  <IconContainer>
                      {indicator.evolution_cmp === 0 && (
                        <img src={Empty} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.evolution_cmp === 2 && (
                        <img src={PeriodEmpty} alt="" style={{ height: "35px", width: "35px" }} />
                      )}

                      {indicator.evolution_cmp === -1 && (<img
                        src={like}
                        alt=""
                        style={{ height: "35px", width: "35px" }}
                      />)}
                      {indicator.evolution_cmp === 1 && (<img
                        src={like}
                        alt=""
                        style={{ height: "35px", width: "35px" }}
                      />)}
                    </IconContainer>
                    {(data.is_state ||
                      (data.is_capital &&
                        !data.is_metropolitan_city &&
                        data.is_county &&
                        data.population > 100000)) && (
                          <IconContainer>
                          {indicator.region_cmp === -1 && (
                            <img src={regionRed} alt="" style={{ height: "35px", width: "35px" }} />
                          )}
                          {indicator.region_cmp === 0 && (
                            <img src={regionOrange} alt=""  style={{ height: "35px", width: "35px" }} />
                          )}
                          {indicator.region_cmp === 1 && (
                            <img src={regionGreen} alt="" style={{ height: "35px", width: "35px" }} />
                          )}
                          {indicator.region_cmp === 2 && (
                            <img src={regionEmpty} alt="" style={{ height: "35px", width: "35px" }} />
                          )}
                        </IconContainer>
                    )}
                    <IconContainer>
                      {indicator.country_cmp === -1 && (
                        <img src={flagRed} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.country_cmp === 0 && (
                        <img src={flagOrange} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.country_cmp === 1 && (
                        <img src={flagGreen} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.country_cmp === 2 && (
                        <img src={flagEmpty} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                    </IconContainer>
                    {!data.is_state && (
                      <IconContainer >
                      {indicator.state_cmp === -1 && (
                        <img src={regionRed} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.state_cmp === 0 && (
                        <img src={regionOrange} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.state_cmp === 1 && (
                        <img src={regionGreen} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                      {indicator.state_cmp === 2 && (
                        <img src={regionEmpty} alt="" style={{ height: "35px", width: "35px" }} />
                      )}
                    </IconContainer>
                    )}
                    {((data.is_capital && data.is_metropolitan_city) ||
                      (data.is_county && data.is_metropolitan_city)) && (
                      <IconContainer color={getColor(indicator.metropolis_cmp)}>
                        {indicator.metropolis_cmp === -1 && (
                          <img src="/static/media/metrop-red.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 0 && (
                          <img src="/static/media/metrop-grey.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 1 && (
                          <img src="/static/media/metrop-green.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 2 && (
                          <img src="/static/media/metrop-empty.png" alt="" />
                        )}
                      </IconContainer>
                    )}
                  </div>
                  <span>
                    Período:
                    <br /> {indicator.period}
                  </span>
                </div>
              </IndicatorCard>
              <IndicatorText
                dangerouslySetInnerHTML={{
                  __html: indicator.content,
                }}
              ></IndicatorText>
            </CardItem>
          ))}
          {!category.worsts_indicators.length && (
            <span>
              O estado/município analisado não possui indicadores com
              performance negativa, segundo os critérios avaliados (performance
              no passado recente e comparações com as médias nacional e
              regional/estadual).
            </span>
          )}
        </Card>
      ))}

      <Card printing={printing}>
        <OtherIndicators>
          <div className="title">
            <IconContainer color="#fe8586">
              <Like />
            </IconContainer>
            Demais indicadores com destaque negativo:
          </div>
          <IndicatorItem>
            {data.category[2].negative_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
            {data.category[1].negative_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
            {data.category[0].negative_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
          </IndicatorItem>
        </OtherIndicators>
      </Card>
    </Container>
  );
}

export default Page3;

import React, { useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, Title } from './styles';
import { FaExclamationCircle } from 'react-icons/fa';

function PercentageBar({
  percentage,
  color,
  title,
  tolltip,
  text,
  time,
  dataTip,
}) {
  const getPercentage = useCallback((partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  }, []);

  const PercentageTimeValue = useMemo(() => {
    let returnValue = 0;
    if (time) {
      returnValue = getPercentage(percentage.time, percentage.total);
    }
    return returnValue;
  }, [getPercentage, percentage, time]);

  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  return (
    <>
      {!!title && (
        <Title>
          {!!dataTip && (
            <FaExclamationCircle
              style={{ marginRight: 5 }}
              data-tip={dataTip}
            />
          )}
          {title}
        </Title>
      )}
      <Container percentage={toFixed(percentage, 2)} color={color} text={text}>
        <div title={`${toFixed(percentage, 2)}%`}>
          {!tolltip && (
            <>
              {!time ? (
                <div>
                  <span>{`${toFixed(percentage, 2)}`}</span>
                </div>
              ) : (
                <div>
                  <span>{`${toFixed(PercentageTimeValue, 2)}`}</span>
                </div>
              )}
              <span>{text && `${text}`}</span>
            </>
          )}
          {tolltip && (
            <div>
              <div className="TollTip">
                <span>
                  {time
                    ? `${PercentageTimeValue}`
                    : `${toFixed(percentage, 2)}%`}
                </span>
              </div>
            </div>
          )}
        </div>
      </Container>
      <ReactTooltip place="right" />
    </>
  );
}

export default PercentageBar;

import React from 'react';
import { useHistory } from 'react-router-dom';
import DashBoardLayout from '../../components/DashboardLayout';
import { Container, BannerContainer, TextContainer, Card } from './styles';
import Conhecimento from '../../assets/conhecimento.png';
import { Column, Row } from '../../styles/components';
import { useAuth } from '../../hooks/auth';

const CoreOfKnowledge = () => {
  const { user } = useAuth();
  const { push } = useHistory();
  return (
    <Container>
      <DashBoardLayout title="Núcleo de conhecimento">
        <Row>
          <Column large="12" medium="12" small="12">
            <BannerContainer>
              <img src={Conhecimento} alt="Plataforma de conhecimento" />
            </BannerContainer>
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <TextContainer>
              <p>
                O Núcleo de Conhecimento do PROSEG Federativo tem por objetivo
                servir como mecanismo de transferência de conhecimentos para os
                entes beneficiários do programa.
              </p>
              <p>
                Esta seção reúne um repositório de documentos, videoaulas e um
                banco de dúvidas frequentes, com elementos relevantes para a
                gestão e monitoramento do programa. Você também pode contribuir
                com referências de documentos e trocar experiências com os
                demais entes participantes do programa.
              </p>
              <p>Clique abaixo e acesse.</p>
            </TextContainer>
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <Card
              background="#10385c"
              onClick={() =>
                push(
                  `/${
                    user.is_admin ? 'management' : 'projects'
                  }/repositorio-documentos`,
                )
              }
            >
              <h1>Acesse o repositório de documentos</h1>
              <span>
                Documentos de gestão padrões da instituição financiadora e
                referências de documentos enviados pelos entes (atas de preço,
                editais, entre outros).
              </span>
            </Card>
            <Card
              background="#0C9FD8"
              onClick={() =>
                push(
                  `/${
                    user.is_admin ? 'management' : 'projects'
                  }/plataforma-ensino`,
                )
              }
            >
              <h1>Acesse a plataforma de ensino </h1>
              <span>
                Videoaulas com fundamentos básicos sobre gestão de projetos,
                monitoramento e avaliação de impactos
              </span>
            </Card>
            <Card
              background="#0B5E83"
              onClick={() =>
                push(
                  `/${user.is_admin ? 'management' : 'projects'}/banco-duvidas`,
                )
              }
            >
              <h1>Acesse o banco de dúvidas </h1>
              <span>
                Respostas para as principais dúvidas sobre a etapa de Gestão e
                Monitoramento da Operação de Crédito
              </span>
            </Card>
          </Column>
        </Row>
      </DashBoardLayout>
    </Container>
  );
};

export default CoreOfKnowledge;

/* eslint-disable */
import React, { useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import TextArea from '../../../../components/TextArea';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';

const AddMainGoal = ({ show, handleClose, handleSuccess, creditOperation }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const schema = Yup.object().shape({
    gen_goal: Yup.string().required('Objetivo geral é obrigatório'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const response = await api.patch(
          `/credits/api/v1/opcredit-manager/${creditOperation.code}/`,
          {
            gen_goal: data.gen_goal,
          },
        );
        handleSuccess(response.data);
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, handleSuccess, creditOperation.code],
  );

  return (
    <Container>
      <Modal
        title="Adicionar Objetivo Geral"
        success={success}
        successText="Objetivo Geral Adicionado com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <TextArea
                name="gen_goal"
                className="objetivo_geral"
                placeholder="Objetivo Geral"
                defaultValue={creditOperation.gen_goal}
                schema={schema}
              />
              <button type="submit">Salvar</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddMainGoal;

/* eslint-disable */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../components/ModalLoader';
import getValidationErrors from '../../../services/getValidationErrors';
import Select from '../../../components/Select';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import { Container } from './styles';

const ExecutiveReportModal = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [entitiesFinaciables, setEntitiesFinaciables] = useState([]);

  const schema = Yup.object().shape({
    ente: Yup.string().required('Ente é obrigatório'),
  });

  useEffect(() => {
    api.get('/credits/api/v1/financing-entity/').then(response => {
      setEntitiesFinaciables(response.data.results);
    });
  }, []);

  const entityFinaciablesOptions = useMemo(() => {
    return entitiesFinaciables.map(entity => ({
      value: entity.code,
      label: `${entity.name}(${entity.abbreviation})`,
    }));
  }, [entitiesFinaciables]);

  const EnteOptions = [
    { value: 'city', label: 'Municípios' },
    { value: 'state', label: 'Estados' },
    // { value: 'country', label: 'País' },
    // { value: 'region', label: 'Região Metropolitana' },
  ];

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const enteString = `?ente=${data.ente}`;

        const entityFinanciableString = data.entity_finance
          ? `entity_finance=${data.entity_finance}`
          : '';

        const queryString = data.entity_finance
          ? `${enteString}&${entityFinanciableString}`
          : `${enteString}`;

        window.open(`/relatorio-executivo-global/${queryString}`, '_blank');

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema],
  );

  return (
    <Container>
      <Modal
        title="Relatório Executivo"
        success={false}
        successText="Publico Alvo Adicionado com sucesso"
        handleClose={handleClose}
        show={show}
        disableInfo
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div style={{ marginBottom: '25px' }}>
                <Select
                  name="entity_finance"
                  label="Entidade Financiadora"
                  placeholder="Selecione a entidade"
                  options={entityFinaciablesOptions}
                  schema={schema}
                />
              </div>
              <div style={{ marginBottom: '25px' }}>
                <Select
                  name="ente"
                  label="Ente(Mutuário)"
                  placeholder="Selecione"
                  options={EnteOptions}
                  schema={schema}
                />
              </div>
              <button type="submit">Filtrar</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default ExecutiveReportModal;

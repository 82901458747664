import styled from 'styled-components';
import CheckCircle from '../../../assets/check-circle.svg';

export const Container = styled.aside`
  grid-area: aside;
  background: var(--aside-gradient);
  transition: all 0.3s;
  overflow-y: auto;

  @media print {
    display: none;
  }

  .logo-container {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    border-bottom: 0.1px solid #227090;
  }

  .menu-aside-links {
    margin-top: 20px;
    list-style: none;
    padding: 0 16px;
    border-bottom: 0.1px solid #227090;
  }

  .icon-link {
    display: flex;

    svg {
      margin-right: 14px;
    }

    a {
      font-weight: 600;
    }

    &.last {
      margin-bottom: 15px;
    }
  }

  .menu-aside-links a {
    color: var(--white);
    text-decoration: underline !important;
    text-decoration-color: transparent !important;
  }
  .menu-aside-links li a:hover {
    text-decoration: underline;
    text-decoration-color: white !important;
  }

  .steps-vertical {
    margin: 1em auto;
    width: 100%;
    padding: 0;
    counter-reset: stepCount;
    text-align: left;
    font: normal normal bold 16px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  .steps-vertical,
  .steps-vertical *::before,
  .steps-vertical *::after {
    box-sizing: border-box;
  }
  .step {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  .step.active ~ .step {
    background-color: gray;
  }
  .step.active ~ .step::before {
    background-color: #4285f4 !important;
  }
  .step-info {
    text-align: center;
    align-self: flex-start;
    line-height: 2rem;
    display: flex;
    position: relative;
  }
  .step-info::before {
    content: url(${CheckCircle});
    text-align: center;
    position: absolute;
    z-index: 2;
    left: -21px;
    top: 0;
    color: rgba(1, 1, 1, 0.85) !important;
    transform: translateX(72%);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: inherit;
    opacity: ${props => (props.active ? '1' : '0.1')};
  }
  .step-label {
    font-weight: 600;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    flex-flow: column nowrap;
    order: 2;
    margin-left: 36px;
    margin-top: 0.2rem;
    text-align: left;
    font: normal bold 16px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .step-content {
    display: block;
    margin-left: 2.25rem;
    text-align: left;
    font-size: 12px !important;
    font: regular 12px IBM Plex Sans;
    letter-spacing: 0px;
    color: #d0d0d0;
  }

  .steps-vertical > .step:not(:last-child):after {
    content: '';
    position: absolute;
    width: 7px;
    height: 39px;
    left: 0.5rem;
    top: 24px;
    background-color: #ffffff;
    opacity: 0;
  }

  .steps-vertical > .step.active-step ~ .step .step-info::before,
  .steps-vertical > .step.active-step ~ .step::after,
  .steps-vertical > .step.active-step::after {
    background-color: #1b5a83 !important;
    opacity: 0.1;
  }

  .steps-vertical > .step.active-step ~ .step .step-content {
    height: 1rem;
  }
  .step {
    height: 60px;
  }

  @media only screen and (min-width: 769px) {
    .aside_close-icon {
      display: none;
    }
  }
`;

export const AsideMenu = styled.ul`
  margin-top: 20px;
  list-style: none;
  padding: 0 16px;
  border-bottom: 0.1px solid #227090;

  .icon-link.home {
    display: flex;
    margin-bottom: 15px;

    .svg {
      width: 38px;
    }

    path {
      fill: var(--white);
    }

    path {
      fill: var(--white);
    }

    .link {
      font-weight: 600;
      color: var(--white);
    }

    &.last {
      margin-bottom: 15px;
    }
  }

  .icon-link.links {
    display: flex;
    margin-bottom: 15px;

    .svg {
      width: 38px;
    }



    .link {
      width: 201px;
      a {
        font-weight: normal;
        color: var(--white) !important;
        font-size: 0.9rem;
      }
    }

    &.last {
      margin-bottom: 15px;
    }
  }
`;

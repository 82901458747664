import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  position: relative;
  margin-bottom: 50px;

  small {
    width: 20%;
    margin-bottom: 5px;
    text-align: left;
    font: normal normal bold 14px/18px IBM Plex Sans;
    color: #6d6e71;
  }

  .value {
    color: #ffffff;
    transform: translateX(17px);
  }

  .small {
    text-align: left;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }

  span {
    font: normal normal bold 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 10px;
    margin-right: 10%;
  }

  & > div {
    flex: 1;
    height: 35px;

    background: #bfbfbf;
    position: relative;
    transition: all 0.5s ease;
  }

  .middleLine {
    height: 35px;
    width: 3px;
    background: #707070;
    position: absolute;
    /* right: 50%; */

    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;

    p {
      position: absolute;
      bottom: -21px;
      transform: translateX(-25%);
    }
  }
`;

export const Title = styled.h1`
  font: normal normal normal 14px/18px IBM Plex Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 5px;
`;

export const Bar = styled.div`
  width: ${props => `${props.percentage}%`};
  background: ${props => props.color};
  height: 35px;

  transition: all 0.5s ease;

  text-align: center;
  font: normal normal bold 14px/18px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

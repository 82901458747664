import styled from 'styled-components';

export const Container = styled.div`
  .donutchart-track {
    fill: transparent;
    stroke: #dae2e5;
    stroke-width: 26;
  }
  .donutchart-indicator {
    fill: transparent;
    stroke: ${props => props.color};
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray 0.3s ease;
  }

  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }

  .donutchart-text {
    font-family: 'Roboto';
    font: normal normal bold 36px/47px IBM Plex Sans;
    fill: ${props => props.color};
  }
  .donutchart-text-val {
    text-align: center;
    position: absolute;
    width: 100%;
    /* font: normal normal bold 30px/47px IBM Plex Sans; */
    font: ${props =>
      props.fontSize
        ? `normal normal bold ${props.fontSize}px/47px IBM Plex Sans`
        : 'normal normal bold 30px/47px IBM Plex Sans'};
    letter-spacing: 0px;
    color: ${props => props.color};
  }
  .donutchart-text-percent {
    font-size: 14px;
  }
  .donutchart-text-label {
    font-size: 9px;
  }
`;

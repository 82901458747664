import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 25px;
  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin: 15px 0;
  }

  .m-5 {
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 28px;
    }
  }
`;

export const EvidenceCard = styled.div`
  margin-top: 25px;
  position: relative;
  width: 100%;
  background: #ffffff;
  height: 198px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  border: 1px solid;
  border-color: ${props => props.color};

  svg {
    margin-top: 20px;
    path {
      fill: ${props => props.color};
    }
  }

  img {
    margin-bottom: 5px;
  }

  span {
    text-align: center;
    font: normal 17px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => props.color};
    font-weight: bold;
  }

  .answerContainer {
    position: absolute;
    bottom: 0;

    height: 66px;
    background: ${props => props.color};

    border-radius: 0 0 20px 20px;
    border: 1px solid;
    border-color: ${props => props.color};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      font: normal bold 20px IBM Plex Sans;
      color: #f8f8f8;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #005782;
  color: #ffffff;

  footer {
    display: flex;
    flex-flow: row wrap;
    color: #fff;
    max-width: 1140px;
    width: 100%;

    .container {
      width: 100%;
      padding: 48px 0;
    }
  }

  .footer > * {
    flex: 1 100%;
  }

  .footer-logo {
    margin-right: 1.25em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo img {
    width: 181px;
    margin-bottom: 10px;
  }

  .nav__title {
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    color: #ffffff;
  }

  .footer address {
    font-style: normal;
    color: #999;
  }

  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: rgb(33, 33, 33, 0.07);
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 0 1.3em;
  }

  .footer ul {
    list-style: none;
    padding-left: 0;
  }

  .footer li {
    line-height: 2em;
  }

  .footer a {
    text-decoration: none;
  }

  .footer__nav {
    display: flex;
    flex-flow: row wrap;
  }

  .footer__nav > * {
    flex: 1 50%;
    margin-right: 1.25em;
  }

  .nav__ul a {
    color: #fff;
  }

  .nav__ul button {
    color: #fff;
    background: transparent;
    font-weight: 700;
  }

  .nav__ul_li_title {
    font-weight: 600;
  }

  .nav__ul_li.subitem {
    margin-left: 15px;
  }

  .nav__ul_li.subitem::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #5eb245;
  }

  .nav__ul--extra {
    column-count: 2;
    column-gap: 1.25em;
  }

  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
  }

  .legal__links {
    display: flex;
    align-items: center;
  }

  .heart {
    color: #2f2f2f;
  }

  .footer2 {
    background: #04547c 0% 0% no-repeat padding-box;
  }

  .Footerfooter {
    max-width: 1140px;
    background: #04547c 0% 0% no-repeat padding-box;
    margin-top: 20px;
    width: 100%;
    height: 78px;
    display: flex;
    align-items: center;

    .textContainer {
      font-size: 13px;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 768px) {
    .footer__nav > * {
      flex: 2;
    }

    .footer-logo {
      align-items: center;
    }

    .footer-logo {
      flex: 1 0px;
    }

    .footer__nav {
      flex: 2 0px;
      color: red;
    }
    /* .nav__ul_li_title {
      text-align: center;
    } */
  }

  @media screen and (max-width: 768px) {
    .nav__ul_li_title {
      text-align: center;
    }
    .nav__ul_li.subitem.subitem {
      text-align: center;
    }
    .Footerfooter {
      padding: 0 20px;
    }
  }
`;

export const Container2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #04547c 0% 0% no-repeat padding-box;
  color: #ffffff;

  .Footerfooter {
    max-width: 1140px;

    margin-top: 20px;
    width: 100%;
    height: 78px;
    display: flex;
    align-items: center;

    .textContainer {
      font-size: 13px;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props =>
        props.tab === 'Criminalidade e Violência'
          ? '15%'
          : props.tab === 'Fatores de Risco'
          ? '49%'
          : props.tab === 'Desempenho Institucional' && '82%'};
    }
  }

  .redirectLink {
    text-decoration: underline;
    color: #004e70;
    font-weight: 700 !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
  }
`;

export const Title = styled.h1`
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  margin-bottom: 0.6rem;
`;

export const SubTitle = styled.h2`
  font: normal normal normal 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  margin-bottom: 1rem;
`;

export const IndicatorTitle = styled.h1`
  text-align: center;
  font: normal normal bold 56px/72px IBM Plex Sans;
  letter-spacing: 0px;
  color: #075e89;

  @media only screen and (max-width: 768px) {
    font: normal normal bold 38px/72px IBM Plex Sans;
  }

  @media only screen and (max-width: 536px) {
    font: normal normal bold 30px/40px IBM Plex Sans;
  }
`;

import React from 'react';

import { Container, BarItem, InfoLabel } from './styles';

function MultiPercentageBar({ data }) {
  return (
    <>
      <Container>
        <div>
          <>
            {data.map(item => (
              <BarItem
                key={item.status}
                color={item.color}
                percentage={item.percentage}
              >
                {item.percentage > 0 && <span>{`${item.percentage}%`}</span>}
              </BarItem>
            ))}
          </>
        </div>
        <div className="info-labels">
          <>
            <InfoLabel color="blue">
              <div />
              Em execução
            </InfoLabel>
            <InfoLabel color="green">
              <div />
              Concluído
            </InfoLabel>
            <InfoLabel color="red">
              <div />
              Em atraso
            </InfoLabel>
            <InfoLabel color="yellow">
              <div />
              Com restrição
            </InfoLabel>
            <InfoLabel color="brown">
              <div />
              Em atraso e com restrição
            </InfoLabel>
          </>
        </div>
      </Container>
    </>
  );
}

export default MultiPercentageBar;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    background: #6d6e71;
    text-align: center;
    border: 1.5px solid #f1f1f1;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border: 1.5px solid #f1f1f1;
    min-height: 153px;

    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;

    &:nth-child(1) {
      font: normal normal bold 14px/18px IBM Plex Sans;
      color: #333333;
    }

    & > div {
      white-space: inherit;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  button {
    background: transparent;
    img {
      width: 40px;
      height: 35px;
      margin-bottom: 5px;
    }
  }
`;

export const Button = styled.button`
  opacity: ${props => (props.blocked ? '0.3' : '1')};
  pointer-events: ${props => (props.blocked ? 'none' : 'inherit')};

  img {
    width: 35px !important;
    height: 30px !important;
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  .react-select__control {
    background: var(--white);
    color: var(--text);
    border: 1px solid #d0d0d0;
    padding: 0 0 0 5px;
    width: 100%;
    height: 50px;
    border-radius: 8px;
  }

  .react-select__placeholder {
    color: var(--light-gray2);
  }

  ${props =>
    props.isErrored &&
    css`
      .react-select__control {
        border-color: #c53030;
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      .react-select__control {
        border-color: var(--dark-blue) !important;
      }
    `}

  ${props =>
    props.isCorrect &&
    css`
      .react-select__control {
        border-color: green !important;
        color: green;
      }
    `}
`;

export const Error = styled.label`
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: #c53030;
  display: flex;
`;

export const Label = styled.label`
  display: flex;
  margin-bottom: 5px;
  color: #9f9f9f !important;
  font-weight: bold;
  color: var(--title-highlight);
`;

import React from 'react';
import { FiX } from 'react-icons/fi';
import { FaInfoCircle, FaCheckCircle } from 'react-icons/fa';

import { Container } from './styles';

const Modal = ({
  handleClose,
  show,
  title,
  success,
  error,
  successText,
  children,
  disableInfo,
  noHeader,
}) => (
  <Container
    show={show}
    success={success}
    error={error}
    className="successModal"
  >
    <section className="modal-main">
      {!success ? (
        <>
       
          <div className={`${noHeader ? "modal-no-header" : "modal-header"} `}>
            <span>{title}</span>
            {disableInfo && (
              <FaInfoCircle
                color="#0C9FD8"
                title="lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum"
              />
            )}
            <button
              type="button"
              className="close-container"
              onClick={handleClose}
            >
              Fechar
              <FiX size={25} onClick={handleClose} />
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </>
      ) : (
        <>
          <FaCheckCircle color="#ffffff" size={86} />
          <p>{successText}</p>
          <button
            type="button"
            className="close-container"
            onClick={handleClose}
          >
            Fechar
            <FiX size={25} onClick={handleClose} />
          </button>
        </>
      )}
    </section>
  </Container>
);

export default Modal;

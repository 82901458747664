/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import DataTable from 'react-data-table-component';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container, DeleteButton } from './styles';

import { MdDelete } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';

const AddGovernance = ({
  show,
  handleClose,
  handleSuccess,
  handleDelete,
  creditOperation,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [governances, setGovernances] = useState([]);
  const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/governance/`;

  const columns = [
    {
      name: 'Função na linha gerencial',
      selector: 'job',
      minWidth: '0px',
    },
    {
      name: 'Nome',
      selector: 'name',
      minWidth: '0px',
    },
    {
      name: 'Deletar',
      minWidth: '0px',
      maxWidth: '80px',
      cell: row => (
        <DeleteButton onClick={() => handleDeleteImpact(row)} type="button">
          <MdDelete size={18} />
        </DeleteButton>
      ),
    },
  ];

  const schema = Yup.object().shape({
    job: Yup.string().required('Cargo é obrigatório'),
    name: Yup.string().required('Nome é obrigatório'),
  });

  const getGovernances = async () => {
    try {
      setLoading(true);
      const response = await api.get(apiUrl);
      setLoading(false);
      setGovernances(response.data.results);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGovernances();
  }, []);

  const handleAddGovernance = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const response = await api.post(apiUrl, {
          job: data.job,
          name: data.name,
          credit_operation: creditOperation.code,
        });
        setGovernances([...governances, response.data]);
        handleSuccess(response.data);
        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);
      }
    },
    [apiUrl, governances, handleSuccess, schema, creditOperation.code],
  );

  const handleDeleteImpact = useCallback(
    async data => {
      try {
        setLoading(true);
        await api.delete(`${apiUrl}${data.id}/`);
        const removeIndex = governances.map(item => item.id).indexOf(data.id);
        governances.splice(removeIndex, 1);
        setGovernances([...governances]);
        handleDelete(governances);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [governances, handleDelete, apiUrl],
  );

  return (
    <Container>
      <Modal
        title="Adicionar Governança (Linha Gerencial)"
        successText="Governança adicionada com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleAddGovernance}>
              <div className="inputs-container">
                <Input name="job" placeholder="Cargo" schema={schema} />
                <Input name="name" placeholder="Nome" schema={schema} />
                <button type="submit">
                  <FaPlus color="#ffffff" size={25} />
                </button>
              </div>
              <DataTable
                columns={columns}
                data={governances}
                noDataComponent="Nenhuma Governança Cadastrada"
                noHeader
              />
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddGovernance;

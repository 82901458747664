import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
`;

export const Content = styled.div``;

import React from 'react';
import RankingBar from '../RankingBar';

import { Container } from './styles';

function StrategicIndicatorGraphic({ data }) {
  return (
    <>
      <Container>
        {data.map(item => (
          <RankingBar
            key={item.name}
            percentage={item.percentage}
            color={item.is_proseg_participant ? '#0c9fd8' : '#D0D0D0'}
            title={item.name}
            value={item.score}
          />
        ))}
      </Container>
    </>
  );
}

export default StrategicIndicatorGraphic;

import React from 'react';
import { Container, Content, Wrapper } from './styles';
import { BsFillCheckCircleFill } from 'react-icons/bs';

function Resultados() {
  return (
    <Container>
      <h1>Resultados Esperados</h1>
      <Content>
        <Wrapper>
          <div>
            <BsFillCheckCircleFill className="icon" size="30" />
          </div>
          <div className="text">
            <p>
              Qualificar o processo de tomada de decisão pelos gestores públicos
              através da facilitação do acesso a evidências que atestem as
              soluções comprovadamente eficazes para a redução da criminalidade
              violenta e mitigação de seus condicionantes e fatores de risco.
            </p>
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <BsFillCheckCircleFill className="icon" size="30" />
          </div>
          <div className="text">
            <p>
              Ampliar e qualificar a capacidade de diagnóstico de estados e
              municípios brasileiros.
            </p>
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <BsFillCheckCircleFill className="icon" size="30" />
          </div>
          <div className="text">
            <p>
              Qualificar o processo de elaboração de projetos na área de
              segurança pública ao facilitar o acesso de estados e municípios a
              soluções baseadas em evidências.
            </p>
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <BsFillCheckCircleFill className="icon" size="30" />
          </div>
          <div className="text">
            <p>
              Estabelecer um novo patamar de relacionamento entre
              estados/municípios e as entidades financiadoras nos processos de
              contratação e gestão de operações de crédito.
            </p>
          </div>
        </Wrapper>
      </Content>
    </Container>
  );
}

export default Resultados;

import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Row, Column } from '../../../styles/components';
import SelectOptions from '../../../components/RegisterModal/options';
import Select from '../../../components/Select';
import ExternalLayout from '../../../components/ExternalLayout';
import { Container, MainContent } from './styles';

function DetailForm() {
  const { goBack } = useHistory();
  const formRef = useRef(null);
  const handleSubmit = useCallback(() => {}, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ExternalLayout headerTransparent={true}>
      <Container>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Metodologia</div>
            </div>
          </div>
          <div className="content">
            <button className="gobackLink" onClick={goBack}>
              Voltar
            </button>
            <div className="title">
              Formulário de Detalhamento de Pré-Proposta
            </div>
            <div className="text">
              <p>
                Cada uma das soluções selecionadas deverão ser detalhadas pelo
                ente com informações que caracterizem as ações e apoiem o
                monitoramento e gestão das mesmas, tais como prazos e custos
                estimados. Estas informações darão origem à pré-proposta de
                financiamento, instrumento que irá apoiar a negociação da
                operação de crédito com as instituições financeiras envolvidas.
                <br />
                <br />
                O módulo de elaboração da pré-proposta somente está disponível
                na área restrita da plataforma, podendo ser acessado apenas por
                estados e municípios habilitados ao PROSEG Federativo.
                <br />
                <br />
                Confira abaixo os Governos Estaduais e as Prefeituras Municipais
                que estão atualmente habilitados:
              </p>
            </div>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              style={{ marginTop: '35px' }}
            >
              <Row>
                <Column small="12" medium="6" large="6">
                  <Select
                    name="location"
                    options={SelectOptions.estados.sort(function (a, b) {
                      if (a.label > b.label) {
                        return 1;
                      }
                      if (a.label < b.label) {
                        return -1;
                      }
                      return 0;
                    })}
                    label="Estado"
                    placeholder="Selecione um Estado"
                  />
                </Column>
                <Column small="12" medium="6" large="6">
                  <Select
                    name="location"
                    options={SelectOptions.municipios.sort(function (a, b) {
                      if (a.label > b.label) {
                        return 1;
                      }
                      if (a.label < b.label) {
                        return -1;
                      }
                      return 0;
                    })}
                    label="Cidade"
                    placeholder="Selecione uma Cidade"
                  />
                </Column>
              </Row>
            </Form>
          </div>
        </MainContent>
      </Container>
    </ExternalLayout>
  );
}

export default DetailForm;

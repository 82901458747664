import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import { FaEdit, FaSearch } from 'react-icons/fa';
import Button from '../../../components/Button';
import PercentageBar from '../../../components/PercentageBar';
import TimePercentageBar from '../../../components/TimePercentageBar';
import { Container, Card, OptionsContainer, CardTitle } from './styles';

const ProjectAcoordion = ({
  projects,
  creditOperation,
  handleOpenDeleteProject,
  handleDeliverablesModal,
}) => {
  const { push } = useHistory();

  const getResultareaValue = useCallback(value => {
    const values = {
      prevencao_social: 'Prevenção Social',
      prevencao_situacional: 'Prevenção Situacional',
      policiamento: 'Policiamento',
      justica_criminal: 'Justiça Criminal',
      reinsercao_social: 'Reinserção Social',
      gestao: 'Gestão e Modernização Institucional',
    };
    return values[value];
  }, []);

  return (
    <Container>
      {projects.map(project => (
        <Accordion
          key={project.id}
          title={`${project.title}`}
          deleteCallBack={() => handleOpenDeleteProject(project.id)}
          hasDelete={
            !!(
              creditOperation.status === 'structuring' &&
              !creditOperation.is_delete
            )
          }
        >
          <Card>
            <OptionsContainer
              show={
                !!(
                  creditOperation.status === 'structuring' &&
                  !creditOperation.is_delete
                )
              }
            >
              <div
                onClick={() =>
                  push(`/projects/projetos-contratados/${project.id}`)
                }
              >
                <FaEdit />
                Editar
              </div>
            </OptionsContainer>
            <div className="content">
              <div className="info">
                <div className="container-text">
                  <CardTitle>Descrição:</CardTitle>
                  <span>{project.title}</span>
                </div>
                <div className="container-text">
                  <CardTitle>Área de Resultado:</CardTitle>
                  <span>{getResultareaValue(project.result_area)}</span>
                </div>
                <div className="container-text">
                  <CardTitle>Secretaria(s) Envolvida(s):</CardTitle>
                  {project.departament_project.map(secretaria => (
                    <span key={secretaria.id}>{secretaria.name}</span>
                  ))}
                </div>
                <div className="container-text">
                  <CardTitle>Responsável:</CardTitle>
                  {project.responsibles.map(responsible => (
                    <span key={responsible.id}>{responsible.name}</span>
                  ))}
                </div>
              </div>
              <div className="cards">
                <div className="content">
                  <div className="bar-container">
                    <PercentageBar
                      percentage={project.execution.physics}
                      title="Execução Fisica(%)"
                      color="yellow"
                    />
                  </div>
                  <div className="bar-container">
                    <PercentageBar
                      percentage={(project.execution.time_month.time / project.execution.time_month.total * 100) > 100 ? 100 : (project.execution.time_month.time / project.execution.time_month.total * 100)}
                      color="blue"
                      title="Tempo(%)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <Button
                color="blue"
                onClick={() => handleDeliverablesModal(project)}
              >
                <FaSearch /> Ver entregas e marcos Críticos
              </Button>
            </div>
          </Card>
        </Accordion>
      ))}
    </Container>
  );
};

export default ProjectAcoordion;

import styled from 'styled-components';

export const Container = styled.div`
  min-height: 600px;

  .content {
    width: 100%;
    max-width: 1140px;
    padding: 24px 0;
    margin: 0 auto;
    margin-top: 91px;
  }

  .redirectLink {
    text-decoration: underline;
    color: #004e70;
    font-weight: 700 !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
  }

  .stateTitle {
    color: #00a9ee;
    font-weight: 700;
    font: normal normal bold 67px/87px IBM Plex Sans;
    margin-bottom: 7px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1141px) {
    .content {
      padding: 25px;
    }
  }
`;

export const Title = styled.h1`
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  margin-bottom: 0.6rem;
`;

export const SubTitle = styled.h2`
  font: normal normal normal 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  margin-bottom: 1rem;
`;

export const DetailHeaderContainer = styled.div`
  display: flex;
  margin-top: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgb(234, 234, 234);

  .cardContainer {
    width: 25%;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    margin-left: 30px;
    span {
      text-align: left;
      font: normal normal bold 17px/22px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }

    h1 {
      text-align: left;
      font: normal normal bold 40px/52px IBM Plex Sans;
      letter-spacing: 0px;
      color: #075e89;
      word-break: break-all;
    }
  }

  @media only screen and (max-width: 768px) {
    .cardContainer {
      width: 35%;
    }
    .titleContainer {
      width: 100%;
      h1 {
        font: normal normal bold 25px/35px IBM Plex Sans;
      }
    }
  }

  @media only screen and (max-width: 585px) {
    flex-direction: column-reverse;
    .cardContainer {
      width: 100%;
    }
    .titleContainer {
      width: 100%;
      margin: 0;
      h1 {
        font: normal normal bold 25px/52px IBM Plex Sans;
      }
    }
  }
`;

export const GraphicContainer = styled.div``;

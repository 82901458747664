/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import ErrorModal from '../../../../components/ErrorModal';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import TextArea from '../../../../components/TextArea';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container, Title } from './styles';
import { Row, Column } from '../../../../styles/components';

const AddCriticalMilestone = ({
  show,
  handleClose,
  handleSuccess,
  handleEditSuccess,
  deliverableId,
  critical,
}) => {
  const formRef = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const schema = Yup.object().shape({
    name: Yup.string().required('Publico alvo é obrigatório'),
    responsible_name: Yup.string().required(
      'Nome do Responsável é obrigatório',
    ),
    organization: Yup.string().required('O orgão é obrigatória'),
    responsible_job: Yup.string().required('O Cargo é obrigatória'),
    responsible_email: Yup.string()
      .required('O Cargo é obrigatória')
      .email('Digite um Email válido'),
    responsible_phone: Yup.string().required('Por favor preencha o telefone'),
    end_date: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de prazo válida',
    ),
  });

  const createCritical = useCallback(
    async (data, splitedDate) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const apiUrl = `/credits/api/v1/entregable/${deliverableId}/critical/`;
        const response = await api.post(apiUrl, {
          responsible: {
            job: data.responsible_job,
            name: data.responsible_name,
            org: data.organization,
            phone: data.responsible_phone,
            email: data.responsible_email,
          },
          name: data.name,
          entregable: deliverableId,
          deadline: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`,
          comments: data.comments,
        });
        handleSuccess(response.data);
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        const { ...error } = err;
        setLoading(false);
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline);
        }
      }
    },
    [deliverableId, schema, handleSuccess],
  );

  const editCritical = useCallback(
    async (data, splitedDate) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const apiUrl = `/credits/api/v1/entregable/${deliverableId}/critical/${critical.id}/`;
        const response = await api.put(apiUrl, {
          responsible: {
            job: data.responsible_job,
            name: data.responsible_name,
            org: data.organization,
            phone: data.responsible_phone,
            email: data.responsible_email,
          },
          name: data.name,
          entregable: deliverableId,
          deadline: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`,
          comments: data.comments,
        });
        handleEditSuccess(response.data);
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        const { ...error } = err;
        setLoading(false);
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline);
        }
      }
    },
    [critical, deliverableId, schema, handleEditSuccess],
  );

  const handleSubmit = useCallback(async () => {
    const data = formRef.current.getData();
    const splitedDate = data.end_date.split('/');
    !!critical
      ? editCritical(data, splitedDate)
      : createCritical(data, splitedDate);
  }, [createCritical, editCritical, critical]);

  return (
    <Container>
      <Modal
        title={!!critical ? `Editar Marco Crítico` : 'Adicionar Marco Crítico'}
        success={success}
        successText={
          !!critical
            ? 'Marco Crítico Editado com sucesso'
            : 'Marco Crítico Adicionado com sucesso'
        }
        handleClose={handleClose}
        show={show}
      >
        {loading && <ModalLoader />}
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Column small="12" medium="10" large="10">
              <Input
                name="name"
                className="name"
                placeholder="Titulo"
                defaultValue={!!critical ? critical.name : ''}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="2" large="2">
              <InputMask
                name="end_date"
                mask="99/99/9999"
                schema={schema}
                maskChar={null}
                defaultValue={
                  !!critical
                    ? `${critical.deadline.split('-')[2]}/${
                        critical.deadline.split('-')[1]
                      }/${critical.deadline.split('-')[0]}`
                    : ''
                }
                placeholder="Prazo"
              />
            </Column>
          </Row>
          <Row>
            <Column small="12" medium="12" large="12">
              <TextArea
                name="comments"
                placeholder="Comentarios"
                defaultValue={!!critical ? critical.comments : ''}
              />
            </Column>
          </Row>
          <Title>Ponto Focal (Responsável)</Title>
          <Row>
            <Column small="12" medium="2" large="10">
              <Input
                name="responsible_name"
                className="responsible_name"
                placeholder="Nome"
                defaultValue={!!critical ? critical.responsible.name : ''}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="2" large="2">
              <Input
                name="organization"
                className="organization"
                placeholder="Orgão"
                defaultValue={!!critical ? critical.responsible.org : ''}
                schema={schema}
              />
            </Column>
          </Row>
          <Row>
            <Column small="12" medium="3" large="3">
              <Input
                name="responsible_job"
                className="responsible_job"
                placeholder="Cargo"
                defaultValue={!!critical ? critical.responsible.job : ''}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="6" large="6">
              <Input
                name="responsible_email"
                className="responsible_email"
                placeholder="Email"
                defaultValue={!!critical ? critical.responsible.email : ''}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="3" large="3">
              <InputMask
                name="responsible_phone"
                mask="(99)99999-9999"
                schema={schema}
                maskChar={null}
                defaultValue={!!critical ? critical.responsible.phone : ''}
                placeholder="Telefone"
              />
            </Column>
          </Row>
          <button className="button" type="button" onClick={handleSubmit}>
            {!!critical ? `Salvar` : 'Salvar'}
          </button>
        </Form>
      </Modal>
      {error && (
        <ErrorModal
          show={error}
          Message={errorMessage}
          handleClose={() => setError(false)}
        />
      )}
    </Container>
  );
};

export default AddCriticalMilestone;

/* eslint-disable */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import DashboardLayout from '../../components/DashboardLayout';
import DataTable from 'react-data-table-component';
import { useCreditOperation } from '../../hooks/creditOperation';
import { useLoader } from '../../hooks/loader';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import ErrorModal from '../../components/ErrorModal';
import Select from '../../components/Select';
import SuccessModal from '../../components/SuccessModal';
import { FaPlus } from 'react-icons/fa';
import { MdAddCircle, MdDelete, MdEdit } from 'react-icons/md';
import {
  Container,
  TitleButton,
  Title,
  BackButton,
  SecretariesInvolvedContainer,
  SecretariatItem,
  ResponsiblesContainer,
  DeleteButton,
  Card,
  AddDeliverableButton,
  DeliverableCard,
} from './styles';
import { Row, Column } from '../../styles/components';

const AddProjects = () => {
  const formRef = useRef(null);
  const { push } = useHistory();
  const { projectId } = useParams();
  const { activeLoader } = useLoader();
  const { creditOperation } = useCreditOperation();
  const [project, setProject] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [secretariats, setSecretariats] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [newResponsibles, setNewResponsibles] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [agreedResults, setAgreedResults] = useState([]);

  const schema = Yup.object().shape({
    title: Yup.string().required('Título é obrigatório'),
    result_area: Yup.string().required('área de resultado é obrigatória'),
    deadline: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de termino válida',
    ),
    departament_project: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().max(255).required(),
        }),
      )
      .min(1, 'Adicione uma secretária'),
    responsibles: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().max(255).required(),
          job: Yup.string().max(255).required(),
        }),
      )
      .min(1, 'Adicione um responsável'),
  });

  const LinkinResponsible = async (responsible, project) => {
    const apiUrl = `/credits/api/v1/contracted-project/${project.id}/responsible/`;
    const response = await api.post(apiUrl, {
      ...responsible,
      contract_project: project.id,
    });
    return response;
  };

  const getAgreedResult = useCallback((results, value) => {
    const agreedResult = results.filter(result => result.id === value)[0];
    return !!agreedResult
      ? { value: agreedResult.id, label: agreedResult.title }
      : null;
  }, []);

  const getAgreeedResults = useCallback(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/agreed-results/`;
    api.get(apiUrl).then(response => {
      setAgreedResults(response.data.results);
    });
  }, [creditOperation.code]);

  const getResultareaValue = value => {
    const values = {
      prevencao_social: {
        value: 'prevencao_social',
        label: 'Prevenção Social',
      },
      prevencao_situacional: {
        value: 'prevencao_situacional',
        label: 'Prevenção Situacional',
      },
      policiamento: { value: 'policiamento', label: 'Policiamento' },
      justica_criminal: {
        value: 'justica_criminal',
        label: 'Justiça Criminal',
      },
      reinsercao_social: {
        value: 'reinsercao_social',
        label: 'Reinserção Social',
      },
      gestao: {
        value: 'gestao',
        label: 'Gestão e Modernização Institucional',
      },
    };
    return values[value];
  };

  useEffect(() => {
    activeLoader(true);
    !!Object.keys(creditOperation).length && activeLoader(false);
  }, [activeLoader, creditOperation]);

  useEffect(() => {
    if (projectId) {
      const projectUrl = `/credits/api/v1/contracted-project/${projectId}/`;
      const responsiblesUrl = `/credits/api/v1/contracted-project/${projectId}/responsible/`;
      const deliverablesUrl = `/credits/api/v1/entregable/`;

      api.get(projectUrl).then(response => setProject(response.data));
      api.get(deliverablesUrl).then(response => {
        const filteredDeliverables = response.data.results.filter(
          deliv => deliv.contract_project === Number(projectId),
        );

        setDeliverables(filteredDeliverables);
      });
      api
        .get(responsiblesUrl)
        .then(response => setResponsibles(response.data.results));
    }
  }, [projectId, getAgreeedResults]);

  useEffect(() => {
    if (projectId && !!Object.keys(project).length) {
      formRef.current?.setFieldValue('indicator', project.indicator);
      formRef.current?.setFieldValue('title', project.title);
      formRef.current?.setFieldValue(
        'deadline',
        `${project.deadline.split('-')[2]}/${project.deadline.split('-')[1]}/${
          project.deadline.split('-')[0]
        }`,
      );
      formRef.current?.setFieldValue(
        'result_area',
        getResultareaValue(project.result_area),
      );
      setSecretariats([...project.departament_project]);
    }
  }, [projectId, project]);

  useEffect(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/agreed-results/`;
    const getAgreeds = async () => {
      const response = await api.get(apiUrl);
      await setAgreedResults(response.data.results);
      formRef.current?.setFieldValue(
        'agreed_result',
        getAgreedResult(response.data.results, project.agreed_result),
      );
    };
    getAgreeds();
  }, [creditOperation.code, project]);

  const handleAddProject = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        data = {
          ...data,
          departament_project: secretariats,
          responsibles,
          credit_operation: creditOperation.code,
        };

        await schema.validate(data, { abortEarly: false });
        const { responsible_job, ...transformedData } = data;
        setLoadingSuccess(true);
        setShowSuccess(true);
        const contractedProject = await api.post(
          '/credits/api/v1/contracted-project/',
          {
            ...transformedData,
            deadline: `${data.deadline.split('/')[2]}-${
              data.deadline.split('/')[1]
            }-${data.deadline.split('/')[0]}`,
          },
        );
        const unresolvedPromises = data.responsibles.map(responsible =>
          LinkinResponsible(responsible, contractedProject.data),
        );
        await Promise.all(unresolvedPromises);
        setLoadingSuccess(false);
        push(`/projects/projetos-contratados/${contractedProject.data.id}`);
      } catch (err) {
        setLoadingSuccess(false);
        setShowSuccess(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        const { ...error } = err;

        if (!!error.response.data.agreed_result) {
          setError(true);
          setErrorMessage('Esse resultado já está vinculado a outro projeto');
        }
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline);
        }
      }
    },
    [schema, secretariats, responsibles, creditOperation.code, push],
  );

  const handleEditProject = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        data = {
          ...data,
          departament_project: secretariats,
          responsibles: [{ name: 'name', job: 'job' }],
          credit_operation: creditOperation.code,
        };

        await schema.validate(data, { abortEarly: false });
        const { responsible_job, responsibles, ...transformedData } = data;
        setLoadingSuccess(true);
        setShowSuccess(true);
        const contractedProject = await api.put(
          `/credits/api/v1/contracted-project/${projectId}/`,
          {
            ...transformedData,
            deadline: `${data.deadline.split('/')[2]}-${
              data.deadline.split('/')[1]
            }-${data.deadline.split('/')[0]}`,
          },
        );
        const unresolvedPromises = newResponsibles.map(responsible =>
          LinkinResponsible(responsible, contractedProject.data),
        );
        await Promise.all(unresolvedPromises);
        setLoadingSuccess(false);
      } catch (err) {
        setLoadingSuccess(false);
        setShowSuccess(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        const { ...error } = err;

        if (!!error.response.data.agreed_result) {
          setError(true);
          setErrorMessage('Esse resultado já está vinculado a outro projeto');
        }
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline);
        }
      }
    },
    [schema, secretariats, creditOperation.code, newResponsibles, projectId],
  );

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      minWidth: '0px',
    },
    {
      name: 'Cargo',
      selector: 'job',
    },
    {
      name: 'Deletar',
      minWidth: '0px',
      maxWidth: '80px',
      cell: row => (
        <DeleteButton
          onClick={() => handleDeleteResponsible(row)}
          type="button"
        >
          <MdDelete size={18} />
        </DeleteButton>
      ),
    },
  ];

  const handleAddSecretariat = useCallback(() => {
    const formValues = formRef.current.getData();
    const addData = {
      id: secretariats.length + 1,
      name: formValues['departament_project'],
    };
    setSecretariats([...secretariats, addData]);
  }, [secretariats]);

  const handleAddResponsible = useCallback(() => {
    const formValues = formRef.current.getData();

    const addData = {
      id: responsibles.length + 1,
      name: formValues['responsibles'],
      job: formValues['responsible_job'],
    };

    setResponsibles([...responsibles, addData]);
    setNewResponsibles([...newResponsibles, addData]);
  }, [responsibles, newResponsibles, projectId]);

  const handleDeleteResponsible = useCallback(
    async data => {
      if (data.hasOwnProperty('contract_project')) {
        await api.delete(
          `/credits/api/v1/contracted-project/${data.contract_project}/responsible/${data.id}/`,
        );
      }
      const removeIndex = responsibles
        .map(responsible => responsible.id)
        .indexOf(data.id);
      responsibles.splice(removeIndex, 1);
      setResponsibles([...responsibles]);
      if (!!projectId) {
        const removeIndex = newResponsibles
          .map(responsible => responsible.id)
          .indexOf(data.id);
        newResponsibles.splice(removeIndex, 1);
        setNewResponsibles([...newResponsibles]);
      }
    },
    [responsibles, newResponsibles, projectId],
  );

  const handleDeletSecretariat = useCallback(
    data => {
      const removeIndex = secretariats
        .map(secretariat => secretariat.id)
        .indexOf(data.id);
      secretariats.splice(removeIndex, 1);
      setSecretariats([...secretariats]);
    },
    [secretariats],
  );

  const ResutAreaOptions = [
    { value: 'prevencao_social', label: 'Prevenção Social' },
    { value: 'prevencao_situacional', label: 'Prevenção Situacional' },
    { value: 'policiamento', label: 'Policiamento' },
    { value: 'justica_criminal', label: 'Justiça Criminal' },
    { value: 'reinsercao_social', label: 'Reinserção Social' },
    {
      value: 'gestao',
      label: 'Gestão e Modernização Institucional',
    },
  ];

  const LinkedResultsOptions = useMemo(() => {
    const options = agreedResults.map(result => ({
      value: result.id,
      label: result.title,
    }));
    return options;
  }, [agreedResults]);

  return (
    <Container>
      <DashboardLayout title="Monitoramento da Operação de crédito">
        <BackButton onClick={() => push('/projects/monitoramento-credito')}>
          Voltar
        </BackButton>
        <BackButton onClick={() => push('/projects/monitoramento-credito')}>
          Voltar para a operação de crédito
        </BackButton>
        <TitleButton>
          {`(${creditOperation.financing_entity} ${creditOperation.code}) ${creditOperation.operation_title}`}
        </TitleButton>
        {!projectId ? (
          <Title>Adicionar Projetos Contratados</Title>
        ) : (
          <Title>Editar Projeto Contratado</Title>
        )}
        <Form
          ref={formRef}
          onSubmit={!!projectId ? handleEditProject : handleAddProject}
        >
          <Row>
            <Column small="12" medium="12" large="12">
              <Input
                name="title"
                className="title"
                placeholder="Titulo"
                schema={schema}
              />
            </Column>
            <Column small="12" medium="12" large="12">
              <InputMask
                name="deadline"
                className="deadline"
                placeholder="Prazo Final"
                mask="99/99/9999"
                maskChar={null}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="6" large="6">
              <Select
                name="result_area"
                className="reasult_area"
                placeholder="Área de Resultado"
                options={ResutAreaOptions}
                schema={schema}
              />
            </Column>
            <Column small="12" medium="6" large="6">
              <Select
                name="agreed_result"
                className="agreed_result"
                placeholder="Selecione o Resultado Vinculado"
                options={LinkedResultsOptions}
                schema={schema}
              />
            </Column>
          </Row>
          <SecretariesInvolvedContainer>
            <h1>Secretarias Envolvidas :</h1>
            <Row>
              <Column small="12" medium="12" large="12">
                <div className="input-container">
                  <Input
                    name="departament_project"
                    className="departament_project"
                    placeholder="Nome da Secretaria"
                    schema={schema}
                  />
                  <button type="button" onClick={() => handleAddSecretariat()}>
                    <FaPlus color="#ffffff" />
                  </button>
                </div>
              </Column>
            </Row>
            <Row className="itens-row">
              <Column small="12" medium="12" large="12">
                {!!secretariats.length &&
                  secretariats.map(secretariat => (
                    <SecretariatItem key={secretariat.name}>
                      <span>{secretariat.name}</span>
                      <button
                        type="button"
                        onClick={() => handleDeletSecretariat(secretariat)}
                      >
                        <MdDelete size={23} color="#FFFFFF" />
                      </button>
                    </SecretariatItem>
                  ))}
                {secretariats.length === 0 && (
                  <p>Nenhuma secretaria adicionada</p>
                )}
              </Column>
            </Row>
          </SecretariesInvolvedContainer>
          <ResponsiblesContainer>
            <h1>Responsável :</h1>
            <Row>
              <Column small="12" medium="12" large="12">
                <div className="input-container">
                  <Input
                    name="responsibles"
                    className="responsible_name"
                    placeholder="Nome do responsável"
                    schema={schema}
                  />
                  <Input
                    name="responsible_job"
                    className="responsible_job"
                    placeholder="Digite o cargo"
                    schema={schema}
                  />
                  <button type="button" onClick={() => handleAddResponsible()}>
                    <FaPlus color="#ffffff" />
                  </button>
                </div>
              </Column>
            </Row>
            <Row className="itens-row">
              <Column small="12" medium="12" large="12">
                <DataTable
                  columns={columns}
                  data={responsibles}
                  noDataComponent=""
                  noHeader
                />
                {responsibles.length === 0 && (
                  <p className="noOptionsMessage">
                    Nenhum responsável adicionado
                  </p>
                )}
              </Column>
            </Row>
          </ResponsiblesContainer>
          <Card shadow>
            {!projectId ? (
              <Title>
                Entregáveis só podem ser adicionados depois de salvar o projeto
              </Title>
            ) : (
              <Title>Adicionar Entregáveis do Projeto :</Title>
            )}
            <Row>
              <Column small="12" medium="4" large="3">
                <AddDeliverableButton
                  readOnly={!!projectId}
                  onClick={() =>
                    push(
                      `/projects/projetos-contratados/${projectId}/entregaveis`,
                    )
                  }
                >
                  <MdAddCircle size={55} color="#AAB9BF" />
                  <span>Adicionar</span>
                </AddDeliverableButton>
              </Column>
              <Column small="12" medium="4" large="3">
                {!!deliverables.length &&
                  deliverables.map(deliverable => (
                    <DeliverableCard key={deliverable.id}>
                      <MdEdit
                        size={18}
                        color="#ffffff"
                        onClick={() =>
                          push(
                            `/projects/projetos-contratados/${projectId}/entregaveis/${deliverable.id}`,
                          )
                        }
                      />
                      <span>{deliverable.id}</span>
                      <p>{deliverable.title}</p>
                    </DeliverableCard>
                  ))}
              </Column>
            </Row>
          </Card>
          <button className="save" type="submit">
            {!projectId ? 'Salvar' : 'Salvar'}
          </button>
        </Form>
        {showSuccess && (
          <SuccessModal
            loading={loadingSuccess}
            handleClose={() => setShowSuccess(false)}
            show={showSuccess}
            successText={
              !!projectId
                ? 'Projeto contratado Editado com Sucesso!!'
                : 'Projeto contratado Adicionado com Sucesso!!'
            }
          />
        )}
        {error && (
          <ErrorModal
            show={error}
            Message={errorMessage}
            handleClose={() => setError(false)}
          />
        )}
      </DashboardLayout>
    </Container>
  );
};

export default AddProjects;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExternalLayout from '../../../components/ExternalLayout';
import Solutions from './components/Solutions';
import { Container, MainContent } from './styles';

function SelectSolutions() {
  const { goBack } = useHistory();

  useEffect(() => {
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <ExternalLayout headerTransparent={true}>
      <Container>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Metodologia</div>
            </div>
          </div>
          <div className="content">
            <button className="gobackLink" onClick={goBack}>
              Voltar
            </button>
            <div className="title">Seleção de Soluções</div>
            <div className="text">
              <p>
                O principal objetivo do PROSEG Federativo é o de qualificar o
                processo de elaboração de projetos na área de segurança pública
                ao facilitar o acesso de estados e municípios a soluções
                baseadas em evidências. <br />
                <br />
                Desse modo, a Plataforma de Segurança está estruturada para
                facilitar o processo de construção e pré-propostas de
                financiamento, as quais poderão, futuramente, servir de base
                para os documentos através dos quais os estados e municípios
                iniciarão, formalmente, os processos de negociação de novas
                operações de crédito junto ao BID/BNDES.
                <br />
                <br />
                A construção dessas pré-propostas se dá a partir da seleção de
                soluções baseadas em evidências que são apresentadas pela
                plataforma ao usuário de acordo com a adequação de cada solução
                aos problemas identificados ao longo da fase de diagnóstico
                (indicadores) e de avaliação de maturidade.
                <br />
                <br />
                Com base na performance do estado/município nos indicadores
                selecionados e nas respostas cadastradas pelo ente no checklist
                de maturidade, as soluções são segmentadas, pela plataforma, em
                dois grupos: soluções recomendadas (possuem clara vinculação aos
                problemas identificados) e soluções elegíveis (não se vinculam
                diretamente aos dados quantitativos ou qualitativos coletados,
                mas podem ser selecionados pelo ente para composição de sua
                pré-proposta).
              </p>
            </div>
            <Solutions />
          </div>
        </MainContent>
      </Container>
    </ExternalLayout>
  );
}

export default SelectSolutions;

import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import { Row, Column } from '../../../../../styles/components';
import img1 from '../../../../../assets/botao1.png';
import img2 from '../../../../../assets/botao4.png';
import { Container, Content, Box } from './styles';

function InitSection() {
  return (
    <Container>
      <Content>
        <Row>
          <Column small="12" medium="6" large="6">
            <Link to="/diagnostico-indicadores/">
              <Box color1="#004e70" color2="#1d5e89">
                <div className="contentBox">
                  <div className="iconContainer">
                    <img src={img1} alt="img" />
                  </div>
                  <div className="textContainer">
                    <p className="title">
                      O que dizem os dados? Qual o desempenho do meu
                      estado/município na segurança pública?
                    </p>
                    <p className="subtitle">
                      Acesse o diagnóstico executivo (acesso público)
                    </p>
                  </div>
                </div>
                <div className="arrowContainer">
                  <FaAngleRight size={50} color="#fff" />
                </div>
              </Box>
            </Link>
          </Column>
          <Column small="12" medium="6" large="6">
            <a
              href="https://esegurancat-idb-blogs.pantheonsite.io/brazil/banco/"
              target="_blank"
              rel="noreferrer"
            >
              <Box color1="#242424" color2="#333333">
                <div className="contentBox">
                  <div className="iconContainer">
                    <img src={img2} alt="img" />
                  </div>
                  <div className="textContainer">
                    <p className="title">
                      O que fazer? Que soluções técnicas e financeiras podem ser
                      contratadas?
                    </p>
                    <p className="subtitle">
                      Conheça as soluções do PROSEG Federativo e estruture sua
                      pré-proposta (área restrita)
                    </p>
                  </div>
                </div>
                <div className="arrowContainer">
                  <FaAngleRight size={50} color="#fff" />
                </div>
              </Box>
            </a>
          </Column>
        </Row>
        <Row>
          <Column small="12" medium="12" large="12">
            <p className="initText">
              A &nbsp; <b>Plataforma de Segurança</b> &nbsp; é uma ferramenta
              desenvolvida pelo Banco Interamericano de Desenvolvimento (BID)
              para desenvolvimento e gestão das operações de crédito do &nbsp;
              <b>"PROSEG Federativo"</b> &nbsp;, programa de financiamento de
              projetos de <b>segurança pública baseada em evidências</b>
              desenvolvido através de uma parceria entre o BID e o Banco
              Nacional de Desenvolvimento Econômico e Social (BNDES).
              <br />
              <br />
              Através desse portal você poderá &nbsp;
              <b>
                acessar dados comparativos e analisar a situação atual e a
                evolução recente de seu estado/município na área da segurança.
              </b>
              &nbsp; Com base nesses insights, você poderá &nbsp;
              <b>
                estruturar propostas para contratar as linhas de crédito do
                PROSEG Federativo e acessar soluções técnicas e financeiras
              </b>
              &nbsp; necessárias à implantação de projetos e políticas com
              &nbsp;
              <b>
                impacto comprovadamente positivo sobre os índices de
                criminalidade e violência.
              </b>
              &nbsp;
              <br />
              <br />
              Estão contempladas soluções em &nbsp;
              <b>06 eixos de atuação, ou “áreas de resultado”</b>:
            </p>
          </Column>
        </Row>
      </Content>
    </Container>
  );
}

export default InitSection;

import styled from 'styled-components';

export const Container = styled.button`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--gray3)`};

  color: var(--white);
  font-weight: bold;
  border: ${props => (props.border ? ` 1px solid var(--${props.border})` : ``)};
  padding: 14px 23px;
  border-radius: 10px;

  display: flex;
  align-items: center;

  transition: background 0.3s ease;

  svg {
    margin-right: 5px;
  }
`;

import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import DataTable from 'react-data-table-component';
import ModalLoader from '../ModalLoader';
import FileInput from '../FileInput';
import Modal from '../Modal';
import api from '../../services/api';
import { Container } from './styles';

const StandardDocuments = ({ show, handleClose, creditOperation }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [docs, setDocs] = useState({});

  const columns = [
    {
      name: 'Tipo de Documento',
      selector: 'type',
      minWidth: '0px',
    },
    {
      name: 'Documento',
      selector: 'doc',
      minWidth: '0px',
      maxWidth: '200px',
      cell: row => {
        return (
          <div className="docContainer">
            <FileInput name={row.doc} title="Anexar Documento" />
            {docs[row.doc] && (
              <a href={docs[row.doc]} download>
                Download
              </a>
            )}
          </div>
        );
      },
    },
  ];

  const data = [
    {
      type: 'Proposta para o Desenvolvimento de Operações (POD)',
      doc: 'doc_pod',
    },
    {
      type: 'Plano de Execução do Projeto (PEP)',
      doc: 'doc_pep',
    },
    {
      type: 'Plano Operacional Anual (POA) ',
      doc: 'doc_poa',
    },
    {
      type: 'Plano de Aquisições (PA)',
      doc: 'doc_pa',
    },
  ];

  useEffect(() => {
    const getDocs = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `/credits/api/v1/opcredit-manager/${creditOperation.code}/docs/`,
        );
        setDocs(response.data.results[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return;
      }
    };
    getDocs();
  }, [creditOperation.code]);

  const handleAddDocs = useCallback(
    async data => {
      setLoading(true);
      const formData = new FormData();
      for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
          if (!!data[name]) formData.append(name, data[name]);
        }
      }
      const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/docs/${docs.id}/`;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      try {
        await api.put(apiUrl, formData, config);
        setLoading(false);
        setSuccess(true);
      } catch (err) {
        const { ...error } = err;
        setLoading(false);
        console.error(error);
      }
    },
    [creditOperation.code, docs.id],
  );

  return (
    <Container>
      <Modal
        title="Documentos de Referência"
        successText="Documentos de referência adicionados com sucesso"
        handleClose={handleClose}
        show={show}
        success={success}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleAddDocs}>
              <DataTable
                columns={columns}
                data={data}
                noDataComponent="Nenhum Documento Cadastrado"
                noHeader
                noTableHead
              />
              <button type="submit">Salvar</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default StandardDocuments;

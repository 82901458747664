import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoader } from '../../hooks/loader';
import { MdAddCircle } from 'react-icons/md';
import { FaBook } from 'react-icons/fa';
import icon1 from '../../assets/icon_painel_1.png';
import icon2 from '../../assets/icon_painel_2.png';
import icon3 from '../../assets/icon_painel_3.png';

import DashboardLayout from '../../components/DashboardLayout';
import AddOperationModal from './modals/AddOperation';
import Button from '../../components/Button';
import api from '../../services/api';

import { Container, ManagementGrid, Card, AdminButtons } from './styles';

const ManagementMonitoring = () => {
  const { push } = useHistory();
  const { activeLoader } = useLoader();
  const [showAddOperationModal, setShowAddOperationModal] = useState(false);

  const [countOperation, setCountOperation] = useState({});

  const getCountOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/count/');
      setCountOperation(response.data);
      activeLoader(false);
    } catch (err) {
      activeLoader(false);
      console.error(err);
    }
  }, [activeLoader]);

  useEffect(() => {
    activeLoader(true);
    getCountOperations();
  }, [activeLoader, getCountOperations]);

  const handleAddSuccess = useCallback(() => {
    getCountOperations();
  }, [getCountOperations]);

  return (
    <Container>
      <DashboardLayout title="Gestão e Monitoramento">
        <ManagementGrid>
          <Card className="card1" noShadow>
            <AdminButtons>
              <button
                type="button"
                onClick={() => setShowAddOperationModal(true)}
              >
                <MdAddCircle size={55} color="#AAB9BF" />
                <span>Cadastrar nova operação de crédito</span>
              </button>
              <section onClick={() => push('/management/operacoes-credito')}>
                <span>{countOperation.count_operation}</span>
                <p>Operações de crédito cadastradas</p>
              </section>
            </AdminButtons>

            {showAddOperationModal && (
              <AddOperationModal
                handleClose={() =>
                  setShowAddOperationModal(!showAddOperationModal)
                }
                handleSuccess={() => handleAddSuccess()}
                show={showAddOperationModal}
              />
            )}
          </Card>

          <Card className="card2">
            <div className="content">
              <h1>
                Olá, bem vindo ao módulo de Gestão e Monitoramento do PROSEG
                Federativo!
              </h1>
              <p>
                Esta etapa é reservada para estados e municípios que concluíram
                o processo de contratação de novas operações de crédito junto ao
                BID/BNDES no âmbito do PROSEG Federativo.
              </p>
              <br />
              <p>
                Nesta seção você pode acompanhar a execução da operação de
                crédito por meio do gerenciamento e monitoramento intensivos dos
                projetos desenvolvidos através dos empréstimos contratados junto
                às entidades financiadoras do programa.
              </p>
              <br />
              <p>
                Clique abaixo e acesse o Painel de Monitoramento dos Projetos e
                o Painel de Monitoramento dos Impactos.
              </p>
              <Button color="gray3">
                <FaBook /> Manual do Usuário
              </Button>
            </div>
          </Card>

          <Card
            className="card3 card"
            onClick={() => push('/management/operacoes-credito')}
          >
            <div className="content">
              <img src={icon1} alt="icone 1" />
              <span>Operações de Crédito do PROSEG Federativo</span>
              <p>
                Visão de conjunto das operações de crédito do PROSEG Federativo
                contratadas por outros entes federativos
              </p>
            </div>
          </Card>

          <Card
            className="card4 card"
            onClick={() => push('/management/monitoramento-impactos')}
          >
            <div className="content">
              <img src={icon2} alt="icone 2" />
              <span>Painel de Monitoramento dos Impactos</span>
              <p>
                Trajetória recente e tendências extrapolativas para os
                indicadores de impacto e visão comparativa com os demais entes
              </p>
            </div>
          </Card>

          <Card
            className="card5 card"
            onClick={() => push('/management/nucleo-conhecimento')}
          >
            <div className="content">
              <img src={icon3} alt="icone 3" />
              <span>Núcleo de Conhecimento</span>
              <p>
                Repositório de documentos, Plataforma de Ensino e Banco de
                Dúvidas
              </p>
            </div>
          </Card>
        </ManagementGrid>
      </DashboardLayout>
    </Container>
  );
};

export default ManagementMonitoring;

/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';
import InputMask from '../../../../components/InputMask';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';
import { Row, Column } from '../../../../styles/components';

const AddRestriction = ({
  show,
  handleClose,
  deliverable,
  critical,
  handleSuccess,
}) => {
  const addCriticalformRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const schema = Yup.object().shape({
    restriction: Yup.string().required('Restrição é obrigatória'),
    providence: Yup.string().required('Providencia é obrigatória'),
    name: Yup.string().required('Noime do Responsável é obrigatório'),
    deadline_providence: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de prazo válida',
    ),
    email: Yup.string()
      .required('O Email é obrigatório')
      .email('Digite um Email válido'),
  });

  // useEffect(() => {
  //   console.log('critical', critical);
  // }, [critical]);

  const handleSubmit = useCallback(
    async data => {
      try {
        addCriticalformRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const splitedDeadLine = data.deadline_providence.split('/');
        await api.post(`/credits/api/v1/critical/${critical.id}/restriction/`, {
          ...data,
          critical: critical.id,
          deadline_providence: `${splitedDeadLine[2]}-${splitedDeadLine[1]}-${splitedDeadLine[0]}`,
        });

        setTimeout(() => {
          setLoading(false);
          setsuccess(true);
          handleSuccess({});
        }, 3000);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          addCriticalformRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [critical.id, schema],
  );

  return (
    <Container>
      <Modal
        title="Adicionar Restrição"
        success={success}
        successText="Restrição Adicionada com Sucesso!!"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={addCriticalformRef} onSubmit={handleSubmit}>
              <Row>
                <Column small="12" medium="12" large="12">
                  <TextArea
                    name="restriction"
                    className="objetivo_geral"
                    placeholder="Restrição"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <TextArea
                    name="providence"
                    className="providence"
                    placeholder="Providencia"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="3" large="4">
                  <InputMask
                    label="Data Limite da Providência"
                    name="deadline_providence"
                    className="deadline_providence"
                    placeholder="Data Limite da Providência"
                    mask="99/99/9999"
                    maskChar={null}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="3" large="4">
                  <Input
                    label="Responsável pela Providência"
                    name="name"
                    className="name"
                    placeholder="Responsável"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="3" large="4">
                  <Input
                    label="Email do Responsável"
                    name="email"
                    className="email"
                    placeholder="Email"
                    schema={schema}
                  />
                </Column>
              </Row>
              <button className="save" type="submit">
                Salvar
              </button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddRestriction;

import styled from 'styled-components';

export const Container = styled.div``;

export const CardTitle = styled.p`
  text-align: left;
  font: normal normal bold 19px/24px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    cursor: pointer;
    margin-right: 5px;
    path {
      fill: var(--blue);
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 21px;
  color: var(--blue);
  margin-bottom: 25px;

  display: ${props => (props.show ? 'flex' : 'none')};

  & > div {
    margin-left: 29px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 22px;
      margin-right: 3px;
    }
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;

  header {
    padding: 10px 16px;
    color: var(--white);
    height: 52px;
    font: normal normal bold 19px/24px IBM Plex Sans;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .content {
    padding: 15px;
    display: flex;

    .info {
      width: 55%;
      padding-right: 20px;

      .container-text {
        margin-bottom: 30px;
        font-size: 1.1rem;

        p,
        span {
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .cards {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .content {
        border: 1px solid #f1f1f1;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        padding: 15px 25px;
      }

      button {
        justify-content: center;
        margin-top: 10px;
      }
    }

    .buttonContainer {
      display: block;
    }
  }
`;

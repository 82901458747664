import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    background: #f1f1f1;

    .FinalizeButtoncontainer {
      display: flex;
      flex-direction: column;
      button {
        transition: background 0.3s ease;
        display: flex;
        align-items: center;

        justify-content: space-between;
        align-self: flex-end;
        background: #5eb245 0% 0% no-repeat padding-box;
        border-radius: 9px;
        margin-top: -50px;
        font: normal normal bold 18px/20px IBM Plex Sans;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 11px;

        & svg {
          margin-right: 5px;
        }

        &:hover {
          background: #359c16;
        }
      }
    }

    .indicators-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    .buttons-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      button {
        transition: background 0.3s ease;
        letter-spacing: 0px;
        color: #ffffff;
        border-radius: 9px;
        padding: 15px;
        text-align: left;
        font: normal normal bold 16px/20px IBM Plex Sans;
        letter-spacing: 0px;
        background: var(--yellow);
        margin-right: 0;

        &:hover {
          background: #c59410;
        }
      }

      button:nth-child(2n) {
        transition: background 0.3s ease;
        background: #6d6e71;
        border-radius: 9px;
        text-align: left;
        font: normal normal bold 16px/20px IBM Plex Sans;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 15px;
        margin-right: 10px;

        &:hover {
          background: #3a3a3a;
        }
      }
    }

    @media screen and (max-width: 830px) {
      .FinalizeButtoncontainer {
        display: block;

        button {
          margin: 0px;
        }
      }
    }
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: var(--white);
    color: var(--dark-blue);
    text-align: center;
    border-left: 1px solid #d0d0d0;
    border-top: 1px solid #d0d0d0;
    text-align: center;
  }

  .rdt_TableCol_Sortable {
    width: 100%;

    & > div {
      width: 100%;
    }
  }

  .rdt_TableCell {
    text-align: center;
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 37px;

    & > div {
      white-space: inherit;
    }
  }

  .emptyDeliverable {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: #00a9ee;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const CardTitle = styled.p`
  text-align: left;
  letter-spacing: 0px;
  font: normal normal normal 18px/30px IBM Plex Sans;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  b {
    color: #004e70;
    font: normal normal bold 19px/24px IBM Plex Sans;
  }

  svg {
    cursor: pointer;
    margin-right: 5px;
    path {
      fill: var(--blue);
    }
  }

  button {
    background: transparent !important;
    color: var(--blue) !important;
    margin: 0 !important;
    border-bottom: 1px solid var(--blue) !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin-left: 10px !important;
    cursor: pointer !important;
    margin-bottom: 5px !important;
  }
`;

export const Card = styled.div`
  background: var(--white);
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;

  border: 1px solid #d0d0d0;
  width: 100%;
  display: grid;
  margin: 15px 0;
`;

export const StatusSpan = styled.span`
  color: ${props => props.color && `var(--${props.color})`};
  text-align: center;
  font: normal normal bold 12px/15px IBM Plex Sans;
  letter-spacing: 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const GestaoButton = styled.button`
  min-width: 36px;
  min-height: 36px;
  margin-left: 10px;

  border-radius: 7px;
  background: ${props => props.color && `var(--${props.color})`};

  span {
    color: var(--white);
    font-weight: bold;
  }
`;

export const FinishButton = styled.button`
  opacity: ${props => (props.readonly ? '0.3' : '1')};
  pointer-events: ${props => (props.readonly ? 'none' : 'inherit')};
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 21px;
  color: var(--blue);
  margin-bottom: 25px;

  position: absolute;
  top: 0;
  right: 0;

  display: ${props => (props.show ? 'flex' : 'none')};

  & > div {
    margin-left: 29px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 22px;
      margin-right: 3px;
    }
  }

  @media screen and (max-width: 768px) {
    top: -19px;
  }
`;

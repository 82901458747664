import styled from 'styled-components';

export const Row = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  &:before,
  &after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

function getWidthGrid(value) {
  if (!value) return;
  let width = (value / 12) * 100;
  return `width: ${width}%`;
}

export const Column = styled.div`
  width: 100%;
  padding: 0.25rem;
  min-height: 1px;
  box-sizing: border-box;
  margin-bottom: 25px;
  display: ${props => (props.display ? 'none' : 'block')};

  @media only screen and (min-width: 769px) {
    ${props => props.small && getWidthGrid(props.small)};
  }

  @media only screen and (min-width: 769px) {
    ${props => props.medium && getWidthGrid(props.medium)};
  }

  @media only screen and (min-width: 1025px) {
    ${props => props.large && getWidthGrid(props.large)};
  }
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import StepDonut from '../StepDonut';
import { Container, MobileContainer } from './styles';

function BreadCrumb({ options, active, setCallBack, diagnostico }) {
  const [mobileDonutText, setMobileDonutText] = useState('1/3');
  const [mobileDonutPercentage, setMobileDonutPercentage] = useState(33.33);

  const openReport = useCallback(() => {
    // window.location.href = `/relatorio-executivo/${diagnostico.id}`;
    window.open(`/relatorio-executivo/${diagnostico.id}`, '_blank');
  }, []);

  const setMobileBreadCrumb = useCallback(active => {
    if (active === 1) {
      setMobileDonutText('1/3');
      setMobileDonutPercentage(33.33);
    }
    if (active === 2) {
      setMobileDonutText('2/3');
      setMobileDonutPercentage(66.66);
    }
    if (active === 3) {
      setMobileDonutText('3/3');
      setMobileDonutPercentage(100);
    }
  }, []);

  useEffect(() => {
    setMobileBreadCrumb(active);
  }, [active, setMobileBreadCrumb]);

  return (
    <>
      <Container>
        <ul className="breadcrumb">
          {options.map((option, index) => (
            <div className="li-container" key={`b-${index}`}>
              <span className="text">{`Passo ${option.step}`}</span>
              <li className={active >= option.step ? 'active' : 'inactive'}>
                {option.link ? (
                  <a href={option.link}>{option.name}</a>
                ) : (
                  <a href="#" onClick={() => setCallBack(option.step)}>
                    {option.name}
                  </a>
                )}
              </li>
            </div>
          ))}
        </ul>
        <button type="button" onClick={openReport} style={{marginBottom: "0%"}}>
          <FaDownload />
          <span className="">Download do Relatório Executivo</span>
        </button>
      </Container>
      <MobileContainer>
        {/* <div className="donutContainer">
          <StepDonut
            value={mobileDonutPercentage}
            text={mobileDonutText}
            type="percent"
            color="#075E89"
            size={116}
            strokewidth={8}
            large
          />
        </div> */}
        <div className="contentContainer">
          <div>
            {active <= 3 && (
              <h1 className="text">{`Passo ${active} - ${
                options[active - 1].name
              }`}</h1>
            )}

            {active <= 2 && (
              <>
                <small>{`Próximo passo - ${options[active].name}`}</small>
                <br />
              </>
            )}
            {active > 1 && (
              <small>{`Passo Anterior - ${options[active - 2].name}`}</small>
            )}
          </div>
          <div>
            {active > 1 && (
              <button type="button" onClick={() => setCallBack(active - 1)}>
                Anterior
              </button>
            )}
            {active <= 2 && (
              <button type="button" onClick={() => setCallBack(active + 1)}>
                Próximo
              </button>
            )}
          </div>
        </div>
      </MobileContainer>
    </>
  );
}

export default BreadCrumb;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUnlock, FaLock } from 'react-icons/fa';
import ExternalLayout from '../../../components/ExternalLayout';
import QuebraCabeca from '../../../assets/quebracabeca.png';
import QuebraCabeca1 from '../../../assets/quebracabeca_1.png';
import QuebraCabeca2 from '../../../assets/quebracabeca_2.png';
import QuebraCabeca3 from '../../../assets/quebracabeca_3.png';
import QuebraCabeca4 from '../../../assets/quebracabeca_4.png';
import { Container, MainContent, MethodologyCard } from './styles';

function Methodology() {
  useEffect(() => {
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <ExternalLayout headerTransparent={true}>
      <Container>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Metodologia</div>
            </div>
          </div>
          <div className="content">
            <div className="title">Como Funciona</div>
            <div className="initContainer">
              <div className="text">
                <p>
                  A Plataforma Brasileira de Gestão por Resultados em Segurança
                  Pública está organizada para conduzir os usuários em uma
                  &nbsp;
                  <b>
                    “jornada” que possibilite a qualificação de suas
                    compreensões a respeito do tema e que oriente a construção
                    de propostas de financiamento alinhadas às evidências
                  </b>
                  &nbsp; e as melhores práticas disponíveis.
                </p>
                <p>
                  Para tanto, a plataforma se subdivide em 04 módulos
                  principais, com o primeiro dele aberto ao público geral e os
                  outros três restritos aos estados e municípios com acesso
                  habilitado à área restrita da plataforma. Veja mais abaixo:
                </p>
              </div>
              <div className="image">
                <img src={QuebraCabeca} alt="quebracabeca" />
              </div>
            </div>
            <div className="cardContainer">
              <MethodologyCard>
                <div className="imageContainer">
                  <img src={QuebraCabeca1} alt="quebracabeca1" />
                </div>
                <div className="cardContentContainer">
                  <h1>1. O que dizem os dados?</h1>
                  <div className="text">
                    <p>
                      A primeira etapa da jornada está &nbsp;
                      <b>aberta a todo e qualquer usuário</b>&nbsp;, e tem como
                      objetivo principal facilitar o acesso dos cidadãos
                      brasileiros a &nbsp;
                      <b>
                        dados e indicadores relevantes para o tema da segurança
                        pública baseada em evidências.
                      </b>
                      &nbsp; Ao acessar essa seção, você poderá acessar &nbsp;
                      <b>dados extraídos de fontes públicas/oficiais</b>
                      &nbsp;e, a partir destes, compreender de forma mais
                      aprofundada a situação do seu estado ou município. &nbsp;
                      <b>
                        Na área logada da plataforma será possível aos gestores
                        públicos agregarem indicadores locais ao diagnóstico,
                      </b>
                      &nbsp; tendo em vista os dados disponíveis em bases de
                      registros administrativos estaduais e municipais.
                    </p>
                  </div>
                  <div className="linkContainer">
                    <Link
                      to="/metodologia/diagnostico-de-indicadores"
                      className="link"
                    >
                      Saiba mais sobre o Diagnóstico de Indicadores
                    </Link>
                    <div className="button">
                      <FaUnlock size={20} />
                      Etapa acessível a todos os estados e municípios
                    </div>
                  </div>
                </div>
              </MethodologyCard>
              <MethodologyCard>
                <div className="imageContainer">
                  <img src={QuebraCabeca2} alt="quebracabeca2" />
                </div>
                <div className="cardContentContainer">
                  <h1>
                    2. Qual o nível de maturidade de meu estado/município na
                    aplicação de soluções de segurança pública baseada em
                    evidências?
                  </h1>
                  <div className="text">
                    <p className="card-metodo-text py-2">
                      Nesta etapa gestores e servidores públicos vinculados aos
                      estados e municípios “alvo” do PROSEG Federativo poderão
                      &nbsp;
                      <b>
                        avaliar o seu estágio de maturidade na aplicação de
                        práticas e soluções respaldadas pelas melhores
                        evidências na área de segurança pública.
                      </b>
                      &nbsp; Essa avaliação é feita de forma automatizada pela
                      plataforma, a partir das respostas dos usuários a um
                      questionário padronizado (“checklist”). &nbsp;
                      <b>Esse módulo está disponível apenas na área restrita</b>
                      &nbsp; da plataforma, cujo acesso se dá mediante cadastro
                      institucional.
                    </p>
                  </div>
                  <div className="linkContainer">
                    <Link
                      to="/metodologia/checklist-de-maturidade/"
                      className="link"
                    >
                      Saiba mais sobre o Checklist de Maturidade
                    </Link>
                    <div className="button">
                      <FaLock size={20} />
                      Etapa de acesso restrito a estados e municípios
                      habilitados
                    </div>
                  </div>
                </div>
              </MethodologyCard>
              <MethodologyCard>
                <div className="imageContainer">
                  <img src={QuebraCabeca3} alt="quebracabeca3" />
                </div>
                <div className="cardContentContainer">
                  <h1>
                    3. Que soluções técnicas posso acessar para alavancar os
                    resultados de meu estado/município na área de segurança
                    pública?
                  </h1>
                  <div className="text">
                    <p>
                      Mediante habilitação pelo BID/BNDES, estados e municípios
                      poderão estruturar, diretamente na plataforma, suas
                      pré-proposta de financiamento. Nessa etapa, os gestores
                      públicos poderão &nbsp;
                      <b>
                        selecionar as soluções que desejam implementar em seu
                        estado/município
                      </b>
                      &nbsp; a partir de um “menu” composto por práticas
                      respaldadas em evidências nacionais e internacionais e
                      alinhadas aos resultados do diagnóstico (etapa 01) e do
                      checklist de maturidade (etapa 02).
                    </p>
                  </div>
                  <div className="linkContainer">
                    <Link
                      to="/metodologia/selecao-de-solucoes/"
                      className="link"
                    >
                      Saiba mais sobre a Seleção de Soluções
                    </Link>
                    <div className="button">
                      <FaLock size={20} />
                      Etapa de acesso restrito a estados e municípios
                      habilitados
                    </div>
                  </div>
                </div>
              </MethodologyCard>
              <MethodologyCard>
                <div className="imageContainer">
                  <img src={QuebraCabeca4} alt="quebracabeca4" />
                </div>
                <div className="cardContentContainer">
                  <h1>
                    4. Que linhas de crédito posso acessar para viabilizar a
                    aplicação prática das soluções selecionadas?
                  </h1>
                  <div className="text">
                    <p className="card-metodo-text py-2">
                      Após selecionar as soluções que desejam implantar em seu
                      estado/município, os usuários deverão &nbsp;
                      <b>
                        detalhar cada solução selecionada (componentes,
                        entregáveis, custo estimado)
                      </b>
                      &nbsp; e consolidar sua pré-proposta de financiamento
                      (detalhamento dos itens de governança e gestão), a qual
                      poderá ser apresentada pelo ente ao BID/BNDES para
                      abertura da negociação formal.
                    </p>
                  </div>
                  <div className="linkContainer">
                    <Link
                      to="/metodologia/formulario-de-detalhamento/"
                      className="link"
                    >
                      Saiba mais sobre o Formulário de Detalhamento de
                      Pré-Proposta
                    </Link>
                    <div className="button">
                      <FaUnlock size={20} />
                      Etapa de acesso restrito a estados e municípios
                      habilitados
                    </div>
                  </div>
                </div>
              </MethodologyCard>
            </div>
          </div>
        </MainContent>
      </Container>
    </ExternalLayout>
  );
}

export default Methodology;

import styled from 'styled-components';

export const Container = styled.div`
  .linkButton {
    background: var(--dark-blue2);
    color: #ffffff;
    padding: 15px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 10px;
    transition: background 0.3s ease;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background: #07415a;
    }
  }
`;

export const TitleButton = styled.div`
  background: #075e89 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  min-height: 66px;

  color: var(--white);
  text-align: left;
  font: normal normal bold 23px/30px IBM Plex Sans;
  margin-top: 18px;
  margin-bottom: 31px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin-top: 31px;
  background: transparent;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 21px;
  color: var(--blue);
  margin-bottom: 25px;

  & > div {
    margin-left: 29px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 22px;
      margin-right: 3px;
    }
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;

  header {
    padding: 10px 16px;
    color: var(--white);
    height: 52px;
    font: normal normal bold 19px/24px IBM Plex Sans;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .content {
    padding: 15px;
    display: flex;

    .info {
      width: 55%;
      padding-right: 20px;

      .container-text {
        margin-bottom: 30px;
        font-size: 1.1rem;

        p,
        span {
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .cards {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .content {
        border: 1px solid #f1f1f1;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        padding: 15px 25px;
      }

      button {
        justify-content: center;
        margin-top: 10px;
      }
    }

    .buttonContainer {
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    .content {
      flex-direction: column-reverse;
      padding: 0;

      .cards,
      .info {
        padding: 0;
        width: 100%;
      }

      .info {
        margin-top: 25px;
      }
    }
  }
`;

export const CardTitle = styled.p`
  text-align: left;
  font: normal normal bold 19px/24px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    cursor: pointer;
    margin-right: 5px;
    path {
      fill: var(--blue);
    }
  }
`;

export const MetroSearch = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: var(--blue);
  border-radius: 5px;
  margin-left: 6px;
  cursor: pointer;

  svg {
    font-size: 12px;
    margin: inherit;
    path {
      fill: var(--white);
    }
  }
`;

export const BlueContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0c9fd8;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px;

  span {
    display: flex;
    align-items: center;

    font: normal normal 18px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;

    svg {
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid var(--gray);
  margin: 20px 0;
  height: 1px;
`;

export const TableContainer = styled.div`
  overflow: auto;
  & > span {
    margin-bottom: 20px;
  }
`;

export const ProjectsContainer = styled.div`
  & > span {
    margin-bottom: 20px;
  }
`;

export const AddButton = styled.button`
  color: var(--text);
  background: transparent;
  border-bottom: 1px solid var(--text);
  margin-top: 10px;

  display: ${props => (props.show ? 'block' : 'none')};
`;

export const HeaderCard = styled.header`
  background: ${props =>
    props.background ? `var(--${props.background})` : 'var(--red)'};
`;

export const ToggleContainer = styled.label`
  display: flex;
  align-items: center;

  .react-toggle:not(.react-toggle--checked) .react-toggle-track,
  .react-toggle:hover:not(.react-toggle--checked) .react-toggle-track {
    background-color: var(--red);
  }

  small {
    color: ${props =>
      props.status === 'structuring' ? 'var(--red)' : '#D0D0D0'};

    font: normal normal normal 18px/30px IBM Plex Sans;
    letter-spacing: 0px;
    margin-right: 5px;
  }

  span {
    color: ${props =>
      props.status === 'execution' ? 'var(--green)' : '#D0D0D0'};

    font: normal normal normal 18px/30px IBM Plex Sans;
    letter-spacing: 0px;
    margin-left: 5px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    text-align: left;
    font: normal normal bold 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    word-break: break-all;
  }

  small {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    text-align: left;
    font: normal normal normal 18px/18px IBM Plex Sans;
    letter-spacing: 0.5px;
    color: #6d6e71;
    margin-top: 10px;
    margin-bottom: 25px;
  }
`;

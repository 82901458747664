/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../../../services/api';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import Radio from '../../../../components/Radio';
import StrategicIndicatorGraphic from '../../../../components/StrategicIndicatorGraphic';
import Donut from '../../../../components/Donut';
import Thief from '../../../../assets/thief.png';
import SisterBrother from '../../../../assets/SisterBrother.png';
import Violence from '../../../../assets/violence.png';
import Jail from '../../../../assets/jail.png';
import {
  Container,
  Card,
  LegendContainer,
  TabButton,
  StrategicIndicatorsContainer,
} from './styles';
import { Row, Column } from '../../../../styles/components';

const StrategicIndicator = () => {
  const Form1 = useRef(null);
  const [tabActive, setTabActive] = useState('1');
  const [radioOption, setRadioOption] = useState('states');
  const [loading, setLoading] = useState(true);
  const [avgCountry, setAvgCountry] = useState(0);
  const [avgStates, setAvgStates] = useState(0);
  const [avgCities, setAvgCities] = useState(0);
  const [avgCapitals, setAvgCapitals] = useState(0);
  const [grafData, setGrafData] = useState([]);
  const [name, setName] = useState('');
  const [strategicIndicators, setStrategicIndicators] = useState([]);

  const RadioOptions = [
    { id: 'states', label: 'Ranking dos Estados' },
    { id: 'counties', label: 'Ranking dos Municípios' },
    { id: 'capitals', label: 'Ranking das Capitais' },
  ];

  const getStrategicIndicators = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/v1/strategic-indicators-report/');
      setStrategicIndicators(response.data);
      setLoading(false);
      setAvgCountry(response.data.cvli.avg_country);
      setAvgStates(response.data.cvli.avg_states);
      setAvgCities(response.data.cvli.avg_cities);
      setAvgCapitals(response.data.cvli.avg_capitals);
      setGrafData(response.data.cvli.states);
      setName(response.data.cvli.complete_name);
      Form1.current?.setFieldValue('option', 'states');
    } catch (err) {
      console.error(err);
    }
  }, []);

  const selectRadioOption = useCallback(
    ev => {
      const value = ev.target.value;

      if (tabActive === '1') {
        if (value === 'states') {
          setGrafData(strategicIndicators.cvli.states);
        } else if (value === 'counties') {
          setGrafData(strategicIndicators.cvli.cities);
        } else {
          setGrafData(strategicIndicators.cvli.capitals);
        }
      } else if (tabActive === '2') {
        if (value === 'states') {
          setGrafData(strategicIndicators.patrimonio.states);
        } else if (value === 'counties') {
          setGrafData(strategicIndicators.patrimonio.cities);
        } else {
          setGrafData(strategicIndicators.patrimonio.capitals);
        }
      } else if (tabActive === '3') {
        if (value === 'states') {
          setGrafData(strategicIndicators.viol_crianca.states);
        } else if (value === 'counties') {
          setGrafData(strategicIndicators.viol_crianca.cities);
        } else {
          setGrafData(strategicIndicators.viol_crianca.capitals);
        }
      } else if (tabActive === '4') {
        if (value === 'states') {
          setGrafData(strategicIndicators.jovens_infratores.states);
        } else if (value === 'counties') {
          setGrafData(strategicIndicators.jovens_infratores.cities);
        } else {
          setGrafData(strategicIndicators.jovens_infratores.capitals);
        }
      }
      setRadioOption(value);
    },
    [strategicIndicators, tabActive],
  );

  const setTab = useCallback((value, data) => {
    const tabValue = Form1.current?.getFieldValue('option');

    setTabActive(value);
    setAvgCountry(data.avg_country);
    setAvgStates(data.avg_states);
    setAvgCities(data.avg_cities);
    setAvgCapitals(data.avg_capitals);
    setName(data.complete_name);
    if (tabValue === 'states') {
      setGrafData(data.states);
      Form1.current.setFieldValue('option', 'states');
    } else if (tabValue === 'counties') {
      setGrafData(data.cities);
      Form1.current.setFieldValue('option', 'counties');
    } else {
      setGrafData(data.capitals);
      Form1.current.setFieldValue('option', 'capitals');
    }
  }, []);

  useEffect(() => {
    getStrategicIndicators();
  }, []);

  return (
    <Container tab={tabActive}>
      <Card shadow>
        <h1>Painel de Indicadores Estratégicos</h1>
        <p>
          Nesta seção você tem acesso aos indicadores estratégicos do PROSEG
          Federativo, relacionados aos objetivos e ao propósito do programa.
        </p>
        <h2>Acesse os painéis de indicadores clicando nos ícones abaixo</h2>
        <Row className="TabsContainer">
          <Column small="12" medium="3" large="3">
            <TabButton
              color="#3aa9eb"
              onClick={() => setTab('1', strategicIndicators.cvli)}
            >
              <img
                src={Violence}
                alt="Crimes Violentos Letais e Intencionais (CVLI)"
              />
              <span>Crimes Violentos Letais e Intencionais (CVLI)</span>
            </TabButton>
          </Column>
          <Column small="12" medium="3" large="3">
            <TabButton
              color="#287baa"
              onClick={() => setTab('2', strategicIndicators.patrimonio)}
            >
              <img src={Thief} alt="Taxa de Crime Patrimonial" />
              <span>Taxa de Crime Patrimonial</span>
            </TabButton>
          </Column>
          <Column small="12" medium="3" large="3">
            <TabButton
              color="#144869"
              onClick={() => setTab('3', strategicIndicators.viol_crianca)}
            >
              <img
                src={SisterBrother}
                alt="Taxa de Violência Doméstica contra Crianças"
              />
              <span>Taxa de Violência Doméstica contra Crianças</span>
            </TabButton>
          </Column>
          <Column small="12" medium="3" large="3">
            <TabButton
              color="#0e385a"
              onClick={() => setTab('4', strategicIndicators.jovens_infratores)}
            >
              <img src={Jail} alt="Taxa de reincidência de jovens infratores" />
              <span>Taxa de reincidência de jovens infratores</span>
            </TabButton>
          </Column>
        </Row>
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Row>
              <Column small="12" medium="12" large="12">
                <StrategicIndicatorsContainer>
                  <h1>{name}</h1>
                  <Row>
                    <Column
                      className="DonutColumn"
                      small="12"
                      medium="3"
                      large="3"
                    >
                      <Donut value={avgCountry} color="#5EB245" />
                      <span>Média Brasil</span>
                    </Column>
                    <Column
                      className="DonutColumn"
                      small="12"
                      medium="3"
                      large="3"
                    >
                      <Donut value={avgStates} color="#0C9FD8" />
                      <span>Média Estados</span>
                    </Column>
                    <Column
                      className="DonutColumn"
                      small="12"
                      medium="3"
                      large="3"
                    >
                      <Donut value={avgCapitals} color="#AC5200" />
                      <span>Média Capitais</span>
                    </Column>
                    <Column
                      className="DonutColumn"
                      small="12"
                      medium="3"
                      large="3"
                    >
                      <Donut value={avgCities} color="#520A53" />
                      <span>Média Municípios Elegíveis</span>
                    </Column>
                  </Row>
                </StrategicIndicatorsContainer>
              </Column>
              <Column small="12" medium="12" large="12">
                <Form ref={Form1} onChange={selectRadioOption}>
                  <Row
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Column small="12" medium="6" large="6">
                      <Radio
                        name="option"
                        value={radioOption}
                        options={RadioOptions}
                      />
                    </Column>
                  </Row>
                </Form>
              </Column>
              <Column small="12" medium="12" large="12">
                {grafData.length > 0 ? (
                  <StrategicIndicatorGraphic data={grafData} />
                ) : (
                  <span className="empty">Nenhum Dado</span>
                )}
              </Column>
            </Row>
          </>
        )}
        <h2 style={{ marginTop: '20px' }}>Legenda:</h2>
        <LegendContainer>
          <div className="legend-item">
            <div className="green" />
            Brasil
          </div>
          <div className="legend-item">
            <div className="blue" />
            Estados participantes do PROSEG Federativo
          </div>
          <div className="legend-item">
            <div className="gray" />
            Demais Estados
          </div>
        </LegendContainer>
      </Card>
    </Container>
  );
};

export default StrategicIndicator;

import React from 'react';
import { FiX } from 'react-icons/fi';

import { Container } from './styles';

const ErrorModal = ({ handleClose, show, Message }) => (
  <Container show={show}>
    <section className="modal-main">
      <FiX color="#ffffff" size={86} />
      <p>{Message}</p>
      <button type="button" className="close-container" onClick={handleClose}>
        Fechar
        <FiX size={25} onClick={handleClose} />
      </button>
    </section>
  </Container>
);

export default ErrorModal;

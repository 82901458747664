import React from 'react';

import { Container, Bar } from './styles';

function RakingBar({ percentage, color, title, value }) {
  return (
    <>
      <Container>
        <small>{title}</small>
        <div>
          <Bar color={color} style={{ width: `${percentage}%` }} />
        </div>
        <span>{!!value ? value : 'N/A'}</span>
      </Container>
    </>
  );
}

export default RakingBar;

import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 455px;
  background: ${props =>
    `${
      props.backgroundColor ? `var(--${props.backgroundColor})` : 'transparent'
    }`};

  background-size: cover;
  overflow: ${props => props.overflow && 'hidden'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.banner-container {
    height: 10vh;
    @media only screen and (max-width: 1260px) {
      background: #442461;
      background-size: cover;
      height: 10vh;
    }
    @media only screen and (max-width: 1002px) {
      height: 10vh;
    }
    @media only screen and (max-width: 768px) {
      height: 0;
    }
  }
  &.companies-container {
    height: 330px;
    @media only screen and (max-width: 768px) {
      height: 270px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1140px;
  display: flex;

  align-items: center;
  margin-top: 142px;
  height: 313px;

  .text {
    max-width: 685px;
    text-align: left;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 10px;
    background: transparent;
  }

  @media only screen and (max-width: 1141px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 505px) {
    margin-top: 95px;
  }

  @media only screen and (max-width: 768px) {
    .text {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 340px) {
    .text {
      font-size: 24px;
    }
  }
`;

export const Sliders = styled.div`
  width: 100%;

  @media only screen and (max-width: 2600px) {
    margin-top: -85px;
  }
  @media only screen and (max-width: 2560px) {
    margin-top: -80px;
  }
  @media only screen and (max-width: 2540px) {
    margin-top: -78px;
  }
  @media only screen and (max-width: 2500px) {
    margin-top: -75px;
  }
  @media only screen and (max-width: 2400px) {
    margin-top: -65px;
  }
  @media only screen and (max-width: 2300px) {
    margin-top: -60px;
  }
  @media only screen and (max-width: 2200px) {
    margin-top: -55px;
  }
  @media only screen and (max-width: 2100px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 2000px) {
    margin-top: -45px;
  }
  @media only screen and (max-width: 1900px) {
    margin-top: -40px;
  }
  @media only screen and (max-width: 1800px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 1700px) {
    margin-top: -30px;
  }
  @media only screen and (max-width: 1650px) {
    margin-top: -25px;
  }
  @media only screen and (max-width: 1600px) {
    margin-top: -19px;
  }
  @media only screen and (max-width: 1500px) {
    margin-top: -20px;
  }
  @media only screen and (max-width: 1400px) {
    margin-top: -18px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -65px;
  }

  .slick-dots {
    transform: translateY(-150%);

    li {
      button {
        &:before {
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }
`;

export const DotsContainer = styled.div`
  transform: translateY(-250%);
  width: 100%;
  max-width: 1140px;
  display: flex;
  align-items: center;
  z-index: 2;

  @media only screen and (max-width: 1141px) {
    padding: 0 25px;
  }

  svg {
    cursor: pointer;
  }

  .dot {
    margin-right: 15px;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px !important;
    height: 15px !important;
    text-indent: inherit;
    cursor: pointer;
    background-clip: inherit;
    border-top: inherit;
    border-bottom: inherit;
    background: transparent;
    border: 1.9px solid #ffffff;
    transition: opacity 0.6s ease;
    border-radius: 50%;
    margin: 0 7px;

    &.active {
      background: #fff;
    }
  }
`;

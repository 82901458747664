import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  a {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: #0c9fd8;
    opacity: 1;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #0f5e7d;
    }
  }

  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props => (props.tab === 'states' ? '25%' : '75%')};
    }
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;
  animation: ${EaseIn} 0.3s;

  h1 {
    margin: 10px 0;
    color: black;
    font-size: 24px;
  }

  h2 {
    text-align: left;
    font: normal normal bold 25px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
    margin-bottom: 25px;
  }
`;

export const Link = styled.a`
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  text-decoration: underline;
  font: normal normal bold 17px/22px IBM Plex Sans;
  letter-spacing: 0px;
  color: #0c9fd8;
  opacity: 1;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #0f5e7d;
  }
`;

export const StateCard = styled.div`
  display: flex;
  height: 130px;
  border: ${props => props.color && `1px solid ${props.color}`};
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;

  .imagecontainer {
    width: 30%;
    background: ${props => props.color && `${props.color}`};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    img {
      width: 100%;
    }

    svg {
      color: #ffffff;
      width: 90px;
      path {
        fill: var(--white);
      }
      rect {
        fill: var(--white);
      }
    }
  }

  .textcontainer {
    width: 70%;
    padding: 15px;

    h1 {
      font: normal normal bold 18px/23px IBM Plex Sans;
      letter-spacing: 0px;
      color: ${props => props.color && `${props.color}`};
      opacity: 1;
    }

    button {
      font: normal normal normal 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: ${props => props.color && `${props.color}`};
      opacity: 1;
      background: transparent;
      border-bottom: ${props => props.color && `1px solid${props.color}`};
    }
  }
`;

export const TableContainer = styled.div`
  animation: ${EaseIn} 0.3s;
  display: grid;
  h1 {
    text-align: center;
    font: normal normal bold 50px/65px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
    margin-bottom: 30px;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #10385c;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 44px;

    & > div {
      white-space: inherit;
    }
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  .legend-item {
    display: flex;
    align-items: center;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-right: 15px;

    .blue {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: #def6ff 0% 0% no-repeat padding-box;
      border: 1px solid #d0d0d0;
      opacity: 1;
    }

    .white {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #d0d0d0;
      opacity: 1;
    }
  }
`;

import React from 'react';

import { Container } from './styles';

function Bar({ percentage, color }) {
  return (
    <>
      <Container percentage={percentage} color={color}>
        <div>
          <div>
            <span>{percentage}</span>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Bar;

import axios from 'axios';
import Cookie from 'js-cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});

api.defaults.headers.common['X-CSRFToken'] = Cookie.get('csrftoken');

export default api;

import React from 'react';

import { Container } from './styles';

function TabContent({ title, subtitle, options, selectOption }) {
  return (
    <Container>
      <div className="Tabtitle">{title}</div>
      <div className="subtitle">{subtitle}</div>
      {options.map(option => (
        <div className="option">
          <button
            className="linkButton"
            type="button"
            onClick={() => selectOption(option)}
          >
            {option.nome}
          </button>
        </div>
      ))}
    </Container>
  );
}

export default TabContent;

import React from 'react';
import Lottie from 'react-lottie';
import PrintLoaderAnimation from '../../assets/loader.json';
import { Container } from './styles';

const PrintLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PrintLoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="printLoader">
      <Lottie
        height={150}
        width={150}
        style={{
          zIndex: 10,
          transform: 'translateY(160%)',
        }}
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  );
};

export default PrintLoader;

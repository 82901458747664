import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;

    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;

    .checkmark {
      min-height: 25px;
      min-width: 25px;
      border-radius: 50%;
      background: #ffffff;
      border: 3px solid #eee;

      &:after {
        content: '';
        position: absolute;
        display: none;

        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }

    &:hover {
      input ~ .checkmark {
        background-color: #ccc;
      }
    }

    input {
      display: flex;
      opacity: 0;
      position: absolute;
      left: 21px;
      cursor: pointer;
    }

    span {
      font-size: 16px;
      color: #737373;
      margin-left: 15px;
    }

    svg {
      font-size: 30px;
      color: #737373;
    }

    input:checked ~ span {
      color: #287bac;
    }

    input:checked ~ svg {
      color: #bfbfbf;
    }

    input:checked ~ .checkmark {
      background: #287bac;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    input:checked {
      color: #cb500b !important;
    }
  }
`;

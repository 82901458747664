import React, { useEffect } from 'react';

import { Container } from './styles';

function StepDonut({
  value,
  size,
  strokewidth,
  color,
  fontSize,
  large,
  type,
  month,
  text,
}) {
  let val = 0;

  type === 'percent' ? (val = value) : (val = value * 10);

  const halfsize = size * 0.5;
  const radius = halfsize - strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (val * circumference) / 100;
  const dashval = strokeval + ' ' + circumference;

  const trackstyle = { strokeWidth: strokewidth };
  const indicatorstyle = {
    strokeWidth: strokewidth,
    strokeDasharray: dashval,
  };
  const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

  useEffect(() => {}, []);

  return (
    <Container color={color} fontSize={fontSize}>
      <svg width={size} height={size} className="donutchart">
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className="donutchart-track"
        />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className="donutchart-indicator"
        />
        <text
          className="donutchart-text"
          x={halfsize}
          y={large ? halfsize + 12 : halfsize + 6}
          style={{ textAnchor: 'middle' }}
        >
          <tspan dy={month && '-10'} className="donutchart-text-val">
            {text}
            <br />
          </tspan>
          <tspan x="75  " dy="20" className="donutchart-text-val">
            {month && 'meses'}
          </tspan>
        </text>
      </svg>
    </Container>
  );
}

export default StepDonut;

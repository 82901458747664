import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #075e89;
  border-radius: 5px;
  height: 180px;
  max-height: 180px;
  padding: 8px 13px;

  .textContainer {
    display: flex;
    width: ${props => (props.showButton ? 'inherit' : '100%')};
    height: 70%;
    justify-content: space-between;
    border-bottom: 1px solid rgb(234, 234, 234);

    .text {
      width: ${props => (props.showButton ? '85%' : '100%')};

      text-align: left;
      font: normal normal bold 15px/19px IBM Plex Sans;
      letter-spacing: 0px;
      color: #075e89;
      word-break: break-word;

      p {
        margin-top: 5px;
        text-align: left;
        font: normal normal normal 13px/14px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
      }
    }

    button {
      margin-left: 3px;
      width: 38px;
      height: 38px;
      background: var(--unnamed-color-04547c) 0% 0% no-repeat padding-box;
      background: #04547c 0% 0% no-repeat padding-box;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.3s ease;

      svg {
        width: 25px;
        height: 25px;
      }

      &:hover {
        background: #044565;
      }
    }
  }

  .bottomContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30%;

    .iconsContainer {
      width: 75%;
      display: flex;
      align-items: center;
    }

    .periodContainer {
      text-align: left;
      font: normal normal normal 10px/15px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }
  }
`;

export const Card = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 5px;

  .imgContainer {
    display: flex;
    align-items: center;

    img {
      border-radius: 8px;
      opacity: 1;
      height: 35px;
      width: 35px;
      padding: 5px;

      &.indicadores-green-border {
        border: 1px solid #5eb245;
        border-radius: 8px;
        opacity: 1;
      }
      &.indicadores-red-border {
        border: 1px solid #fd5454;
        border-radius: 8px;
        opacity: 1;
      }

      &.indicadores-empty-border {
        border: 1px solid #aab9bf;
        border-radius: 8px;
        opacity: 1;
      }

      &.indicadores-grey-border {
        border: 1px solid #d29500;
        border-radius: 8px;
        opacity: 1;
      }
    }
  }
`;



export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${props => `1px solid ${props.color}`};
  width: ${props => (props.legend ? `30px` : '35px')};
  height: ${props => (props.legend ? `30px` : '35px')};
  padding: ${props => props.legend && '5px'};
  margin-top: ${props => props.legend && '10px'};
  border-radius: 8px;
  opacity: 1;
  margin-right: 10px;
  margin-top: 5px;

  img {
    width: 25px;
  }

  svg {
    width: 25px;
    transform: ${props => (props.rotate ? `rotate(180deg)` : 'rotate(0deg)')};
    path {
      fill: ${props => props.color};
    }
  }
`;
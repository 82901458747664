import * as React from 'react'
import api from '../../services/api';
import { Pdf } from '@cherryit/components'
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom'

const SelfEvaluationReport = () => {
  const history = useHistory()
  const refPdf = React.useRef()
  const options = {
    orientation: "p",
    unit: "px",
    format: "A4",
  };
  const [ selfEvaluation, setSelfEvaluation ] = React.useState([])
  const start = React.useCallback(async () => {
    const response = await api.get(`/platform/api/v1/self-evaluation/`);
    setSelfEvaluation(response.data)
  }, [])
  React.useEffect(() => {
    start()
  }, [start])

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ margin: '15px', cursor: 'pointer' }}>
        <FaArrowLeft onClick={() => window.open('/platform/autoavaliacao/')} />
      </div>
      <div ref={refPdf}>
        {selfEvaluation.map(({key, display, value}) => {
          console.log({key, value})
          return (
            <div key={key} style={{ margin: '5px', padding: '5px' }}>
              <div style={{ margin: '5px', fontWeight: 'bold' }}>
                {display}
              </div>
              <div style={{ margin: '5px', padding: '5px' }}>
                <table>
                  <tr>
                    <th style={{ padding: 5 }}>
                      Código
                    </th>
                    <th style={{ padding: 5 }}>
                      Pergunta
                    </th>
                  </tr>
                  {value.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ padding: 5 }}>
                          {item.code}
                        </td>
                        <td style={{ padding: 5 }}>
                          {item.statement}
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div>
          )
        })} 
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '100%', marginTop: '20px' }}>
        <Pdf
          targetRef={refPdf}
          filename="questionario.pdf"
          options={options}
          x={0}
          y={0}
          scale={1.0}
          onComplete={({ pdf, filename }) => {
            pdf.save(filename);
          }}
        >
          {(prop) => {
            return (
              <button style={{ padding: '5px', borderRadius: '8px' }} onClick={prop.toPdf}>
                Baixar PDF
              </button>
            );
          }}
        </Pdf>
      </div>
    </div>
  )
}

export { SelfEvaluationReport }
import React from 'react';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';
import { TabButton, CategoryColumn } from './styles';

function Categories({ categories, setTab }) {
  return (
    <>
      {categories.map((category, index) => (
        <CategoryColumn large="4" medium="4" small="4" key={`cat-${index}`}>
          <TabButton
            color={
              category.name === 'Criminalidade e Violência'
                ? '#3aa9ee'
                : category.name === 'Fatores de Risco'
                ? '#287bac'
                : category.name === 'Desempenho Institucional'
                ? '#14486b'
                : '#3aa9ee'
            }
            onClick={() => setTab(category.name, 2, category.indicators)}
          >
            <div className="contentContainer">
              <div className="text">
                <span>{category.name}</span>
              </div>
              <div className="iconcontainer">
                {category.name === 'Criminalidade e Violência' && <Algemas />}
                {category.name === 'Fatores de Risco' && <Hands />}
                {category.name === 'Desempenho Institucional' && <Government />}
              </div>
            </div>
            <div className="indicatorLink">
              <h2>Ver Painel de Indicadores</h2>
            </div>
          </TabButton>
        </CategoryColumn>
      ))}
    </>
  );
}

export default Categories;

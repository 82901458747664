import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../assets/loader.json';

const ModalLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      height={150}
      width={150}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default ModalLoader;

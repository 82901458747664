import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  transition: all 0.3s ease;

  width: 100%;

  button {
    border-radius: 5px !important;
    max-width: 290px;
    width: 317px;
    margin-bottom: 15px;
    border: none;
    background: #004e70 0% 0% no-repeat padding-box;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    align-self: flex-end;
    padding: 12px 10px;

    svg {
      margin-right: 5px;
    }
  }

  .breadcrumb {
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    width: 100%;

    display: flex;
  }

  .breadcrumb div li {
    border-radius: 5px;
  }

  .li-container {
    display: flex;
    flex-direction: column;

    span {
      font: normal normal normal 14px/18px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
    }
  }

  .breadcrumb div li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 11px 15px 12px 31px;
    background: #004e70;
    position: relative;
    display: block;
    float: left;
  }

  .breadcrumb div li a:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-left: 16px solid #004e70;
    position: absolute;
    top: 54%;
    margin-top: -22px;
    left: 100%;
    z-index: 2;
  }

  .breadcrumb div li a:before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-left: 16px solid white;
    position: absolute;
    top: 54%;
    margin-top: -22px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  .breadcrumb div li a {
    font-weight: 400;
  }

  .breadcrumb div li.active a {
    
  }

  .breadcrumb div li:first-child a {
    padding-left: 10px;
  }

  .breadcrumb div li:nth-child(2) a {
    background: #e2e2e2 !important;
    color: #6e6f72 !important;
  }

  .breadcrumb div li:nth-child(2) a:after {
    border-left-color: #e2e2e2;
  }

  .breadcrumb div li:nth-child(3) a {
    background: #e2e2e2;
  }

  .breadcrumb div li:nth-child(3) a:after {
    border-left-color: #e2e2e2;
  }

  .breadcrumb div li:last-child a {
    color: #ffffff;
    background: #e2e2e2;
  }

  .breadcrumb divli:last-child a:after {
    border: 0;
    background: #004e70;
  }

  .breadcrumb div li.active:nth-child(2) a {
    background: #004e70 !important;
  }

  .breadcrumb div li.active:nth-child(2) a:after {
    border-left-color: #004e70 !important;
  }

  .breadcrumb div li.active:nth-child(3) a {
    background: #004e70 !important;
  }

  .breadcrumb div li.active:nth-child(3) a:after {
    border-left-color: #004e70 !important;
  }

  .breadcrumb div li.active:last-child a {
    color: #ffffff !important;
    background: #004e70 !important;
  }

  .breadcrumb div:nth-child(3) li.active a:after {
    border: 0;
    background: #004e70 !important;
  }
  .breadcrumb div:nth-child(3) li a:after {
    border: 0;
  }

  @media only screen and (max-width: 1135px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  align-items: center;
  margin-top: 25px;

  .donutContainer {
    width: 116px;
  }
  .contentContainer {
    margin-left: 15px;
    h1 {
      color: #075e89;
    }
    small {
      font-size: 13px;
    }
    button {
      background: #00a9ee;
      color: #fff;
      font-weight: bold;
      padding: 10px;
      border-radius: 7px;
      transition: background 0.3s ease;
      margin-right: 5px;
      margin-top: 10px;

      &:hover {
        background: #02a0e0;
      }
    }
  }

  @media only screen and (max-width: 1135px) {
    display: flex;
  }

  @media only screen and (max-width: 558px) {
    .contentContainer {
      h1 {
        font-size: 20px;
      }
    }
  }
`;

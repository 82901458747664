import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import Cookie from 'js-cookie';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [showLoginModal, setLoginModal] = useState(false);
  const [showForgotModal, setForgotModal] = useState(false);
  const [showResetModal, setResetModal] = useState(false);
  const [isLogged, setIsLogged] = useState(() => {
    return !!Cookie.get('sessionid');
  });

  const getUser = async () => {
    const response = await api.get('/accounts/my-profile/');
    if (response.data.institutional_mail === '') signOut();
    else setUser(response.data);
  };

  useEffect(() => {
    const currentPath = window.location.href.replace(/http(s)?:\/\//g, '').split('/', 2).at(1);
    // if (currentPath && currentPath != '') getUser();
  }, [window.location.href]);

  useEffect(() => {
    if (isLogged) getUser();
  }, [isLogged]);

  const signOut = useCallback(() => {
    Cookie.remove('sessionid');
    setIsLogged(false);
    window.location.href = '/';
  }, []);

  const changeLoginModal = useCallback(data => {
    setLoginModal(data);
  }, []);

  const changeForgotModal = useCallback(data => {
    setForgotModal(data);
  }, []);

  const changeResetModal = useCallback(data => {
    setResetModal(data);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        showLoginModal,
        changeLoginModal,
        showResetModal,
        changeResetModal,
        showForgotModal,
        changeForgotModal,
        isLogged,
        signOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth só pode ser usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };

import React from 'react';
import DashBoardLayout from '../../components/DashboardLayout';
import { Container, BannerContainer, TextContainer, ImageCard } from './styles';
import { Column, Row } from '../../styles/components';
import Banner from '../../assets/bidacademy@2x.png';
import ClassCentral from '../../assets/classcentral.png';
import EDX from '../../assets/edx.png';

const TeachingPlatform = () => {
  return (
    <Container>
      <DashBoardLayout title="Plataforma de ensino">
        <Row>
          <Column large="12" medium="12" small="12">
            <BannerContainer>
              <img src={Banner} alt="Bid Academy" />
            </BannerContainer>
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <TextContainer>
              <p>
                <b>O Banco Interamericano de Desenvolvimento (BID)</b> oferece,
                por meio de diferentes plataformas,{' '}
                <b>cursos em modalidade à distância (MOOC).</b> O objetivo
                desses cursos é promover o acesso à formação contínua de
                qualidade e melhorar as capacidades por meio da formação,{' '}
                <b>mesclando conteúdos teóricos/metodológicos</b> com
                <b>exemplos práticos,</b> baseados na experiência do próprio
                BID, bem como de seus parceiros estratégicos. Abaixo você
                encontra os links de acesso às plataformas que estão sendo
                utilizadas pelo BID neste momento para disponibilização de
                cursos relevantes à gestão de operações de crédito e de seus
                projetos relacionados.
              </p>
              <h3>
                Clique nos ícones abaixo para conferir os cursos que estão sendo
                ofertados nesse momento:
              </h3>
            </TextContainer>
          </Column>
        </Row>
        <Row>
          <Column large="6" medium="6" small="12">
            <ImageCard>
              <a
                href="https://cursos.iadb.org/pt-br"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ClassCentral} alt="ClassCentral" />
              </a>
            </ImageCard>
          </Column>
          <Column large="6" medium="6" small="12">
            <ImageCard>
              <a
                href="https://www.edx.org/es/school/idbx"
                target="_blank"
                rel="noreferrer"
              >
                <img src={EDX} alt="EDX" />
              </a>
            </ImageCard>
          </Column>
        </Row>
      </DashBoardLayout>
    </Container>
  );
};

export default TeachingPlatform;

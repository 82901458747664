import React from 'react';
import { Container, Button } from './styles';

const MailTo = ({ mailto, children }) => {
  return (
    <Container>
      <Button
        to="#"
        target="_blank"
        onClick={e => {
          window.location = mailto;
          e.preventDefault();
        }}
      >
        {children}
      </Button>
    </Container>
  );
};

export default MailTo;

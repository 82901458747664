import React from 'react';
import { Container } from './styles';
import Table from '../Table';
import { defaults } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import { FaExclamationCircle } from 'react-icons/fa';
import sanitizeHtml from 'sanitize-html';
import LineChart from '../../../../../components/LineChart';
import BarChart from '../../../../../components/BarChart';

defaults.plugins.legend.position = 'bottom';

const Graphic = ({ data, title, subtitle, description, type, calculation_formula, content }) => {
  console.log(data, 'data');

  return (
    <>
      <Container>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '25px' }}>
          <h1>{title}</h1>
          <FaExclamationCircle
            style={{ marginLeft: 5 }}
            data-tip={sanitizeHtml(`
              <div>
                <div> Definição: ${description} </div>
                <div> Fórmula de Cálculo: ${calculation_formula} </div>
              </div>
            `)}
            data-html={true}
          />
        </div>
        <small>{subtitle}</small>
        {content && (
          <p
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
        
        {/* {type === 'line' && <Line data={data.data} options={data.data.options} />} */}
        {type === 'line' && <LineChart data={data.data} options={data.data.options} />}
        {/* {(type === 'stacked_bar' || type === 'bar') && (
          <Bar data={data.data} options={data.data.options} />
        )} */}
        {/* {(type === 'stacked_bar' || type === 'bar') && (
          <StackedBarChart data={data.data} options={data.data.options} />
        )} */}
        {(type === 'stacked_bar' || type === 'bar') && (
          <BarChart data={data.data} options={data.options} />
        )}
        {(type === 'table' || type === 'binary_table') && <Table data={data} />}
      </Container>
      <ReactTooltip html={true} place="right" />
    </>
  );
}

export default Graphic;

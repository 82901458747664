import React, { useEffect, useCallback, useState } from 'react';
import api from '../../../../../services/api';
import { Row, Column } from '../../../../../styles/components';
import Categories from '../Categories';
import IndicatorCard from '../IndicatorCard';
import DiagnosticLengend from '../DiagnosticLengend';
import {
  Container,
  Title,
  SubTitle,
  CardRow,
  CardColumn,
  CategoryRow,
} from './styles';

import icon_open_collapse_down from '../../../../../assets/icon_open_collapse_down.png';
import IndicatorAccordion from '../../../../../components/IndicatorAccordion';

function IndicatorsPanel({
  diagnostico,
  setBreadCrumbActive,
  setDetailIndicator,
  tab,
  setTab,
}) {
  const [indicators, setIndicators] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [loadingIndicators, setLoadingIndicators] = useState(true);


  const getSubCategory = async () => {
    try {
      
      const response = await api.get(
        `/v1/sub-category/`,
      );


      setSubCategory(response.data.results.map(subcat=>{
        return {id: subcat.id, name: subcat.name, open: false, indicators: indicators.filter(i => i.indicator.sub_category === subcat.id)}
      }));
      
    } catch (err) {
      
    }
  };

  const openAll = (e) => {
    e.preventDefault();
    setSubCategory(subCategory.map(subcat => {
      return {...subcat, open: !subcat.open};
    }));
  
  }


  const setSubOpenAccordion = (sub) => {
    setSubCategory(subCategory.map(subcat => {
      return sub.id === subcat.id ? {...subcat, open: !subcat.open} : subcat
    }));
  }

  const setSubIndicators = (sub) => {
    setSubCategory(subCategory.map(subcat => {
      return sub.id === subcat.id ? {...subcat, open: !subcat.open} : subcat
    }));
  }

  const getIndicators = useCallback(async url => {
    try {
      const response = await api.get(url)
      
      console.log({ url })
      console.log({ response })
      setIndicators(response.data.indicators);
      
      setLoadingIndicators(false);
    } catch (err) {
      setLoadingIndicators(false);
    }
  }, []);

  const setBreadCrumb = useCallback(
    (tabName, breadCrumbNumber, url) => {
      setTab(tabName);
      setBreadCrumbActive(breadCrumbNumber);
      if (breadCrumbNumber === 2)
        document.getElementById('IndicatorsPanel').scrollIntoView();
      getIndicators(url);
    },
    [setBreadCrumbActive, setTab, getIndicators],
  );

  const initIndicators = useCallback(() => {
    const filteredCategory = diagnostico.categories.filter(
      category => category.name === tab,
    )[0];
    setBreadCrumb(tab, 2, filteredCategory.indicators);
    document.getElementById('IndicatorsPanel').scrollIntoView();
  }, [diagnostico.categories, tab, setBreadCrumb]);

  const initDetailIndicator = useCallback(
    indicator => {
      setDetailIndicator(indicator);
      setBreadCrumbActive(4);
      document.getElementById('mainContent').scrollIntoView();
    },
    [setDetailIndicator, setBreadCrumbActive],
  );

  useEffect(() => {
    initIndicators();
    // getSubCategory();
    document.getElementById('IndicatorsPanel').scrollIntoView();
  }, [initIndicators]);

  useEffect(() => {
    // console.log(indicators)
    getSubCategory()
  }, [indicators])

  return (
    <Container tab={tab}>
      <Title style={{ marginTop: '31px' }}>Painel de Indicadores</Title>
      <SubTitle style={{ marginBottom: '31px' }}>
        O dashboard abaixo apresenta o desempenho de seu estado/município em
        cada um dos indicadores selecionados, considerando a sua performance
        recente (série histórica) e alguns referenciais comparativos. Para
        visualizar as informações de cada indicador, clique em cima de cada
        painél
      </SubTitle>
      <Title>Acesse os painéis de indicadores clicando nos ícones abaixo</Title>
      <CategoryRow style={{ marginTop: '20px' }} className="TabsContainer">
        <Categories
          categories={diagnostico.categories}
          setTab={setBreadCrumb}
        />
        {/* <Column large="12" medium="12" small="12" style={{ textAlign: 'center' }} > */}
          {/* <Link to="/metodologia/indicador-sintetico" className="redirectLink">
            Saiba mais sobre os indicadores selecionados
          </Link> */}
          {/* <Title style={{ marginTop: '31px', fontSize: '45px' }}>{tab}</Title> */}
        {/* </Column> */}
      </CategoryRow>
      {/* <Row id="IndicatorsPanel">
        {loadingIndicators ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Column large="12" medium="12" small="12">
                <IndicatorTitle>{tab}</IndicatorTitle>
              </Column>
            </Row>
            <CardRow>
              {indicators.map((indicator, index) => (
                <CardColumn large="3" medium="4" small="12" key={`ind-${index}`}>
                  <IndicatorCard
                    indicator={indicator}
                    showButton
                    initDetailIndicator={initDetailIndicator}
                  />
                </CardColumn>
              ))}
            </CardRow>
          </>
        )}
      </Row> */}
      <Row id="IndicatorsPanel" >
        <Column large="12" medium="12" small="12" style={{ textAlign: 'center', fontWeight: "300!important"  }} >
          {/* <Link to="/metodologia/indicador-sintetico" className="redirectLink">
            Saiba mais sobre os indicadores selecionados
          </Link> */}
          <Title style={{ marginTop: '40px', marginBottom: '30px', font: "Open Sans", }}>{tab}</Title>
        </Column>
        <Column large="12" medium="12" small="12" style={{marginBottom: 0, textAlign: 'right', color: '#004e70',}} >
          <a href="" onClick={(e) => {openAll(e)}} style={{cursor: 'pointer'}}><img src={icon_open_collapse_down} style={{marginRight: '5px', verticalAlign: 'middle'}} />Abrir todos</a>
        </Column>

        <br/>
        { subCategory.map(sub => {
          return <IndicatorAccordion
          key={`sba-${sub.id}`}
          title={`${sub.name}`}
          hasDelete={false}
          deleteCallBack={() => console.log(true)}
          active={sub.open}
          onOpen={() => setSubOpenAccordion(sub)}
          show={sub.indicators.length > 0}
        >
         

            <CardRow>
              {sub.indicators.map((indicator, index) => (
                <CardColumn large="4" medium="4" small="12" key={`indsub-${index}`}>
                  <IndicatorCard
                    indicator={indicator}
                    showButton
                    initDetailIndicator={initDetailIndicator}
                  />
                </CardColumn>
              ))}
            </CardRow>
          
        </IndicatorAccordion>
        
        })}

      </Row>
  
  <DiagnosticLengend diagnostico={diagnostico} />
</Container>
      
  );
}

export default IndicatorsPanel;

import React, { useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import ModalLoader from '../../components/ModalLoader';
import Modal from '../../components/Modal';
import { Container } from './styles';

const ConfirmationModal = ({
  show,
  handleClose,
  handleSuccess,
  handleEditSuccess,
  deliverableId,
  title,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await handleSuccess();
    setLoading(false);
    handleClose();
  }, []);

  return (
    <Container>
      <Modal
        title={title}
        success={success}
        handleClose={handleClose}
        disbleInfo
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <button className="button" type="button" onClick={handleSubmit}>
                Sim
              </button>
              <button className="no" type="button" onClick={handleClose}>
                Não
              </button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default ConfirmationModal;

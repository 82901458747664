import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .m-5 {
    margin-bottom: 5px !important;
  }

  .contentContainer {
    display: flex;

    .text {
      color: #6d6e71;
      font-size: 22px;
      text-align: justify;
      line-height: 1.5;
    }

    .image {
      margin-top: 40px;
      margin-left: 32px;
    }
  }

  .formContainer {
    margin-top: 30px;
    .selectContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .react-select__control {
        background: #a7a7a7;
        border: 0;
        border-radius: 5px;
      }

      .react-select__placeholder,
      .react-select__single-value {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
      }

      .react-select__menu {
        .react-select__group-heading {
          color: #164e70;
          font-weight: 700;
          background: #fff;
        }
      }

      .react-select__indicator {
        font-size: 24px;
        svg {
          path {
            fill: #fff;
          }
        }
      }

      .button {
        background: #004e70;
        border-radius: 5px;
        color: #fff;
        width: 100%;
        font-size: 20px;
        height: 44px;
        font-weight: 700;
        display: flex;
        align-items: center;
        padding: 0 17px;
      }
    }
  }

  @media only screen and (max-width: 975px) {
    .contentContainer {
      flex-direction: column;

      .image {
        margin-top: 0;
        margin-left: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 28px;
    }
    .formContainer {
      .selectContainer {
        .button {
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 336px) {
    .contentContainer {
      .image {
        margin-top: 0;
        margin-left: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 90%;
      }
    }

    .formContainer {
      .selectContainer {
        .button {
          font-size: 13px;
        }
      }
    }
  }
`;

import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import * as Yup from 'yup';
import { Container, Error, Label } from './styles';

const TextArea = ({
  name,
  label,
  rounded,
  schema,
  disabled,
  icon: Icon,
  children,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleChange = useCallback(async () => {
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        inputRef.current?.value,
        { abortEarly: true },
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <Container
        disabled={disabled}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isCorrect={isCorrect}
        rounded={rounded}
      >
        <textarea
          disabled={disabled}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleChange}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
        {children}
        {Icon && <Icon size={20} />}
      </Container>
      {!!error && <Error>{error}</Error>}
    </>
  );
};

export default TextArea;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 98%;

  small {
    margin-right: 20px;
    width: 415px;
    text-align: right;
    font: normal normal normal 13px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 10px;
    margin-right: 10%;
  }

  & > div {
    position: relative;
    flex: 1;
    height: 21px;
    border-radius: 4px;
    position: relative;
    transition: all 0.5s ease;

    .sidebar {
      position: absolute;
      width: 3px;
      height: calc(100% + 12px);
      background: #707070;
    }

    .position {
      position: absolute;
      left: 0;
      font: normal normal bold 14px/18px IBM Plex Sans;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
  }
`;

export const Title = styled.h1`
  font: normal normal normal 14px/18px IBM Plex Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 5px;
`;

export const Bar = styled.div`
  width: ${props => `calc(${props.percentage - 1}%)`};
  background: ${props => props.color};
  height: 21px;
  border-radius: 4px;
  transition: all 0.5s ease;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    margin: 0;
    width: 38px;
    display: flex;
    justify-content: center;

    transform: translateX(35px);
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 994px) {
    h1 {
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: 1;
  @media screen and (max-width: 994px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-right: 30px;
  .icon {
    color: dodgerblue;
  }
  .text {
    margin-left: 10px;
    width: 100%;
  }
  @media screen and (max-width: 994px) {
    width: 100%;

    .text {
      width: 100%;
    }
  }
`;

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import DashBoardLayout from '../../components/DashboardLayout';
import Loader from '../../components/Loader';
import Solution from '../../components/Solution';
import { ReactComponent as Balance } from '../../assets/balance-green.svg';
import { ReactComponent as Building } from '../../assets/building-orange.svg';
import { ReactComponent as Family } from '../../assets/family-yellow.svg';
import { ReactComponent as Idea } from '../../assets/idea-blue.svg';
import { ReactComponent as Police } from '../../assets/police-officer-red.svg';
import { ReactComponent as Reincercao } from '../../assets/reinsercao-purple.svg';
import { Container, SolutionTypeContainer, Card } from './styles';
import { Column, Row } from '../../styles/components';

const SolutionsList = () => {
  const { solutionId } = useParams();
  const [loader, setLoader] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [gestaoSolutions, setGestaoSolutions] = useState([]);
  const [policiamentoSolutions, setPoliciamentoSolutions] = useState([]);
  const [prevencaoSocialSolutions, setPrevencaoSocialSolutions] = useState([]);
  const [reincercaoSocial, setReincercaoSocial] = useState([]);
  const [prevencaoSituacional, setPrevencaoSituacional] = useState([]);
  const [justicaCriminalSolutions, setJusticaCriminalSolutions] = useState([]);

  const getAreaSolutions = useCallback((solutions, area) => {
    return solutions.filter(solution => solution.result_area === area);
  }, []);

  useEffect(() => {
    setLoader(true);
    api.get(`/platform/v1/solutions/${solutionId}/`).then(response => {
      const results = response.data.results;
      const gestao = getAreaSolutions(results, 'gestao');
      const policiamento = getAreaSolutions(results, 'policiamento');
      const prevencao_social = getAreaSolutions(results, 'prevencao_social');
      const reinsercao_social = getAreaSolutions(results, 'reinsercao_social');
      const justica_criminal = getAreaSolutions(results, 'justica_criminal');
      const prevencao_situacional = getAreaSolutions(
        results,
        'prevencao_situacional',
      );
      setSolutions(results);
      setGestaoSolutions(gestao);
      setPoliciamentoSolutions(policiamento);
      setPrevencaoSocialSolutions(prevencao_social);
      setReincercaoSocial(reinsercao_social);
      setPrevencaoSituacional(prevencao_situacional);
      setJusticaCriminalSolutions(justica_criminal);
      setLoader(false);
    });
  }, [solutionId, getAreaSolutions]);

  return (
    <Container>
      <DashBoardLayout title="Seleção de Soluções">
        {loader ? (
          <Loader />
        ) : (
          <>
            <Row>
              {solutions.length <= 0 && <h1>Nenhuma Solução Selecionada</h1>}
              {gestaoSolutions.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#10385c">
                      <Idea />
                      <h1>Gestão e Modernização Institucional</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
              {policiamentoSolutions.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#891f1f">
                      <Police />
                      <h1>Policiamento</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
              {prevencaoSocialSolutions.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#bd9312">
                      <Family />
                      <h1>Prevenção Social</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
              {reincercaoSocial.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#520a54">
                      <Reincercao />
                      <h1>Reinserção Social</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
              {prevencaoSituacional.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#ac5201">
                      <Building />
                      <h1>Prevenção Situacional</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
              {justicaCriminalSolutions.map(solution => (
                <Column key={solution.code} small="12" medium="12" large="12">
                  <Card>
                    <SolutionTypeContainer color="#1c6456">
                      <Balance />
                      <h1>Justiça Criminal</h1>
                    </SolutionTypeContainer>
                    <Solution solution={solution} />
                  </Card>
                </Column>
              ))}
            </Row>
          </>
        )}
      </DashBoardLayout>
    </Container>
  );
};

export default SolutionsList;

import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    form {
      .inputs-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          margin-right: 15px;
        }

        button {
          width: 115px;
          height: 50px;
          border-radius: 10px;
          background: #5eb245;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;

          &:hover {
            background: #34a911;
          }
        }
      }
    }
  }

  .rdt_Table {
    margin-top: 25px;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #164e70;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 45px;

    & > div {
      white-space: inherit;
    }
  }
`;

export const DeleteButton = styled.button`
  background: var(--red);
  color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;

  &:hover {
    background: #ef3030;
  }
`;

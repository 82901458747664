import React, { useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { FaExclamationCircle } from 'react-icons/fa';
import { Container, Bar } from './styles';

function Line({ color, title, small, value, middleValue, dataTip }) {
  const percentage = useCallback((partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  }, []);

  const PercentageValue = useMemo(() => {
    const returnValue =
      value >= middleValue
        ? percentage(value, value)
        : percentage(value, middleValue);

    return returnValue;
  }, [value, percentage]);

  const PercentageLineValue = useMemo(() => {
    const returnValue =
      value >= middleValue ? percentage(middleValue, value) : 100;
    return returnValue;
  }, [value, middleValue, percentage]);

  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  return (
    <>
      <Container>
        <small>
          {!!dataTip && (
            <FaExclamationCircle
              style={{ marginRight: 5 }}
              data-tip={dataTip}
            />
          )}
          {title}
        </small>
        {small && <p className="small">{small}</p>}
        <div>
          <div
            className="middleLine"
            style={{ left: `${PercentageLineValue}%` }}
          >
            <p>{middleValue}</p>
          </div>
          <Bar
            color={color}
            style={{ width: `${PercentageValue}%`, justifyContent: 'flex-end' }}
          >
            <span className="value">{toFixed(value)}</span>
          </Bar>
        </div>
      </Container>
      <ReactTooltip place="right" />
    </>
  );
}

export default Line;

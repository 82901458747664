import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: var(--white);
  color: var(--gray2);
  border: 1px solid var(--light-gray2);

  border-radius: 10px;
  padding: 16px;
  width: 100%;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--dark-blue);
      border-color: var(--dark-blue);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--dark-blue);
    `}

  ${props =>
    props.isCorrect &&
    css`
      border-color: green;
      color: green;
    `}

  ${props =>
    props.isDisabled &&
    css`
      background: #ebebeb;
    `}

  input {
    color: var(--text);
    flex: 1;
    background: transparent;
    border: 0;
    width: 100%;

    &::placeholder {
      color: var(--light-gray2);
    }
  }

  /* label {
    display: flex;
    margin-bottom: 5px;
    color: #9f9f9f !important;
    font-weight: bold !important;
    color: var(--title-highlight);
  } */

  svg {
    margin-right: 10px;
  }
`;
export const Error = styled.label`
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: var(--red);
  display: flex;
`;

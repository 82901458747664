import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogged, user } = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        return isLogged && !user.is_admin ? (
          <Component />
        ) : user.is_admin ? (
          <Redirect to={{ pathname: '/management' }} />
        ) : (
          (window.location.href = '/')
        );
      }}
    />
  );
};

export default PrivateRoute;

import React from 'react';

import { Container } from './styles';

const Button = ({ color, padding, border, children, ...rest }) => (
  <Container
    color={color}
    padding={padding}
    type="button"
    border={border}
    {...rest}
  >
    {children}
  </Container>
);

export default Button;

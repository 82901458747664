import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  a {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: #0c9fd8;
    opacity: 1;
    cursor: pointer;
    transition: color 0.3s ease;
    animation: ${EaseIn} 0.3s;

    &:hover {
      color: #0f5e7d;
    }
  }

  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props => (props.tab === 'states' ? '25%' : '75%')};
    }
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
  display: grid;
  animation: ${EaseIn} 0.3s;

  h1 {
    margin: 10px 0;
    color: black;
    font-size: 24px;
  }

  h2 {
    text-align: left;
    font: normal normal normal 16px/20px IBM Plex Sans;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 0px;
    color: #04547c;
    opacity: 1;
  }
`;

export const ImpactCard = styled.div`
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  width: 97%;
  height: 175px;
  opacity: 1;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  text-align: center;

  .operation-title {
    text-align: left;
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
  }

  .operation-code {
    text-align: left;
    font: normal normal bold 23px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #0c9fd8;
    margin-bottom: 15px;
  }

  .operation-name {
    font: normal normal bold 22px/29px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
  }
`;

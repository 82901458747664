import styled from 'styled-components';

export const Container = styled.div`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px 20px;

  .mt5 {
    margin-top: 10px;
  }

  .legendText {
    text-align: left;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #04547c;
  }

  .legendTitle {
    text-align: left;
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }

  .legendName {
    text-align: left;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    margin-left: 5px;
  }

  .iconContainer {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    height: 32px;
  }

  .indicadores-green-border {
    border: 1px solid #5eb245;
    border-radius: 8px;
    opacity: 1;
    height: 32px;
    width: 35px;
    padding: 5px;
  }
  .indicadores-red-border {
    border: 1px solid #fd5454;
    border-radius: 8px;
    opacity: 1;
    height: 32px;
    width: 35px;
    padding: 5px;
  }

  .indicadores-empty-border {
    border: 1px solid #aab9bf;
    border-radius: 8px;
    opacity: 1;
    height: 32px;
    width: 35px;
    padding: 5px;
  }

  .indicadores-grey-border {
    border: 1px solid #d29500;
    border-radius: 8px;
    opacity: 1;
    height: 32px;
    width: 35px;
    padding: 5px;
  }
`;

/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Loader from '../../../../components/Loader';
import getValidationErrors from '../../../../services/getValidationErrors';
import { isBefore, isAfter, isEqual } from 'date-fns';
import DataTable from 'react-data-table-component';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import Modal from '../../../../components/Modal';
import ErrorModal from '../../../../components/ErrorModal';
import api from '../../../../services/api';
import { Container, ProgramationContainer } from './styles';
import { Row, Column } from '../../../../styles/components';

const EditAgreedResults = ({
  show,
  handleClose,
  handleSuccess,
  deliverable,
  operation,
  agreedResult,
}) => {
  const UpdateformRef = useRef(null);
  const TableFormRef = useRef(null);
  const RealizedTableFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const [resp, setResp] = useState(agreedResult);
  const [programming, setProgramming] = useState([]);
  const [realized, setRealized] = useState([]);
  const [endYear, setEndYear] = useState('');
  const [initYear, setInitYear] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const schema = Yup.object().shape({
    title: Yup.string().required('Titulo é obrigatório'),
    indicator: Yup.string().required('Indicador é obrigatório'),
    unit_measure: Yup.string().required('Unidade de medida é obrigatória'),
    ref_value: Yup.string().required('Valor de referência é obrigatório'),
    source: Yup.string().required('A Fonte é obrigatória'),
    goal: Yup.string().required('A meta é obrigatória'),
    resp_measure: Yup.string().required(
      'Responsável pela medida é obrigatório',
    ),
    deadline: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de prazo válida',
    ),
  });

  const calculateYearsBetween = useCallback((startDate, endDate) => {
    const startYear = startDate.getYear() + 1900,
      endYear = endDate.getYear() + 1900,
      output = [];
    for (var year = startYear; year <= endYear; year++) output.push(year);
    return output;
  }, []);

  const generateProgramming = useCallback(
    (initYear, deadlineYear, programmingData, type) => {
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadlineYear}`),
      );
      const programmingArray = years.map(year => {
        const filteredYear = programmingData.filter(
          p => p.year === Number(year),
        )[0];
        return {
          year: year,
          january: !!filteredYear ? filteredYear.jan : 0,
          february: !!filteredYear ? filteredYear.feb : 0,
          march: !!filteredYear ? filteredYear.mar : 0,
          april: !!filteredYear ? filteredYear.apr : 0,
          may: !!filteredYear ? filteredYear.may : 0,
          june: !!filteredYear ? filteredYear.jun : 0,
          july: !!filteredYear ? filteredYear.jul : 0,
          august: !!filteredYear ? filteredYear.aug : 0,
          september: !!filteredYear ? filteredYear.sep : 0,
          october: !!filteredYear ? filteredYear.oct : 0,
          november: !!filteredYear ? filteredYear.nov : 0,
          dezembro: !!filteredYear ? filteredYear.dec : 0,
        };
      });
      if (type === 'programming') setProgramming([...programmingArray]);
      if (type === 'realized') setRealized([...programmingArray]);
    },
    [calculateYearsBetween],
  );

  const getYearData = useCallback((data, year) => {
    const rename = {
      [`january${year}`]: 'jan',
      [`february${year}`]: 'feb',
      [`march${year}`]: 'mar',
      [`april${year}`]: 'apr',
      [`may${year}`]: 'may',
      [`june${year}`]: 'jun',
      [`july${year}`]: 'jul',
      [`august${year}`]: 'aug',
      [`september${year}`]: 'sep',
      [`october${year}`]: 'oct',
      [`november${year}`]: 'nov',
      [`dezembro${year}`]: 'dec',
    };
    var filteredYear = Object.keys(data).reduce((res, key) => {
      if (key.indexOf(year) !== -1) {
        res[rename[key]] = !!data[key] ? data[key] : 0;
      }
      return res;
    }, {});
    return { year, ...filteredYear };
  }, []);

  const changeEndYear = useCallback(() => {
    const value = UpdateformRef.current.getFieldValue('deadline').split('/');

    setResp({
      ...resp,
      deadline: `${value[2]}-${value[1]}-${value[0]}`,
    });
  }, [resp]);

  const handleSave = useCallback(async data => {
    const tableData = TableFormRef.current.getData();
    const RealizedDataTable = RealizedTableFormRef.current.getData();
    const deadline = data['deadline'].split('/');
    const years = calculateYearsBetween(
      new Date(`Jan 1 ${initYear}`),
      new Date(`Jan 1 ${deadline[2]}`),
    );
    const filteredYears = years.map(year => getYearData(tableData, year));
    const filteredRealizedYears = years.map(year =>
      getYearData(RealizedDataTable, year),
    );

    const postData = {
      ...data,
      goal: Number(data.goal),
      deadline: `${deadline[2]}-${deadline[1]}-${deadline[0]}`,
      programming_result: filteredYears,
      realized_result:
        operation.status === 'execution'
          ? filteredRealizedYears
          : resp.realized_result,
      credit_operation: operation.code,
    };

    try {
      UpdateformRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      setLoading(true);
      const response = await api.patch(
        `/credits/api/v1/opcredit-manager/${operation.code}/agreed-results/${resp.id}/`,
        postData,
      );
      setResp({ ...response.data });
      handleSuccess();
      setLoading(false);
      setsuccess(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        UpdateformRef.current?.setErrors(errors);
        return;
      }
      setLoading(false);
      const { ...error } = err;
      if (Object.keys(error.response.data).length) {
        if (!!error.response.data.programming_result) {
          setError(true);
          setErrorMessage(error.response.data.programming_result[0]);
        }
        if (!!error.response.data.realized_result) {
          setError(true);
          setErrorMessage(error.response.data.realized_result[0]);
        }
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline[0]);
        }
      }
    }
  }, []);

  useEffect(() => {
    const splitedDate = resp.deadline.split('-');
    UpdateformRef.current?.setData({
      title: resp.title,
      indicator: resp.indicator,
      unit_measure: resp.unit_measure,
      resp_measure: resp.resp_measure,
      ref_value: resp.ref_value,
      source: resp.source,
      goal: resp.goal,
      deadline: `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`,
    });
  }, [resp]);

  useEffect(() => {
    if (!!resp) {
      generateProgramming(
        initYear,
        resp.deadline.split('-')[0],
        resp.programming_result,
        'programming',
      );
      generateProgramming(
        initYear,
        resp.deadline.split('-')[0],
        resp.realized_result,
        'realized',
      );
    }
  }, [generateProgramming, resp, initYear]);

  useEffect(() => {
    if (!!Object.keys(operation).length) {
      setInitYear(operation.start_date.split('-')[0]);
      setEndYear(operation.start_date.split('-')[0]);
    }
  }, [operation]);

  const handleDisabled = useCallback((initDate, endDate, month, year) => {
    if (!!initDate && !!endDate) {
      const actualDate = new Date(`${month} 1 ${year}`);
      const newInitDate = new Date(
        `${initDate.split('-')[1]} 1  ${initDate.split('-')[0]}`,
      );
      const newEndDate = new Date(
        `${endDate.split('/')[1]} 1 ${endDate.split('/')[2]}`,
      );
      const initIsBefore = isBefore(actualDate, newInitDate);
      const endIsAfter = isAfter(actualDate, newEndDate);
      const isEqualInit = isEqual(actualDate, newInitDate);
      return (initIsBefore || endIsAfter) && !isEqualInit ? true : false;
    } else {
      return null;
    }
  }, []);

  const hasDisabled = useCallback((initDate, endDate, month, year) => {
    const value = handleDisabled(initDate, endDate, month, year);
    const actualDate = new Date(`${month} 1 ${year}`);
    const newInitDate = new Date(
      `${initDate.split('-')[1]} 1 ${initDate.split('-')[0]}`,
    );
    const initIsBefore = isBefore(actualDate, newInitDate);
    return !!value ? value : initIsBefore;
  }, []);

  const columns = [
    {
      name: 'Ano',
      selector: 'year',
    },
    {
      name: 'Jan',
      selector: 'january',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jan',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`january${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.january === 0 ? null : row.january}
          />
        );
      },
    },
    {
      name: 'Fev',
      selector: 'february',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Feb',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`february${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.february === 0 ? null : row.february}
          />
        );
      },
    },
    {
      name: 'Mar',
      selector: 'march',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Mar',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`march${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.march === 0 ? null : row.march}
          />
        );
      },
    },
    {
      name: 'Abr',
      selector: 'april',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Apr',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`april${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.april === 0 ? null : row.april}
          />
        );
      },
    },
    {
      name: 'Mai',
      selector: 'may',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'May',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`may${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.may === 0 ? null : row.may}
          />
        );
      },
    },
    {
      name: 'Jun',
      selector: 'jun',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jun',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`june${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.june === 0 ? null : row.june}
          />
        );
      },
    },
    {
      name: 'Jul',
      selector: 'jul',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Jul',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`july${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.july === 0 ? null : row.july}
          />
        );
      },
    },
    {
      name: 'Ago',
      selector: 'august',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Aug',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`august${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.august === 0 ? null : row.august}
          />
        );
      },
    },
    {
      name: 'Set',
      selector: 'september',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Sep',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`september${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.september === 0 ? null : row.september}
          />
        );
      },
    },
    {
      name: 'Out',
      selector: 'october',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Oct',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`october${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.october === 0 ? null : row.october}
          />
        );
      },
    },
    {
      name: 'Nov',
      selector: 'november',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Nov',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`november${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.november === 0 ? null : row.november}
          />
        );
      },
    },
    {
      name: 'Dez',
      selector: 'dezembro',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          operation.start_date,
          UpdateformRef?.current?.getFieldValue('deadline'),
          'Dec',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`dezembro${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.dezembro === 0 ? null : row.dezembro}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Modal
        title="Editar Resultado pactuado"
        success={success}
        successText="Resultado pactuado Editado com Sucesso!!"
        handleClose={handleClose}
        show={show}
      >
        <>
          {loading && <Loader />}
          <Form ref={UpdateformRef} onSubmit={handleSave}>
            <Row>
              <Column small="12" medium="12" large="12">
                <Input
                  label="Titulo"
                  name="title"
                  className="title"
                  placeholder="Titulo"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="12" large="12">
                <Input
                  label="Indicador"
                  name="indicator"
                  className="indicator"
                  placeholder="Indicador"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="3">
                <Input
                  label="Unidade de Medida"
                  name="unit_measure"
                  className="unit_measure"
                  placeholder="Unidade de Medida"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="9">
                <Input
                  label="Responsável pela Medição"
                  name="resp_measure"
                  className="resp_measure"
                  placeholder="Responsável pela Medição"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="3">
                <Input
                  type="number"
                  label="Valor de Referência"
                  name="ref_value"
                  className="ref_value"
                  placeholder="Valor de Referência"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="9">
                <Input
                  label="Fonte"
                  name="source"
                  className="source"
                  placeholder="Fonte"
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="4">
                <InputMask
                  label="Prazo Final"
                  name="deadline"
                  className="deadline"
                  placeholder="Prazo Final"
                  mask="99/99/9999"
                  maskChar={null}
                  onChange={changeEndYear}
                  schema={schema}
                />
              </Column>
              <Column small="12" medium="4" large="4">
                <Input
                  label="Meta"
                  name="goal"
                  className="goal"
                  placeholder="Meta"
                  schema={schema}
                />
              </Column>
            </Row>
            <Form ref={TableFormRef} onSubmit={handleSave}>
              <ProgramationContainer
                readonly={operation.status === 'execution'}
              >
                <h1>Programação :</h1>
                <DataTable
                  columns={columns}
                  data={programming}
                  noDataComponent=""
                  noHeader
                />
              </ProgramationContainer>
            </Form>
            <Form ref={RealizedTableFormRef} onSubmit={handleSave}>
              {operation.status === 'execution' && (
                <ProgramationContainer>
                  <h1>Realizado :</h1>
                  <DataTable
                    columns={columns}
                    data={realized}
                    noDataComponent=""
                    noHeader
                  />
                </ProgramationContainer>
              )}
            </Form>
            <button className="save" type="submit">
              Salvar
            </button>
          </Form>
        </>
      </Modal>
      {error && (
        <ErrorModal
          show={error}
          Message={errorMessage}
          handleClose={() => setError(false)}
        />
      )}
    </Container>
  );
};

export default EditAgreedResults;

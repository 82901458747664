/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components';

// interface buttonProps {
//   color: string;
//   padding: string;
// }

// interface dropdownProps {
//   active: boolean;
// }

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ButtonContainer = styled.button`
  font-family: 'Source Sans Pro' sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 40px;
  padding: 0 3rem;
  padding: ${props => `0 ${props.padding}rem`};
  background: ${props => props.color};
  border-radius: 6px;
  border: 0;
  transition: background 0.2s;
  color: var(--white);
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);

  button {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:hover {
      background: #dadada;
    }

    & > svg {
      margin-top: 4px !important;
      margin-left: 5px !important;
    }
  }

  ${props =>
    props.active &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';

import { Container, Error } from './styles';

const InputCurrency = ({
  name,
  label,
  disabled,
  icon: Icon,
  schema,
  ...rest
}) => {
  const inputRef = useRef(null);
  const NumberFormatRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const MAX_VAL = 2147483647;

  const withValueLimit = inputObj => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
  };

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleChange = useCallback(async () => {
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        inputRef.current.value,
        { abortEarly: true },
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: NumberFormatRef.current,
      getValue: ref => {
        if (ref.state.numAsString) {
          return Number(ref.state.numAsString);
        }
        return '';
      },
      setValue: (ref, value) => {
        if (value) {
          return value;
        }
        return '';
      },
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <Container
        className="currency-container"
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isCorrect={isCorrect}
        isDisabled={!!disabled}
      >
        <NumberFormat
          getInputRef={inputRef}
          ref={NumberFormatRef}
          disabled={!!disabled}
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          fixedDecimalScale
          decimalScale={2}
          defaultValue={defaultValue}
          allowNegative={false}
          isAllowed={withValueLimit}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onValueChange={handleChange}
          {...rest}
        />
        {Icon && <Icon size={20} />}
      </Container>
      {!!error && <Error>{error}</Error>}
    </>
  );
};

export default InputCurrency;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;

  span {
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;
    color: var(--white);
    font-weight: bold;
    transition: all 0.5s ease;
  }

  & > div {
    flex: 1;
    height: 21px;
    border-radius: 4px;
    background: var(--gray);
    position: relative;
    transition: all 0.5s ease;
  }

  & > div > div {
    width: ${props => `${props.percentage}%`};
    background: ${props =>
      props.color ? `var(--${props.color})` : `var(--blue)`};

    height: 21px;
    border-radius: 4px;
    transition: all 0.5s ease;
  }

  .TollTip {
    color: #ffffff;
    background: ${props =>
      props.color ? `var(--${props.color})` : `var(--blue)`};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    transition: opacity 0.4s;
    left: ${props => props.percentage && `calc(${props.percentage}% - 25px);`};
    bottom: calc(100% + 7px);

    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: ${props =>
        props.color
          ? `var(--${props.color}) transparent`
          : `var(--blue) transparent`};
      border-width: 6px 6px 0 6px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Title = styled.h1`
  font: normal normal normal 14px/18px IBM Plex Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import styled from 'styled-components';

export const Container = styled.div`
  .redirectLink {
    text-decoration: underline;
    color: #004e70;
    font-weight: 700 !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
  }

  .m-5 {
    margin-bottom: 5px;
  }
`;

export const Title = styled.h1`
  margin-top: 15px;
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  margin-bottom: 0.6rem;
`;

export const EvidenceCard = styled.div`
  width: 100%;
  background: ${props => props.color};

  height: 172px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 5px;
  }

  span {
    text-align: center;
    font: normal 17px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: bold;
  }

  h3 {
    text-align: center;
    font: italic 14px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &:hover {
    padding: 15px;
    cursor: pointer;
    h3 {
      display: flex;
    }

    span,
    img {
      display: none;
    }
  }
`;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import { formatPrice } from '../../services/currencyFormatter';
import html2canvas from 'html2canvas';
import DataTable from 'react-data-table-component';
import DashboardLayout from '../../components/DashboardLayout';
import Solution from '../../components/Solution';
import PdfHeader from '../../assets/pdfheader.png';
import Loader from '../../components/Loader';
import ImpactAccordeon from '../../components/ImpactAccordion';
import SuccessModal from '../../components/SuccessModal';
import TitleInfo from '../../components/TitleInfo';
import { Row, Column } from '../../styles/components';

import {
  Container,
  Title,
  Card,
  SubTitle,
  FormSectionContainer,
  PrintableBodyWrapper,
  PrintableDiv,
  FormButton,
  SolutionsContainer,
  TotalContainer,
} from './styles';

const PreProposal = () => {
  const printRef = useRef(null);
  const { preProposalId } = useParams();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [preProposal, setPreProposal] = useState({
    user: {},
    administration: [],
  });
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [involvedSecretaries, setInvolvedSecretaries] = useState([]);
  const [territorialFocus, setTerritorialFocus] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [printing, setPrinting] = useState(false);

  const JsonParser = useCallback(val => {
    try {
      return JSON.parse(val);
    } catch (err) {
      return [];
    }
  }, []);

  useEffect(() => {
    api
      .get(`/platform/v1/pre-proposal/${preProposalId}/`)
      .then(response => {
        setPreProposal(response.data);

        const parsedSecretaries =
          typeof response.data.administration === 'string'
            ? JsonParser(response.data.involved_secretaries)
            : response.data.involved_secretaries;

        const parsedTerritorialFocus =
          typeof response.data.administration === 'string'
            ? JsonParser(response.data.territorial_focus)
            : response.data.territorial_focus;

        setInvolvedSecretaries(parsedSecretaries);
        setTerritorialFocus(parsedTerritorialFocus);

        setLoading(false);
      });
  }, [preProposalId, JsonParser]);

  const getFile = async url => {
    let response = await fetch(url);
    let data = await response.blob();

    const stringArr = url.split('.');
    const teste = stringArr.lastIndexOf('.');

    console.log('asdasd', stringArr, teste);

    const getType = mime => {
      const types = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        'application/vnd.ms-powerpoint': 'ppt',
        'image/svg+xml': 'svg',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          'pptx',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          'xlsx',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          'docx',
      };
      return types[mime];
    };
    return new File([data], `file.${getType(data.type)}`, { type: data.type });
  };

  const handleSelectImpact = useCallback(
    data => {
      const exists = selectedImpacts.filter(
        impact => impact.code === data.code,
      );

      if (exists.length && data.indicators.length) {
        const removeIndex = selectedImpacts
          .map(imp => imp.code)
          .indexOf(data.code);
        selectedImpacts.splice(removeIndex, 1);
        setSelectedImpacts([...selectedImpacts, data]);
      } else if (exists.length && !data.indicators.length) {
        const removeIndex = selectedImpacts
          .map(imp => imp.code)
          .indexOf(data.code);
        selectedImpacts.splice(removeIndex, 1);
        setSelectedImpacts([...selectedImpacts]);
      } else {
        setSelectedImpacts([...selectedImpacts, data]);
      }
    },
    [selectedImpacts],
  );

  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  const printDocument = useCallback(() => {
    window.scrollTo(0, 0);
    setLoadingSuccess(true);
    setShowSuccess(true);
    setPrinting(true);
    setTimeout(() => {
      html2canvas(printRef.current, {
        ignoreElements: function (element) {
          if (element.classList.contains('successModal')) {
            return true;
          }
        },
      }).then(async canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF('pt', 'mm');
        let position = 0;

        const base64 = await toDataURL(PdfHeader);
        doc.addImage(base64, 'PNG', 0, 0, 210, 39);
        doc.addImage(imgData, 'PNG', 10, 42, imgWidth, imgHeight + 25);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(0, 287, 210, 15, 'F');

        heightLeft -= pageHeight;
        heightLeft = heightLeft + 45;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;

          doc.addPage();
          doc.addImage(
            imgData,
            'PNG',
            10,
            position + 13,
            imgWidth,
            imgHeight + 25,
          );

          doc.setFillColor(0, 0, 0, 0);
          doc.rect(0, 0, 210, 10, 'F');

          doc.setFillColor(0, 0, 0, 0);
          doc.rect(0, 287, 210, 15, 'F');

          heightLeft -= pageHeight;
          heightLeft = heightLeft + 15;
        }
        window.open(doc.output('bloburl'));
        setPrinting(false);
        setLoadingSuccess(false);
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (!!Object.keys(preProposal.user).length) {
      const SolutionsTotal = preProposal.solutions.reduce(function (
        total,
        solution,
      ) {
        return total + solution.cost_estimate;
      },
      0);
      setTotal(SolutionsTotal);
      setLoading(false);
    }
  }, [preProposal]);

  const secretariesColumns = [
    {
      name: 'Nome da Secretaria',
      selector: 'secretary_name',
      minWidth: '0px',
    },
  ];

  const territorialFocusColumns = [
    {
      name: 'Municipio/Bairro',
      selector: 'location',
      minWidth: '0px',
    },
    {
      name: 'População Estimada',
      selector: 'population',
      minWidth: '0px',
    },
  ];

  return (
    <Container>
      <DashboardLayout title="Pré Proposta">
        {loading ? (
          <Loader />
        ) : (
          <PrintableBodyWrapper ref={printRef}>
            <Title>Pré-Proposta</Title>
            <SubTitle>Informações do Usuário</SubTitle>
            <Card color="#f8f8f8" shadow>
              {!!preProposal && !!Object.keys(preProposal.user).length && (
                <>
                  <span>Nome:</span>
                  <p>{preProposal.user.full_name}</p>
                  <span>Orgão de atuação no governo/prefeitura:</span>
                  <p>
                    {!!preProposal.user.orgao_atuacao
                      ? `${preProposal.user.orgao_atuacao}`
                      : 'Não Informado'}
                  </p>
                  <span>Cargo :</span>
                  <p>
                    {!!preProposal.user.role && preProposal.user.role !== 'None'
                      ? `${preProposal.user.role}`
                      : 'Não Informado'}
                  </p>
                  <span>E-mail:</span>
                  <p>{preProposal.user.institutional_mail}</p>
                  <span>Telefone:</span>
                  <p>
                    {!!preProposal.user.phone
                      ? `${preProposal.user.phone}`
                      : 'Não Informado'}
                  </p>
                </>
              )}
            </Card>
            <FormSectionContainer>
              <h1>
                Objetivo Geral da operação de crédito a ser contratada
                <TitleInfo info="Nesse campo deve-se inserir um texto (sintético) através do qual seja apresentada a finalidade geral do projeto e os objetivos específicos que deseja atender." />
              </h1>
              <PrintableDiv>{preProposal.general_goals}</PrintableDiv>
            </FormSectionContainer>
            <FormSectionContainer>
              <h1 style={{ marginBottom: '15px', marginTop: '15px' }}>
                Impactos Desejados
                <TitleInfo info="Nesta seção o objetivo geral da operação deve ser detalhada em termos dos impactos que se deseja efetivar, mediante a execução da operação de crédito. Esses impactos foram selecionados automaticamente, dada sua vinculação às soluções selecionadas. Para cada impacto você deverá especificar pelo menos um indicador de mensuração." />
              </h1>
              {preProposal.desired_impacts.map(impact => (
                <ImpactAccordeon
                  key={impact.code}
                  title={impact.name}
                  impact={impact}
                  selected={selectedImpacts}
                  callback={handleSelectImpact}
                  printing={printing}
                />
              ))}
            </FormSectionContainer>
            <Row>
              <Column large="12" medium="12" small="12">
                <FormSectionContainer>
                  <h1>Administração e Governança</h1>
                  <small>
                    Secretaria Estadual / Municipal - Principal Responsável
                    Pelas Politicas de Segurança
                    <TitleInfo info=" Preencha os dados do(a) secretário(a) municipal/estadual que lidera o órgão que será o principal responsável pela coordenação do processo de execução da operação de crédito e da implantação das soluções financiadas. Caso o usuário cadastrado na plataforma seja o(a) próprio(a) secretário(a), replique as informações constantes da seção acima." />
                  </small>
                  <PrintableDiv style={{ display: 'inline-block' }}>
                    {!!preProposal.administration.length && (
                      <>
                        <span>Nome da Secretária</span>
                        <p>{preProposal.administration[0].secretary_title}</p>
                        <span>Secretário(a) Atual</span>
                        <p>{preProposal.administration[0].secretary_name}</p>
                        <span>Telefone</span>
                        <p>{preProposal.administration[0].phone}</p>
                        <span>Email</span>
                        <p>{preProposal.administration[0].email}</p>
                      </>
                    )}
                  </PrintableDiv>
                </FormSectionContainer>
              </Column>
              <Column small="12" medium="12" large="12">
                <div className="downloadDiv">
                  <span>Organograma</span>
                  <a href={preProposal.organogram_file} download>
                    download
                  </a>
                </div>
              </Column>
              <Column small="12" medium="12" large="12">
                <div className="downloadDiv">
                  <span>Regimento Interno</span>
                  <a href={preProposal.internal_regiment_file} download>
                    download
                  </a>
                </div>
              </Column>
            </Row>
            <FormSectionContainer>
              <small>
                Secretaria(s) / Orgãos Envolvidos (Partes interessadas /
                'Stakeholders')
                <TitleInfo info="Campo em que devem ser apresentados os órgãos que deverão ser envolvidos direta ou indiretamente para a boa execução dos projetos / soluções ('partes interessadas'/'stakeholders')." />
              </small>
              <Row className="itens-row">
                <Column small="12" medium="12" large="12">
                  <DataTable
                    columns={secretariesColumns}
                    data={involvedSecretaries}
                    noDataComponent=""
                    noHeader
                  />
                  {involvedSecretaries.length === 0 && (
                    <p className="noOptionsMessage">
                      Nenhuma Secretária Adicionada
                    </p>
                  )}
                </Column>
              </Row>
            </FormSectionContainer>
            <FormSectionContainer>
              <h1>
                Detalhamento da Governança
                <TitleInfo
                  info="Apresentação dos principais órgãos e secretarias que estarão à frente dos projetos a serem contemplados pela operação de crédito (PROSEG Federativo). Na sequência, deve ser detalhada a forma pela qual eles se articulam entre si. Se houver necessidade de alguma parceria externa (órgãos do governo federal e/ou outro ente subnacional) especificar qual, qual a estrutura de governança necessária e a forma como será formalizada. 
                          Obs.: se, após a implantação do projeto, for necessária uma nova estrutura de governança para a sua correta operacionalização, detalhar: qual estrutura será criada, quais instituições estarão à frente, quais ações são necessárias para a estruturação da nova governança."
                />
              </h1>
              <Row>
                <Column small="12" medium="12" large="12">
                  <PrintableDiv display={printing}>
                    {preProposal.governance_detail}
                  </PrintableDiv>
                </Column>
              </Row>
            </FormSectionContainer>
            <FormSectionContainer>
              <h1>
                Alinhamento Estratégico
                <TitleInfo info="Nesse campo deve-se inserir um texto (sintético) através do qual demonstre-se a forma como a contratação de operação de crédito junto ao PROSEG Federativo se alinha com i) a estratégia de segurança pública do estado/município; e (ii) com instrumentos de planejamento de curto, médio e longo prazos do estado/município. Considere demonstrar a previsão do projeto no Plano Plurianual e na Lei Orçamentária em vigor, se houver. Também poderão ser anexados, nesse campo, os documentos que sintetizem os principais instrumentos de planejamento de curto, médio e longo prazos da Segurança Pública e/ou da própria adminsitração  estadual / municipal que contenha orientações (metas, diretrizes, etc.) à área de segurança pública." />
              </h1>
              <Row>
                <Column small="12" medium="12" large="12">
                  <PrintableDiv>
                    {preProposal.strategic_alignments}
                  </PrintableDiv>
                </Column>
                <Column small="12" medium="12" large="12">
                  <div className="downloadDiv">
                    <span>
                      Instrumento de Planejamento e/ou Orientação Estratégica
                    </span>
                    <a href={preProposal.planning_instrument} download>
                      download
                    </a>
                  </div>
                </Column>
              </Row>
            </FormSectionContainer>
            <FormSectionContainer>
              <h1>
                Foco Territorial
                <TitleInfo info=" No caso de governos estaduais, deve-se especificar, nesta seção, os municípios prioritários (principais destinatários dos entregáveis cadastrados) e suas respectivas populações, conforme estimativa mais recente disponível. No caso de Prefeituras Municipais, devem ser especificados os bairros e suas respectivas populações (estimativa, conforme dados mais recentes disponíveis)." />
              </h1>
              <Row className="itens-row">
                <Column small="12" medium="12" large="12">
                  <DataTable
                    columns={territorialFocusColumns}
                    data={territorialFocus}
                    noDataComponent=""
                    noHeader
                  />
                  {territorialFocus.length === 0 && (
                    <p className="noOptionsMessage">
                      Nenhum Foco Territorial Adicionado
                    </p>
                  )}
                </Column>
              </Row>
            </FormSectionContainer>
            <PrintableDiv>
              <Row>
                <Column small="12" medium="12" large="12">
                  <Title>Soluções</Title>
                  {!!preProposal && !!Object.keys(preProposal.user).length && (
                    <>
                      {preProposal.solutions.map(solution => (
                        <>
                          <SolutionsContainer key={solution.code}>
                            <Solution solution={solution} />
                          </SolutionsContainer>
                        </>
                      ))}
                      <TotalContainer>{`Estimativa Total: ${formatPrice(
                        total,
                      )}`}</TotalContainer>
                    </>
                  )}
                </Column>
              </Row>
            </PrintableDiv>
            <Row className="itens-row">
              <Column
                small="12"
                medium="12"
                large="12"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <FormButton onClick={() => printDocument()}>
                  Imprimir
                </FormButton>
              </Column>
            </Row>
            {showSuccess && (
              <SuccessModal
                loading={loadingSuccess}
                handleClose={() => setShowSuccess(false)}
                show={showSuccess}
                successText="Impresso"
              />
            )}
          </PrintableBodyWrapper>
        )}
      </DashboardLayout>
    </Container>
  );
};

export default PreProposal;

import React from 'react';

import DashboardLayout from '../../components/DashboardLayout';

import { Container } from './styles';

const UnderConstruction = () => {
  return (
    <Container>
      <DashboardLayout title="Em desenvolvimento">
        <h1>Pagina em Desenvolvimento</h1>
      </DashboardLayout>
    </Container>
  );
};

export default UnderConstruction;

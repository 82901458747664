import React from 'react';

import { Container, Bar } from './styles';

function GraphicBar({ percentage, color, title, value, position }) {
  return (
    <>
      <Container>
        <small>{title}</small>
        <div title={title}>
          <div className="sidebar" />
          <Bar color={color} style={{ width: `${percentage}%` }}>
            <span>{position}</span>
          </Bar>
        </div>
      </Container>
    </>
  );
}

export default GraphicBar;

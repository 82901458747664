export const criminalityData = [
  {
    code: '1',
    indicator: 'Taxa de crimes contra o patrimônio',
    calculation_method: 'Ocorrência de crimes contra o patrimônio / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '1.1.',
    indicator: 'Taxa de roubos a estabelecimentos comerciais',
    calculation_method: 
      'Ocorrência de crimes contra o patrimônio (roubo a estabelecimento comercial) / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '1.2.',
    indicator: 'Taxa de roubos a residências',
    calculation_method: 'Ocorrência de crimes contra o patrimônio (roubo a residência) / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '1.3.',
    indicator: 'Taxa de roubos a transeuntes',
    calculation_method: 'Ocorrência de crimes contra o patrimônio (Roubo a transeunte) / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '1.4.1',
    indicator: 'Taxa de roubos a instituições financeiras',
    calculation_method:
      'Ocorrência de crimes contra o patrimônio (roubo a instituição financeira) / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '1.4.2',
    indicator: 'Taxa de roubos a instituições financeiras',
    calculation_method:
      'Ocorrência de crimes contra o patrimônio (roubo a instituição financeira) / população residente * 100.000',
    source: 'SINESP',
  },
  {
    code: '1.5',
    indicator: 'Taxa de roubos de cargas',
    calculation_method: 'Ocorrência de crimes contra o patrimônio (roubos de cargas) / população residente * 100.000',
    source: 'SINESP',
  },
  {
    code: '2',
    indicator: 'Taxa de Roubos e Furtos de Veículos',
    calculation_method: 'Ocorrência de Roubos e Furtos de veículos / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '2.1.',
    indicator: 'Taxa de Roubos de Veículos',
    calculation_method: 'Ocorrência de Roubos de veículos / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '2.2.',
    indicator: 'Taxa de Furtos de Veículos',
    calculation_method: 'Ocorrência de Furtos de veículos / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '3',
    indicator: 'Taxa de Mortes Violentas Intencionais (MVI)',
    calculation_method: 'Ocorrência de Mortes Violentas Intencionais (MVI) / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '3.1.1.',
    indicator: 'Taxa de Homicídios Dolosos',
    calculation_method: 'Ocorrências de homicídios dolosos / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '3.1.2',
    indicator: 'Taxa de Homicídios Dolosos',
    calculation_method: 'Ocorrências de homicídios dolosos / população residente * 100.000',
    source: 'SINESP',
  },
  {
    code: '3.2.',
    indicator: 'Taxa de Latrocínio',
    calculation_method: 'Ocorrência de latrocínios/população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '3.3.',
    indicator: 'Taxa de Lesão Corporal seguida de Morte',
    calculation_method: 'Ocorrência de lesões corporais seguidas de morte/população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '3.4.',
    indicator: 'Taxa de Mortes Decorrentes de Intervenções Policiais em serviço e fora',
    calculation_method:
      'Ocorrência de mortes decorrentes de intervenções policiais em serviço e fora/população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '91',
    indicator: 'Taxa de Tentativas de Homicídios',
    calculation_method: 'Ocorrências de tentativas de homicídios / população residente * 100.000',
    source: 'SINESP',
  },
  {
    code: '8',
    indicator: 'Taxa de Homicídios (população total)',
    calculation_method: 'Número de Homicídios / população residente * 100.000',
    source: 'DataSUS',
  },
  {
    code: '8.1',
    indicator: 'Proporção de Homicídios por raça/cor',
    calculation_method: 'Número de Homicídios (por raça ou cor) / habitantes (por raça ou cor) * 100.000',
    source: 'DataSUS',
  },
  {
    code: '8.2',
    indicator: 'Proporção de Homicídios por sexo',
    calculation_method: 'Número de Homicídios (por sexo) / habitantes (por sexo) * 100.000',
    source: 'DataSUS',
  },
  {
    code: '13',
    indicator: 'Taxa de Homicídios de negros',
    calculation_method: 'Número de Homicídios (de pretos e pardos) / habitantes (pretos e pardos) * 100.000',
    source: 'DataSUS/PNAD',
  },
  {
    code: '14',
    indicator: 'Taxa de Homicídios de jovens',
    calculation_method: 'Número de Homicídios (jovens) / habitantes (jovens) * 100.000',
    source: 'DataSUS',
  },
  {
    code: '12',
    indicator: 'Taxa de Homicídios por Arma de Fogo',
    calculation_method: 'Número de Homicídios por armas de fogo / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '15',
    indicator: 'Taxa de Agressões (população total)',
    calculation_method: 'Número de internações por agressão / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '89',
    indicator: 'Taxa de Agressões (homens)',
    calculation_method: 'Número de internações por agressão (homens) / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '26',
    indicator: 'Taxa de Estupro de Vulneráveis',
    calculation_method: 'Número de vítimas de estrupo de vulneráveis / população residente * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '27',
    indicator:
      'Taxa de violência doméstica (crianças - menores de 14 anos - e adolescentes e jovens - entre 15 e 29 anos)',
    calculation_method:
      'Número de notificações sobre ocorrência de casos suspeitos e/ou confirmados de violência doméstica entre crianças, adolescentes e jovens (até 29 anos) / habitantes na faixa etária * 100.000',
    source: 'SINAN',
  },
  {
    code: '29',
    indicator:
      'Taxa populacional de denúncias de violência contra crianças e adolescentes (Nº ligações Disque 100)',
    calculation_method: 'Número de ligações para o Disque 100 / habitantes na faixa etária * 100.000',
    source: 'Ministério da Mulher, da Família e dos Direitos Humanos (MMFDH)',
  },
  {
    code: '16',
    indicator: 'Taxa de Homicídios contra mulheres',
    calculation_method: 'Número de Homicídios contra mulheres / habitantes (mulheres) * 100.000',
    source: 'DataSUS',
  },
  {
    code: '17',
    indicator: 'Taxa de Feminicídios',
    calculation_method: 'Número de vítimas de feminicídio / habitantes (mulheres) * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '18',
    indicator: 'Taxa de Violência Doméstica contra mulheres',
    calculation_method: 'Notificações de violência doméstica contra mulheres / habitantes (mulheres) * 100.000',
    source: 'SINAN',
  },
  {
    code: '21',
    indicator: 'Taxa de Agressões contra mulheres',
    calculation_method: 'Número de internações de pacientes do sexo feminino / habitantes (mulheres) * 100.000',
    source: 'DataSUS',
  },
  {
    code: '19',
    indicator: 'Taxa de Violência Sexual contra mulheres',
    calculation_method: 'Número de internações de mulheres em decorrência de violência sexual / habitantes (mulheres) * 100.000',
    source: 'SINAN',
  },
  {
    code: '20',
    indicator: 'Taxa de Estupros contra mulheres',
    calculation_method: 'Número de Estupros / habitantes (mulheres) * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '24',
    indicator: 'Taxa populacional de denúncias de violência contra mulher (Nº ligações Disque 180)',
    calculation_method: 'Número de ligações para o Disque 180 / habitantes (mulheres) * 100.000',
    source: 'Ministério da Mulher, da Família e dos Direitos Humanos (MMFDH) e SINAN',
  },
  {
    code: '25',
    indicator: 'Taxa de Medidas protetivas (violência contra mulheres), por cem mil mulheres residentes',
    calculation_method: 'Número de medidas protetivas emitidas / população residente (mulheres) * 100.000',
    source: 'CNJ',
  },
  {
    code: '28',
    indicator: 'Taxa de violência doméstica (população total)',
    calculation_method: 'Número de notificações / população residente *  100.000',
    source: 'SINAN',
  },
];

export const RiskFactorsData = [
  {
    code: '30',
    indicator: 'Taxa de Internação por Agressão por arma de fogo',
    calculation_method: 'Número de internações por agressão por arma de fogo / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '72',
    indicator: 'Jovens nem-nem-nem',
    calculation_method: 'Jovens que não trabalham, não procuram emprego e não estudam / população de jovens * 100.000',
    source: 'PNAD-C',
  },
  {
    code: '66',
    indicator: 'Taxa de gravidez na adolescência',
    calculation_method: 'Número de nascidos vivos de mães com idade entre 10 e 19 anos / total de nascidos vivos * 100',
    source: 'DataSUS',
  },
  {
    code: '31',
    indicator: 'Proporção de Suicídios com Armas de Fogo',
    calculation_method: 'Número de suicídios com armas de fogo / total de suicídios * 100',
    source: 'DataSUS',
  },
  {
    code: '32',
    indicator: 'Posse e Porte Ilegal de Armas de Fogo',
    calculation_method: 'Registros de ocorrências policiais (posse e porte ilegais de armas de fogo) / população * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '33',
    indicator: 'Número de Apreensões de Armas de Fogo',
    calculation_method: 'Número absoluto de armas de fogo apreendidas',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '34',
    indicator: 'Tráfico de Entorpecentes',
    calculation_method: 'Registros de ocorrências policiais (tráfico de drogas) / população * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '35',
    indicator: 'Posse e uso de entorpecentes',
    calculation_method: 'Registros de ocorrências policiais (posse e uso de entorpecentes) / população * 100.000',
    source: 'Anuário de Segurança Pública',
  },
  {
    code: '36',
    indicator: 'Taxa de óbitos por abuso de drogas',
    calculation_method: 'Número de óbitos por uso de drogas / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '36.1.',
    indicator: 'Taxa de óbitos por abuso de drogas (entre 15 e 29 anos)',
    calculation_method: 'Número de óbitos de jovens (15 a 29 anos) por uso de drogas / total de jovens * 100.000',
    source: 'DataSUS',
  },
  {
    code: '37',
    indicator: 'Taxa de óbitos totalmente atribuíveis ao uso abusivo de álcool',
    calculation_method: 'Número de óbitos totalmente atribuíveis ao uso de álcool / habitantes * 100.000',
    source: 'DataSUS',
  },
  {
    code: '37.1',
    indicator: 'Taxa de óbitos totalmente atribuíveis ao uso abusivo de álcool (entre 15 e 29 anos)',
    calculation_method: 
      'Número de óbitos de jovens totalmente atribuíveis ao uso de álcool / total de jovens * 100.000',
    source: 'DataSUS',
  },
  {
    code: '61',
    indicator: 'Percentual da população vivendo em moradias inadequadas',
    calculation_method: 'Número de pessoas que residem em domicílios inadequados / população total * 100',
    source: 'PNAD-C',
  },
  {
    code: '62',
    indicator: 'Percentual de domicílios particulares ocupados em aglomerados subnormais',
    calculation_method: 'Domicílios particulares ocupados em aglomerados subnormais / total de domicílios * 100',
    source: 'IBGE',
  },
  {
    code: '64',
    indicator: 'Percentual de Crianças de 0 a 3 anos frequentando creche',
    calculation_method: 
      'Número de crianças entre 0 e 3 anos frequentando a creche / total de crianças entre 0 e 3 anos * 100',
    source: 'PNAD-C',
  },
  {
    code: '64.1',
    indicator: 'Taxa de cobertura do Ensino Infantil Municipal (Creche)',
    calculation_method: 
      'Número de matrículas em creches / estimativa populacional (crianças de 0 a 3 anos) * 100',
    source: 'Censo Escolar e DataSUS (projeção populacional)',
  },
  {
    code: '65',
    indicator: 'Percentual de Crianças de 4 a 5 anos frequentando pré-escola',
    calculation_method:
      'Número de crianças entre 4 a 5 anos frequentando a pré escola / total de crianças entre 4 e 5 anos * 100',
    source: 'PNAD-C',
  },
  {
    code: '65.1',
    indicator: 'Taxa de cobertura do Ensino Infantil Municipal (Pré-Escola)',
    calculation_method: 'Número de matrículas em creches / estimativa populacional (crianças de 4 e 5 anos) * 100',
    source: 'Censo Escolar',
  },
  {
    code: '67.1',
    indicator: 'Taxa de Aprendizagem Adequada (E.F.I)',
    calculation_method: 'Proporção de alunos que alcançaram pelo menos o nível básico nas disciplinas de português e matemática, em relação ao total de alunos que realizaram a prova Brasil. O cálculo em si é feito através da fórmula [(alunos PT/total) * 100 + (alunos MT/total) * 100] / 2',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '67.2',
    indicator: 'Taxa de Aprendizagem Adequada (E.F.II)',
    calculation_method: 'Proporção de alunos que alcançaram pelo menos o nível básico nas disciplinas de português e matemática, em relação ao total de alunos que realizaram a prova Brasil. O cálculo em si é feito através da fórmula [(alunos PT/total) * 100 + (alunos MT/total) * 100] / 2',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '67.3',
    indicator: 'Taxa de Aprendizagem Adequada (E.M)',
    calculation_method: 
      'Média entre o percentual de alunos que alcançou pelo menos o nível básico nas disciplinas de português e matemática',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '68.1',
    indicator: 'IDEB E.F. I',
    calculation_method: 
      'Nota é calculada com base no desempenho dos alunos na Prova Brasil e a taxa de aprovação (0 a 10)',
    source: 'INEP',
  },
  {
    code: '68.2',
    indicator: 'IDEB E.F. II',
    calculation_method: 
      'Nota é calculada com base no desempenho dos alunos na Prova Brasil e a taxa de aprovação (0 a 10)',
    source: 'INEP',
  },
  {
    code: '68.3',
    indicator: 'IDEB E.M',
    calculation_method: 
      'Nota é calculada com base no desempenho dos alunos na Prova Brasil e a taxa de aprovação (0 a 10)',
    source: 'INEP',
  },
  {
    code: '69.1',
    indicator: 'Taxa de Abandono (E.F. II)',
    calculation_method: 'Alunos que abandonam o E.F. II / total de alunos (E.F II) * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '69.2',
    indicator: 'Taxa de Abandono (E.M.)',
    calculation_method: 'Alunos que abandonam o E.M. / total de alunos (E.M.) * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '70.1',
    indicator: 'Taxa de Reprovação (E.F. II)',
    calculation_method: 'Alunos que reprovam no E.F. II / total de alunos (E.F. II) * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '70.2',
    indicator: 'Taxa de Reprovação (E.M.)',
    calculation_method: 'Alunos que reprovam no E.M. / total de alunos (E.M.) * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '71.1',
    indicator: 'Taxa de distorção série-idade (Ensino Fundamental)',
    calculation_method: 'Número de alunos com idade acima da esperada (E.F. II) / total de alunos * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '71.2',
    indicator: 'Taxa de distorção série-idade (Ensino Médio)',
    calculation_method: 'Número de alunos com idade acima da esperada (E.M.) / total de alunos * 100',
    source: 'Censo Escolar/INEP',
  },
  {
    code: '73',
    indicator: 'Taxa de Escolaridade Média da população de jovens e jovens adultos (entre 18 e 29 anos)',
    calculation_method: 
      'Soma dos anos de estudos das pessoas na faixa etária / população total residente dessa faixa etária * 100',
    source: 'PNAD-C',
  },
  {
    code: '74',
    indicator: 'Percentual da população de 15 a 29 anos apta ao EJA EF',
    calculation_method: 
      'População (entre 15 e 29 anos) apta ao EJA EF / população total (entre 15 e 29 anos) * 100',
    source: 'PNAD-C',
  },
  {
    code: '75',
    indicator: 'Percentual da população de 18 a 29 anos apta ao EJA EM',
    calculation_method: 'População (entre 18 e 29 anos) apta ao EJA EM / população total (entre 18 e 29 anos) * 100',
    source: 'PNAD-C',
  },
  {
    code: '80',
    indicator: 'Percentual da População de 15 anos ou mais analfabetos',
    calculation_method:
      'Números de pessoas analfabetas maiores de 15 anos / população total (maior de 15 anos) * 100',
    source: 'PNAD-C',
  },
  {
    code: '81',
    indicator: 'Taxa de Escolaridade Média da população adulta (≥ 18 anos)',
    calculation_method:
      'Soma dos anos de estudos das pessoas na faixa etária / população total residente (18 anos ou mais) * 100',
    source: 'PNAD-C',
  },
  {
    code: '76',
    indicator: 'Taxa de Desocupação entre Jovens',
    calculation_method:
      'Número de jovens desocupados / força de trabalho entre jovens * 100',
    source: 'PNAD-C',
  },
  {
    code: '77',
    indicator: 'Taxa de Desalento entre Jovens',
    calculation_method:
      'Número de jovens desalentados / força de trabalho potencial entre jovens * 100',
    source: 'PNAD-C',
  },
  {
    code: '78',
    indicator: 'Taxa de Desocupação (população em idade ativa)',
    calculation_method: 'Número de desocupados / força de trabalho * 100',
    source: 'PNAD-C',
  },
  {
    code: '79',
    indicator: 'Taxa de Desalento (população em idade ativa)',
    calculation_method: 'Número de desalentados / força de trabalho potencial * 100',
    source: 'PNAD-C',
  },
  {
    code: '80',
    indicator: 'Taxa de Desalento (mulheres)',
    calculation_method: 
      'Número de mulheres desalentadas em idade ativa / força de trabalho potencial entre mulheres * 100',
    source: 'PNAD-C',
  },
  {
    code: '82',
    indicator: 'Percentual de domicílios vulneráveis à pobreza (RDPC < ½ S.M)',
    calculation_method: 'Número de domicíios com RDPC menor que 1/2 S.M. / total de domicílios * 100',
    source: 'PNAD-C',
  },
  {
    code: '83',
    indicator: 'Percentual de domicílios com RDPC < 1/2 S.M. chefiados por mulheres e monoparentais',
    calculation_method: 
      'Número de domicílios com RDPC < 1/2 S.M. chefiados por mulheres e monoparentais / total de domicílios com RDPC inferior a 1/2 S.M. * 100',
    source: 'PNAD-C',
  },
  {
    code: '84',
    indicator: 'Percentual de Pessoas em situação de pobreza e extrema pobreza',
    calculation_method: 
      'Total de pessoas inscritas no Cadastro único em situação de pobreza ou extrema pobreza / população residente * 100',
    source: 'CadÚnico',
  },
  {
    code: '85',
    indicator:'Percentual de Famílias Pobres ou Extremamente-Pobres Cobertas pelo Bolsa Família',
    calculation_method:
      'Famílias em situação de pobreza ou extrema pobreza beneficiárias do Bolsa Família / Famílias inscritas no Cadastro Único em situação de pobreza ou extrema pobreza * 100',
    source: 'CadÚnico',
  },
  {
    code: '86',
    indicator: 'Renda Domiciliar per Capita',
    calculation_method: 'Soma da renda de todas as fontes dos moradores / número total de moradores',
    source: 'PNAD-C',
  },
  {
    code: '87',
    indicator: 'Coeficiente de Gini',
    calculation_method: 'Medida que varia entre 0 e 1. Quanto mais perto de 1, maior a desigualdade',
    source: 'PNAD-C',
  },
];

export const institutionalPerformanceData = [
  {
    code: '54',
    indicator: 'Percentual de domicílios com saneamento adequado (água, esgoto e coleta de lixo)',
    calculation_method: 'Domicílios com saneamento adequado* / total de domicílios * 100.000',
    source: 'PNAD-C',
  },
  {
    code: '60',
    indicator: 'Cobertura estimada da população sus-dependente pelas equipes da atenção básica',
    calculation_method:
      'Estimativa de cobertura populacional referente ao número de equipes de saúde da família e o número de equipes de atenção básica. (Nº ESF * 3.450) + (Nº AB param. + Nº  Esf equivalentes)*3.000 / população * 100',
    source: 'SCNES e IBGE/e-Gestor da Atenção Básica',
  },
  {
    code: '63',
    indicator:
      'Quantidade de ambientes de cultura, esporte e lazer (biblioteca, museu, teatro, centro cultural, arquivo público, ginásio poliesportivo, cinema, etc.) sob responsabilidade da gestão municipal',
    calculation_method: 'Número absoluto dado pela soma do número de unidades em cada categoria',
    source: 'MUNIC',
  },
  {
    code: '38',
    indicator: 'Taxa de Aprisionamento (Nº de Presos por 100 mil habitantes)',
    calculation_method: 'Número de presos / habitantes * 100.000',
    source: 'INFOPEN',
  },
  {
    code: '39',
    indicator:
      'Percentual de Presos sem condenação (razão entre o total de presos sem condenação e a População prisional)',
    calculation_method: 'Total de presos sem condenação / população prisional * 100',
    source: 'INFOPEN',
  },
  {
    code: '41',
    indicator:
      'Déficit Carcerário (razão entre o número de presos e o número de vagas por estado)',
    calculation_method: 'Número de presos / número de vagas por estado * 100',
    source: 'INFOPEN',
  },
  {
    code: '42',
    indicator: 'Taxa de suicídio no sistema prisional',
    calculation_method: 'Número de suicídios / total de presos * 100.000',
    source: 'INFOPEN',
  },
  {
    code: '43',
    indicator: 'Taxa de fugas do sistema prisional',
    calculation_method: 'Número de fugas / total de presos * 100.000',
    source: 'INFOPEN',
  },
  {
    code: '44',
    indicator:
      'Taxa de incidentes com violência no sistema prisional (violações e/ou homicídios)',
    calculation_method: 'Incidentes com violência / total de presos * 100.000',
    source: 'INFOPEN',
  },
  {
    code: '45',
    indicator: 'Taxa de reclusos que frequentam ensino fundamental ou médio',
    calculation_method:
      'Quantidade de pessoas privadas de liberdade em atividade educacional (ensino fundamental ou médio, tanto à distância quanto presencial) / total da população carcerária',
    source: 'INFOPEN',
  },
  {
    code: '48',
    indicator: 'Existência de unidades de atendimento a adolescentes em conflito com a lei',
    calculation_method: 'Valor binário, existência ou não de unidades de atendimento ao adolescente em conflito com a lei',
    source: 'MUNIC',
  },
  {
    code: '49',
    indicator: 'Quantidade de unidades de atendimento a adolescentes em conflito com a lei',
    calculation_method: 'Número absoluto de unidades de atendimento ao adolescente em conflito com a lei',
    source: 'MUNIC',
  },
  {
    code: '50',
    indicator: 'Taxa de adolescentes no sistema socioeducativo',
    calculation_method: 'Total de adolescentes no sistema socioeducativo /  total de adolescentes * 100.000',
    source: 'SINASE',
  },
  {
    code: '46',
    indicator: 'Municípios que realizam serviços socioassistenciais a encarcerados / egressos do sistema carcerário',
    calculation_method: 
      'Valor binário, prestação ou não de serviços socioassistenciais a encarcerado/egressos do sistema carcerário',
    source: 'MUNIC',
  },
  {
    code: '22',
    indicator: 'Existência de abrigo institucional para mulheres em situação de violência',
    calculation_method: 
      'Valor binário, existência ou não de abrigo institucional para mulheres em situação de violência',
    source: 'MUNIC',
  },
  {
    code: '23',
    indicator: 'Existência de Centro Especializado de Atendimento à Mulher (CEAM)/Centro de Referência e Atendimento à Mulher (CRAM)/Núcleos Integrados de Atendimento às Mulheres em Situação de Violência (NIAM)',
    calculation_method: 
      'Valor binário, prestação ou não de equipamentos especializados de atendimento a mulheres em situação de violência',
    source: 'MUNIC',
  },
  {
    code: '51',
    indicator: 'Efetivo Policial (a cada 100.000 hab)',
    calculation_method:'Número de policiais civis e militares / habitantes * 100.000',
    source: 'Fórum de Segurança Pública',
  },
  {
    code: '52',
    indicator: 'Proporção de presos por funcionário de custódia',
    calculation_method: 'Total de presos / Total de Funcionários de Custódia * 100',
    source: 'INFOPEN',
  },
  {
    code: '53',
    indicator: 'Gasto na função segurança pública (% da despesa total)',
    calculation_method: 'Gastos na função segurança publica / gastos totais do governo estadual * 100',
    source: 'Fórum de Segurança Pública',
  },
  {
    code: '11',
    indicator: 'Taxa de "Homicídios Ocultos" / "Mortes Violentas com Causa Indeterminada" (população total)',
    calculation_method: 'Óbitos cuja causa é indeterminada / população residente * 100.000',
    source: 'DataSUS',
  },
  {
    code: '55',
    indicator: 'Taxa de Cobertura da Rede CRAS (com relação às famílias com menos de 1/2 S.M)',
    calculation_method:
      'Número total de CRAS / famílias inseridas no CadÚnico com rendimento inferior a 1/2 salário mínimo * 5.000. Obs.: Fórmula de cálculo recomendada no "Pacto de Aprimoramento do SUAS" de 2012 (ver mais em: (http://aplicacoes.mds.gov.br/sagi/dicivip_datain/ckfinder/userfiles/files/Boletim_Vigil%C3%A2ncia_04_Pacto de Aprimoramento - Corrigida Tabela 12.pdf - ver página 07)',
    source: 'CadÚnico / IBGE',
  },
  {
    code: '56',
    indicator: 'Taxa de Cobertura da Rede CRAS (com relação ao total de domicílios)',
    calculation_method: 'Número total de CRAS / 20% do Total de domicílios * 5 mil. Obs.: Fórmula de cálculo recomendada no "Pacto de Aprimoramento do SUAS" de 2012 (ver mais em: (http://aplicacoes.mds.gov.br/sagi/dicivip_datain/ckfinder/userfiles/files/Boletim_Vigil%C3%A2ncia_04_Pacto de Aprimoramento - Corrigida Tabela 12.pdf - ver página 07)',
    source: 'CadÚnico / PNAD',
  },
  {
    code: '57',
    indicator: 'Taxa de Cobertura Populacional da Rede CREAS',
    calculation_method: 'Número total de CREAS / população total * 200.000 Obs.: Fórmula de cálculo recomendada no "Pacto de Aprimoramento do SUAS" de 2012 (ver mais em: (http://aplicacoes.mds.gov.br/sagi/dicivip_datain/ckfinder/userfiles/files/Boletim_Vigil%C3%A2ncia_04_Pacto de Aprimoramento - Corrigida Tabela 12.pdf - ver página 09)',
    source: 'CadÚnico / IBGE',
  },
  {
    code: '58',
    indicator:
      'Existência de Equipamentos (CRAS, CREAS, Centro de Referência especializado para a população em situação de rua, centro-dia)',
    calculation_method: 'Valor binário, existência ou não de CRAS, CREAS, Centro de Referência Especializado para População em Situação de Rua, e existência de Centro dia',
    source: 'MUNIC',
  },
  {
    code: '59',
    indicator:
      'Quantidade de Equipamentos (CRAS, CREAS, Centro de Referência especializado para a população em situação de rua, centro-dia)',
    calculation_method: 'Soma do número de unidades de cada tipo',
    source: 'MUNIC',
  },
  {
    code: '88',
    indicator: 'Capacidade de Pagamento (CAPAG)',
    calculation_method:
      ' A metodologia do cálculo* é dada por três indicadores: endividamento, poupança corrente e índice de liquidez. A classificação final da capacidade de pagamento do ente será determinada a partir da combinação das classificações parciais dos três indicadores, podendo ser: A, B, C ou D',
    source: 'Secretaria do Tesouro Nacional (STN)',
  },
];

import styled, { css } from 'styled-components';

export const Label = styled.label`
  display: flex;
  margin-bottom: 5px;
  color: var(--title-highlight);
`;

export const Container = styled.div`
  background: var(--white);
  color: var(--gray2);
  border: 1px solid var(--light-gray2);
  border-radius: 10px;
  padding: 0 0 0 16px;
  width: 100%;
  height: 200px;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.disabled &&
    css`
      background: #d7d9da !important;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: var(--orange);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--orange);
    `}

    ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  
    ${props =>
    props.isCorrect &&
    css`
      border-color: var(--green);
      color: var(--green);
    `}

  textarea {
    color: var(--text);
    flex: 1;
    background: transparent;
    border: 0;
    width: 100%;
    height: 85%;
    font: normal normal normal 16px/20px IBM Plex Sans;

    &::placeholder {
      color: var(--light-gray2);
    }
  }

  label {
    padding-bottom: 15px;
    color: var(--title-higlight);
  }

  svg {
    margin-right: 10px;
  }
`;

export const Error = styled.label`
  font-family: 'Inter', sans-serif;
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: var(--red);
  display: flex;
`;

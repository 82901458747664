import styled from 'styled-components';

export const Container = styled.div`
  p {
    font: normal normal normal 18px/23px IBM Plex Sans;
    text-align: justify;
    letter-spacing: 0px;
    color: #6d6e71;
    width: 100%;
  }

  .imgContainer {
    img {
      padding: 10px 5%;
      width: 100%;
      margin-top: 27px;
    }
  }

  .buttonLink {
    text-align: left;
    font: normal normal bold 12px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    width: 144px;
    height: 40px;

    background: #075e89;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

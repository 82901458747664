import React, { useCallback, useMemo } from 'react';
import GraphicBar from './GraphicBar';

import { Container, LegendContainer } from './styles';

function ExecutiveReportGraphic({ data }) {
  const GetPercentValue = useCallback((number, percentToGet) => {
    return (percentToGet / 100) * number;
  }, []);

  const ChangedData = useMemo(() => {
    const arrLength = data.length;
    const percentValue = GetPercentValue(arrLength, 20);

    const items1 = data
      .slice(0, percentValue)
      .map(item => ({ ...item, color: '#5EB245' }));

    const items2 = data
      .slice(percentValue, percentValue * 2)
      .map(item => ({ ...item, color: '#30E861' }));

    const items3 = data
      .slice(percentValue * 2, percentValue * 3)
      .map(item => ({ ...item, color: '#FFC21D' }));

    const items4 = data
      .slice(percentValue * 3, percentValue * 4)
      .map(item => ({ ...item, color: '#E37100' }));

    const items5 = data
      .slice(percentValue * 4, percentValue * 5)
      .map(item => ({ ...item, color: '#de2b2b' }));

    const returnArr = [...items1, ...items2, ...items3, ...items4, ...items5];

    return returnArr;
  }, [data, GetPercentValue]);

  return (
    <>
      <Container>
        <div className="graphicContainer">
          {ChangedData.map(item => (
            <GraphicBar
              key={item.name}
              percentage={!!item.percent ? item.percent : 0}
              color={item.color}
              title={item.name}
              value={0}
              position={!!item.ranking ? `${item.ranking}º` : 'N/D'}
            />
          ))}
        </div>
        <LegendContainer>
          <div className="legend-item">
            <div className="green1" />
            top 20%
          </div>
          <div className="legend-item">
            <div className="green2" />
            top 40%
          </div>
          <div className="legend-item">
            <div className="yellow" />
            top 60%
          </div>
          <div className="legend-item">
            <div className="orange" />
            top 80%
          </div>
          <div className="legend-item">
            <div className="red" />
            top 100%
          </div>
        </LegendContainer>
      </Container>
    </>
  );
}

export default ExecutiveReportGraphic;

import styled from 'styled-components';

export const Container = styled.div`
  .card3,
  .card4,
  .card5 {
    background: #f8f8f8;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        text-align: center;
        font: normal normal bold 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: var(--dark-blue);
        margin: 15px 5px;
      }

      p {
        text-align: center;
        font: normal normal normal 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        opacity: 1;
      }
    }
  }
`;

export const ManagementGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 23px;
  grid-template-areas:
    'card1 card2 card2'
    'card3 card4 card5';

  .card1 {
    grid-area: card1;
    width: 100%;

    header {
      background: var(--blue);

      h1 {
        text-align: left;
        font: normal normal bold 20px/25px 'IBM Plex Sans';
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    .content {
      .info-container {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        svg {
          margin-right: 8px;
          path {
            fill: var(--gray2);
          }
        }

        span {
          text-align: left;
          font: normal normal normal 14px/30px IBM Plex Sans;
          letter-spacing: 0px;
          color: #6d6e71;

          .green {
            color: var(--green);
          }

          .blue {
            color: var(--blue);
          }
        }
      }

      .bar-container {
        span {
          margin-bottom: 15px;
        }
      }
    }
  }
  .card2 {
    grid-area: card2;
    width: 100%;

    .content {
      position: relative;
      height: 100%;

      h1 {
        text-align: left;
        font: normal normal bold 29px/23px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        margin-bottom: 30px;
        line-height: 1.1;
      }

      p {
        text-align: left;
        font: normal normal normal 18px/23px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
      }

      button {
        bottom: 18px;
        margin-top: 20px;
      }
    }
  }
  .card3 {
    grid-area: card3;
    width: 100%;
    background: #f8f8f8;
    cursor: pointer;
  }
  .card4 {
    grid-area: card4;
    width: 100%;
    background: #f8f8f8;
    cursor: pointer;
  }
  .card5 {
    grid-area: card5;
    width: 100%;
    background: #f8f8f8;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'card1'
      'card2'
      'card3'
      'card4'
      'card5';

    .card1 {
      min-height: inherit;
    }
  }
`;

export const Card = styled.section`
  background: var(--white);
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px;
  overflow: hidden;

  header {
    padding: 10px 16px;
  }

  .content {
    padding: 15px;
  }
`;

export const StatusOperation = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.structuring ? 'var(--red)' : 'var(--green)')};
  margin-bottom: 20px;

  font: normal normal normal 12px/15px IBM Plex Sans;
  letter-spacing: 0px;

  svg {
    margin-right: 5px;
  }
`;

import React, { createContext, useCallback, useState, useContext } from 'react';

const LoaderContext = createContext({});

const LoaderProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const activeLoader = useCallback(active => {
    setIsActive(active);
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        isActive,
        activeLoader,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

function useLoader() {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader só pode ser usado com um LoaderProvider');
  }

  return context;
}

export { LoaderProvider, useLoader };

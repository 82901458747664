import styled from 'styled-components';

const ColorsVariations = {
  gestao: '#10385c',
  policiamento: '#891f1f',
  prevencao_social: '#bd9312',
  prevencao_situacional: '#ac5201',
  reinsercao_social: '#520a54',
  justica_criminal: '#1c6456',
};

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 79px;
  justify-content: center;
  padding: 0 20px;

  background: ${props =>
    props.area ? `${ColorsVariations[props.area]}` : 'gray'};

  span {
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #f8f8f8;
  }

  h1 {
    font: normal normal bold 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #f8f8f8 !important;
  }
`;

export const DeliverableContainer = styled.div`
  background: #f1f1f1;
  h1 {
    text-align: left;
    font: normal normal bold 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    margin-bottom: 20px;

    background: #f1f1f1;
    color: ${props =>
      props.area ? `${ColorsVariations[props.area]}` : 'gray'};
  }
`;

export const Deliverable = styled.div`
  background: #f1f1f1;
  margin-top: 5px;
  h1 {
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    margin-bottom: 15px;
    font-size: 19px;
  }
  h2 {
    text-align: left;
    font: normal normal bold 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }
`;

export const EstimativaContainer = styled.div`
  margin-bottom: 20px !important;
  span {
    color: ${props =>
      props.area ? `${ColorsVariations[props.area]}` : 'gray'};
    font: normal normal normal 20px/25px IBM Plex Sans;
    font-weight: bold;
  }
`;

export const ValueInput = styled.div`
  height: 58px;

  display: flex;
  align-items: center;
  border: 0;
  padding: 15px 10px;
  color: #6d6e71;
  font: normal normal bold 25px/33px IBM Plex Sans;

  text-align: left !important;

  color: ${props => (props.area ? `${ColorsVariations[props.area]}` : 'gray')};
  border: ${props =>
    props.area
      ? `2px solid ${ColorsVariations[props.area]}`
      : '2px solid gray'};
`;

import React, { useRef, useEffect, useCallback, useState } from 'react';
import * as Yup from 'yup';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

const Select = ({ name, label, schema, isMulti, onChange, ...rest }) => {
  const selectRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        selectRef.current.state.value?.value,
        { abortEarly: true },
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Container
        isErrored={!!error}
        isFocused={isFocused}
        isCorrect={isCorrect}
      >
        <ReactSelect
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          isMulti={isMulti}
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          ref={selectRef}
          classNamePrefix="react-select"
          onChange={onChange}
          {...rest}
        />
      </Container>
      {!!error && <Error>{error}</Error>}
    </>
  );
};
export default Select;

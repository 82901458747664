import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { Container, BarItem, InfoLabel, GraphContainer } from './styles';

function MultiPercentageBar({ data, financial }) {
  const [graphData, setGraphData] = useState(data);
  const isFloat = useCallback(x => !!(x % 1), []);

  useEffect(() => {
    setGraphData(data);
  }, []);

  const changedData = useMemo(() => {
    const returnData = graphData.map(item => {
      let a = {};
      if (item.hasOwnProperty('agreed_result')) {
        a = {
          ...item,
          graphData: [
            {
              percentage: isFloat(item.agreed_result.finish)
                ? item.agreed_result.finish.toFixed(0)
                : item.agreed_result.finish,
              color: '#5CB24B',
            },
            {
              percentage: isFloat(item.agreed_result.no_start)
                ? item.agreed_result.no_start.toFixed(0)
                : item.agreed_result.no_start,
              color: '#C1856F',
            },
            {
              percentage: isFloat(item.agreed_result.start)
                ? item.agreed_result.start.toFixed(0)
                : item.agreed_result.start,
              color: '#F7A643',
            },
          ],
        };
      }
      if (item.hasOwnProperty('entregables')) {
        a = {
          ...item,
          graphData: [
            {
              percentage: isFloat(item.entregables.finish)
                ? item.entregables.finish.toFixed(0)
                : item.entregables.finish,
              color: '#5CB24B',
            },
            {
              percentage: isFloat(item.entregables.no_start)
                ? item.entregables.no_start.toFixed(0)
                : item.entregables.no_start,
              color: '#C1856F',
            },
            {
              percentage: isFloat(item.entregables.start)
                ? item.entregables.start.toFixed(0)
                : item.entregables.start,
              color: '#F7A643',
            },
          ],
        };
      }

      if (item.hasOwnProperty('executed')) {
        a = {
          ...item,
          graphData: [
            {
              percentage: isFloat(item.executed)
                ? item.executed.toFixed(0)
                : item.executed,
              color: '#5CB24B',
            },
          ],
        };
      }
      return a;
    });
    return returnData;
  }, [graphData, isFloat]);

  return (
    <>
      <Container>
        {changedData.map(item => (
          <GraphContainer className="graphContainer">
            <div className="nameContainer">{item.name}</div>
            <div className="barContainer">
              <>
                {item.graphData.map(graph => (
                  <>
                    {graph.percentage > 0 && (
                      <BarItem
                        color={graph.color}
                        percentage={graph.percentage}
                        key={graph.name}
                      >
                        <span>{`${
                          graph.percentage > 0 ? graph.percentage : ''
                        }`}</span>
                      </BarItem>
                    )}
                  </>
                ))}
              </>
            </div>
          </GraphContainer>
        ))}
        <div className="info-labels">
          <>
            {!financial && (
              <>
                <InfoLabel color="#5CB24B">
                  <div />
                  Concluido
                </InfoLabel>
                <InfoLabel color="#F7A643">
                  <div />
                  Execução parcial
                </InfoLabel>
                <InfoLabel color="#C1856F">
                  <div />A iniciar
                </InfoLabel>
              </>
            )}
            {financial && (
              <InfoLabel color="#5CB24B">
                <div />
                Executado (Pago)
              </InfoLabel>
            )}
          </>
        </div>
      </Container>
    </>
  );
}

export default MultiPercentageBar;

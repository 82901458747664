/* eslint-disable */
import React, { useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container } from './styles';

const AddTargetAudience = ({
  show,
  handleClose,
  handleSuccess,
  creditOperation,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const schema = Yup.object().shape({
    target_public: Yup.string().required('Público alvo é obrigatório'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const response = await api.patch(
          `/credits/api/v1/opcredit-manager/${creditOperation.code}/`,
          {
            target_public: data.target_public,
          },
        );
        handleSuccess(response.data);
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, handleSuccess, creditOperation.code],
  );

  return (
    <Container>
      <Modal
        title="Adicionar Público Alvo"
        success={success}
        successText="Publico Alvo Adicionado com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="target_public"
                className="publico_alvo"
                placeholder="Público Alvo"
                defaultValue={creditOperation.target_public}
                schema={schema}
              />
              <button type="submit">Salvar</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddTargetAudience;

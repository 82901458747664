import React, { useRef, useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { isBefore, isAfter, isEqual } from 'date-fns';
import { Form } from '@unform/web';
import DashboardLayout from '../../components/DashboardLayout';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import InputCurrency from '../../components/InputCurrency';
import { Container, BackButton, ProgramationContainer } from './styles';

const FinancialExecutionAdmin = () => {
  const TableFormRef = useRef(null);
  const TableFormRef2 = useRef(null);
  const TableFormRef3 = useRef(null);
  const TableFormRef4 = useRef(null);
  const { goBack } = useHistory();
  const { operationCode } = useParams();
  const [execution, setExecution] = useState([]);
  const [creditOperation, setCreditOperation] = useState({});
  const [programming, setProgramming] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [endYear, setEndYear] = useState('0');
  const [initYear, setInitYear] = useState('');

  const calculateYearsBetween = useCallback((startDate, endDate) => {
    const startYear = startDate.getYear() + 1900,
      endYear = endDate.getYear() + 1900,
      output = [];
    for (var year = startYear; year <= endYear; year++) output.push(year);
    return output;
  }, []);

  const generateProgramming = useCallback(
    (initYear, deadlineYear) => {
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadlineYear}`),
      );
      const programmingArray = years.map(year => {
        return {
          year: year,
          january: { value: 0, year },
          february: { value: 0, year },
          march: { value: 0, year },
          april: { value: 0, year },
          may: { value: 0, year },
          june: { value: 0, year },
          july: { value: 0, year },
          august: { value: 0, year },
          september: { value: 0, year },
          october: { value: 0, year },
          november: { value: 0, year },
          dezembro: { value: 0, year },
        };
      });
      setProgramming([...programmingArray]);
    },
    [calculateYearsBetween],
  );

  const generateEditProgramming = useCallback(
    (initYear, deadlineYear, programmingData) => {
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadlineYear}`),
      );
      const programmingArray = years.map(year => {
        const filteredYear = programmingData.filter(
          p => p.year === Number(year),
        )[0];
        return {
          year: year,
          january: !!filteredYear ? filteredYear.jan : 0,
          february: !!filteredYear ? filteredYear.feb : 0,
          march: !!filteredYear ? filteredYear.mar : 0,
          april: !!filteredYear ? filteredYear.apr : 0,
          may: !!filteredYear ? filteredYear.may : 0,
          june: !!filteredYear ? filteredYear.jun : 0,
          july: !!filteredYear ? filteredYear.jul : 0,
          august: !!filteredYear ? filteredYear.aug : 0,
          september: !!filteredYear ? filteredYear.sep : 0,
          october: !!filteredYear ? filteredYear.oct : 0,
          november: !!filteredYear ? filteredYear.nov : 0,
          dezembro: !!filteredYear ? filteredYear.dec : 0,
        };
      });
      return programmingArray;
    },
    [calculateYearsBetween],
  );

  const getYearData = useCallback((data, year, credit_operation) => {
    const rename = {
      [`january${year}`]: 'jan',
      [`february${year}`]: 'feb',
      [`march${year}`]: 'mar',
      [`april${year}`]: 'apr',
      [`may${year}`]: 'may',
      [`june${year}`]: 'jun',
      [`july${year}`]: 'jul',
      [`august${year}`]: 'aug',
      [`september${year}`]: 'sep',
      [`october${year}`]: 'oct',
      [`november${year}`]: 'nov',
      [`dezembro${year}`]: 'dec',
    };
    var filteredYear = Object.keys(data).reduce((res, key) => {
      if (key.indexOf(year) !== -1) {
        res[rename[key]] = !!data[key] ? data[key] : 0;
      }
      return res;
    }, {});
    return { year, credit_operation, ...filteredYear };
  }, []);

  useEffect(() => {
    api.get('/credits/api/v1/opcredit-manager/').then(response => {
      const filteredOperation = response.data.results.filter(
        op => op.code === operationCode,
      )[0];
      setCreditOperation(filteredOperation);
    });

    const executionUrl = `/credits/api/v1/opcredit-manager/${operationCode}/execution-finance/`;
    api.get(executionUrl).then(response => {
      setExecution(response.data.results);

      const filteredFinance = response.data.results.filter(
        fin => fin.code === operationCode,
      );

      if (filteredFinance.length) {
        const expected = generateEditProgramming(
          initYear,
          endYear,
          filteredFinance[0].expected,
        );

        const settled = generateEditProgramming(
          initYear,
          endYear,
          filteredFinance[0].settled,
        );

        const pawned = generateEditProgramming(
          initYear,
          endYear,
          filteredFinance[0].pawned,
        );

        const paid = generateEditProgramming(
          initYear,
          endYear,
          filteredFinance[0].paid,
        );

        const finance = {
          code: operationCode,
          expected,
          settled,
          pawned,
          paid,
        };
        setExecution(finance);
      } else {
        generateProgramming(generateEditProgramming, endYear);
      }
    });
  }, [
    generateProgramming,
    endYear,
    initYear,
    generateEditProgramming,
    operationCode,
  ]);

  useEffect(() => {
    if (!!Object.keys(creditOperation).length) {
      setInitYear(creditOperation.start_date.split('-')[0]);
      setEndYear(creditOperation.end_date.split('-')[0]);
    }
  }, [creditOperation]);

  const handleSave = useCallback(
    async code => {
      const expectedTableData = TableFormRef.current.getData();
      const settledTableData = TableFormRef2.current.getData();
      const pawnedTableData = TableFormRef3.current.getData();
      const paidTableData = TableFormRef4.current.getData();

      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${endYear}`),
      );

      const expectedFilteredYears = years.map(year =>
        getYearData(expectedTableData, year, code),
      );
      const settledFilteredYears = years.map(year =>
        getYearData(settledTableData, year, code),
      );
      const pawnedFilteredYears = years.map(year =>
        getYearData(pawnedTableData, year, code),
      );
      const paidFilteredYears = years.map(year =>
        getYearData(paidTableData, year, code),
      );

      const postData = {
        code,
        expected: expectedFilteredYears,
        settled: settledFilteredYears,
        pawned: pawnedFilteredYears,
        paid: paidFilteredYears,
      };

      try {
        setLoadingSuccess(true);
        setShowSuccess(false);
        const url = `/credits/api/v1/opcredit-manager/${code}/execution-finance/${code}/`;
        const response = await api.patch(url, postData);
        const expected = generateEditProgramming(
          endYear,
          response.data.expected,
        );
        const settled = generateEditProgramming(
          initYear,
          endYear,
          response.data.settled,
        );
        const pawned = generateEditProgramming(
          initYear,
          endYear,
          response.data.pawned,
        );
        const paid = generateEditProgramming(
          initYear,
          endYear,
          response.data.paid,
        );
        const finance = {
          code,
          expected,
          settled,
          pawned,
          paid,
        };
        setExecution(finance);
        setLoadingSuccess(false);
        setShowSuccess(true);
      } catch (err) {
        console.error(err);
      }
    },
    [calculateYearsBetween, endYear, initYear, getYearData],
  );

  const handleDisabled = useCallback((initDate, endDate, month, year) => {
    const actualDate = new Date(`${month} 1 ${year}`);
    const initIsBefore = isBefore(actualDate, new Date(initDate));
    const endIsAfter = isAfter(actualDate, new Date(endDate));
    const initMonth = new Date(initDate).getMonth() + 1;
    const isEqualInit = isEqual(
      actualDate,
      new Date(`${initMonth} 1 ${initDate.split('-')[0]}`),
    );
    return (initIsBefore || endIsAfter) && !isEqualInit ? true : false;
  }, []);

  const columns = [
    {
      name: 'Ano',
      selector: 'year',
    },
    {
      name: 'Jan',
      selector: 'january',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Jan',
            row.year,
          )}
          name={`january${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.january === 0 ? null : row.january}
        />
      ),
    },
    {
      name: 'Fev',
      selector: 'february',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Feb',
            row.year,
          )}
          name={`february${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.february === 0 ? null : row.february}
        />
      ),
    },
    {
      name: 'Mar',
      selector: 'march',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Mar',
            row.year,
          )}
          name={`march${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.march === 0 ? null : row.march}
        />
      ),
    },
    {
      name: 'Abr',
      selector: 'april',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Apr',
            row.year,
          )}
          name={`april${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.april === 0 ? null : row.april}
        />
      ),
    },
    {
      name: 'Mai',
      selector: 'may',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'May',
            row.year,
          )}
          name={`may${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.may === 0 ? null : row.may}
        />
      ),
    },
    {
      name: 'Jun',
      selector: 'june',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Jun',
            row.year,
          )}
          name={`june${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.june === 0 ? null : row.june}
        />
      ),
    },
    {
      name: 'Jul',
      selector: 'july',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Jul',
            row.year,
          )}
          name={`july${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.july === 0 ? null : row.july}
        />
      ),
    },
    {
      name: 'Ago',
      selector: 'august',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Aug',
            row.year,
          )}
          name={`august${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.august === 0 ? null : row.august}
        />
      ),
    },
    {
      name: 'Set',
      selector: 'september',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Sep',
            row.year,
          )}
          name={`september${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.september === 0 ? null : row.september}
        />
      ),
    },
    {
      name: 'Out',
      selector: 'october',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Oct',
            row.year,
          )}
          name={`october${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.october === 0 ? null : row.october}
        />
      ),
    },
    {
      name: 'Nov',
      selector: 'november',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Nov',
            row.year,
          )}
          name={`november${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.november === 0 ? null : row.november}
        />
      ),
    },
    {
      name: 'Dez',
      selector: 'dezembro',
      minWidth: '210px',
      cell: row => (
        <InputCurrency
          disabled={handleDisabled(
            creditOperation.start_date,
            creditOperation.end_date,
            'Dec',
            row.year,
          )}
          name={`dezembro${row.year}`}
          placeholder="Digite o valor"
          defaultValue={row.dezembro === 0 ? null : row.dezembro}
        />
      ),
    },
  ];

  return (
    <Container>
      <DashboardLayout title="Execução Financeira">
        <>
          <BackButton onClick={() => goBack()}>Voltar</BackButton>
          <Form ref={TableFormRef} onSubmit={handleSave}>
            <ProgramationContainer readonly>
              <h1>Previsto :</h1>
              <DataTable
                columns={columns}
                data={execution.expected}
                noDataComponent=""
                noHeader
              />
            </ProgramationContainer>
          </Form>
          <Form ref={TableFormRef2} onSubmit={handleSave}>
            <ProgramationContainer readonly>
              <h1>Empenhado :</h1>
              <DataTable
                columns={columns}
                data={execution.settled}
                noDataComponent=""
                noHeader
              />
            </ProgramationContainer>
          </Form>
          <Form ref={TableFormRef3} onSubmit={handleSave}>
            <ProgramationContainer readonly>
              <h1>Liquidado :</h1>
              <DataTable
                columns={columns}
                data={execution.pawned}
                noDataComponent=""
                noHeader
              />
            </ProgramationContainer>
          </Form>
          <Form ref={TableFormRef4} onSubmit={handleSave}>
            <ProgramationContainer readonly>
              <h1>Pago :</h1>
              <DataTable
                columns={columns}
                data={execution.paid}
                noDataComponent=""
                noHeader
              />
            </ProgramationContainer>
          </Form>
        </>
      </DashboardLayout>
    </Container>
  );
};

export default FinancialExecutionAdmin;

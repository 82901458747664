import styled from 'styled-components';

export const Container = styled.div`
  .info-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  div {
    width: 15px;
    height: 15px;
    background: gray;
    margin-right: 5px;
  }
`;

import React, { useState, useEffect } from 'react';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';
import Slider from './components/Slider';
import InitSection from './components/InitSection';
import ResultAreas from './components/ResultAreas';
import HowAccess from './components/HowAccess';
import WhoCanAccessSection from './components/WhoCanAccessSection';

import { Container, MainContent } from './styles';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={true}>
          <Container>
            <MainContent>
              <Slider />
              <div className="content">
                <InitSection />
              </div>
              <div className="imageWrapper">
                <div className="imageContent">
                  <ResultAreas />
                </div>
              </div>
              <div className="grayWrapper">
                <div className="grayContent">
                  <WhoCanAccessSection />
                </div>
              </div>
              <div className="content">
                <HowAccess />
              </div>
            </MainContent>
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default Home;

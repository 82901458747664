import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    margin-bottom: 0px;
  }

  .info-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  div {
    width: 15px;
    height: 15px;
    background: ${props => (props.color ? `var(--${props.color})` : 'gray')};
    margin-right: 5px;
  }
`;

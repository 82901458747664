import React, { useState, useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { FaTrash } from 'react-icons/fa';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
  DeleteContainer,
} from './styles';

const Accordion = ({ title, hasDelete, deleteCallBack, children, faq }) => {
  const [active, setActive] = useState(false);

  const handleToggle = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <Container faq={faq}>
      <AccordionItem onClick={handleToggle} faq={faq}>
        <InitialContainer active={active} faq={faq}>
          <div className="text-container">
            <div>
              <h1>{title}</h1>
              <button type="button" className="icon-container">
                <div className="arrowIcon">
                  <IoIosArrowForward size={25} />
                </div>
                <DeleteContainer
                  className="delete"
                  onClick={() => deleteCallBack(true)}
                  show={hasDelete}
                >
                  <FaTrash size={20} color="#FD5454" />
                </DeleteContainer>
              </button>
            </div>
          </div>
        </InitialContainer>
      </AccordionItem>
      <CollapseContainer active={active} faq={faq}>
        {children}
      </CollapseContainer>
    </Container>
  );
};

export default Accordion;

import React from 'react';

import Arte from '../../../../../assets/img_objetivo_2.png';
import { Container, Content } from './styles';

function Proseg() {
  return (
    <Container>
      <h1>O PROSEG Federativo</h1>
      <Content>
        <div>
          <p>
            O Programa Federativo para Segurança Pública Inteligente (PROSEG
            Federativo) é fruto de uma parceria entre o Banco Interamericano de
            Desenvolvimento (BID) e o Banco Nacional de Desenvolvimento
            Econômico e Social (BNDES), que tem por objetivo facilitar o acesso
            de municípios e estados brasileiros a soluções técnicas e
            financeiras relevantes à alavancagem da qualidade dos projetos de
            segurança baseada em evidências no Brasil.
          </p>
          <p>
            Através dessa parceria, mobilizou-se uma linha de crédito de US$ 180
            milhões, que estará disponível a entes federativos aderentes aos
            critérios de eligibilidade (ver abaixo), tendo em vista o
            financiamento de projetos de entre US$ 2 milhões e US$ 20 milhões.
            Após cadastro e acesso à área restrita, disponibilizam-se soluções
            digitais orientadas à geração de diagnósticos aprofundados e à
            construção de uma pré-proposta de financiamento que poderá ser
            apresentada às agências mutuantes para início dos trâmites
            administrativos.
          </p>
        </div>
        <div className="img-wrapper">
          <img
            src={Arte}
            alt="Pessoas reunidas em uma mesa com vários papeis espalhados em cima dela, uma pessoa segura um tablet com imagens de gráficos."
          />
        </div>
      </Content>
    </Container>
  );
}

export default Proseg;

import React, { useState, useCallback, useEffect } from 'react';
import ModalLoader from '../../../../components/ModalLoader';
import DataTable from 'react-data-table-component';
import Modal from '../../../../components/Modal';
import SuccessModal from '../../../../components/SuccessModal';
import api from '../../../../services/api';
import { Container, StatusSpan, SearchButton } from './styles';

const PendingRestrictions = ({
  show,
  handleClose,
  criticals,
  handleSuccess,
  isPendingRestrictionsFinish
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const [data, setData] = useState([]);

  const getStatusColor = color => {
    const status = {
      inProgress: 'blue',
      done: 'green',
    };
    return status[color];
  };

  const getStatusName = name => {
    const status = {
      inProgress: 'Em progresso',
      done: 'Concluído',
    };
    return status[name];
  };

  const handleFinish = useCallback(async (id, criticalId) => {
    try {
      setLoadingSuccess(true);
      setShowSuccess(true);
      const url = `/credits/api/v1/critical/${criticalId}/restriction/${id}/`;
      await api.patch(url, { status: 'done' });
      setLoadingSuccess(false);
      handleSuccess({});
      getData();
    } catch (err) {
      console.error(err);
    }
  }, []);

  const [ columns, setColumns ] = React.useState([])

  React.useEffect(() => {
    let arr = [
      {
        name: 'Restrição',
        selector: 'restriction',
        minWidth: '0px',
      },
      {
        name: 'Providencia',
        selector: 'providence',
        minWidth: '0px',
      },
      {
        name: 'Marco Crítico',
        selector: 'comments',
        minWidth: '0px',
      },
      {
        name: 'Ponto Focal',
        selector: 'responsible',
        minWidth: '200px',
        cell: row => (
          <div>
            <b>Nome:</b>
            {row.responsible.name}
            <br />
            {/* <b>Orgão:</b>
            {row.responsible.org}
            <br /> */}
            <b>Contato:</b>
            {row.responsible.email}
          </div>
        ),
      },
      {
        name: 'Prazo',
        selector: 'deadline',
        minWidth: '0px',
        maxWidth: '120px',
      },
      {
        name: 'Status',
        selector: 'status',
        minWidth: '0px',
        maxWidth: '180px',
        cell: row => (
          <StatusSpan color={getStatusColor(row.status)}>
            {getStatusName(row.status)}
          </StatusSpan>
        ),
      },
    ]
    if (isPendingRestrictionsFinish) {
      arr.push({
        name: 'Gestão',
        selector: 'id ',
        maxWidth: '120px',
        cell: row => (
          <SearchButton onClick={() => handleFinish(row.id, row.critical)}>
            Finalizar
            {/* <FaSearch size={18} color="#ffffff" /> */}
          </SearchButton>
        ),
      })
    }
    setColumns(arr)
  }, [handleFinish, isPendingRestrictionsFinish])

  const getData = useCallback(async () => {
    const unresolvedPromises = criticals.map(async crit => {
      const url = `/credits/api/v1/critical/${crit.id}/restriction/`;
      const restrictions = await api.get(url);

      const filteredRestrictions = restrictions.data.results.filter(
        crit => crit.status !== 'done',
      );
      if (filteredRestrictions.length) {
        const retorno = filteredRestrictions.map(rest => {
          const deadline = rest.deadline_providence.split('-');
          return {
            restriction: rest.restriction,
            providence: rest.providence,
            comments: crit.name,
            responsible: {
              name: rest.name,
              email: rest.email,
            },
            deadline: `${deadline[2]}/${deadline[1]}/${deadline[0]}`,
            status: rest.status,
            id: rest.id,
            critical: crit.id,
          };
        });
        return retorno;
      } else {
        return [];
      }
    });
    const promisesResponse = await Promise.all(unresolvedPromises);
    const concatArray = [].concat.apply([], [...promisesResponse]);
    setData(concatArray);
  }, [criticals]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Modal
        title="Restrições Pendentes"
        success={success}
        successText="Marco crítico Concluido com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={data}
              noDataComponent="Nenhuma Restrição"
              noHeader
            />
            {showSuccess && (
              <SuccessModal
                loading={loadingSuccess}
                handleClose={() => setShowSuccess(false)}
                show={showSuccess}
                successText="Restrição Finalizada Com Sucesso!!"
              />
            )}
          </>
        )}
      </Modal>
    </Container>
  );
};

export default PendingRestrictions;

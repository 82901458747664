import styled from 'styled-components';

const ColorsVariations = {
  gestao: '#10385c',
  policiamento: '#891f1f',
  prevencao_social: '#bd9312',
  prevencao_situacional: '#ac5201',
  reinsercao_social: '#520a54',
  justica_criminal: '#1c6456',
};

export const Container = styled.div`
  .rdt_Table {
    margin-bottom: 20px !important;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: ${props =>
      props.area ? `${ColorsVariations[props.area]}` : 'gray'} !important;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 44px;

    & > div {
      white-space: inherit;
    }
  }
`;

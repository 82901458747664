import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex;
  align-items: center; */

  position: relative;
  margin-top: 25px;

  & > div {
    flex: 1;
    height: 21px;
    border-radius: 4px;
    background: var(--gray);
    position: relative;
    transition: all 0.5s ease;
    display: flex;
  }

  .info-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background: #ffffff;
    flex-wrap: wrap;
  }
`;

export const BarItem = styled.div`
  width: ${props => props.percentage && `${props.percentage}%`};
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--blue)`};

  height: 21px;
  transition: all 0.5s ease;

  span {
    display: flex;
    align-self: center;
    justify-content: center;
    transform: translateY(-20px);

    font: normal normal bold 16px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => (props.color ? `var(--${props.color})` : `var(--blue)`)};
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  div {
    width: 15px;
    height: 15px;
    background: ${props => (props.color ? `var(--${props.color})` : 'gray')};
    margin-right: 5px;
  }
`;

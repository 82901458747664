import styled from 'styled-components';

export const Container = styled.div`
  .modal-body {
    form {
      display: flex;
      flex-direction: column;
      button {
        align-self: flex-end;
        background: #5eb245;
        border-radius: 9px;

        color: #ffffff;
        font-weight: 600;

        width: 109px;
        height: 50px;
        margin-top: 20px;
      }
    }
  }
`;

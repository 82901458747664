import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background: ${props => (props.transparent ? 'rgba(0,0,0,0.3)' : '#0d374e')};
  z-index: 4;
  height: 97.688px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 6px #00000029;

  .react-select__control {
    background: #f8f8f8;
    border: 0;
  }

  .react-select__placeholder,
  .react-select__single-value {
    font-size: 18px;
    color: #757575;
  }

  .react-select__menu {
    .react-select__group-heading {
      color: #495057;
      background: #f8f8f8;
      font-weight: 700;
    }
  }

  .react-select__loading-indicator {
    font-size: 10px !important;
    svg {
      path {
        /* fill: rgb(29, 94, 137) !important; */
      }
    }
  }

  .react-select__indicator {
    font-size: 24px;
    svg {
      path {
        /* fill: rgb(29, 94, 137); */
      }
    }
  }

  .react-select__placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div`
  display: flex;

  .LogoContainer {
    img {
      width: 160px;
      cursor: pointer;
    }
  }

  .ItensContainer {
    display: flex;
    align-items: center;
    width: 100%;
    .navbar {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      list-style: none;
      align-items: center;
      .navbarItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        padding: 5px;

        svg {
          margin-right: 5px;
        }

        .dropdownButton {
          font-weight: 700;
          display: flex;
          align-items: center;

          svg {
            margin-left: 3px;
            margin-right: 5px;
          }
        }

        &:hover {
          background: #fff;
          color: #0d374e;
          border-radius: 8px;

          .dropdownButton {
            color: #0d374e;
          }
        }
      }
      .buttonNav {
        background: #00a9ee 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        font-size: 14px;
        font-family: IBM Plex Sans;
        font-weight: 500;
        padding: 9px;
        color: #fff;
        display: flex;
        align-items: center;
        height: 35px;

        &:hover {
          background: #fff;
          color: #0d374e;
        }

        svg {
          margin-left: 1px;
          font-size: 20px;
        }
      }
    }
  }

  .mobileMenuButton {
    width: 42px;
    height: 42px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #6d6d6d;
    border-radius: 0.25rem;
  }

  @media only screen and (max-width: 855px) {
    justify-content: space-between;
    align-items: center;

    .ItensContainer {
      display: none;
    }

    .mobileMenuButton {
      display: flex;
    }
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  max-height: 65.688px;
  color: #fff;

  @media only screen and (max-width: 1141px) {
    padding: 0 25px;
  }
`;

export const SearchBox = styled.div`
  width: 100%;
  height: 360px;
  position: absolute;
  top: 98px;
  font: medium 14px IBM Plex Sans;
  background-color: rgba(0, 169, 238, 0.92);
  opacity: 0;
  transition: all 0.3s ease;
  display: none;
  align-items: center;
  justify-content: center;

  .searchBoxContainer {
    max-width: 1140px;
    h1 {
      color: #fff;
      font-size: 24px;
      font-family: 'IBM Plex Sans', sans-serif;
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 1141px) {
    .searchBoxContainer {
      padding: 0 25px;
    }
  }

  ${props =>
    props.active &&
    css`
      opacity: 1;
      display: flex;
    `};
`;

export const MobileMenu = styled.div`
  .mobileMenuContainer {
    width: 100%;

    height: 0;
    padding: 0 25px;
    display: none;
    position: absolute;
    z-index: 0;
    top: 97.688px;
    overflow-y: hidden;
    background: ${props => (props.transparent ? 'transparent' : '#0d374e')};
    transition: height 0.3s ease-in-out;

    .navbar {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      list-style: none;

      .navbarItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        padding: 9px 0;
        color: #ffff;
        font-size: 15px;

        .dropdownButton {
          font-weight: 700;
          font-size: 15px;
          padding-left: 0;
          display: flex;
          align-items: center;

          svg {
            margin-left: 3px;
            font-size: 10px;
          }
        }

        &:hover {
          background: #fff;
          color: #0d374e;
          border-radius: 8px;

          .dropdownButton {
            color: #0d374e;
          }
        }
      }
      .buttonNav {
        background: #00a9ee 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        font-size: 14px;
        font-family: IBM Plex Sans;
        font-weight: 500;
        padding: 9px;
        color: #fff;
        display: flex;
        align-items: center;
        height: 35px;
        width: 227px;

        &:hover {
          background: #fff;
          color: #0d374e;
        }

        svg {
          margin-left: 1px;
          font-size: 20px;
        }
      }
    }

    ${props =>
      props.active &&
      css`
        height: 215px;
        z-index: 2;
      `};
  }

  @media only screen and (max-width: 855px) {
    justify-content: space-between;
    align-items: center;

    .mobileMenuContainer {
      display: flex;
    }
  }
`;

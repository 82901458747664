import styled from 'styled-components';

export const Container = styled.div`
  .modal-main {
    max-width: 768px;
  }

  .modal-header {
    height: 0px !important;
    span {
      display: none;
    }
  }

  .modal-body {
    padding: 0px !important;
  }

  .detailContainer {
    padding: 24px 16px;

    h5 {
      font-weight: 700 !important;
      font-size: 20px;
      color: #212529;
      margin-bottom: 15px;
    }

    h1 {
      color: #004e70;
      font-weight: 700 !important;
    }

    .textContainer {
      margin-top: 24px;

      label {
        font-family: 'IBM Plex Sans', sans-serif !important;
        color: #004e70;
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .loginContainer {
      .formContainer {
        width: 100%;
      }

      .infoContainer {
        display: none;
      }
    }
  }
`;

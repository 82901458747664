import React, { useEffect } from 'react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';
import {  FaInfoCircle } from 'react-icons/fa';
import {
  Container,
  Title,
  TitleText,
  Card,
  IndicatorCard,
  IndicatorContainer,
  GraphContainer,
  Graph,
  SideBar,
  Bar,
  GradientBar,
  IconContainer,
} from './styles';

function Page1({ data, printing }) {
  useEffect(() => {
    HTMLCollection.prototype.map = Array.prototype.map;
    const textContainers = document.getElementsByClassName('TextContainer');
    textContainers.map(el => console.log(el))


    textContainers.map(el => {
      // el.childNodes[3].setAttribute(
      //   'title',
      //   'foram considerados como tendo performances positivas os indicadores em que o estado/município teve trajetória de melhora no período de referência (máx. 05 anos), e no qual a performance foi equivalente ou superior aos referenciais comparativos no último ano da série (médias nacional e regional/estadual)',
      // );

      let span = document.createElement("span");
    
      span.innerHTML = "<img src='/static/media/circle-info-solid.svg' style='vertical-align: middle;' width='15' /> &nbsp;"
      
      span.setAttribute(
        'title',
        'foram considerados como tendo performances positivas os indicadores em que o estado/município teve trajetória de melhora no período de referência (máx. 05 anos), e no qual a performance foi equivalente ou superior aos referenciais comparativos no último ano da série (médias nacional e regional/estadual)',
      );

      el.childNodes[3].prepend(span)

      let span2 = document.createElement("span");
    
      span2.innerHTML = "<img src='/static/media/circle-info-solid.svg' style='vertical-align: middle;' width='15' /> &nbsp;"

      span2.setAttribute(
        'title',
        'foram considerados como tendo performances negativas os indicadores em que o estado/município teve trajetória de piora no período de referência (máx. 05 anos), e no qual a performance foi inferior aos referenciais comparativos no último ano da série (médias nacional e regional/estadual)',
      );

      el.childNodes[4].prepend(span2)

      // el.childNodes[4].setAttribute(
      //   'title',
      //   'foram considerados como tendo performances negativas os indicadores em que o estado/município teve trajetória de piora no período de referência (máx. 05 anos), e no qual a performance foi inferior aos referenciais comparativos no último ano da série (médias nacional e regional/estadual)',
      // );
      return el;
    });
  }, []);

  return (
    <Container>
      <Title>Principais destaques</Title>
      <TitleText>
      Esta seção apresenta uma análise da performance do seu estado/município em relação a alguns referenciais (média brasileira, média regional etc.). Você também tem acesso a uma visão geral do desempenho dos indicadores e pode ver os cinco Melhores e Piores resultados do seu estado/município.
      </TitleText>

      {data?.category.map(category => (
        <Card printing={printing}>
          <IndicatorContainer>
            <IndicatorCard
              color={
                category.category_name === 'Criminalidade e Violência'
                  ? '#0c9fd8'
                  : category.category_name === 'Desempenho Institucional'
                  ? '#00486B'
                  : category.category_name === 'Fatores de Risco'
                  ? '#007BAC'
                  : '#0c9fd8'
              }
              printing={printing}
            >
              <div className="IconContainer">
                {category.category_name === 'Criminalidade e Violência' && (
                  <Algemas />
                )}
                {category.category_name === 'Desempenho Institucional' && (
                  <Government />
                )}
                {category.category_name === 'Fatores de Risco' && <Hands />}
                <h2>{category.category_name}</h2>
              </div>
              {/* <div className="GraphContainer">
                <DonutChart
                  value={category.score}
                  color={
                    category.category_name === 'Criminalidade e Violência'
                      ? '#0c9fd8'
                      : category.category_name === 'Desempenho Institucional'
                      ? '#00486B'
                      : category.category_name === 'Fatores de Risco'
                      ? '#007BAC'
                      : '#0c9fd8'
                  }
                  size={60}
                  fontSize={16}
                  strokewidth={6}
                  large={false}
                />
                <small>Nota 0-10</small>
              </div> */}
            </IndicatorCard>
          </IndicatorContainer>
          <GraphContainer>
            <div
              className="TextContainer"
              dangerouslySetInnerHTML={{
                __html: category.report_content2,
              }}
            ></div>

            <Graph>
              <div>
                <div className="graphContainer">
                  <div className="sideBars">
                    <SideBar color="#39D600" background="#39d60017">
                      <span>Melhores resultados</span>
                    </SideBar>
                    <SideBar color="#FF0000" background="#ff00001c">
                      <span>Piores resultados</span>
                    </SideBar>
                  </div>
                  <div className="bars" title="teste">
                    {category.bests_indicators.length > 0 && (
                      <div className="bests">
                        {category.bests_indicators.map(indicator => (
                          <div
                            className="barContainer"
                            title={
                              data.is_state
                                ? 'Ranking Estados'
                                : data.is_capital
                                ? 'Ranking Capitais'
                                : data.is_county
                                ? `Ranking: (${data.abbreviations_state})`
                                : 'teste'
                            }
                            key={indicator.name}
                          >
                            <Bar color="#39D600">
                              <span>{indicator.name}</span>
                            </Bar>
                            <span className="placing">
                              {!!indicator.ranking
                                ? `${indicator.ranking}º`
                                : 'N/D'}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}

                    {category.bests_indicators.length === 0 && (
                      <div className="empty">
                        <span>
                          O estado/município analisado não possui indicadores
                          com performance positiva em todos os critérios
                          avaliados (performance no passado recente e
                          comparações com as médias nacional e
                          regional/estadual).
                        </span>
                      </div>
                    )}
                    {category.worsts_indicators.length > 0 && (
                      <div className="worst">
                        {category.worsts_indicators.map(indicator => (
                          <div
                            className="barContainer"
                            title={
                              data.is_state
                                ? 'Ranking Estados'
                                : data.is_capital
                                ? 'Ranking Capitais'
                                : data.is_county
                                ? `Ranking: (${data.abbreviations_state})`
                                : 'teste'
                            }
                            key={indicator.name}
                          >
                            <Bar color="#FF0000">
                              <span>{indicator.name}</span>
                            </Bar>
                            <span className="placing">
                              {!!indicator.ranking
                                ? `${indicator.ranking}º`
                                : 'N/D'}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                    {category.worsts_indicators.length === 0 && (
                      <div className="empty">
                        <span>
                          O estado/município analisado não possui indicadores
                          com performance negativa, quando considerados os
                          critérios utilizados (performance no passado recente e
                          comparações com as médias nacional e
                          regional/estadual).
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="gradient">
                    <IconContainer
                      printing={printing}
                      color="#39D600"
                      className="up"
                    >
                      <FaThumbsUp color="#ffffff" size={20} />
                    </IconContainer>
                    <GradientBar />
                    <IconContainer
                      printing={printing}
                      color="#FF0000"
                      className="down"
                    >
                      <FaThumbsDown color="#ffffff" size={20} />
                    </IconContainer>
                  </div>
                </div>
              </div>
            </Graph>
          </GraphContainer>
        </Card>
      ))}
    </Container>
  );
}

export default Page1;

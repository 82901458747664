import React from 'react';
import { Column } from '../../../../../styles/components';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';
import { TabButton } from './styles';

function Categories({ setTab }) {
  return (
    <>
      <Column large="4" medium="4" small="4">
        <TabButton color="#3aa9ee" onClick={() => setTab(1)}>
          <div className="contentContainer">
            <Algemas size={82} />
            <span>Criminalidade e Violência</span>
          </div>
        </TabButton>
      </Column>
      <Column large="4" medium="4" small="4">
        <TabButton color="#287bac" onClick={() => setTab(2)}>
          <div className="contentContainer">
            <Hands size={82} />
            <span>Fatores de Risco</span>
          </div>
        </TabButton>
      </Column>
      <Column large="4" medium="4" small="4">
        <TabButton color="#14486b" onClick={() => setTab(3)}>
          <div className="contentContainer">
            <Government size={82} />
            <span>Desempenho Institucional</span>
          </div>
        </TabButton>
      </Column>
    </>
  );
}

export default Categories;

import React, { useState, useEffect } from 'react';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';
import ImageHeader from '../../../assets/faq.png';
import { Link } from 'react-router-dom';

import { Container, FlexWrapper, MainContent } from './styles';

function FichaTecnica() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={true}>
          <Container>
            <MainContent>
              <div className="imageWrapper">
                <div className="imageContent">
                  <div className="titleContent">O Programa</div>
                </div>
              </div>
              <div className="content">
                <div className="tab-container">
                  <Link
                    to="/programa/sobre-o-programa"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">O PROSEG Federativo</div>
                  </Link>

                  <Link
                    to="/programa/ficha-tecnica"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab1">Ficha Técnica</div>
                  </Link>
                  <Link
                    to="/programa/perguntas-e-respostas"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Perguntas Frequentes</div>
                  </Link>
                </div>
              </div>
              <div className="content">
                <h1>Equipe (ficha técnica)</h1>
                <FlexWrapper>
                  <p className="text">
                    O Programa Federativo para Segurança Pública Inteligente
                    (PROSEG Federativo) foi concebido pela equipe do{' '}
                    <u>Cluster de Segurança Cidadã e Justiça</u>, vinculado à
                    Divisão de Inovação para Servirt ao Cidadão do{' '}
                    <strong>
                      Banco Interamericano de Desenvolvimento (BID),
                    </strong>{' '}
                    emparceria com o Departamento de Gestão Pública da área de
                    Gestão Pública e Socioambiental do{' '}
                    <strong>
                      Banco Nacional de Desenvolvimento Econômico e Social
                      (BNDES).
                    </strong>{' '}
                    Em a de consolidação metodológica do programa metodológico e
                    de desenvolvimento técnológico da sua plataforma, o BID e o
                    BNDES contaram com o apoio da{' '}
                    <strong> Macroplan Prospectiva Estratégia & Gestão,</strong>{' '}
                    uma empresa brasileira de consultoria especializada na
                    articulação de visão estratégica, técnicas de analytics e
                    ferramentas de tecnologia da informação para prover o
                    suporte necessário à qualificação de processos executivos de
                    tomada de decisão. Confira, abaixo, as equipes envolvidas no
                    projeto:
                  </p>
                </FlexWrapper>
              </div>
              <div className="content">
                <FlexWrapper>
                  <div className="wrapper">
                    <div className="left">
                      <a
                        href="https://www.iadb.org/pt"
                        target="_blank"
                        rel="noreferrer"
                        className="text-header"
                      >
                        BID{' '}
                      </a>
                      <div className="left mt">
                        <p>
                          <b>Coordenação:</b>
                          <p>Rodrigo Serrano</p>
                        </p>
                        <p>
                          <b>Equipe Técnica:</b>
                          <p>Alberto Kopittke</p>
                          <p>Beatrice Zimmerman</p>
                          <p>Érica Mássimo</p>
                          <p>Fernando Cafferata</p>
                          <p>Flavia Carbonari</p>
                          <p>Lauren Xerxenevsky</p>
                          <p>Maria de Fátima Cartaxo</p>
                          <p>Patricia Domec</p>
                          <p>Pedro das Neves</p>
                          <p>Rodrigo Pantoja</p>
                          <p>Sérgio Britto Lima</p>
                          <p>Tiago Cordeiro</p>
                        </p>
                      </div>
                    </div>
                    <div className="middle">
                      <a
                        href="https://www.bndes.gov.br/wps/portal/site/home"
                        target="_blank"
                        rel="noreferrer"
                        className="text-header"
                      >
                        BNDES
                      </a>
                      <div className="middle mt">
                        <p>
                          <b>Coordenação:</b>
                          <p>Paula Barbosa</p>
                        </p>
                        <p>
                          <b>Equipe Técnica:</b>
                          <p>André Cleiman</p>
                          <p>Rodrigo Sécca</p>
                          <p>Taísa Idalino</p>
                        </p>
                      </div>
                    </div>
                    <div className="right">
                      <a
                        href="https://www.macroplan.com.br"
                        target="_blank"
                        rel="noreferrer"
                        className="text-header"
                      >
                        MACROPLAN CONSULTORIA
                      </a>
                      <div className="right mt">
                        <p>
                          <b>Diretor do projeto:</b> Gustavo Morelli
                        </p>
                        <p>
                          <b>Gerente do projeto:</b> Éber Gonçalves
                        </p>
                        <p>
                          <b>Consultor do projeto:</b> Lucas Cândido, Fernando
                          Gonlaçves e Joao Pedro Walcacer
                        </p>

                        <p>
                          <b>Coordenadora de dados:</b> Adriana Fontes
                        </p>
                        <p>
                          <b>Consultora especialista em dados:</b> Roberta
                          Teixeira
                        </p>
                        <p>
                          <b>Cientista de dados:</b> Rachel Bastos
                        </p>
                        <p>
                          <b>Design:</b> Luiza Raj e Tatiane Limani
                        </p>
                        <p>
                          <b>Líder de tecnologia:</b> Alexandro Jordão
                        </p>
                        <p>
                          <b>Analista de TI:</b> Bruno Young
                        </p>
                        <p>
                          <b>Desenvolvimento do sistema:</b>{' '}
                          <a
                            href="https://cherryit.co"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Equipe Cherry IT
                          </a>
                          <br />
                          <a href="_" target="_blank" rel="noreferrer">
                            FPWebDigital
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="left">
                    <a
                      href="https://www.iadb.org/pt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BID{' '}
                    </a>
                  </div>
                  <div className="middle">
                    <a
                      href="https://www.bndes.gov.br/wps/portal/site/home"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BNDES
                    </a>
                  </div>
                  <div className="right">
                    <a
                      href="https://www.macroplan.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MACROPLAN CONSULTORIA
                    </a>
                  </div>
                </FlexWrapper>
                <FlexWrapper>
                  <div className="left mt">
                    <p>
                      <b>Coordenação:</b>
                      <p>Rodrigo Serrano</p>
                    </p>
                    <p>
                      <b>Equipe Técnica:</b>
                      <p>Alberto Kopittke</p>
                      <p>Beatrice Zimmerman</p>
                      <p>Érica Mássimo</p>
                      <p>Flavia Carbonari</p>
                      <p>Lauren Xerxenevsky</p>
                      <p>Maria de Fátima Cartaxo</p>
                      <p>Patricia Domec</p>
                      <p>Pedro das Neves</p>
                      <p>Rodrigo Pantoja</p>
                      <p>Tiago Cordeiro</p>
                    </p>
                  </div>
                  <div className="middle mt">
                    <p>
                      <b>Coordenação:</b>
                      <p>Paula Barbosa</p>
                    </p>
                    <p>
                      <b>Equipe Técnica:</b>
                      <p>André Cleiman</p>
                      <p>Rodrigo Sécca</p>
                      <p>Taísa Idalino</p>
                    </p>
                  </div>
                  <div className="right mt">
                    <p>
                      <b>Diretor do projeto:</b> Gustavo Morelli
                    </p>
                    <p>
                      <b>Gerente do projeto:</b> Sérgio Britto Lima
                    </p>
                    <p>
                      <b>Consultor do projeto:</b> Lucas Cândido
                    </p>
                    <p>
                      <b>Estagiário do projeto:</b> João Pedro Walcacer
                    </p>
                    <p>
                      <b>Coordenadora de dados:</b> Adriana Fontes
                    </p>
                    <p>
                      <b>Consultora especialista em dados:</b> Roberta Teixeira
                    </p>
                    <p>
                      <b>Cientista de dados:</b> Rachel Bastos
                    </p>
                    <p>
                      <b>Design:</b> Luiza Raj e Tatiane Limani
                    </p>
                    <p>
                      <b>Líder de tecnologia:</b> Aldo Polastre
                    </p>
                    <p>
                      <b>Analista de TI:</b> Bruno Young
                    </p>
                    <p>
                      <b>Desenvolvimento do sistema:</b>{' '}
                      <a
                        href="https://cherryit.co"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Equipe Cherry IT
                      </a>
                    </p>
                  </div>
      */}
                </FlexWrapper>
              </div>
            </MainContent>
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default FichaTecnica;

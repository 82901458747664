import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import Button from '../Button';
import { Container } from './styles';

const FileInput = ({ name, title, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback(e => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    }
    setPreview(file.name);
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container>
      <Button type="button" color="orange" padding="1" rounded>
        {title ? `${title}` : 'Anexar Arquivo'}
      </Button>
      {!!preview && preview}
      <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      {!!error && <span>{error}</span>}
    </Container>
  );
};

export default FileInput;

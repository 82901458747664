import React, { useState, useCallback } from 'react';
import { Container, ButtonContainer, DropdownContainer } from './styles';

const DropdownButton = ({ color, padding, itens, children, ...rest }) => {
  const [active, setActive] = useState(false);

  const handleClickItem = useCallback(callback => {
    callback();
    setActive(false);
  }, []);

  return (
    <Container>
      <ButtonContainer
        color={color}
        padding={padding}
        onClick={() => setActive(!active)}
        type="button"
        {...rest}
      >
        {children}
      </ButtonContainer>
      <DropdownContainer active={active} onBlur={() => setActive(false)}>
        {itens.map(item => (
          <button
            type="button"
            key={item.name}
            onClick={() => handleClickItem(item.onClick)}
          >
            {item.name}
          </button>
        ))}
      </DropdownContainer>
    </Container>
  );
};

export default DropdownButton;

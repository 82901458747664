import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Row, Column } from '../../../styles/components';
import ExternalLayout from '../../../components/ExternalLayout';
import { Container, MainContent } from './styles';
import { useAuth } from '../../../hooks/auth';
import { FaEnvelope, FaSignInAlt, FaCheckCircle, FaLock } from 'react-icons/fa';
import Input from '../../../components/Input';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import getValidationErrors from '../../../services/getValidationErrors';
import api from '../../../services/api';


const schema = Yup.object().shape({
    new_password: Yup.string()
      .required('senha é obrigatório'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], "Confirmação de senha não corresponde")
      .required('confirmação é obrigatório')
  });
  


function ResetPassword() {
  const { goBack } = useHistory();
  const formRef = useRef(null);
  const { code } = useParams();


  const { changeForgotModal, changeLoginModal } = useAuth();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(code);
    
  }, []);


  const handleSubmit = useCallback(async data => {
    try {
      formRef.current.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      data['code'] = code;

      await api.post('/accounts/register-new-password/', data);

      setLoading(false);

      setSuccessMessage(true);

      // window.location = '/projects/';
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        console.log(errors)
        formRef.current.setErrors(errors)
        return;
      } else {
        setErrorMessage(true)
      }
      setLoading(false);
      console.error('err', err);
    }
  }, []);


  return (
    <ExternalLayout headerTransparent={true}>
      <Container>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Cadastre sua nova senha</div>
            </div>
          </div>
          <div className="content">
            <button className="gobackLink" onClick={()=> window.location = '/'}>
              Voltar
            </button>
            <div className="title">
            {/* Cadastre sua nova senha */}
            </div>
            <div className="text">
            <div className="loginContainer">
            {successMessage ? (
              <div className="formContainer">
                <h1 className="successTitle">
                  Reset de senha concluido com sucesso &nbsp;
                  <FaCheckCircle size={25} color="#00a9ee" />
                </h1>
                <small>
                  Realize uma nova tentativa de login.
                </small>
                
              </div>
            ) : (
              <div className="formContainer">
                <Form ref={formRef} onSubmit={handleSubmit}>

                  <Input
                    id="new_password"
                    name="new_password"
                    icon={FaLock}
                    className="input"
                    type="password"
                    placeholder="Senha"
                    />

                    <br/>

                    <Input
                        id="confirm_password"
                        name="confirm_password"
                        icon={FaLock}
                        className="input"
                        type="password"
                        placeholder="Confirmar Senha"
                        />
                  {error && <div className="errormessage">{error}</div>}
                  <div className="submitContainer">
                    <button type="submit" className="submit">
                        <FaSignInAlt />
                        Enviar
                    </button>
                  </div>
                </Form>

              </div>
            )}

            {errorMessage ? (
              <div className="errormessage" style={{color: 'red'}}>
                <small>
                  Código de recuperação inválido
                </small>
              </div>
            ) : ""}
            </div>
            </div>
           
          </div>
        </MainContent>
      </Container>
    </ExternalLayout>
  );
}

export default ResetPassword;

import styled from 'styled-components';

export const Container = styled.div`
  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props =>
        props.tab === '1'
          ? '15%'
          : props.tab === '2'
          ? '49%'
          : props.tab === '3' && '82%'};
    }
  }

  .redirectLink {
    text-decoration: underline;
    color: #004e70;
    font-weight: 700 !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
  }
`;

export const Title = styled.h1`
  font: normal normal 25px/34px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  margin-bottom: 0.6rem;
`;

export const SubTitle = styled.h2`
  font: normal normal normal 18px/23px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  margin-bottom: 1rem;
`;

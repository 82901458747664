import styled, { css, keyframes } from 'styled-components';

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 100000000000000;
  animation: ${EasyIn} 0.3s;

  section {
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;

    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    width: 60%;
    height: auto;

    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    background: var(--red);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    animation: ${EasyIn} 0.5s;
    .close-container {
      position: fixed;
      top: -40px;
      right: 0;
      font: normal normal bold 26px/34px IBM Plex Sans;
      color: var(--white);
      background: transparent;

      display: flex;
      align-items: center;

      svg {
        margin-top: 4px;
        color: var(--white);

        cursor: pointer;
      }
    }

    p {
      font: normal normal bold 26px/34px IBM Plex Sans;
      color: var(--white);
      margin-top: 17px;
    }

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dark-blue);
      height: 65px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font: normal normal bold 26px/34px IBM Plex Sans;
      color: var(--white);

      span {
        margin-right: 13px;
        font-size: 19px;
      }

      .close-container {
        position: fixed;
        top: -40px;
        right: 0;
        font: normal normal bold 26px/34px IBM Plex Sans;
        color: var(--white);
        background: transparent;

        display: flex;
        align-items: center;

        svg {
          margin-top: 4px;
          color: var(--white);

          cursor: pointer;
        }
      }
    }

    .modal-body {
      padding: 15px;
      overflow: auto;
      max-height: 65vh;
    }

    @media screen and (max-width: 768px) {
      .modal-header {
        font-size: 19px;
      }

      .modal-header {
        .close-container {
          font-size: 19px;
        }
      }
    }

    @media screen and (max-width: 425px) {
      .modal-header {
        font-size: 14px;
      }

      .modal-header {
        .close-container {
          font-size: 16px;
        }
      }
    }
  }

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;

import React from 'react';
import Modal from '../Modal';
import { Container } from './styles';


const ResetModal = ({ show, handleClose }) => {
    return (
        <Container>
            <Modal
                title="Cadastre sua nova senha"
                success={false}
                successText=""
                handleClose={handleClose}
                show={show}
            >
                <div id="change-password-change">
                    <div
                    class="modal-header d-flex justify-content-end p-0"
                    id="modal-header"
                    >
                        <div>
                            <button
                            type="button"
                            class="modal-fechar text-white"
                            data-dismiss="modal"
                            >
                            Fechar<i class="ml-2 fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                <div class="modal-body d-flex" id="modal-body">
                <div
                    style="border-radius: 8px"
                    class="flex-grow-1 d-flex flex-column w-50 modal-background px-3 py-4"
                >
                    <h3
                    style="color: #212529"
                    class="d-flex justify-content-center font-weight-bold mt-2"
                    >
                    Cadastre sua nova senha
                    </h3>

                    <form
                    id="form-change-new-password"
                    onsubmit="changePassword(event)"
                    >
                    <input type="hidden" id="change-user-id" value="{{ user.pk }}" />

                    <div
                        class="d-flex px-2 py-1 align-items-center modal-input-border mt-2"
                    >
                        <input
                        autocomplete="off"
                        class="flex-grow-1 modal-input"
                        type="text"
                        id="change-user-name"
                        style="font-size: 20px"
                        value="{{ user.full_name }}"
                        readonly
                        />
                    </div>

                    <div
                        class="d-flex px-2 py-1 align-items-center modal-input-border mt-2"
                    >
                        <input
                        autocomplete="off"
                        class="flex-grow-1 modal-input"
                        name="email"
                        type="password"
                        id="save-change-password"
                        style="font-size: 20px"
                        placeholder="Nova Senha"
                        value=""
                        />
                    </div>

                    <div
                        class="d-flex px-2 py-1 align-items-center modal-input-border mt-2"
                    >
                        <input
                        autocomplete="off"
                        class="flex-grow-1 modal-input"
                        name="senha"
                        type="password"
                        id="save-change-password-confirmation"
                        style="font-size: 20px"
                        placeholder="Confirmação de Senha"
                        value=""
                        />
                    </div>

                    <div class="d-flex justify-content-between mt-2">
                        <p
                        id="error-change-password"
                        class="text-danger"
                        style="font-size: 14px"
                        ></p>
                    </div>

                    <div class="d-flex mt-3 justify-content-end">
                        <button
                        class="mt-1 button-enviar text-white px-4 py-2 font-weight-bold mb-2 float-right"
                        type="submit"
                        name="button"
                        >
                        Salvar
                        </button>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </Modal>
        </Container>
    )
}

export default ResetModal;
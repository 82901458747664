import React from 'react';

import { ReactComponent as Mao } from '../../../../../assets/reinsercao-purple.svg';
import { ReactComponent as Lampada } from '../../../../../assets/idea-blue.svg';
import { ReactComponent as Balanca } from '../../../../../assets/balance-green.svg';
import { ReactComponent as Police } from '../../../../../assets/police-officer-red.svg';
import { ReactComponent as Casa } from '../../../../../assets/family-yellow.svg';
import { ReactComponent as Predio } from '../../../../../assets/building-orange.svg';
import { Row, Column } from '../../../../../styles/components';
import { Container, EvidenceCard } from './styles';

function AnswersCard() {
  return (
    <Container>
      <Row>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#bd9313">
            <Casa />
            <span>Prevenção Social</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#ac5200">
            <Predio />
            <span>Prevenção Situacional</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#891f1f">
            <Police />
            <span>Policiamento</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#106456">
            <Balanca />
            <span>Justiça Criminal</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>

        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#520a53">
            <Mao />
            <span>Reinserção Social</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>
        <Column large="4" medium="4" small="12" className="m-5">
          <EvidenceCard color="#10385c">
            <Lampada />
            <span>Gestão e Modernização Institucional</span>
            <div className="answerContainer">
              <h1>50 Perguntas</h1>
            </div>
          </EvidenceCard>
        </Column>
      </Row>
    </Container>
  );
}

export default AnswersCard;

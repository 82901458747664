import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 25px;

  display: flex;

  .graphName {
    justify-self: center;
    align-self: center;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }

  .bars {
    width: 69%;
  }

  .info-labels {
    width: 31%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #ffffff;
  }
`;

export const BarItem = styled.div`
  width: 100%;
  height: ${props => `${props.percentage}%`};
  background: ${props => props.color};

  transition: all 0.5s ease;

  display: flex;
  align-self: center;
  justify-content: center;

  span {
    display: flex;
    align-self: center;
    justify-content: center;

    font: normal normal bold 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
`;

export const InfoLabel = styled.div`
  display: flex;

  margin-right: 10px;
  font: normal normal normal 12px/15px IBM Plex Sans;
  letter-spacing: 0px;
  color: #6d6e71;
  align-items: center;
  margin-bottom: 10px;

  div {
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px;
    background: ${props => props.color};
  }
`;

export const GraphContainer = styled.section`
  display: flex;
  width: 76px;
  height: 218px;
  align-items: center;

  border-bottom: 1px solid #cccccc;
  justify-content: center;
  width: 100%;

  display: flex;
  flex-direction: column;

  .nameContainer {
    width: 32%;
    text-align: right;
    font: normal normal normal 12px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    margin-right: 5px;
  }

  .barContainer {
    display: flex;
    flex-direction: column;
    width: 76px;
    height: 100%;
    background: #e8e8e8;
  }
`;

import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 90px 1fr 50px;
  grid-template-areas:
    'aside header'
    'aside main'
    'aside footer';
  height: 100vh;

  animation: ${EaseIn} 0.3s;

  @media only screen and (max-width: 769px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 90px 1fr 50px;
    grid-template-areas:
      'header'
      'main'
      'footer';
    height: 100vh;

    aside {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 280px;
      position: fixed;
      overflow-y: auto;
      z-index: 2;

      transform: ${props =>
        props.active ? `translateX(0)` : `translateX(-285px)`};

      .aside_close-icon {
        position: absolute;
        right: 10px;
        top: 23px;
        color: #ffffff;
        font-size: 28px;
      }
    }
  }

  @media print {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 'main';
    height: 100vh;
    width: 100vw;
  }
`;

export const Header = styled.header`
  grid-area: header;
  background: var(--whitesmoke);
  padding: 0 40px;

  display: flex;

  .menu-icon {
    position: absolute;
    display: flex;
    top: 19px;
    left: 1px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 12px;
    font-size: 23px;
    color: #164e70;
  }

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #004e70;
    font-size: 30px;
    width: 100%;

    h1 {
      font-weight: normal;
      font-size: 2rem;
    }

    .profile {
      display: flex;

      align-items: center;

      img {
        image-rendering: optimizeQuality;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .mobilePhoto {
        display: none;
      }

      .profile-data {
        flex-direction: column;
        padding: 16px;
        height: 50px;
        justify-content: center;
        align-items: left;
        border-right: 1px solid #d0d0d0;
        display: flex;

        h3 a {
          display: flex;
          flex-direction: column;
          font: normal bold 14px IBM Plex Sans;
          letter-spacing: 0px;
          color: #075e89;
          opacity: 1;
        }

        h3 a span {
          text-align: left;
          font: normal 14px IBM Plex Sans;
          letter-spacing: 0px;
          color: #6d6e71;
          opacity: 1;
        }
      }

      .logout-form {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          border: none;
          background: none;
          padding: 16px;
          font-size: 15px;
          color: #6d6e71;

          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .aside_close-icon {
    position: absolute;
    visibility: visible;
    top: 20px;
    right: 20px;
    font-size: 35px;
    cursor: pointer;
  }

  @media only screen and (min-width: 1441px) {
    padding: 0 10%;
  }

  @media only screen and (min-width: 769px) {
    .menu-icon {
      display: none;
    }
    .aside_close-icon {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .header-wrap {
      h1 {
        font-size: 23px;
      }

      .profile {
        .mobilePhoto {
          display: flex;
        }

        .userImg {
          display: none;
        }

        .profile-data {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .header-wrap {
      h1 {
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 365px) {
    .header-wrap {
      h1 {
        font-size: 15px;
      }
    }
  }
`;

export const Main = styled.main`
  grid-area: main;
  background: var(--white);
  margin-bottom: 30px;
`;

export const MainOverview = styled.div`
  padding: 0 40px;
  margin-top: 20px;
  animation: ${EaseIn} 0.3s;

  @media only screen and (min-width: 1441px) {
    padding: 0 10%;
  }
`;

export const Footer = styled.footer`
  grid-area: footer;
  background: var(--whitesmoke);
`;

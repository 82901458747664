import html2canvas from 'html2canvas';

const getPageCanvas = ElementReference => {
  const page = html2canvas(ElementReference, {
    ignoreElements: function (element) {
      if (element.classList.contains('printLoader')) {
        return true;
      }
    },
  });
  return page;
};

const printDocumentPage = (Canvas, PdfInstance, notIsLast) => {
  const imgData = Canvas.toDataURL('image/svg');
  const imgWidth = 212;
  const pageHeight = 290;
  const imgHeight = (Canvas.height * imgWidth) / 1120;
  let position = 0;
  let heightLeft = imgHeight;

  let date = new Date();
  let dateFormatted =
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

  PdfInstance.addImage(imgData, 'svg', 0, 0, 212, imgHeight);
  PdfInstance.setFontSize(8);
  PdfInstance.setTextColor(109, 110, 113);
  PdfInstance.text(`Relatório emitido em ${dateFormatted}`, 159, 5);
  PdfInstance.setFillColor(0, 0, 0, 0);
  PdfInstance.rect(0, 287, 210, 15, 'F');
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    PdfInstance.addPage();

    PdfInstance.addImage(
      imgData,
      'svg',
      0,
      position + 13.8,
      imgWidth,
      imgHeight,
    );

    PdfInstance.setFillColor(0, 0, 0, 0);
    PdfInstance.rect(0, 0, 210, 10, 'F');

    PdfInstance.text(`Relatório emitido em ${dateFormatted}`, 159, 5);

    PdfInstance.setFillColor(0, 0, 0, 0);
    PdfInstance.rect(0, 287, 210, 15, 'F');

    heightLeft -= pageHeight;
  }
  notIsLast && PdfInstance.addPage();
};
export { printDocumentPage, getPageCanvas };

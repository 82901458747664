import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 5px;
`;

export const TitleText = styled.div`
  position: absolute;
  background: ${props => (props.color ? `${props.color}` : '#3a7cac')};
  font-size: ${props => (props.icon ? '12px' : '18px')};
  width: ${props => (props.icon ? '100px' : '500px')};
  top: ${props => (props.icon ? '-35px' : '-100px')};
  left: -25px;
  color: #ffffff;
  padding: ${props => (props.icon ? '3px' : '11px')};
  border-radius: 15px;
  display: ${props => (props.show ? 'block' : 'none')};
  z-index: 10;
`;

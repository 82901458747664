import styled from 'styled-components';

export const Container = styled.div`
  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props =>
        props.tab === '1'
          ? '15%'
          : props.tab === '2'
          ? '49%'
          : props.tab === '3' && '82%'};
    }
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #00a9ee;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 44px;

    & > div {
      white-space: inherit;
    }
  }

  small {
    text-align: left;
    font: normal normal normal 13px/17px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
  }
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin: 7px 0;
  background: transparent;
`;

export const Title = styled.h1`
  text-align: left;
  font: normal normal 600 33px/43px IBM Plex Sans;
  letter-spacing: 0px;
  color: #242424;
  opacity: 1;
`;

export const TextContainer = styled.div`
  p {
    text-align: left;
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    margin-bottom: 25px;
  }
`;

export const TabButton = styled.button`
  position: relative;
  background: var(--white);
  width: 100%;
  height: 210px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #007bac`};
  border-radius: 20px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;

  span {
    text-align: center;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;
  }

  div {
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
    width: 100%;
    height: 54px;
    background: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      font: normal normal bold 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #f8f8f8;
      opacity: 1;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div``;

export const TabButton = styled.button`
  position: relative;
  background: var(--white);
  width: 100%;

  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #007bac`};
  border-radius: 8px;
  display: flex;

  .contentContainer {
    border-radius: 8px;
    height: 172px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 18px;
    background: ${props => (props.color ? `${props.color}` : `#007bac`)};

    svg {
      margin-bottom: 5px;
      path {
        fill: #ffffff;
      }
    }
    img {
      width: 64px;
    }
    span {
      color: #ffffff;
      font: normal normal bold 17px/22px IBM Plex Sans;
      letter-spacing: 0px;
    }
  }
`;

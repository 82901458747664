import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useAuth } from '../../../../../hooks/auth';
import { Row, Column } from '../../../../../styles/components';
import Select from '../../../../../components/Select';
import SelectOptions from '../../../../../components/RegisterModal/options';
import Mapa from '../../../../../assets/mapa_pessoas.png';
import { Container } from './styles';

function WhoCanAccessSection() {
  const formRef = useRef(null);
  const { changeLoginModal } = useAuth();
  const handleSubmit = useCallback(() => {}, []);
  const [location, setLocation] = useState(null);
  return (
    <Container>
      <div className="contentContainer">
        <div className="text">
          <h1>Quem pode acessar?</h1>
          <p>
            A seção de &nbsp;
            <b>
              diagnóstico tem dados para todos os estados e municípios
              brasileiros
            </b>
            &nbsp; e é de acesso público. Porém, o &nbsp;
            <b>
              acesso às linhas de crédito que compõem o PROSEG Federativo está
              limitado, nesse primeiro momento, aos 26 estados brasileiros, o
              Distrito Federal e um total de 315 municípios,
            </b>
            &nbsp; selecionados de acordo com seu porte populacional e/ou com a
            sua relevância em termos da incidência de criminalidade violenta no
            Brasil
          </p>
          <div className="formContainer">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="selectContainer">
                <Row>
                  <Column small="12" medium="6" large="6" className="m-5">
                    <Select
                      name="location"
                      options={SelectOptions.estados.sort(function (a, b) {
                        if (a.label > b.label) {
                          return 1;
                        }
                        if (a.label < b.label) {
                          return -1;
                        }
                        return 0;
                      })}
                      placeholder="Veja a lista de Estados"
                      onChange={e => setLocation(e)}
                    />
                  </Column>
                  <Column small="12" medium="6" large="6" className="m-5">
                    <Select
                      isDisabled={!location}
                      name="location"
                      options={(() => {
                        return SelectOptions.municipios.filter(municipio => {
                          if (municipio.label === location?.label) {
                            return true;
                          }
                          return false;
                        });
                      })()}
                      placeholder="Veja a lista de Municipios"
                    />
                  </Column>
                  <Column small="12" medium="12" large="12" className="m-5">
                    <button
                      type="button"
                      className="button"
                      onClick={() => changeLoginModal(true)}
                    >
                      Se interessou? Elabore sua pré-proposta e acesse as linhas
                      de crédito!
                    </button>
                  </Column>
                </Row>
              </div>
            </Form>
          </div>
        </div>
        <div className="image">
          <img src={Mapa} alt="mapa" />
        </div>
      </div>
    </Container>
  );
}

export default WhoCanAccessSection;

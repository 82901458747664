import React, { useState, useEffect } from 'react';
import ModalLoader from '../ModalLoader';
import Modal from '../Modal';

const SuccessModal = ({ loading, show, handleClose, successText }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    loading ? setSuccess(false) : setSuccess(true);
  }, [loading]);

  return (
    <Modal
      title="Carregando"
      successText={successText}
      handleClose={handleClose}
      show={show}
      success={success}
    >
      {loading ? <ModalLoader /> : <div />}
    </Modal>
  );
};

export default SuccessModal;

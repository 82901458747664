import React from 'react';
import DataTable from 'react-data-table-component';
import { Container } from './styles';

const DeliverableTable = ({ products, area }) => {
  const columns = [
    {
      name: 'Indicador do Produto ',
      selector: 'name',
    },
    {
      name: 'Meta Física',
      selector: 'physical_goal',
    },
    {
      name: 'Prazo(Dias)',
      selector: 'deadline',
    },
  ];

  return (
    <Container area={area}>
      <DataTable
        columns={columns}
        data={products}
        noDataComponent=""
        noHeader
      />
    </Container>
  );
};

export default DeliverableTable;

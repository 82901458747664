import styled from 'styled-components';

export const Container = styled.div`
  .rdt_Table {
    margin-top: 25px;
  }

  .rdt_TableCol {
    font: normal normal bold 17px/22px IBM Plex Sans;
    background: #6d6e71;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    color: #6d6e71;
    background: #f1f1f1;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 60px;
    font: normal normal 400 16px/20px IBM Plex Sans;
    letter-spacing: 0px;

    opacity: 1;

    & > div {
      white-space: inherit;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    .save {
      width: 115px;
      height: 50px;
      border-radius: 10px;
      background: #5eb245;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;
      color: #ffffff;
      align-self: flex-end;
      font-weight: bold;
      margin-top: 20px;
    }
  }
`;

export const TitleButton = styled.div`
  background: #00a9ee 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  min-height: 66px;

  color: var(--white);
  text-align: left;
  font: normal normal bold 23px/30px IBM Plex Sans;
  margin-top: 18px;
  margin-bottom: 31px;
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin-top: 31px;
  background: transparent;
  margin-right: 20px;
`;

export const Title = styled.h1`
  font: normal normal bold 26px/34px IBM Plex Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;

export const SecretariesInvolvedContainer = styled.section`
  h1 {
    text-align: left;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #9f9f9f;
    opacity: 1;
  }

  .input-container {
    display: flex;

    button {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      background: #5eb245;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;
      margin-left: 15px;

      &:hover {
        background: #34a911;
      }
    }
  }

  .itens-row {
    margin-top: -25px;
  }
`;

export const SecretariatItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    background: var(--gray);
    color: var(--white);
    font: normal normal normal 16px/20px IBM Plex Sans;
    color: var(--text2);
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border-radius: 9px;
    padding-left: 18px;
    display: flex;
    align-items: center;
  }

  button {
    background: var(--red) !important;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: inherit;
  }
`;

export const ResponsiblesContainer = styled.section`
  h1 {
    text-align: left;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #9f9f9f;
    opacity: 1;
  }

  .input-container {
    display: flex;

    & > div {
      margin-right: 15px;
      margin-top: 0px;
    }
    button {
      min-width: 50px;
      height: 50px;
      border-radius: 10px;
      background: #5eb245;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;

      &:hover {
        background: #34a911;
      }
    }
  }

  .itens-row {
    margin-top: -25px;
  }

  .noOptionsMessage {
    margin-top: -22px;
  }
`;

export const DeleteButton = styled.button`
  background: var(--red);
  color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;

  &:hover {
    background: #ef3030;
  }
`;

export const Card = styled.section`
  background: ${props =>
    props.color ? `var(--${props.color})` : `var(--white)`};
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  box-shadow: ${props => props.shadow && '0px 1px 6px #00000029'};
  width: 100%;
`;

export const AddDeliverableButton = styled.div`
  margin-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 180px;
  border: 2px dashed #aab9bf;
  background: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  pointer-events: ${props => !props.readOnly && 'none'};
  opacity: ${props => !props.readOnly && '0.2'};

  &:hover {
    background: var(--gray);
  }

  span {
    max-width: 195px;
    margin-top: 15.5px;
    font: normal normal bold 25px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #aab9bf;
    opacity: 1;
  }
`;

export const DeliverableCard = styled.div`
  height: 180px;
  margin-top: 10px;
  background: #aab9bf 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  svg {
    align-self: flex-end;
    cursor: pointer;
  }

  p {
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }

  span {
    text-align: left;
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
`;

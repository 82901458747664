import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import SyntheticIndicator from './Tabs/SyntheticIndicator';
import StrategicIndicator from './Tabs/StrategicIndicator';
import ImpactIndicator from './Tabs/ImpactIndicator';
import ImpactManager from './Tabs/ImpactManager';
import { useAuth } from '../../hooks/auth';
import { Container, TabsContainer, Tab } from './styles';

const ImpactsMonitoring = () => {
  const { user } = useAuth();
  const [tabActive, setTabActive] = useState(() =>
    user.is_admin ? 'strategic_indicator' : 'impact_manager',
  );

  return (
    <Container>
      <DashboardLayout title="Monitoramento dos Impactos">
        <TabsContainer tab={tabActive}>
          {user.is_admin && (
            <>
              {/* <Tab
                active={tabActive === 'synthetic_indicator'}
                onClick={() => setTabActive('synthetic_indicator')}
              >
                Monitoramento do Indicador Sintético
              </Tab> */}
              <Tab
                active={tabActive === 'strategic_indicator'}
                onClick={() => setTabActive('strategic_indicator')}
              >
                Monitoramento dos Indicadores Estratégicos
              </Tab>
              <Tab
                active={tabActive === 'impact_indicator'}
                onClick={() => setTabActive('impact_indicator')}
              >
                Monitoramento dos Indicadores de Impacto (por operação)
              </Tab>
            </>
          )}
          {!user.is_admin && (
            <>
              {/* <Tab
                active={tabActive === 'synthetic_indicator'}
                onClick={() => setTabActive('synthetic_indicator')}
              >
                Indicador Sintético
              </Tab> */}
              <Tab
                active={tabActive === 'impact_manager'}
                onClick={() => setTabActive('impact_manager')}
              >
                Meus Indicadores de Impacto
              </Tab>
            </>
          )}
        </TabsContainer>
        {/* {tabActive === 'synthetic_indicator' && <SyntheticIndicator />} */}
        {tabActive === 'strategic_indicator' && <StrategicIndicator />}
        {tabActive === 'impact_indicator' && <ImpactIndicator />}
        {tabActive === 'impact_manager' && <ImpactManager />}
      </DashboardLayout>
    </Container>
  );
};

export default ImpactsMonitoring;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 25px;
`;

export const AccordionItem = styled.div`
  background: var(--white);
  width: 100%;
  height: 56px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const InitialContainer = styled.div`
  display: flex;
  margin: 0 25px;

  .text-container {
    width: 100%;
    font-family: 'Source Sans Pro' !important;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      text-align: left;
      font: normal normal bold 14px/18px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }

    .info-container {
      display: flex;
      align-items: center;
      margin-right: 15px;
      .status-circle {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background: var(--red);
        margin-right: 5px;
      }

      .restriction-flag {
        width: 24px;
        height: 31px;
        background: var(--yellow);
        color: var(--white);
        font: normal normal bold 16px/20px IBM Plex Sans;
        letter-spacing: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }
  }

  button {
    transition: all 0.5s ease;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;

    .arrowIcon {
      svg {
        display: flex;
        margin-right: 3px;
      }

      ${props =>
        props.active &&
        css`
          transform: rotate(90deg);
        `};
    }
  }
`;

export const CollapseContainer = styled.div`
  transition: all 0.5s ease;
  background: var(--white);
  min-height: 0;
  padding: 0 20px;
  height: 0;
  transition: all 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${props =>
    props.active &&
    css`
      min-height: 30px;
      height: auto;
      transition: all 0.25s ease-in;
      padding: 20px;
    `}
`;
export const DeleteContainer = styled.div`
  z-index: 100;
  display: ${props => (props.show ? 'inherit' : 'none')};
`;

import React from 'react';
import PercentageBar from '../PercentageBar';

import { Container, InfoLabel } from './styles';

function StackedPercentageBar({ percentage }) {
  return (
    <>
      <Container>
        <PercentageBar percentage={100} color="blue" />
        <PercentageBar percentage={percentage} color="green" />
        <div className="info-labels">
          <>
            <InfoLabel color="blue">
              <div />
              Previsto
            </InfoLabel>
            <InfoLabel color="green">
              <div />
              Executado
            </InfoLabel>
          </>
        </div>
      </Container>
    </>
  );
}

export default StackedPercentageBar;

import React, { useState, useCallback, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { IoIosArrowForward } from 'react-icons/io';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
} from './styles';

const ImpactAccordeon = ({ title, impact, selected, callback, printing }) => {
  const [active, setActive] = useState(false);
  const [mostra, setMostra] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [indicators, setIndicators] = useState([]);

  const handleToggle = useCallback(() => {
    setActive(!active);
  }, [active]);

  const columns = [
    {
      name: 'Indicador',
      selector: 'indicator_name',
      minWidth: '0px',
    },
    {
      name: 'Fonte',
      selector: 'source',
      minWidth: '0px',
    },
    {
      name: 'Definição / Formula de Calculo',
      selector: 'calculation_method',
      minWidth: '0px',
    },
  ];

  useEffect(() => {
    if (printing) {
      if (active === false) {
        setActive(printing);
      }
    }
  }, [printing, active]);

  const rowSelectCritera = row => {
    if (!!selectedRows) {
      const a = selectedRows.filter(sel => sel.code === impact.code);
      if (a.length) {
        const exists = a[0].indicators.filter(
          ind => ind.indicator_name === row.indicator_name,
        );
        !!exists.length && setActive(true);
        return !!exists.length;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSelect = useCallback(
    data => {
      setSelectedRows(data.selectedRows);
      callback({ ...impact, indicators: data.selectedRows });
    },
    [callback, impact],
  );

  useEffect(() => {
    const execute = async () => {
      await setIndicators(impact.indicators);
      await setSelectedRows(selected);
      setMostra(true);
    };
    execute();
  }, [impact, selected]);

  return (
    <Container onClick={handleToggle}>
      <AccordionItem>
        <InitialContainer active={active} printing={printing}>
          <div className="text-container">
            <div>
              <div className="info-container">
                <h1>{title}</h1>
              </div>
              <button
                type="button"
                className="icon-container"
                onClick={handleToggle}
              >
                <IoIosArrowForward size={25} color="#ffffff" />
              </button>
            </div>
          </div>
        </InitialContainer>
      </AccordionItem>
      <CollapseContainer active={active}>
        {mostra && (
          <DataTable
            selectableRows
            columns={columns}
            data={indicators}
            onSelectedRowsChange={handleSelect}
            selectableRowSelected={rowSelectCritera}
            noDataComponent="Nenhum Indicador Vinculado a esse impacto"
            noHeader
          />
        )}
      </CollapseContainer>
    </Container>
  );
};

export default ImpactAccordeon;

import styled from 'styled-components';
import Image from '../../../assets/metodologia_novo.png';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(234, 234, 234);
  margin-top: 20px;
  padding-bottom: 25px;

  h1 {
    color: #00a9ee;
    font-weight: 700;
    font: normal normal bold 67px/87px IBM Plex Sans;
    margin-bottom: 7px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font: normal normal bold 55px/87px IBM Plex Sans;
    }
  }

  @media only screen and (max-width: 425px) {
    h1 {
      font: normal normal bold 35px/45px IBM Plex Sans;
    }
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content {
    width: 100%;
    max-width: 1140px;
    padding: 24px 0;
    padding-top: 0;

    .title {
      text-align: left;
      font: normal normal 600 42px/55px IBM Plex Sans;
      letter-spacing: 0px;
      color: #242424;
      padding: 30px 0;
    }

    .gobackLink {
      text-align: left;
      text-decoration: underline;
      font: normal normal 600 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
      opacity: 1;
      width: 45px;
      height: 20px;
      padding: 20px 0;
      background: #ffffff;
    }

    .text {
      text-align: left;
      font: normal normal normal 18px/23px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
      width: 100%;
    }


    .submitContainer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          flex-direction: row;
          background: transparent;
          margin-top: 45px;
          padding-bottom: 28px;
          border-bottom: 1px solid #a09f9f;
          border-radius: 0px;

          .forgotButton {
            background: transparent;
            border-bottom: 1px solid black;
            font-size: 11px;
          }

          .submit {
            background: #3aa9ee;
            color: #fff;
            padding: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.3s ease;

            svg {
              margin-right: 5px;
              path {
                fill: #fff;
              }
            }

            &:hover {
              background: #2f82b5;
            }
          }
        }
  }

  .imageWrapper {
    width: 100%;
    height: 300px;
    background-image: url(${Image});
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;

      .titleContent {
        text-align: left;
        font: normal normal bold 55px/71px IBM Plex Sans;
        letter-spacing: 0px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000029;
        height: 348px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  .grayWrapper {
    width: 100%;
    padding-top: 0;
    background: #f8f8f8;

    display: flex;
    flex-direction: column;
    align-items: center;

    .grayContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      .title {
        font-size: 30px;
      }
      .initContainer {
        .text {
          width: 100%;
        }
        .image {
          display: none;
        }
      }
    }

    .imageWrapper {
      .imageContent {
        padding: 0 24px;

        .titleContent {
          font-size: 37px;
        }
      }
    }
  }

  @media only screen and (max-width: 1141px) {
    .content {
      padding: 0 24px;
    }

    .imageWrapper {
      .imageContent {
        padding: 0 24px;
      }
    }

    .grayWrapper {
      .grayContent {
        padding: 0 24px;
      }
    }
  }
`;

export const MethodologyCard = styled.div`
  width: 100%;
  background: #f8f8f8;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 48px;
  display: flex;

  .imageContainer {
    display: block;
    margin-right: 30px;
  }

  .cardContentContainer {
    max-width: 811px;
    h1 {
      text-align: left;
      font: normal normal 600 24px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
    }

    .text {
      margin-top: 15px;
      width: 100%;

      text-align: left;
      font: normal normal normal 18px/23px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }

    .linkContainer {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      align-items: flex-end;

      .link {
        text-align: left;
        text-decoration: underline;
        font: normal normal medium 16px/20px IBM Plex Sans;
        letter-spacing: 0px;
        color: #004e70;
      }

      .button {
        min-width: 360px;
        height: 45px;
        background: #d0d0d0 0% 0% no-repeat padding-box;
        border-radius: 5px;
        text-align: left;
        font: normal normal medium 14px IBM Plex Sans;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: space-between;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;

    .imageContainer {
      display: none;
    }

    .linkContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        margin-bottom: 15px;
      }

      .button {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 445px) {
    .linkContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        min-width: 100% !important;
      }
    }
  }
`;

import styled from 'styled-components';
import Image from '../../../assets/background-image-home.jpg';

export const Container = styled.div`
  .react-select__control {
    background: #fff;
    height: 50px;
    border-radius: 16px;
  }

  .react-select__placeholder,
  .react-select__single-value {
    background: transparent;
    border: 0;
    width: 100%;
    font: normal normal normal 24px/32px IBM Plex Sans;
  }

  .react-select__placeholder {
    color: #aab9bf;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .react-select__loading-indicator {
    font-size: 10px !important;
    svg {
      path {
      }
    }
  }

  .react-select__indicator {
    font-size: 24px;
    svg {
      path {
      }
    }
  }

  .react-select__input {
    font: normal normal normal 24px/32px IBM Plex Sans;
  }

  .react-select__menu {
    .react-select__group-heading {
      color: #495057;
      background: #f8f8f8;
      font-weight: 700;
    }
  }
`;

export const MainHeader = styled.div`
  background: #f8f8f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1140px;
    padding: 24px 0;
    .title {
      font: normal normal 600 42px/55px IBM Plex Sans;

      color: #000000;
      margin-bottom: 17px;
    }
    .subtitle {
      font: normal normal normal 15px/19px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
    }
    form {
      display: flex;
      align-items: baseline;
      width: 100%;
      border-bottom: 1px solid rgb(234, 234, 234);

      & > div {
        margin: 17px 0;
        width: 83%;
      }

      .searchButton {
        border: 0px;
        color: rgb(255, 255, 255);
        background-color: rgb(0, 169, 238) !important;
        font-weight: bold;
        margin-left: 20px;
        border-radius: 16px !important;
        width: 154px;
        height: 48px;
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 1141px) {
    .content {
      padding: 0 24px;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(234, 234, 234);
  margin-top: 20px;
  padding-bottom: 25px;

  h1 {
    color: #00a9ee;
    font-weight: 700;
    font: normal normal bold 67px/87px IBM Plex Sans;
    margin-bottom: 7px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font: normal normal bold 55px/87px IBM Plex Sans;
    }
  }

  @media only screen and (max-width: 425px) {
    h1 {
      font: normal normal bold 35px/45px IBM Plex Sans;
    }
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1140px;
    padding: 24px 0;
    padding-top: 0;
  }

  .imageWrapper {
    width: 100%;
    padding-top: 0;
    background-image: linear-gradient(black, black), url(${Image});
    background-size: contain;
    background-blend-mode: saturation;

    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;
    }
  }

  .grayWrapper {
    width: 100%;
    padding-top: 0;
    background: #f8f8f8;

    display: flex;
    flex-direction: column;
    align-items: center;

    .grayContent {
      width: 100%;
      max-width: 1140px;
      padding: 24px 0;
    }
  }

  @media only screen and (max-width: 1141px) {
    .content {
      padding: 0 24px;
    }

    .imageWrapper {
      .imageContent {
        padding: 0 24px;
      }
    }

    .grayWrapper {
      .grayContent {
        padding: 0 24px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div``;

export const TabButton = styled.button`
  position: relative;
  background: var(--white);
  width: 100%;
  height: 172px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #007bac`};
  border-radius: 20px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;

  .contentContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px;
    img {
      width: 64px;
    }

    .text {
      display: flex;
      align-items: center;

      width: 65%;
      span {
        font: normal normal bold 20px/25px IBM Plex Sans;
        text-align: initial;
        letter-spacing: 0px;
        color: ${props => (props.color ? `${props.color}` : `#007bac`)};
      }
    }

    .iconcontainer {
      background: ${props => (props.color ? `${props.color}` : `#007bac`)};
      border-radius: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;

      svg {
        width: 60px !important;
        path {
          fill: #ffffff;
        }
      }
    }
  }

  span {
    text-align: center;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;
  }

  .indicatorLink {
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
    width: 100%;
    height: 41px;
    background: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      font: normal normal bold 16px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #f8f8f8;
      opacity: 1;
    }
  }
`;

function getWidthGrid(value) {
  if (!value) return;
  let width = (value / 12) * 100;
  return `width: ${width}%`;
}

export const CategoryColumn = styled.div`
  width: 100%;
  padding: 0.25rem;
  min-height: 1px;
  box-sizing: border-box;
  margin-bottom: 25px;
  display: ${props => (props.display ? 'none' : 'block')};

  @media only screen and (min-width: 1136px) {
    ${props => props.small && getWidthGrid(props.small)};
  }

  @media only screen and (min-width: 1136px) {
    ${props => props.medium && getWidthGrid(props.medium)};
  }

  @media only screen and (min-width: 1281px) {
    ${props => props.large && getWidthGrid(props.large)};
  }
`;

import React from 'react';
import DashBoardLayout from '../../../components/DashboardLayout';
import { Column, Row } from '../../../styles/components';
import Arte from '../../../assets/arte9.png';
import { Container } from './styles';

const Dashboard = () => {
  return (
    <Container>
      <DashBoardLayout title="Início">
        <Row>
          <Column large="12" medium="12" small="12">
            <p>
              O PROSEG Federativo busca promover o uso de evidências na
              formulação de políticas públicas na área de segurança pública ao
              facilitar o acesso de estados e municípios a soluções (técnicas e
              financeiras) orientadas a implantação de projetos e políticas com
              impacto comprovadamente positivo sobre a incidência da violência,
              seus condicionantes e fatores de risco, em cinco áreas de
              resultados.
              <br />
              <br />
              Esta plataforma está estruturada de modo a conduzir você em um
              “jornada” que possibilite qualificar sua compreensão a respeito do
              tema da Segurança Pública baseada em evidências.
              <br />
              <br />
              Ao longo do processo você poderá melhor compreender a situação do
              seu estados ou município na área de segurança pública e selecionar
              práticas condizentes com os problemas identificados para compor a
              sua pré-proposta de financiamento.
              <br />
              <br />
              Para mais informações, leia o passo a passo abaixo:
            </p>
          </Column>
        </Row>
        <div className="imgContainer">
          <a
            href="/static/media/manual-usuario-modulo-01.pdf"
            download=""
            className="buttonLink"
          >
            Manual do usuário
          </a>
          <div className="img">
            <img src={Arte} alt="Placeholder" />
          </div>
        </div>
      </DashBoardLayout>
    </Container>
  );
};

export default Dashboard;
